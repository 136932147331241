import React from "react";

import { ESTADO_CIVIL } from "../../settings/enums";
import { Dropdown } from "../../components";

const DropdownEstadoCivil = ({ value, handleChange }) => (
  <Dropdown
    label="*Estado Civil:"
    defaultLabel="Selecione seu estado civil"
    name="civilStatus"
    data={ESTADO_CIVIL}
    value={value}
    handleChange={handleChange}
  />
);

export { DropdownEstadoCivil };
