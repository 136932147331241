

const PRODUCTS = [
    { 
        brand: "Própria",
        cost: 50,
        created_at: "2022-09-07 06:53:30",
        description: "Balm para barba de exemplo BarberTrack.",
        establishment: 5,
        gift: 0,
        id: 1,
        last_quantity_updated_at: null,
        name: "Balm para Barba",
        photo: null,
        quantity: 35,
        quota: "0.00",
        quota_included: 0,
        unit: null,
        updated_at: "2022-09-07 06:53:30"
    },
    { 
        brand: "Artesanal",
        cost: 10,
        created_at: "2022-09-07 06:53:30",
        description: "Cerveja de exemplo BarberTrack.",
        establishment: 5,
        gift: 0,
        id: 2,
        last_quantity_updated_at: null,
        name: "Cerveja",
        photo: null,
        quantity: 100,
        quota: "0.00",
        quota_included: 0,
        unit: null,
        updated_at: "2022-09-07 06:53:30"
    }
];

export { PRODUCTS };
