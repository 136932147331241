import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { BlockPicker } from 'react-color';

import { Actions } from '../../flux';
import { texts } from '../../settings';
import MinhaContaNav from '../../components/MinhaContaNav';
import { userService } from '../../_services'
import { establishmentService } from '../../_services/establishment.service'

class Design extends Component {
	state = {
        loaded: false,
        loadingSubmit: false,
        pickColor: false,
        color: '#ccc',
        image: null,
        photoSource: null,
        remove: false,
        imageBG: null,
        photoSourceBG: null,
        removeBG: false,
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		userService.getMyData().then(data => {
			this.setState({
                color: (data.establishment_data.design) ? data.establishment_data.design.public_background_color : '#ccc',
                photoSource: (data.establishment_data.design) ? data.establishment_data.design.public_banner : null,
                photoSourceBG: (data.establishment_data.design) ? data.establishment_data.design.public_background_image : null,
                loaded: true
			})
		})
    }

	handleFile = (files) => {
		if (files.length > 0) {
			this.setState({
				photoSource: URL.createObjectURL(files[0]),
				image: files[0],
                remove: false,
			})
		} else {
			this.setState({ photoSource: null, image: null, remove: true })
		}
	}

    removeFile = () => {
        this.setState({
            photoSource: null,
            image: null,
            remove: true,
        })
    }

	handleFileBG = (files) => {
		if (files.length > 0) {
			this.setState({
				photoSourceBG: URL.createObjectURL(files[0]),
				imageBG: files[0],
                removeBG: false,
			})
		} else {
			this.setState({ photoSourceBG: null, imageBG: null, removeBG: true })
		}
	}

    removeFileBG = () => {
        this.setState({
            photoSourceBG: null,
            imageBG: null,
            removeBG: true,
        })
    }
    
    saveInfo = () => {
        const {
            color,
            image,
            remove,
            imageBG,
            removeBG
        } = this.state;
        this.setState({loadingSubmit: true});

        let values = {
            public_background_color: color,
        }
        if (image) {
            values.public_banner = image;
        }

        if (remove == true) {
            values.remove_banner = true;
        }
        if (imageBG) {
            values.public_background_image = imageBG;
        }

        if (removeBG == true) {
            values.remove_background_image = true;
        }

        console.log('values', values);

        establishmentService.updateDesign(values).then(res => {
            if (res.success) {
                Actions.showSuccess({ title: 'Visual atualizado!' });
                Actions.userUpdated();
            } else {
                Actions.showError(res.error)
            }
            this.setState({loadingSubmit: false});
        }).catch(err => {
            Actions.showError(err.error);
            this.setState({loadingSubmit: false});
        })
    }
	
	handleChangeComplete = color => this.setState({ color: color.hex, pickColor: false })

    handleChange = color => this.setState({ color: color.hex })

	render = () => {
        const { loaded, loadingSubmit, color, photoSource, photoSourceBG, pickColor } = this.state

        console.log('render photoSource', photoSource)
        console.log('render photoSourceBG', photoSourceBG)

        // console.log('render design', color);

        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Visual - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Visual</h1>
                </div>

				<div className="row">
					<div className="col-md-12">
						<MinhaContaNav active = {'design'} />
					</div>

                    {
                        !loaded &&
                        <div className="col-md-12 mt-20">
                            <p>Carregando ...</p>
                        </div>
                    }

                    {
                        loaded && 
                        <div className="col-md-4 mt-20">
                            <div className="box">
                                <div className="box-header">
                                    <b>Configurações da página customizada</b>
                                </div>

                                <div>
                                    <div className="box-body">

                                        <div className="form-group">
                                            <label className="form-label">Cor de fundo </label>
                                            { !pickColor && color && <div className="color-selected" style={{backgroundColor: color}}></div> }
                                            { !pickColor && <button className="btn btn-sm btn-info" onClick={() => this.setState({pickColor:true})}>Selecionar cor</button> }
                                            { pickColor && <BlockPicker color={ color } onChange={ this.handleChange } onChangeComplete={ this.handleChangeComplete } /> }
                                        </div>

                                        <div className="form-group mt-20">
                                            <label className="form-label">Logo customizado</label>
                                            {
                                                photoSource &&
                                                <div className="img">
                                                    <img src={photoSource} />
                                                </div>
                                            }
                                            <br />

                                            {
                                                photoSource && 
                                                <div className="btn btn-sm btn-secondary mr-10" onClick={this.removeFile}>
                                                    Remover imagem
                                                </div>
                                            }

                                            <label className="btn btn-sm btn-info">
                                                Selecionar imagem
                                                <input type="file" name="photoFile" className="hidden" onChange={e => this.handleFile(e.target.files) } />
                                            </label>
                                        </div>

                                        <div className="form-group mt-20">
                                            <label className="form-label">Fundo customizado</label>
                                            {
                                                photoSourceBG &&
                                                <div className="img">
                                                    <img src={photoSourceBG} />
                                                </div>
                                            }
                                            <br />

                                            {
                                                photoSourceBG && 
                                                <div className="btn btn-sm btn-secondary mr-10" onClick={this.removeFileBG}>
                                                    Remover imagem
                                                </div>
                                            }

                                            <label className="btn btn-sm btn-info">
                                                Selecionar imagem
                                                <input type="file" name="photoFileBG" className="hidden" onChange={e => this.handleFileBG(e.target.files) } />
                                            </label>
                                        </div>

                                    </div>

                                    <div className="box-footer">
                                        {
                                            loadingSubmit &&
                                            <p className="mb-20 text-lg pull-left"><i className="i-spinner loading"></i> Carregando...</p>
                                        }

                                        {
                                            !loadingSubmit &&
                                            <button type="submit" className="btn btn-success" onClick={this.saveInfo}>Salvar alterações</button>
                                        }
                                    </div>
                                </div>
    
                            </div>
    
                        </div>
                    }

				</div>
				
			</div>
    	);
	}
}

export { Design };