import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import moment from 'moment'

import { Actions } from '../../flux'
import { texts } from '../../settings/'
import Avatar from "../../components/Avatar"
import { WhatsappButton } from "../../components/WhatsappButton"
import { numberToReal } from '../../utils/money'
import { differenceBetweenDates } from '../../_helpers/format-date'
import UnitsCustomSelect from "../../components/UnitsCustomSelect"
import { UpgradeButton } from '../../components/UpgradeButton'
import { PeriodFilter } from '../../components/PeriodFilter'

import "./Gerencial.scss";
import { ordersService, unitsService, plansService } from '../../_services';

class Faturamento extends Component {
	state = {
		units: [],
		items: [],
		loading: false,
		userPlan: null,
		loaded: false,
		periodStart: new Date(),
		periodEnd: new Date(),
		moneyAmount: 0,
		cardAmount: 0,
		pixAmount: 0,
		unitSelected: null
	}

	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		// Carrega os dados
		this.loadPlan();
		// this.loadData();
		this.loadUnits();
	}

	loadPlan = () => {
		this.setState({ loading: true }) 
		setTimeout(() => {
			plansService.getMyPlan().then(data => {
				if (Object.keys(data).length > 0) {
					this.setState({
						userPlan: data,
						loaded: true
					})

				} else {
					this.setState({
						loaded: true,
						loading: false,
					})
				}
			})
		}, 50)
	}

	loadData = () => {
		this.setState({ loading: true }) 
		setTimeout(() => {
			const { periodStart, periodEnd, unitSelected, units } = this.state
			let unit = unitSelected !== null ? units[unitSelected].public_string : null

			plansService.getMyPlan().then(data => {
				if (Object.keys(data).length > 0) {
					this.setState({
						userPlan: data,
						loaded: true
					})


					let isFree = (data && data.plan_details.slug === 'free');
					if (!isFree) {
						ordersService.getOrders(periodStart, periodEnd, unit).then(data => {
							this.parseInfos(data);
							this.setState({
								items: data,
								loading: false
							})
						})
					}

				} else {
					this.setState({
						loaded: true,
						loading: false,
					})
				}
			})
		}, 50)
	}

	loadUnits = () => {
		unitsService.myUnits().then(res => {
			// console.log('myUnits', res)
			this.setState({ units: res })
		})
	}

	changeStart = date => {
		this.setState({ periodStart: date });
		this.loadData();
	}

	changeEnd = date => {
		this.setState({ periodEnd: date });
		this.loadData();
	}

	changeDates = (start, end) => {
		this.setState({
			periodStart: start,
			periodEnd: end
		});
		this.loadData();
	}

	parseInfos = data => {
		let card = 0;
		let pix = 0;
		let money = 0;
		data.map(item => {
			item.payments.map(payment => {
				if (payment.method == 'money') {
					money += payment.amount;
				}
				if (payment.method == 'card') {
					card += payment.amount;
				}
				if (payment.method == 'pix') {
					pix += payment.amount;
				}
			})
		})
		this.setState({
			pixAmount: pix,
			cardAmount: card,
			moneyAmount: money,
		})
	}

	sumTotal = items => {
		return (items.length) ? items.sum('total') : 0; 
	}

	averageTotal = items => {
		return (items.length) ? items.sum('total') / items.length : 0; 
	}

	listServices = services => {
		let serv = [];
		services.map(obj => {
			serv.push(`${obj.name} ${ numberToReal(obj.price) }`);
		})
		return serv.join(' & ');
	}

	sumServices = services => {
		let total = 0;
		services.map(obj => {
			total += obj.price;
		})
		return total;
	}

	selectUnit = index => {
		this.setState({ unitSelected: index })

		this.loadData();
	}

	renderPayments = items => {
		return (
			<div>
				{items.map((pay, indexp) => (
					<div key={indexp}>
						{
							pay.method === 'money' && <p><i className="payments payments-xs payments-money"></i> Dinheiro</p>
						}
						{
							pay.method === 'pix' && <p><i className="payments payments-xs payments-pix"></i> Pix</p>
						}
						{
							pay.method === 'card' && <p><i className="payments payments-xs payments-generic-card"></i> Cartão</p>
						}
						{/* pay.method === 'money'
							? <i key={indexp} className="payments payments-xs payments-money"></i> 
							: (pay.method === 'pix')
								? <i key={indexp} className="payments payments-xs payments-pix"></i>
								: <i key={indexp} className="payments payments-xs payments-generic-card"></i> */}
					</div>
				))}
			</div>
		);
	}

	renderList = items => {
		return (
			<div className="items">
                {
                    items.length > 0 &&
					<div className="table-responsive">
						<table className="table table-bordered">
							<tbody>
								<tr>
									<th>ID</th>
									<th>Unidade</th>
									<th>Cliente</th>
									<th>Profissional</th>
									<th>Data</th>
									<th>Serviços</th>
									<th>Total</th>
									<th>Método de pagamento</th>
									{/* <th>Status</th> */}
								</tr>
                                {items.map((item, index) => (
                                    <tr key={index}>
										<td>{item.id}</td>
										<td>{item.unit_data.name}</td>
										<td>
											{(item.client_data && item.client_data.status == 0) && `Cliente excluído` }
											{(item.client_data && item.client_data.status == 1) && <Avatar data={item.client_data} size="xs" /> }
											{(item.client_data && item.client_data.status == 1) && 
												<WhatsappButton 
													phonenumber={item.client_data.cellphone}
													size='sm'
													marginTop={0} 
													text={``} /> }
										</td>
										<td>{item.schedule_data.professional_data && <Avatar data={item.schedule_data.professional_data} size="xs" /> }</td>
                                        <td>{moment(item.created_at).format("DD.MM.YY - HH:mm")}</td>
                                        <td>
											{item.schedule_data && item.schedule_data.services.map((service, i) => `${i > 0 ? ', ' : ''} ${service.name} (${numberToReal(service.price)})`)}
										</td>
                                        <td>{numberToReal(item.total)}</td>
										<td>
											{ this.renderPayments(item.payments) }
											{/* { item.payments.map((pay, indexp) => (
												pay.method === 'money'
													? <i key={indexp} className="payments payments-xs payments-money"></i> 
													: (pay.method === 'pix')
														? <i key={indexp} className="payments payments-xs payments-pix"></i>
														: <i key={indexp} className="payments payments-xs payments-generic-card"></i>
											)) } */}
										</td>
										{/* <td>
											{item.status_data.name == 'finalizado' && <span class={`status-icon bg-warning`}></span>} 
											{item.status_data.name == 'faturado' && <span class={`status-icon bg-success`}></span>} 
											{item.status_data.name == 'cancelado' && <span class={`status-icon bg-danger`}></span>} 
											{item.status_data.name == 'aguardando-finalizacao' && <span class={`status-icon bg-secondary`}></span>} 
											{item.status_data.description}
										</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum item</p> 
                }
			</div>
		);
	}

	render = () => {
		const { userPlan, items, loading, periodStart, periodEnd, cardAmount, pixAmount, moneyAmount, units, unitSelected } = this.state
		const dayInterval = differenceBetweenDates(periodStart, periodEnd);
		const faturados = items.filter(obj => obj.status_data.name == 'faturado');
		const finalizados = items.filter(obj => obj.status_data.name == 'finalizado');
		const cancelados = items.filter(obj => obj.status_data.name == 'cancelado');

		let isFree = (userPlan && userPlan.plan_details.slug === 'free');
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Faturamento - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Faturamento</h1>
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / <Link to="/gerencial">Gerencial</Link> / Faturamento</li>
							</ol>
						</nav>
					</div>
				</div>
				

				{ 
					userPlan && !isFree &&
					<div className="row mb-20">
						<div className="col-md-12">
							<p className="mb-10">Unidade:</p>
						</div>
						<div className="col-md-4">
							{
								units.length > 0 &&
								<UnitsCustomSelect
									showAll
									items = {units}
									selectedText = "Visualizando faturamento"
									onSelectUnit = {i => this.selectUnit(i)}
									selected = {unitSelected} />
							}
						</div>
					</div>
				}
				
				{ 
					userPlan && !isFree &&
					<PeriodFilter
						initStart={new Date()}
						initEnd={new Date()}
						onChangeStart={date => this.changeStart(date)}
						onChangeEnd={date => this.changeEnd(date)}
						onChangeDates={(start, end) => this.changeDates(start, end)}
						/>
				}

                {loading && <div className="body-loading"><i className="i-spinner loading super-loading mb-15"></i><br />Carregando</div>}

				{
					!loading && 
					<div className="row">
						<div className="col-md-12">
							<p className="mb-10">Resumo:</p>
						</div>
					  <div className="col-md-6 col-lg-3">
						<div className="info-box">
						  <div className="info-box-content">
							<div className="info-box-text">
								<span className="info-box-number">
									{numberToReal(this.sumTotal(faturados))}
								</span>
								Total faturado
								<br />
								<br />
								<small>
									<h6 className="mb-0">{numberToReal(this.averageTotal(faturados))}</h6>
									Ticket médio
								</small>
							</div>
						  </div>
						</div>
					  </div>
					  
					  <div className="col-md-6 col-lg-3">
						<div className="info-box">
						  <div className="info-box-content">
							<div className="info-box-text">
								<span className="info-box-number">
									{numberToReal(moneyAmount)} 
								</span>
								Recebido em dinheiro
							</div>
							{/* <div className="info-box-icon bg-success">
								<i className="fe fe-dollar-sign"></i>
							</div> */}
							<div className="info-box-icon">
								<i className="payments payments-money"></i>
							</div>
						  </div>
						</div>
					  </div>

					  <div className="col-md-6 col-lg-3">
						<div className="info-box">
						  <div className="info-box-content">
							<div className="info-box-text">
								<span className="info-box-number">
									{numberToReal(cardAmount)} 
								</span>
								Recebido no cartão
							</div>
							{/* <div className="info-box-icon bg-success">
							  	<i className="fe fe-credit-card"></i>
							</div> */}
							<div className="info-box-icon">
								<i className="payments payments-generic-card"></i>
							</div>
						  </div>
						</div>
					  </div>

					  <div className="col-md-6 col-lg-3">
						<div className="info-box">
						  <div className="info-box-content">
							<div className="info-box-text">
								<span className="info-box-number">
									{numberToReal(pixAmount)} 
								</span>
								Recebido no Pix
							</div>
							{/* <div className="info-box-icon bg-success">
							  	<i className="fe fe-credit-card"></i>
							</div> */}
							<div className="info-box-icon">
								<i className="payments payments-pix"></i>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				}

				{
					!loading &&
					<div className="box">
						<div className="box-header">
							<h3>Entradas ({items.length})</h3>
							<div className="btn-actions">
								{/* {
									dayInterval == 0 &&
									<button className="btn btn-success mr-10"><i className="fe fe-check-circle mr-5"></i>Fechar dia</button>
								} */}
								{ 
									(userPlan && !isFree) &&
									<button className="btn btn-info" onClick={this.loadData}><i className="fe fe-refresh-cw mr-5"></i>Atualizar</button>
								}
							</div>
						</div>
						<div className="box-body">

							{
								(!userPlan || isFree) && <UpgradeButton hasPlan={isFree} />
							}

							{ (userPlan && !isFree) && this.renderList(items) }

						</div>
					</div>
				}
				
			</div>
    	);
	}
}

export { Faturamento };