import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { clientsService } from '../../_services/clients.service'
import { texts, images } from '../../settings/'
import { imageApi } from '../../_helpers/imageApi'

import "./Clientes.scss";
import { getInitials } from '../../_helpers/clean-string';

class Clientes extends Component {
	state = {
		clients: [],
		loaded: false,
		filterText: ''
	}

	changeValue = v => {
		this.setState({filterText: v.target.value})

		if (v.target.value !== '') {
			this.filter();
		} else {
			this.getAll();
		}
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		clientsService.myClients().then(data => {
			this.setState({
				clients: data,
				loaded: true
			})
			console.log('get myClients', data)
		})
	}

	filter = () => {
		this.setState({ loaded: false })
		const { filterText } = this.state
		clientsService.findByName(filterText).then(res => {
			console.log('findByName res', res)
			if (res.success) {
				// console.log('My clients', res.data)
				this.setState({
					clients: res.data,
					loaded: true
				})
			}
		})
		.catch(err => {
			// Actions.showError('Ocorreu um erro desconhecido');
			console.log('Ocorreu um erro desconhecido');
		})
	}

	myClients = items => {
		return (
			<div className="clients-list row">
				{items.map(item => (
					<Link className="user-panel col-12 col-md-4" key={item.id} to={`/clientes/${item.id}`}>
						<div className="user-panel-image">
                            {/* {
                                item.photo !== '' && item.photo !== null &&
                                <div className="avatar avatar-sm">
                                    <img src={imageApi(item.photo)} />
                                </div>
                            }
                            {
                                item.photo == '' || item.photo == null &&
                                <div className="avatar avatar-secondary avatar-sm">{getInitials(item.name)}</div>
                            } */}
							<div className="avatar avatar-secondary avatar-sm">{getInitials(item.name)}</div>
						</div>
						<div className="user-panel-info">
							<p>{item.name}</p>
							<small className="text-black-50">{item.email}</small>
						</div>
						<div className="user-panel-actions">
							{/* <a href="#"><i className="fas fa-ellipsis-v"></i></a> */}
						</div>
					</Link>
				))}
			</div>
		);
	}

	clearFilter = () => {
		this.setState({filterText: ''});
		this.getAll();
	}

	render = () => {
		const { clients, loaded, filterText } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Meus clientes - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Meus clientes</h1>
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / Clientes</li>
							</ol>
						</nav>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-md-6 ">
						<label>Filtrar:</label>

						<div className="input-group">
							<input value={filterText} placeholder="Busque pelo nome, celular ou e-mail" onChange={e => this.changeValue(e)} className="form-control" />
							{
								filterText !== '' &&
								<div className="input-group-append">
									<button className="btn btn-outline-secondary" onClick={() => this.clearFilter()}>Limpar</button>
								</div>
							}
						</div>
					</div>
					<div className="col-12 col-md-6 text-right">
						<Link to="/clientes/criar" className="btn btn-info pull-right">Cadastrar cliente</Link>
					</div>
				</div>
				
                <div className="content-header">
                    {/* <Link to="/plano/migrar" className="btn btn-primary">Migrar plano</Link> */}
                </div>

				<div className="row">
					<div className="col-lg-12">
						<div className="box">
							<div className="box-header">
								<h3>Todos ({clients.length})</h3>
							</div>
							<div className="box-body">

								{ loaded && this.myClients(clients) }

								{
									!loaded &&
									<div className="row" style={{ height: 200, paddingTop: 80 }}>
										<div className="col-md-12 text-center">
											<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
										</div>
									</div>
								}

								{
									loaded && clients.length === 0 &&
									<div className="row" style={{ height: 200, paddingTop: 80 }}>
										<div className="col-md-12 text-center">
											<p className="mb-20 text-lg">Nenhum cadastro</p>
										</div>
									</div>
								}
								
							</div>
							{/* <div className="box-footer">
								<a href="#" className="text-secondary text-sm d-block">Ver mais <i className="fas fa-chevron-down"></i></a>
							</div> */}
						</div>
					</div>
				</div>
				
			</div>
    	);
	}
}

export { Clientes };