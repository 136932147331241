export function numberToReal(value) {
  var n = (typeof value === 'number') ? value : parseFloat(value);
  n = n.toFixed(2).toString();
  var numero = n.split('.');
  numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
  return numero.join(',');
}

export function numberToDollar(value) {
  var n = (typeof value === 'number') ? value : parseFloat(value);
  n = n.toFixed(2).toString();
  var numero = n.split('.');
  numero[0] = "$ " + numero[0].split(/(?=(?:...)*$)/).join(',');
  return numero.join('.');
}


export function sumArray(items, field) {
  if (items.length > 0) {
    let total = 0;

    items.map((item, index) => {
      total += parseFloat(item[field]);
    })

    return total;
  } else {
    return 0;
  }
}


export function sumProductsByQuantity(items) {
  if (items.length > 0) {
    let total = 0;

    items.map((item, index) => {
      let quantity = (item.quantitySelected) ? item.quantitySelected : 1;
      // total += (!item.gift) ? item.cost * quantity : 0;
      total += item.cost * quantity;
    })

    return total;
  } else {
    return 0;
  }
}


export function sumGiftsByQuantity(items) {
  if (items.length > 0) {
    let total = 0;

    items.map((item, index) => {
      let quantity = (item.quantitySelected) ? item.quantitySelected : 1;
      total += (item.gift) ? item.cost * quantity : 0;
    })

    return total;
  } else {
    return 0;
  }
}