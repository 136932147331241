import React from "react";
import { Link } from 'react-router-dom';

import "./UpgradeButton.scss";

const UpgradeButton = ({
  unit,
  hasPlan,
  block,
  success,
  label,
  small
}) => {
    
    const btnClasses = [
        'btn',
        'btn-upgrade',
        (small) ? 'btn-small' : 'btn-large',
        (success) ? 'btn-green' : '',
        (block) ? 'btn-block' : ''
    ]

    let link =  `${(unit)?`/unidade/${unit}`:''}/plano/migrar`;
    return (
        <Link to={link} className={btnClasses.join(' ')}>
            {
                !small && 
                <div className="c-ic">
                    {!hasPlan && <i className="fa fa-lock"></i>}
                    {hasPlan && <i className="fa fa-star"></i>}
                </div>
            }

            {
                !small && 
                <div className="c-txt">
                    {!hasPlan && <b className="tt">Assine agora</b>}
                    {
                        !hasPlan &&
                        <p className="tx">
                            { label && label }
                            { !label && 'e tenha acesso total ao BarberTrack' }
                        </p>
                    }

                    {hasPlan && <b className="tt">Mude de plano</b>}
                    {
                        hasPlan && 
                        <p className="tx">
                            { label && label }
                            { !label && 'e eleve o nível da sua barbearia' }
                        </p>
                    }
                </div>
            }

            { small && <i className="fa fa-lock mr-10"></i>}
            { small && <span>Assine agora</span>}

        </Link>
    );
};

export { UpgradeButton };