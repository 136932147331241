import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { texts } from '../../settings/'

class MinhaConta extends Component {
	state = {
		userPlan: null,
		user: null,
		loaded: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();
	}

	render = () => {
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Minha Conta - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Minha Conta</h1>
                </div>

                <div className="row">
                    <div className="col-md-4">

                        <div className="list-group">
                            <Link to="/minha-conta/perfil" className="list-group-item list-group-item-action">
                                <i className="fe fe-user mr-10"></i>
                                Editar perfil
                            </Link>
                            <Link to="/minha-conta/company" className="list-group-item list-group-item-action">
                                <i className="fe fe-globe mr-10"></i>
                                Editar empresa
                            </Link>
                            <Link to="/minha-conta/usuarios" className={`list-group-item list-group-item-action`}>
                                <i className="fe fe-user mr-10"></i>
                                Usuários
                            </Link>
                            <Link to="/minha-conta/enderecos" className="list-group-item list-group-item-action">
                                <i className="fe fe-home mr-10"></i>
                                Endereços
                            </Link>
                            <Link to="/minha-conta/design" className="list-group-item list-group-item-action">
                                <i className="fe fe-image mr-10"></i>
                                Visual
                            </Link>
                            <Link to="/plano" className="list-group-item list-group-item-action">
                                <i className="fe fe-star mr-10"></i>
                                Planos
                            </Link>
                        </div>

                    </div>
                </div>
				
			</div>
    	);
	}
}

export { MinhaConta };