import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { imageApi } from '../../_helpers/imageApi'
import { userService } from '../../_services/user.service'
import { productsService } from '../../_services/products.service'

import MoneyInput from '../../components/MaskedInput/money'

const form = yup.object().shape({
	name: yup
		.string()
		.required('Campo obrigatório'),

	description: yup
		.string()
		.required('Campo obrigatório'),

	cost: yup
		.string()
		.required('Campo obrigatório'),

	brand: yup
		.string()
});

class EditarProduto extends Component {
	state = {
        produto: null,
		user: null,
		loading: false,
		success: false,
		photoSource: null,
		file: null,
		checked: false,
        percent: 0,
        cost: 0
	}

	componentDidMount = () => {
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

		this.setState({ loading: true })
        
		const { match: { params } } = this.props;
		productsService.getOne(params.id).then(data => {
			console.log('productsService', data)
			this.setState({
				produto: data,
                loading: false,
                cost: data.cost
            })
            
            if (data.quota_included == true) {
                this.setState({
                    checked: true,
                    percent: data.quota
                })
            }
		})
	}

	saveInfo = values => {
		const { file, checked, percent, cost, produto } = this.state

        values.cost 			= parseFloat(cost);
		values.quota_included	= (checked) ? 1 : 0;
        values.quota 			= (checked) ? percent : 0;
        
        if (file) {
            values.photoFile = file;
        }

		productsService.update(produto.id, values).then(res => {
			Actions.showSuccess({
				title: 'Produto alterado!',
			})
			this.setState({ success: true })
		}).catch(err => {
			Actions.showError(err.error)
		})
    }

	handleFile = (files) => {
		if (files.length > 0) {
			this.setState({
				photoSource: URL.createObjectURL(files[0]),
				file: files[0]
			})
		} else {
			this.setState({photoSource:null, file: null})
		}
	}

	render = () => {
		const { loading, user, produto, success, checked, photoSource, percent, cost } = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Editar Produto - { texts.title }</title>
	            </Helmet>

				{
					success && 
					<Redirect to={{
                        pathname: `/produtos`,
                        state: { from: this.props.location }
                        }}
                    />
				}

	    		<div className="products">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">Dashboard</Link> / <Link to="/produtos">Produtos</Link> / Editar Produto
								</li>
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-6">
							{
								produto &&
                                <h2 className="content-title">{produto.name}</h2>
                            }

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading && produto &&
								<div className="box">
									<div className="box-header">
										<b>Informações do produto</b><br />
										{/* <span>{unit.establishment_data.name} - <b>{unit.name}</b></span> */}
									</div>

									<Formik
										initialValues={{
											name: produto.name,
											description: produto.description,
											cost: produto.cost,
											brand: produto.brand,
										}}
										validationSchema={form}
										onSubmit={values => this.saveInfo(values)}
										>
										{({ errors, touched, setFieldValue, isValid, fields }) => (
											<Form>
												<div className="box-body">

													<div className="form-group row">
														<label className="form-label col-3 text-right">Foto</label>
														<div className="col-3">
                                                            {
                                                                produto.photo && !photoSource &&
                                                                <div className="img img-square img-square-lg img-rounded">
                                                                    <img src={imageApi(produto.photo)} />
                                                                </div>
                                                            }

															{
																photoSource &&
                                                                <div className="img img-square img-square-lg img-rounded">
                                                                    <img src={photoSource} />
                                                                </div>
															}
                                                        </div>

                                                        <div className="col-4">
                                                            <p>Escolha uma nova foto:</p>
															<input type="file" name="photoFile" className="form-control" onChange={e => this.handleFile(e.target.files) }  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="photoFile" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3 text-right">Nome <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="name" className="form-control" placeholder="Ex: Óleo para barba"  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3 text-right">Descrição <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="description" component="textarea" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="description" /></small>
														</div>
													</div>


													<div className="form-group row">
														<label className="form-label col-3 text-right">Marca</label>
														<div className="col-9">
															<Field name="brand" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="brand" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3 text-right">Preço <sup className="text-danger">*</sup></label>
														<div className="col-4">
                                                            <div className="input-group">
                                                                <div className="input-group-icon">R$</div>
																<MoneyInput value={cost} onChange={e => this.setState({cost: e.value})} />
                                                            </div>
															<small className="text-danger"><ErrorMessage className="text-danger" name="cost" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3"></label>
														<div className="col-9">
															<label className="form-checkbox-custom">
																<input checked={(checked) ? "checked" : ""} onChange={() => this.setState({checked:!checked})} type="checkbox" />
																<span className="form-label">Produto entra na comissão do profissional?</span>
															</label>
														</div>
													</div>

													{
														checked &&
														<div className="form-group row">
															<label className="form-label col-3">Cota para o produto</label>
															<div className="col-4">
															<div className="input-group">
																<input
																	type="number"
																	className="form-control"
																	value={percent}
																	min="0"
																	onChange={e => this.setState({percent: e.target.value})} />
																<div className="input-group-icon">%</div>
															</div>
															</div>
														</div>
													}

												</div>

												<div className="box-footer">
													<button type="submit" className="btn btn-success pull-right">Salvar</button>
												</div>
											</Form>
										)}
									</Formik>

								</div>
							}

						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { EditarProduto };