import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import moment from 'moment'

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { numberToReal } from '../../utils/money';
import { maskPhone } from '../../utils/masks';
import { UpgradeButton } from '../../components/UpgradeButton';
import { PeriodFilter } from '../../components/PeriodFilter'

import "./Gerencial.scss";
import { plansService, professionalsService } from '../../_services';

class Comissoes extends Component {
	state = {
		items: [],
		userPlan: null,
		periodStart: null,
		periodEnd: null,
		loading: false,
		loaded: false,
	}

	constructor(props) {
		super(props);
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		// Carrega os dados
		this.loadData();
	}

	loadData = () => {
		this.setState({ loading: true }) 
		setTimeout(() => {
			const { periodStart, periodEnd } = this.state
			plansService.getMyPlan().then(data => {
				if (Object.keys(data).length > 0) {
					this.setState({
						userPlan: data,
						loaded: true
					})

					let isFree = (data && data.plan_details.slug === 'free');
					if (!isFree) {
						professionalsService.getComissions(periodStart, periodEnd).then(comissions => {
							this.setState({
								items: comissions.data,
								loaded: comissions.success,
								loading: false
							})
						});
					}

				} else {
					this.setState({
						loaded: true,
						loading: false,
					})
				}
			})
		}, 50)
	}

	changeStart = date => {
		this.setState({ periodStart:date });
		this.loadData();
	}

	changeEnd = date => {
		this.setState({ periodEnd:date });
		this.loadData();
	}

	changeDates = (start, end) => {
		this.setState({
			periodStart: start,
			periodEnd: end
		});
		this.loadData();
	}

	renderCards = items => {
		const { periodStart, periodEnd } = this.state
		return (
			<div className="items">
                {
                    items.length > 0 &&
					<div className="table-responsive">
						{items.map((professional, index) => (
							<div className="box" key={index}>
								<div className="box-header">
									<h3>{professional.name} <small>{professional.role} {maskPhone(professional.cellphone)}</small></h3>
								</div>
								<div className="box-body">										
									<div className="row mb-20">
										{
											(!periodStart && !periodEnd) && 
											<div className="col-12 col-md-4">
												<div className="info-box">
													<div className="info-box-content">
														<div className="info-box-text">
															<span className="info-box-number">{numberToReal(professional.comissions.today)}</span>
															Hoje
														</div>
													</div>
												</div>
											</div>
										}
										{
											(!periodStart && !periodEnd) && 
											<div className="col-12 col-md-4">
												<div className="info-box">
													<div className="info-box-content">
														<div className="info-box-text">
															<span className="info-box-number">{numberToReal(professional.comissions.week)}</span>
															Esta semana
														</div>
													</div>
												</div>
											</div>
										}
										{
											(!periodStart && !periodEnd) && 
											<div className="col-12 col-md-4">
												<div className="info-box">
													<div className="info-box-content">
														<div className="info-box-text">
															<span className="info-box-number">{numberToReal(professional.comissions.month)}</span>
															Este mês
														</div>
													</div>
												</div>
											</div>
										}
										{
											(periodStart && periodEnd) && 
											<div className="col-12 col-md-4">
												<div className="info-box">
													<div className="info-box-content">
														<div className="info-box-text">
															<span className="info-box-number">{numberToReal(professional.comissions.filtered)}</span>
															{moment(periodStart).format('DD/MM/YYYY')} à {moment(periodEnd).format('DD/MM/YYYY')}
														</div>
													</div>
												</div>
											</div>
										}
									</div>
										
								</div>
							</div>
						))}
					</div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum item</p> 
                }
			</div>
		);
	}

	render = () => {
		const { userPlan, items, loading } = this.state
		let isFree = (userPlan && userPlan.plan_details.slug === 'free')

        return (
			<div className="views container-fluid">
				<Helmet>
	                <title>Comissões - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Comissões</h1>
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / <Link to="/gerencial">Gerencial</Link> / Comissões</li>
							</ol>
						</nav>
					</div>
				</div>
				
				{ 
					userPlan && !isFree &&
					<PeriodFilter
						onChangeStart={date => this.changeStart(date)}
						onChangeEnd={date => this.changeEnd(date)}
						onChangeDates={(start, end) => this.changeDates(start, end)}
						/>
				}

                {loading && <div className="body-loading"><i className="i-spinner loading super-loading mb-15"></i><br />Carregando</div>}

				{
					!loading &&
					<div>
						{/* {
							(userPlan && !isFree) &&
							<button className="btn btn-info" onClick={this.loadData}><i className="fe fe-refresh-cw mr-5"></i>Atualizar</button>
						} */}

						{ (!userPlan || isFree) && <UpgradeButton hasPlan={isFree} /> }

						{ (userPlan && !isFree) && this.renderCards(items) }
					</div>
				}
			</div>
    	);
	}
}

export { Comissoes };