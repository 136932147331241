const ESTADO_CIVIL = [
  {
    label: "Solteiro",
    value: "S"
  },
  {
    label: "Casado",
    value: "C"
  },
  {
    label: "Divorciado",
    value: "D"
  },
  {
    label: "Separado",
    value: "SP"
  },
  {
    label: "Viúvo",
    value: "V"
  }
];

export { ESTADO_CIVIL };
