import React from "react";
import { Link } from 'react-router-dom';

import './Sidebar.scss'

// Utils
import { Actions, Events, Dispatcher } from '../../flux'
import { images } from '../../settings'
import { allRoles, partnerRoles, professionalRoles } from '../../settings/roles'

// Helpers & Services
import { unitsService, userService } from '../../_services'

class Sidebar extends React.PureComponent {
	state = {
		loaded: false, 
		menuOpened: (window.innerWidth >= 768) ? true : false,
		panelSelected: 0,
		panels: ['Painel geral', 'Painel Sumaré', 'Painel Vinhedo'],
		panelMenuOpened: false,
		panelSettings: false,
		user: {},

		units: [],
		unitsLoaded: false
	}

	componentWillMount = () => {
		Dispatcher.on(Events.TOGGLE_MENU, this.toggleMenu.bind(this) );
		Dispatcher.on(Events.USER_UPDATED, this.userUpdate.bind(this) );
	}

	componentWillUnmount = () => {
		Dispatcher.removeListener(Events.TOGGLE_MENU, this.toggleMenu );
		Dispatcher.removeListener(Events.USER_UPDATED, this.userUpdate );
	}

	componentDidMount = () => {
		this.getMyData();
		this.getUnits();
	}

	userUpdate = () => {
		this.getMyData();
	}

	getMyData = () => {
		userService.getMyData().then(data => {
			if (data.terms === 0) Actions.acceptTermsShow();

			this.setState({
				user: data,
				loaded: true
			})
		})
	}

	getUnits = () => {
		unitsService.myUnits().then(res => {
			if (res.length > 0) {
				this.setState({
					units: res,
					unitsLoaded: true
				})
			}
		})
	}

	toggleMenu = () => {
		const { menuOpened } = this.state
		this.setState({menuOpened:!menuOpened})
	}

	toggleMenuPanels = () => {
		const { panelMenuOpened } = this.state
		this.setState({panelMenuOpened:!panelMenuOpened})
	}

	toggleSettings = () => {
		const { panelSettings } = this.state
		this.setState({panelSettings:!panelSettings})
	}

	toggleMenuMobile = () => {
		if (window.innerWidth < 768) {
			Actions.toggleMenu()
		}
	}

	changePanel = panelId => {
		const { units } = this.state
		const index = units.map(obj => obj.unity_id).indexOf(panelId);
		this.setState({panelSelected:index})
		this.toggleMenuPanels();
	}

	resendActivationEmail = () => {
		userService.sendVerificationEmail().then(data => {
			console.log('sendVerificationEmail', data);
		})
	}

	render = () => {
		const {
			menuOpened,
			panelMenuOpened, 
			panelSettings, 
			user,
			loaded
		} = this.state

		// console.log('render sidebar', user)

		const sidebarStyle = { display: 'block'};
		
		return (
			<aside className = {(menuOpened) ? "sidebar" : "sidebar sidebar-collapse"} style = {sidebarStyle}>
				<Link to="/" className="logo">
					<img src={ images.logo } />
					<b>{user.role_label}</b>
					<span>BarberTrack</span>
				</Link>
				
				<nav>

					{
						loaded &&
						!user.establishment_data && 
						<ul className="sidebar-list">

							{/* {
								user.status === 0 &&
								<li>
									<div className="alert mt-10">
										<h4> <i className="fe fe-alert-octagon"></i> Alerta</h4>
										Verifique seu e-mail e confirme sua conta.
										<br/>
										<div className="btn btn-sm btn-light mt-10" onClick={() => this.resendActivationEmail()}>Reenviar e-mail</div>
									</div>
								</li>
							} */}
							
							<li>
								<Link to="/" className="sidebar-list-item">
									<i className="fe fe-home"></i> <span>Início</span>
								</Link>
							</li>
						</ul>
					}

					{
						loaded &&
						user.establishment_data && 
						<ul className="sidebar-list" onClick={() => this.toggleMenuMobile()}>

							{/* {
								user.status === 0 &&
								<li>
									<div className="alert mt-10">
										<h4> <i className="fe fe-alert-octagon"></i> Alerta</h4>
										Verifique seu e-mail e confirme sua conta.
										<br/>
										<div className="btn btn-sm btn-light mt-10" onClick={() => this.resendActivationEmail()}>Reenviar e-mail</div>
									</div>
								</li>
							} */}

							<li className="divisor" style={{height: '30px'}}></li>

							{
								user.establishment_data.units.length > 0 && 
								<li>
									<div className="sidebar-list-item sidebar-list-item--rounded sidebar-list-item--success pointer" onClick={() => Actions.showModalSchedule()}>
										<i className="fe fe-plus"></i> <span>Novo atendimento</span>
									</div>
								</li>
							}
							<li className="divisor"></li>

							{
								user.establishment_data.units.length === 0 && 
								<li>
									<Link to="/" className="sidebar-list-item">
										<i className="fe fe-home"></i> 
										<span>Início</span>
									</Link>
								</li>
							}

							{
								user.establishment_data.units.length > 0 && 
								<li className="sidebar-list-item ">
									<i className="fe fe-calendar"></i> 
									<span>Visualize a agenda</span> 
								</li>
							}

							{
								user.establishment_data.units.length > 0 && 
								<li>
									<ul style={{display: 'block'}}>
										<li>
											<Link to="/agenda" className="sidebar-list-item">
												<span>{(user.role_label === 'Profissional') ? 'Minha agenda' : 'Agenda'}</span>
											</Link>
										</li>
										<li>
											<Link to="/" className="sidebar-list-item">
												<span>Página customizada</span>
											</Link>
										</li>
									</ul>
								</li>
							}

							<li className="divisor"></li>

							{
								partnerRoles.includes(user.role_label) && 
								<li className="sidebar-list-item ">
									<i className="fe fe-scissors"></i> 
									<span>Sua Barbearia</span> 
								</li>
							}

							{
								partnerRoles.includes(user.role_label) && 
								<li>
									<ul style={{display: 'block'}}>
										<li>
											<Link to="/unidades" className="sidebar-list-item">
												<span>Unidades</span>
											</Link>
										</li>
										<li>
											<Link to="/unidades" className="sidebar-list-item">
												<span>Profissionais</span>
											</Link>
										</li>
										<li>
											<Link to="/unidades" className="sidebar-list-item">
												<span>Serviços</span>
											</Link>
										</li>
										<li>
											<Link to="/clientes" className="sidebar-list-item">
												<span>Clientes</span>
											</Link>
										</li>
										<li>
											<Link to="/produtos" className="sidebar-list-item">
												<span>Estoque</span>
											</Link>
										</li>
									</ul>
								</li>
							}

							<li className="divisor"></li>

							{
								allRoles.includes(user.role_label) && 
								<li className="sidebar-list-item">
									<i className="fe fe-dollar-sign"></i> 
									<span>Financeiro</span> 
								</li>
							}

							{
								partnerRoles.includes(user.role_label) && 
								<li>
									<ul style={{display: 'block'}}>
										<li>
											<Link to="/gerencial" className="sidebar-list-item">
												<span>Relatórios</span>
											</Link>
										</li>
										{/* <li>
											<Link to="/minha-conta/bank" className="sidebar-list-item">
												<span>BarberTrack PAY</span>
											</Link>
										</li> */}
									</ul>
								</li>
							}

							{
								professionalRoles.includes(user.role_label) && 
								<li>
									<ul style={{display: 'block'}}>
										<li>
											<Link to="/gerencial/comissoes" className="sidebar-list-item">
												<span>Minhas comissões</span>
											</Link>
										</li>
										{/* <li>
											<Link to="/minha-conta/bank" className="sidebar-list-item">
												<span>BarberTrack PAY</span>
											</Link>
										</li> */}
									</ul>
								</li>
							}

							<li className="divisor"></li>

							{
								allRoles.includes(user.role_label) && 
								<li className="sidebar-list-item ">
									<i className="fe fe-settings"></i> 
									<span>Configurações</span> 
								</li>
							}

							{
								allRoles.includes(user.role_label) && 
								<li>
									<ul style={{display: 'block'}}>
										<li>
											<Link to="/minha-conta" className="sidebar-list-item">
												<span>Minha conta</span>
											</Link>
										</li>
									</ul>
								</li>
							}

							{/* <li className="divisor"></li>

							{
								partnerRoles.includes(user.role_label) && 
								<li className="sidebar-list-item ">
									<i className="fe fe-settings"></i> 
									<span>Faça um upgrade</span> 
								</li>
							}

							{
								partnerRoles.includes(user.role_label) && 
								<li>
									<ul style={{display: 'block'}}>
										<li>
											<Link to="/plano" className="sidebar-list-item">
												<span>Plano</span>
											</Link>
										</li>
									</ul>
								</li>
							} */}

							<li className="divisor"></li>
							<li className="divisor"></li>
							<li className="divisor"></li>
							
						</ul>
					}
				</nav>
			</aside>
		)
	}
}

export { Sidebar };