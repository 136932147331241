import React, { Component } from "react"
import { numberToReal, numberToDollar } from '../../utils/money'

class OrderServicesList extends Component {
    state = {
        services: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ services: items })
    }

	renderList = items => {
		return (
			<div className="items">
                {
                    items.length > 0 &&
				    <div className="table-responsive">
                        <table className="table table-stripped mt-15 mb-25">
                            <tbody>
                                <tr>
                                    <th>Item</th>
                                    <th>Serviço</th>
                                    {/* <th>Duração</th> */}
                                    <th>Valor</th>
                                </tr>
                                {items.map((item, index) => (
                                    <tr key={item.id}> 
                                        <td>{index+1}</td>
                                        <td>{item.name}</td>
                                        {/* <td>{item.duration}</td> */}
                                        <td>{numberToReal(item.price)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum serviço</p>
                }
			</div>
		);
	}

    render = () => {
        const { services } = this.state;
        return (
            <div className="services-list">
                {this.renderList(services)}
            </div>
        );
    }
}


export default OrderServicesList;