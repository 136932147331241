import "./Views.scss";

export { PrivateRoute } from "./PrivateRoute";

export { Login } from "./login/Login";
export { Signup } from "./signup/Signup";

export { Home } from "./home/Home";

export { Configure } from "./configure/Configure"

export { NotFound } from "./errors/NotFound";

export { Agenda } from "./agenda/Agenda";
export { AgendaPublic } from "./agenda-public/AgendaPublic";

export { Unidades } from "./unidades/Unidades";
export { CriarUnidade } from "./unidades/CriarUnidade";
export { CriarHorarioAtendimento } from "./unidades/CriarHorarioAtendimento";
export { VerUnidade } from "./unidades/VerUnidade";

export { CriarProfissional } from "./profissionais/CriarProfissional";
export { VerProfissional } from "./profissionais/VerProfissional";
export { CadastroProfissional } from "./profissionais/Cadastro";
export { EditarProfissional } from "./profissionais/EditarProfissional";

export { Servicos } from "./servicos/Servicos";
export { CriarServico } from "./servicos/CriarServico";

export { Produtos } from "./produtos/Produtos";
export { CriarProduto } from "./produtos/CriarProduto";
export { EditarProduto } from "./produtos/EditarProduto";

export { Plano } from "./plano/Plano";
export { PlanoMigrar } from "./plano/Migrar";

export { Clientes } from "./clientes/Clientes";
export { CriarCliente } from "./clientes/CriarCliente";
export { VerCliente } from "./clientes/VerCliente";

export { Gerencial } from "./gerencial/Gerencial";
export { Agendamentos } from "./gerencial/Agendamentos";
export { Comissoes } from "./gerencial/Comissoes";
export { Atendimentos } from "./gerencial/Atendimentos";
export { Faturamento } from "./gerencial/Faturamento";

export { MinhaConta } from "./minha-conta/MinhaConta";
export { Bank } from "./bank/Bank";
export { EditProfile } from "./edit-profile/EditProfile";
export { EditCompany } from "./edit-company/EditCompany";
export { Design } from "./design/Design";

export { Cartoes } from "./cartoes/Cartoes";
export { Enderecos } from "./enderecos/Enderecos";

export { PacoteServicos } from "./pacote-servicos/PacoteServicos";
export { CriarPacoteServicos } from "./pacote-servicos/CriarPacoteServicos";
export { PacoteServicosAjustes } from "./pacote-servicos/PacoteServicosAjustes";
export { VenderPacoteServicos } from "./pacote-servicos/VenderPacoteServicos";

export { Usuarios } from "./users/Usuarios";
export { CadastroUser } from "./users/Cadastro";

export { Locked } from "./locked/Locked";
