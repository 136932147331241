import React from 'react';
import MaskedInput from 'react-text-mask';

const mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];

const input = ({
    onChange,
    value,
    disabled = false
}) => {
    return (
        <MaskedInput
            disabled={disabled}
            guide={false}
            className="form-control"
            value={value}
            mask={mask}
            onChange={onChange}
            />
    )
}

export default input;