// LIBS
import axios from 'axios';
import { settings } from "../settings";
import { userService } from './user.service'
import { parseJwt } from '../_helpers/jwt'
 
const API = axios.create({
  baseURL: settings.apiUrl,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
});

// Add a request interceptor
API.interceptors.request.use(
  config => {
    const token =  localStorage.getItem("token");
    if (token) {
      if (parseJwt(token).exp < Date.now() / 1000) {
        userService.logout();
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    // console.log('POST CONFIG ', config)
    return config;
  },
  
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  response => {
    // console.log('POST response ', response)
    return response;
  },
  
  error => {
    const { status } = error.response
    console.log('POST error ', status)
    if (status === 401 && userService.isAuthenticated()) {
      userService.logout();
    }
    return Promise.resolve(error.response);
  }
);

export default API;
