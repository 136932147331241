import { handleResponse } from '../_helpers'
import API from './api'
import moment from 'moment'

export const ordersService = {
    finishOrder,
    getOne,
    getOrders
}

function finishOrder(body) {
    return API.post(`/partner/orders`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function getOrders(from, to, unit) { 
    // console.log('getOrders', from, to, unit)
    let _from = moment(from).format('YYYY-MM-DD');
    let _to = moment(to).format('YYYY-MM-DD');
    return API.get(`/partner/orders${from?'?from='+_from:''}${to?'&to='+_to:''}${unit?'&unit='+unit:''}`)
            .then(handleResponse)
            .then(user => user)
}

function getOne(id) {
    return API.get(`/partner/order/${id}`)
            .then(handleResponse)
            .then(data => data)
}