import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import moment from 'moment';
import 'moment/locale/pt-br';
import ReactTooltip from 'react-tooltip'

import "./Agenda.scss";

import { Actions } from '../../flux';
import { sumDurationByServices, parseHours, scheduleHour } from '../../utils/time'
import { findIndexWithPropertyValue } from '../../_helpers/common'
import { texts, agenda, settings } from '../../settings';
import { schedulesService } from '../../_services/schedule.service'
import { professionalsService } from '../../_services/professionals.service'
import { unitsService } from "../../_services/units.service";
import UnitsCustomSelect from "../../components/UnitsCustomSelect";
import Schedule from '../../components/Schedule'
import ProfessionalAgenda from '../../components/ProfessionalAgenda'
import AgendaItem from '../../components/AgendaItem';
import { isEarlier, HORARIOS } from "../../settings/enums";
import { publicLink, publicLinkWhatsapp } from '../../_helpers/url' 
import { userService } from '../../_services';

var colors = { "default": "#edc03c" }

class Agenda extends Component {
	constructor(props){
		super(props);

		this.state = {
			loading: true,
			units: [],
			professionals: [],
			items: [],
			itemsComplete: [],
			selected: [],
			cellHeight: agenda.cellHeight,
			showModal: false,
			locale: "pt-br",
			rowsPerHour: 2,
			numberOfDays: 1,
			startDate: new Date(),
			unitSelected: 0,
			now: new Date(),
			horarios: []
		}


		this.agendaScroll = React.createRef();

		this.timer;

		this.handleCellSelection = this.handleCellSelection.bind(this)
		this.handleItemEdit = this.handleItemEdit.bind(this)
		this.handleRangeSelection = this.handleRangeSelection.bind(this)
		this.updateAgenda = this.updateAgenda.bind(this)
	}

	handleCellSelection = (item, professional) => {
		const { units, unitSelected, startDate } = this.state
		let hour = moment(item).format("HH:mm:ss");
		if (isEarlier(startDate, hour)) {
			let day2 = moment(startDate).format("DD/MM/YY");
			let hour2 = moment(item).format("HH:mm");
			Actions.showError(`Não é possível agendar dia ${day2} às ${hour2}. Selecione um horário no futuro.`)
		} else {
			Actions.scheduleHourUpdated(hour);
			Actions.showModalSchedule(startDate, hour, units[unitSelected], professional);
		}
	}

	handleItemEdit = item => {
		// console.log('handleItemEdit', item);
		const { itemsComplete } = this.state
		let schedule = itemsComplete.filter(obj => obj.schedule_id === item._id);
		schedule = schedule[0];
		if (schedule.order_data) {
			// console.log('abre o modal do pedido', schedule)
			// alert('abre o modal do pedido');
		} else {
			Actions.showModalScheduleDetails(schedule);
		}
	}

	handleRangeSelection = item => {
		console.log('handleRangeSelection', item)
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getUnits();
		Actions.scheduleDateUpdated(new Date());

		if (settings.isMobile) {
			this.setState({horarios: HORARIOS});
		}
	}

	componentWillUnmount = () => {
		this.clearUpdate();
	}

	getUnits = () => {
		this.setState({ loading: true })
		unitsService.myUnits().then(res => {
			if (res.length > 0) {
				// console.log('myUnits res', res)
				this.setState({ units: res })
				
				this.getProfessionals();
				// this.updateAgenda();
				this.periodicUpdate();
            } else {
				this.setState({ loading: false })
			}
        })
	}

	getProfessionals = () => {
		const { units, unitSelected } = this.state
		const userRole = userService.getUserRole();
		// console.log('Agenda userRole', userRole);

		professionalsService.getProfessionalsByUnit(units[unitSelected].public_string).then(res => {
			if (userRole == 'Profissional') {
				userService.getLocalData().then(data => {
					if (data.user) {
						const me = res.filter(pro => pro.user === data.user.user_id);
						this.setState({ professionals: me });
						this.updateAgenda();
					}
				});
			} else {
				this.setState({ professionals: res })
				this.updateAgenda();
			}
		})
		.catch(err => {
			console.log('getProfessionalsByUnit err', err)
			Actions.showError('Não foi possível encontrar profissionais');
			this.setState({ loading: false })
		})
	}

	periodicUpdate = () => {
		this.timer = setInterval(() => this.updateAgenda(), 5000)
	}

	clearUpdate = () => clearInterval(this.timer)

	updateAgenda = () => {
		const { units, unitSelected, startDate } = this.state
		if (units.length > 0) {
			schedulesService.getSchedulesByUnit(units[unitSelected].public_string, startDate).then(res => {
				// console.log('getSchedulesByUnit', res)
				this.parseEvents(res);
				this.setState({ loading: false })
			})
			.catch(err => {
				console.log('getSchedulesByUnit err', err)
				this.setState({ loading: false })
			})
		}

		// this.verifyScroll();
	}

	verifyScroll = () => {
		const { now } = this.state;
		let hour = moment(new Date()).format('HH:mm');
		this.setState({now: hour})


		if (this.agendaScroll.current) {
			// console.log(this.agendaScroll.current.getClientReacts());
			// this.agendaScroll.current.scrollIntoView({
			// 	behavior: 'smooth',
			// 	// block: 'start',
			// });
			// console.log(this.agendaScroll.current.offsetTop);

			// // this.agendaScroll.current.scrollTop;
			// this.agendaScroll.current.scrollTop = this.agendaScroll.current.offsetTop - 50;
		}
	}

	selectUnit = index => {
		this.clearUpdate();
		this.setState({unitSelected: index, loading: true})

		setTimeout(() => {
			this.getProfessionals();
			this.periodicUpdate();
		}, 50)
	}

	parseEvents = items => {
		let newItems = [];
		let newColors = {};
		items.map(item => {
			// if (item.order_data === null) {
				let colorIds = [];
				let minutes = 5;
				if (item.services.length > 0) {
					item.services.map(service => {
						newColors[service.service_data.service_id.toString()] = service.service_data.color;
						colorIds.push(service.service_data.service_id.toString());
					});
					minutes = sumDurationByServices(item.services);
				} else {
					newColors.default = '#edc03c';
					colorIds.push('default')
				}


	
				let date = new Date(`${item.when} ${item.hour}`);
				let endD = new Date(date.getTime() + minutes*60000);
				let obj = {
					_id           : item.schedule_id,
					name          : (item.client_data) ? item.client_data.name : 'sem nome',
					startDateTime : new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
					endDateTime   : new Date(endD.getFullYear(), endD.getMonth(), endD.getDate(), endD.getHours(), endD.getMinutes()),
					classes       : colorIds.join(' '),
					professional  : item.professional_data,
					order		  : (item.order_data === null) ? null : item.order_data[0],
					finished	  : (item.order_data === null) ? false : true
				}
				newItems.push(obj);
			// }
		})
		this.setState({
			colors: newColors,
			items:newItems,
			itemsComplete:items
		})
	}

	changeStart = date => {
		this.setState({ startDate:date });
		// console.log('changeStart', date);
		Actions.scheduleDateUpdated(date);
	}

	renderAgendaHeader = professionals => {
		return (
			<div className="agenda__headers">
				{
					professionals.length > 0 &&
					professionals.map((item, index) => (
						<div className="agenda__headers__header" key={index}><span>{item.name}</span></div>
					))
				}
			</div>
		)
	}

	renderAgenda = professionals => {
		let unit = this.state.units[this.state.unitSelected];
		// console.log('renderAgenda', unit)
		return (
			<div className="agenda__list">
				<div className="agenda__scroll">
					<div className="agenda__scroll__content" ref={this.agendaScroll}>
						{
							professionals.length > 0 &&
							professionals.map((item, index) => (
								<div className="professional_column" key = {index}>
									<ProfessionalAgenda
										hideHours = {(index == 0) ? true : false} 
										professional = {item}
										colors={this.state.colors}
										startDate = {this.state.startDate}
										items = {this.state.items}
										unit = {unit}
										openingHours = {unit.opening_hours}
										nowUpdate = {this.state.now}
										onHandleItemEdit = {this.handleItemEdit.bind(this)}
										onHandleCellSelection={this.handleCellSelection.bind(this)}
										onHandleRangeSelection={this.handleRangeSelection.bind(this)}
									/>
								</div>
							))
						}
					</div>
				</div>
			</div>
		)
	}

	parseItemsInHours = (openingHours, startDate, items) => {
		var hours = parseHours(startDate, openingHours, []);
		var newItems = {};
		items.forEach(item => {
			const stringHour = scheduleHour(item.startDateTime).toString();
			let itemIndex = findIndexWithPropertyValue(hours.hours, stringHour);
			if (itemIndex > 0) {
				if (!newItems[stringHour]) {
					newItems[stringHour] = [];
				}
				newItems[stringHour].push(item);
			}
		});
		return newItems;
	}

	renderMobileAgenda = () => {
		const { items, colors, units, unitSelected, startDate } = this.state;
		let unit = units[unitSelected];
		const newItems = this.parseItemsInHours(unit.opening_hours, startDate, items);
		return (
			<div className="professional_agenda">
				{
					Object.keys(newItems).map(hindex => {
						let hourData = newItems[hindex];
						let stringHour = HORARIOS.filter(hour => hour.value === hindex);
						return (
							<div className='professional_agenda__hour' key={hindex}>
								<span>{(stringHour) ? stringHour[0].label.replace(':', 'h') : ``}</span>
								{hourData.map((itemData, index) => {
									const itemClasses = [
										'custom_agenda_item',
										(itemData.finished) ? 'custom_agenda_item--finished' : ''
									]
									return (
										<AgendaItem
											key={index}
											style={{backgroundColor: colors[itemData.classes]}}
											className={itemClasses.join(' ')}
											item={itemData}
											showProfessional
											handleClick={this.handleItemEdit.bind(this)}
											/>
									)
								})}
							</div>
						)
					})
				}
			</div>
		)
	}

	copyLink = (e) => {
		// console.log('copyyyy')
		this.copyInput.select();
		document.execCommand('copy');
		e.target.focus();
		// this.setState({ copySuccess: true });
	};
	

	render = () => {
		const classItem = ['not_found'];
		const { units, unitSelected, loading, professionals } = this.state
		// console.log('units', units);
		// console.log('unitSelected', unitSelected);
        return (
			<div className="agenda_view">
			
				<Helmet>
	                <title>Agenda - { texts.title }</title>
	            </Helmet>

	    		<div className={ classItem.join(' ') }>
					
                	{loading && <div className="body-loading"><i className="i-spinner loading super-loading mb-15"></i><br />Carregando</div>}

					{
						!loading && 
						<div className="agenda_header">
							<div className="row">
	
								<div className="col-md-1 mt-10 agenda_header__title">
									<p>Agenda de:</p>
								</div>
	
								<div className="col-md-4">

									<UnitsCustomSelect
										items = {units}
										selectedText = "Visualizando agendamentos da unidade"
										onSelectUnit = {i => this.selectUnit(i)}
										selected = {unitSelected} />
	
								</div>
	
								<div className="col-md-3 period-combo">
									<div className="period-combo__item">
										Data: 
										<Schedule
											setDate = {this.state.startDate}
											onSelectDates = {date => this.changeStart(date)} />
									</div>
								</div>
	
								<div className="col-md-4 text-right">

									{/* {
										units[unitSelected] && 
										<span>
		
											<a href={publicLinkWhatsapp(units[unitSelected].public_string)} className="btn btn-lg btn-rounded btn-outline-info">
												<i className="fe fe-share"></i> <span><b>COMPARTILHAR</b></span>
											</a>
										</span>
									} */}

									<button className="btn btn-lg btn-new-schedule btn-rounded btn-success ml-10" onClick={() => Actions.showModalSchedule()}>
										<i className="fe fe-plus"></i> <span><b>NOVO ATENDIMENTO</b></span>
									</button>
								</div>
	
							</div>
						</div>
					}


					{
						!settings.isMobile && 
						<div className="agenda_body">
							{ !loading && this.renderAgendaHeader(professionals) }
							{ !loading && this.renderAgenda(professionals) }
						</div>
					}

					{
						settings.isMobile && 
						<div className="agenda_body__mobile">
							{ !loading && this.renderMobileAgenda() }
						</div>
					}
					
				</div>
			</div>
    	);
	}
}

export { Agenda };