import { handleResponse } from '../_helpers';
import { userService } from './user.service'
import API from './api'

export const establishmentService = {
    setup,
    getBySlug,
    updateDesign,
    update,
};

function setup(body) {
    return userService.getLocalData().then(data => {
        body.user = data.user.user_id;
        body.email = data.user.email;

        return API.post(`/partner/me/setup`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
    })
}

function getBySlug(slug) {
    return API.get(`/public/company/${slug}`)
        .then(handleResponse)
        .then(res => res)
        .catch(err => {
            return Promise.reject({error: err})
        });
}

function updateDesign(body) {
    const formData = new FormData();
    formData.append('public_background_color', body.public_background_color);

    if (body.public_banner) {
      formData.append('public_banner', body.public_banner);
    }

    if (body.remove_banner) {
      formData.append('remove_banner', (body.remove_banner == true) ? 1 : 0);
    }

    if (body.public_background_image) {
      formData.append('public_background_image', body.public_background_image);
    }

    if (body.remove_background_image) {
      formData.append('remove_background_image', (body.remove_background_image == true) ? 1 : 0);
    }

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }

    return API.post(`/partner/design`, formData, config)
        .then(handleResponse)
        .then(res => res)
        .catch(err => {
            return Promise.reject({error: err})
        });
}

function update(body) {
  return API.put(`/partner/establishments`, body)
          .then(handleResponse)
          .then(res => res);
}