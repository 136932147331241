import { handleResponse } from '../_helpers'
import API from './api'
import { cookie } from '../_helpers/cookie'
import moment from 'moment'

export const plansService = {
    getOne,
    getPlans,
    getMyPlan,
    getMyPlans,
    getUnitPlan,
    getValid,
    makePayment,
    getPublicPlans
}

function getPlans() { 
    return API.get(`/partner/plans`)
            .then(handleResponse)
            .then(data => data)
}

function getPublicPlans() { 
    return API.get(`/plans`)
            .then(handleResponse)
            .then(data => data)
}

function getOne(id) {
    return API.get(`/partner/plan/${id}`)
            .then(handleResponse)
            .then(data => data)
}

function getMyPlan() { 
    return API.get(`/partner/me/plan`)
            .then(handleResponse)
            .then(data => data)
}

function getUnitPlan(id) { 
    return API.get(`/partner/unit/${id}/plan`)
            .then(handleResponse)
            .then(data => data)
}

function getMyPlans() { 
    return API.get(`/partner/me/plans`)
            .then(handleResponse)
            .then(data => data)
}

function getValid() {
    var userPlan = cookie.getItem('user_plan');
    userPlan = JSON.parse(userPlan);

    if (!userPlan) {
        return true;
    }

    return userPlan.expired === false;

    // let today = new Date();
    // let validDate = new Date(userPlan.activated_at);
    // // let validDate = new Date(userPlan.valid_date.date);

    // if (today.getTime() < validDate.getTime()) {
    //     return true;

    // } else if (today.getTime() > validDate.getTime()) {
    //     return false;

    // } else {
    //     return true;
    // }
}

function makePayment(body) {
    return API.post(`/partner/payments`, body)
            .then(handleResponse)
            .then(res => res);
}