import React, { Component } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Helmet } from "react-helmet";

import { Actions } from '../../flux';
import { texts } from '../../settings';
import MinhaContaNav from '../../components/MinhaContaNav';
import { userService, establishmentService } from '../../_services'

const form = yup.object().shape({
	name: yup
		.string()
        .required('Campo obrigatório'),

    phonenumber: yup
        .string()
        .nullable(),

    legal_name: yup
        .string()
        .nullable(),

    document: yup
        .string()
        .required('Campo obrigatório'),
});

class EditCompany extends Component {
	state = {
        loaded: false,
        loadingSubmit: false,
        user: null,
        company: null
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		userService.getMyData().then(data => {
            console.log('getMyData', data.establishment_data);

			this.setState({
                company: data.establishment_data,
                user: data,
                loaded: true
			})
		})
    }
    
    saveInfo = values => {
        console.log('saveInfo', values);

        this.setState({loadingSubmit: true});

        establishmentService.update(values).then(res => {
            if (res.success) {
                Actions.showSuccess({ title: 'Perfil atualizado!' });
                Actions.userUpdated();
            } else {
                Actions.showError(res.error)
            }
            this.setState({loadingSubmit: false});
        }).catch(err => {
            Actions.showError(err.error);
            this.setState({loadingSubmit: false});
        })
    }

	render = () => {
        const { loaded, loadingSubmit } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Minha empresa - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Minha empresa</h1>
                </div>

				<div className="row">
					<div className="col-md-12">
						<MinhaContaNav active = {'company'} />
					</div>

                    {
                        !loaded &&
                        <div className="col-md-12 mt-20">
                            <p>Carregando ...</p>
                        </div>
                    }

                    {
                        loaded && 
                        <div className="col-md-6 mt-20">
                            <div className="box">
                                <div className="box-header">
                                    <b>Informações da empresa</b>
                                </div>
    
                                <Formik
                                    initialValues={{
                                        name: this.state.company.name,
                                        phonenumber: this.state.company.phonenumber,
                                        document: this.state.company.document,
                                        legal_name: this.state.company.legal_name
                                    }}
                                    validationSchema={form}
                                    onSubmit={values => this.saveInfo(values)}
                                    >
                                    {({ errors, touched, setFieldValue, isValid }) => (
                                        <Form>
                                            <div className="box-body">
    
                                                <div className="form-group">
                                                    <label className="form-label">Nome do negócio<sup className="text-danger">*</sup></label>
                                                    <Field name="name" className="form-control" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
                                                </div>
    
                                                <div className="form-group">
                                                    <label className="form-label">E-mail</label>
                                                    <input className="form-control" defaultValue={this.state.company.email} disabled />
                                                </div>
    
                                                <div className="form-group">
                                                    <label className="form-label">Razão social</label>
                                                    <Field name="legal_name" className="form-control" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="legal_name" /></small>
                                                </div>
    
                                                <div className="form-group">
                                                    <label className="form-label">Telefone principal</label>
                                                    <Field name="phonenumber" className="form-control" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="phonenumber" /></small>
                                                </div>
    
                                                <div className="form-group">
                                                    <label className="form-label">Documento<sup className="text-danger">*</sup></label>
                                                    <Field name="document" className="form-control" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="document" /></small>
                                                </div>
    
                                            </div>
    
                                            <div className="box-footer">
                                                {
                                                    loadingSubmit &&
                                                    <p className="mb-20 text-lg pull-left"><i className="i-spinner loading"></i> Carregando...</p>
                                                }
    
                                                {
                                                    !loadingSubmit &&
                                                    <button type="submit" className="btn btn-success">Salvar alterações</button>
                                                }
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
    
                            </div>
    
                        </div>
                    }

				</div>
				
			</div>
    	);
	}
}

export { EditCompany };