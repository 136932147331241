import React, { Component } from 'react';

import { browserHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import InputMask from 'react-input-mask';

import './NotFound.scss';

import {
    Actions
} from '../../flux';

import {
	images,
	texts
} from '../../settings/';

// *
// React router Example
//	https://reacttraining.com/react-router/web/example/auth-workflow
// *

class NotFound extends Component {

	constructor(props) {
		super(props);

        this.state = {
            width: window.innerWidth,
            user: '',
            password: ''
        };

		this.images = images;
		this.texts = texts;

        this.runTransition = this.runTransition.bind(this);
	}

    runTransition() {
    	Actions.globalTransition();
    }

	componentDidMount() {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();
	}

	componentWillUnmount() {
	}

	render() {
        const { width } = this.state;
        const isMobile = width <= 769;

        const classItem = [
        	'not_found'
        ];

        return (
			<div className="views">
			
				<Helmet>
	                <title>404 - Não encontrado - { this.texts.title }</title>
	            </Helmet>

	    		<div className={ classItem.join(' ') }>

	    			<div className="not_found_view">
	    				<h1>404</h1>
	    				<p>Não encontrado</p>
	            	</div>

				</div>
			</div>
    	);
	}
}

export { NotFound };