import React, { Component } from "react"
import { numberToReal } from '../../utils/money'

class ServicesCombo extends Component {
    state = {
        services: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ services: items })
    }
    
    componentDidUpdate = () => {
        const { items } = this.props
        const { services } = this.state
        if (items.length !== services.length) {
            this.setState({ services: items })
        }
    }

    select = index => {
        const { services } = this.state
        const { onChangeServices } = this.props
        let newServices = services;
        newServices[index].selected = (newServices[index].selected == true) ? false : true;
        if (onChangeServices) onChangeServices(newServices)
    }

	renderList = items => {
		return (
			<div>
                {
                    items.length > 0 &&
				    <div className="button-services">
                        {items.map((item, index) => (
                            <button
                                key={item.service_id}
                                className={`text-left btn btn-rounded btn-sm mr-5 mb-5 ${item.selected ? 'btn-primary' : 'btn-light'}`}
                                onClick = {() => this.select(index)}
                                style={{
                                    borderColor: item.color,
                                    backgroundColor: item.selected ? item.color : 'inherit'
                                }}
                                >
                                {/* {`${item.name} - ${numberToReal(item.price)}`} */}
                                {item.name}<br />{numberToReal(item.price)}
                            </button>
                        ))}
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Cadastre ao menos 1 serviço</p>
                }
			</div>
		);
	}

    render = () => {
        const { services } = this.state;
        return (
            <div className="services-combo">
                {this.renderList(services)}
            </div>
        );
    }
}


export default ServicesCombo;