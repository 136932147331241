import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { userService } from '../../_services/user.service'
import { unitsService } from '../../_services/units.service'
import { professionalsService } from '../../_services/professionals.service'
import viaCep from '../../_services/viacep'
import PhoneInput from '../../components/MaskedInput/phone'

const formProfissional = yup.object().shape({
	name: yup
		.string()
		.required('Campo obrigatório'),

	email: yup
		.string()
		.email('E-mail inválido')
		.required('Campo obrigatório'),

	cellphone: yup
		.string(),

	role: yup
		.string(),

	description: yup
		.string()
});

class EditarProfissional extends Component {
	state = {
		unit: null,
		loading: false,
		success: false,
		percent: 0,
		professional: null
	}

	componentDidMount = () => {
		const { match: { params } } = this.props;
		
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

        this.setState({ loading: true })
        
		professionalsService.getOne(params.id).then(data => {
            console.log('editProfessional', data)
            let lastQuotaIndex = data.quotas.length - 1;
			this.setState({
                professional: data,
                percent: parseFloat(data.quotas[lastQuotaIndex].quota),
				loading: false
			})
		})
	}

	saveInfo = values => {
		const { professional } = this.state

		console.log('save', values)

		this.setState({loadingSubmit: true});

		professionalsService.update(professional.id, values).then(res => {
			if (res.success) {
				Actions.showSuccess({ title: 'Profissional atualizado!' })
				this.setState({ success: true })
			} else {
				Actions.showError(res.error)
			}
			this.setState({loadingSubmit: false});
		}).catch(err => {
			Actions.showError(err.error)
			this.setState({loadingSubmit: false});
		})
	}

	render = () => {
		const { match: { params } } = this.props;
		const { loading, loadingSubmit, success, professional } = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Editar Profissional - { texts.title }</title>
	            </Helmet>

				{
					success && 
					<Redirect to={{
					pathname: `/profissionais/${params.id}`,
					state: { from: this.props.location }
					}}
				/>
				}

	    		<div className="units">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
                                {
                                    !loading && professional &&
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link> / <Link to="/unidades">Unidades</Link> / <Link to={`/unidades/${professional.unity.public_string}`}>{professional.unity.name}</Link> / Editar profissional
                                    </li>
                                }
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-8">
							<h2 className="content-title">Editar Profissional</h2>

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading && professional &&
								<div className="box">
									<div className="box-header">
										<span><b>{professional.name}</b></span>
									</div>

									<Formik
										initialValues={{
											name: professional.name,
											email: professional.email,
											cellphone: professional.cellphone,
											role: professional.role,
											description: professional.description
										}}
										validationSchema={formProfissional}
										onSubmit={values => this.saveInfo(values)}
										>
										{({ errors, touched, setFieldValue, isValid }) => (
											<Form>
												<div className="box-body">
													<div className="form-group row">
														<div className="col-6">
															<b>Informações do profissional</b>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Nome <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="name" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Cargo <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="role" className="form-control" placeholder="Ex: Barbeiro"  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="role" /></small>
														</div>
													</div>

													<div className="form-group row">	
														<label className="form-label col-3">E-mail <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="email" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="email" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Celular</label>
														<div className="col-9">
															<PhoneInput
																name="cellphone"
																className="form-control"
																onChange={e => setFieldValue('cellphone', e.target.value)}
															/>
															<small className="text-danger"><ErrorMessage className="text-danger" name="cellphone" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Descrição</label>
														<div className="col-9">
															<Field name="description" component="textarea" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="description" /></small>
														</div>
													</div>

												</div>

												<div className="box-footer">
													{
														!loadingSubmit &&
														<Link to={`/profissionais/${professional.id}`} className="btn btn-secondary">Voltar</Link>
													}
													{
														loadingSubmit &&
														<p className="mb-20 text-lg pull-right"><i className="i-spinner loading"></i> Carregando...</p>
													}
													{
														!loadingSubmit &&
														<button type="submit" className="btn btn-success pull-right">Salvar</button>
													}
												</div>
											</Form>
										)}
									</Formik>

								</div>
							}

						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { EditarProfissional };