import React, { Component } from "react";
import { Link } from 'react-router-dom';

import { settings } from "../../settings/";
import { imageApi } from '../../_helpers/imageApi';
import { getInitials } from "../../_helpers/clean-string";

class ProfessionalsList extends Component {
    state = {
        professionals: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ professionals: items })
    }

	renderList = items => {
		return (
			<div className="items">
                {items.length > 0 && items.map(item => (
					<Link className="user-panel" key={item.id} to={`/profissionais/${item.id}`}>
                        <div className="user-panel-image">
                            {/* {
                                item.photo !== '' && item.photo !== null &&
                                <div className="avatar avatar-sm">
                                    <img src={imageApi(item.photo)} />
                                </div>
                            }
                            {
                                item.photo == '' || item.photo == null &&
                                <div className="avatar avatar-secondary avatar-sm">{getInitials(item.name)}</div>
                            } */}
                            <div className="avatar avatar-secondary avatar-sm">{getInitials(item.name)}</div>
                        </div>
                        <div className="user-panel-info">
                            <p>{item.name}</p>
                            <small className="text-black-50">{item.role}</small>
                            {
                                (item.has_schedule  === 1)
                                ? <small><i className="fe fe-check-circle text-success"></i> Executa serviços</small>
                                : <small><i className="fe fe-alert-octagon text-danger"></i> Não executa serviços</small>
                            }
                        </div>
                    </Link>
				))}
                
                {
                    items.length == 0 &&
                    <p className="text-lg">Cadastre ao menos 1 profissional.</p>
                }
			</div>
		);
	}

    render = () => {
        const { professionals } = this.state;
        return (
            <div className="professionals-list">
                {this.renderList(professionals)}
            </div>
        );
    }
}


export default ProfessionalsList;