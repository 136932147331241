import React, { Component } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import 'react-datepicker/dist/react-datepicker.css';
import "./Schedule.scss";

registerLocale('pt', pt);
setDefaultLocale('pt');
 
class Schedule extends Component {
  state = {
    date: null,
    min: null
  }

  componentDidMount = () => {
    const { setDate } = this.props
    if (setDate) {
      this.setState({ date: setDate })
    }
  }
 
  onChange = date => {
    console.log('date', date)
    const { onSelectDates } = this.props
    this.setState({ date }) 
    if (onSelectDates) onSelectDates(date)
  }

  componentDidUpdate = nextProps => {
    const { minDate, setDate } = this.props
    if (minDate !== nextProps.minDate) {
      this.setState({min:minDate})
    }

    if (setDate !== nextProps.setDate) {
      this.setState({date:setDate})
    }
  }
 
  render() {
    return (
      <div className="custom-datepicker">
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={this.state.date}
          minDate={this.state.min}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default Schedule;