import React from 'react';
import MaskedInput from 'react-text-mask';

function mask(userInput) {
    let numbers = userInput.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join("").length;
    }
  
    if (numberLength > 10) {
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

const input = ({
    onChange,
    value,
    placeholder,
    disabled = false
}) => {
    return (
        <MaskedInput
            disabled={disabled}
            guide={false}
            className="form-control"
            mask={mask}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            />
    )
}

export default input;