import { handleResponse } from '../_helpers'
import API from './api'

export const servicePacksService = {
    getOne,
    myServicePacks,
    myServicePacksSolds,
    create,
    update,
    remove
}

function create(body) {
    return API.post(`/partner/service-packs`, body)
            .then(handleResponse)
            .then(res => res);
}

function update(id, body) {
    return API.put(`/partner/service-pack/${id}`, body)
            .then(handleResponse)
            .then(res => res);
}

function getOne(id) {
    return API.get(`/partner/service-pack/${id}`)
            .then(handleResponse)
            .then(data => data)
}

function myServicePacks() { 
    return API.get(`/partner/service-packs`)
            .then(handleResponse)
            .then(data => data)
}

function myServicePacksSolds() { 
    return API.get(`/partner/service-packs-solds`)
            .then(handleResponse)
            .then(data => data)
}

function remove(id) {
    return API.delete(`/partner/service-pack/${id}`)
            .then(handleResponse)
            .then(data => data)
}