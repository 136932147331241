import React, { Component } from "react"
import { numberToReal } from '../../utils/money'

class ServicesTags extends Component {
    state = {
        services: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ services: items })
    }
    
    componentDidUpdate = () => {
        const { items } = this.props
        const { services } = this.state
        if (items.length !== services.length) {
            this.setState({ services: items })
        }
    }

	renderList = items => {
		return (
			<div>
                {
                    items.length > 0 &&
				    <div className="tags-services">
                        {items.map((item, index) => (
                            <span
                                key={index}
                                className={`text-left mr-5 mb-5 badge badge-pill badge-primary`}
                                style={{
                                    borderColor: (item.color) ? item.color : item.service_data.color,
                                    backgroundColor: (item.color) ? item.color : item.service_data.color
                                }}
                                >
                                {item.name} - {numberToReal(item.price)}
                            </span>
                        ))}
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Cadastre ao menos 1 serviço</p>
                }
			</div>
		);
	}

    render = () => {
        const { services } = this.state;
        return (
            <div className="services-tags">
                {this.renderList(services)}
            </div>
        );
    }
}


export default ServicesTags;