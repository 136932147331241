import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { userService } from '../../_services/user.service'
import { productsService } from '../../_services/products.service'

import ServicesList from '../../components/ServicesList'

const form = yup.object().shape({
	name: yup
		.string()
		.required('Campo obrigatório'),

	description: yup
		.string()
		.required('Campo obrigatório'),

	cost: yup
		.string()
		.required('Campo obrigatório'),


	photo: yup
		.string(),

	quantity: yup
		.string()
		.required('Campo obrigatório'),

	brand: yup
		.string()
});

class CriarProduto extends Component {
	state = {
		user: null,
		loading: false,
		success: false,
		photoSource: null,
		file: null,
		checked: false,
		percent: 0
	}

	componentDidMount = () => {
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

		this.setState({ loading: true })

		userService.getMyData().then(data => {
			this.setState({
				user: data,
				loading: false
			})
		})
	}

	saveInfo = values => {
		const { user, file, checked, percent } = this.state

		values.quantity 		= parseInt(values.quantity)
		values.cost 			= parseFloat(values.cost.replace(',','.'))
		values.establishment 	= user.establishment_data.establishment_id;
		values.photoFile 		= file;
		values.quota_included	= (checked) ? 1 : 0;
		values.quota 			= percent;

		console.log(values);

		productsService.create(values).then(res => {
			console.log('productsService create', res)
			Actions.showSuccess({
				title: 'Produto cadastrado!',
			})
			this.setState({ success: true })
		}).catch(err => {
			Actions.showError(err.error)
		})
    }

	handleFile = (files) => {
		if (files.length > 0) {
			this.setState({
				photoSource: URL.createObjectURL(files[0]),
				file: files[0]
			})
		} else {
			this.setState({photoSource:null, file: null})
		}
	}

	render = () => {
		const { loading, user, success, checked, photoSource, percent} = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Cadastrar Produto - { texts.title }</title>
	            </Helmet>

				{
					success && 
					<Redirect to={{
					pathname: `/produtos`,
					state: { from: this.props.location }
					}}
				/>
				}

	    		<div className="products">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">Dashboard</Link> / <Link to="/produtos">Produtos</Link> / Cadastrar Produto
								</li>
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-6">
							<h2 className="content-title">Cadastrar Produto</h2>

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading &&
								<div className="box">
									<div className="box-header">
										<b>Informações do produto</b><br />
										{/* <span>{unit.establishment_data.name} - <b>{unit.name}</b></span> */}
									</div>

									<Formik
										initialValues={{
											name: '',
											description: '',
											cost: '',
											photo: '',
											quantity: '',
											brand: '',
										}}
										validationSchema={form}
										onSubmit={values => this.saveInfo(values)}
										>
										{({ errors, touched, setFieldValue, isValid }) => (
											<Form>
												<div className="box-body">

													<div className="form-group row">
														<label className="form-label col-3 text-right">Nome <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="name" className="form-control" placeholder="Ex: Óleo para barba"  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3 text-right">Descrição <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="description" component="textarea" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="description" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3 text-right">Foto</label>
														<div className="col-9">
															{
																photoSource &&
																<div className="img img-square img-square-lg img-rounded">
																	<img src={photoSource} />
																</div>
															}
															<input type="file" name="photoFile" className="form-control" onChange={e => this.handleFile(e.target.files) }  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="photoFile" /></small>
														</div>
													</div>


													<div className="form-group row">
														<label className="form-label col-3 text-right">Marca</label>
														<div className="col-9">
															<Field name="brand" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="brand" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3 text-right">Preço <sup className="text-danger">*</sup></label>
														<div className="col-4">
                                                            <div className="input-group">
                                                                <div className="input-group-icon">R$</div>
															    <Field name="cost" className="form-control" />
                                                            </div>
															<small className="text-danger"><ErrorMessage className="text-danger" name="cost" /></small>
														</div>

														<label className="form-label col-2 text-right">Quantidade</label>
														<div className="col-3">
															<input type="number" className="form-control" onChange={e => setFieldValue('quantity', e.target.value)} />
															{/* <Field name="quantity" className="form-control" /> */}
															<small className="text-danger"><ErrorMessage className="text-danger" name="quantity" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3"></label>
														<div className="col-9">
															<label className="form-checkbox-custom">
																<input checked={(checked) ? "checked" : ""} onClick={() => this.setState({checked:!checked})} type="checkbox" />
																<span className="form-label">Produto entra na comissão do profissional?</span>
															</label>
														</div>
													</div>

													{
														checked &&
														<div className="form-group row">
															<label className="form-label col-3">Cota para o produto</label>
															<div className="col-4">
															<div className="input-group">
																<input
																	type="number"
																	className="form-control"
																	value={percent}
																	min="0"
																	onChange={e => this.setState({percent: e.target.value})} />
																<div className="input-group-icon">%</div>
															</div>
															</div>
														</div>
													}

												</div>

												<div className="box-footer">
													<button type="submit" className="btn btn-secondary pull-right">Cadastrar</button>
												</div>
											</Form>
										)}
									</Formik>

								</div>
							}

						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { CriarProduto };