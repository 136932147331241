import React, { Component } from 'react';

import { Actions } from '../../flux';
import { userService } from '../../_services/user.service'

class CriarUsuario extends Component {
	state = {
        email: null,
		loading: false,
        success: false
    }

	saveInfo = () => {
        const { email } = this.state
        const { onDismiss } = this.props
        
        console.log(`saveInfo`, email);
        if (!email) {
            Actions.showError('Informe o e-mail');
            return;
        }

        this.setState({loadingSubmit: true});

        userService.sendUserInvitation(email).then(res => {
            Actions.showSuccess({ title: res.message })
            onDismiss();
            this.setState({loadingSubmit: false});
        }).catch(err => {
            Actions.showError(err.error)
            onDismiss();
            this.setState({loadingSubmit: false});
        })
    }

	render = () => {
        const { loadingSubmit } = this.state
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="box">
                        <div className="box-header">
                            <b>Convidar usuário</b>
                        </div>

                        <div className="box-body">
                            <div className="form-group row">
                                <label className="form-label col-3">E-mail <sup className="text-danger">*</sup></label>
                                <div className="col-9">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={e => this.setState({email: e.target.value})} />
                                </div>
                            </div>
                        </div>

                        <div className="box-footer">                            
                            {
                                loadingSubmit &&
                                <p className="mb-20 text-lg pull-left"><i className="i-spinner loading"></i> Carregando...</p>
                            }
                            {
                                !loadingSubmit &&
                                <button type="submit" className="btn btn-success pull-right" onClick={() => this.saveInfo()}>Enviar</button>
                            }
                        </div>

                    </div>

                </div>
            </div>
    	);
	}
}

export { CriarUsuario };