import React from "react";

// Utils
import { userService, plansService } from '../../_services'
import { Actions, Events, Dispatcher } from '../../flux';
import { toggleFullscreen } from '../../_helpers/common';
import Avatar from "../../components/Avatar";
import { cookie } from "../../_helpers/cookie";
import { Link } from "react-router-dom";

class Header extends React.PureComponent {
	state = {
		user: null,
		loaded: false,
		infosOpened: false,
		userPlan: null
	}

	componentWillMount = () => {
		Dispatcher.on(Events.USER_UPDATED, this.userUpdate.bind(this) );
	}

	componentWillUnmount = () => {
		Dispatcher.removeListener(Events.USER_UPDATED, this.userUpdate );
	}

	componentDidMount = () => {
		// userService.getLocalData().then(data => {
		// 	console.log('getLocalData', data)
		// 	this.setState({
		// 		user: data,
		// 		loaded: true
		// 	})

		// 	this.getMyPlan();
		// })

		this.getMyData();
		this.getMyPlan();
	}

	userUpdate = () => {
		this.getMyData();
	}

	getMyData = () => {
		userService.getLocalData().then(data => {
			// console.log('getLocalData', data)
			
			if (data.user === null || data.user === undefined) {
				userService.logout();
			}

			this.setState({
				user: data,
				loaded: true
			})
		})
	}

	getMyPlan = () => {
		plansService.getMyPlan().then(data => {
			// console.log('getMyPlan', data)
			if (Object.keys(data).length > 0) {
				cookie.setSessionItem('user_plan', JSON.stringify(data));
				this.setState({ userPlan: data });
				Actions.planUpdated();
			}
		})
	}

	toggleProfile = () => {
		const { infosOpened } = this.state
		this.setState({infosOpened:!infosOpened});
	}

	toggleFullscreenHandler = () => {
		const doc = document.documentElement;
		toggleFullscreen(doc)
	}

	logout = () => {
		userService.logout();
	}

	collapseMenu = () => {
		Actions.toggleMenu()
	}

	render = () => {
		const { user, loaded, infosOpened, userPlan } = this.state
		return (
			<header className="header">
				<ul className="header-nav">
					<li>
						<button id="js-toggle-sidebar" className="header-nav-item" onClick={() => this.collapseMenu()}>
							<i className="fas fa-bars"></i>
						</button>
					</li>
				</ul>
				{
					loaded &&
					userPlan && 
					<ul className="header-nav d-none d-sm-flex">
						<li>
							{
								userPlan.plan_details.slug == 'free' &&
								<Link to="/plano" className="btn btn-upgrade"><i className="fa fa-lock mr-10"></i> {`Assine agora`}</Link>
							}
							
							{
								userPlan.plan_details.slug != 'free' &&
								<div className="btn btn-light"><i className="fa fa-star mr-5"></i> {`Plano ${userPlan.plan_details.name}`}</div>
							}
							
						</li>
					</ul>
				}

				{/* {
					loaded &&
					user.establishment_data && 
					<ul className="header-nav d-none d-sm-flex">
						<li>
							<div className={(panelMenuOpened) ? "sidebar-list-item pointer active" : "sidebar-list-item pointer" } onClick={() => this.toggleMenuPanels()}>
								<i className="fe fe-sliders"></i> 
								<span>{dashboardSelected}</span>
								<i className="fas fa-chevron-down pull-right"></i>
							</div>
						</li>
					</ul>
				} */}
				
				<ul className="header-nav pull-right">
					<li>
						<div className="header-nav-item pointer fullscreen-btn" onClick={() => this.toggleFullscreenHandler()}>
							<i className="fas fa-expand-arrows-alt"></i>
						</div>
					</li>

					<li>
						<div data-dropdown className="user-panel pointer" onClick={() => this.toggleProfile()}>
							<div className="user-panel-image">
								{ loaded && <Avatar data={user.user} /> }
							</div>
							{
								loaded && 
								<div className="user-panel-info">
									<p>{user.user.name}</p>
									<small className="text-black-50">{user.user.role_label}</small>
								</div>
							}
						</div>

						<ul className="dropdown-menu" style={{display:(infosOpened)?'block':'none'}}>
							<li><a className="dropdown-item" onClick={() => this.logout()}><i className="fas fa-power-off"></i> Logout</a></li>
						</ul>
					</li>

				</ul>
			</header>
		)
	}
}

export { Header };