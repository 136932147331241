import { handleResponse } from '../_helpers'
import { userService } from './user.service'
import API from './api'

export const servicesService = {
    create,
    update,
    getOne,
    getServicesByUnit,
    getActiveServicesByUnit
}

function create(body) {
    return API.post(`/partner/services`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function update(id, body) {
    return API.put(`/partner/service/${id}`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function getServicesByUnit(unity_id) {
    return API.get(`/partner/unit/${unity_id}/services`)
            .then(handleResponse)
            .then(user => user)
}

function getActiveServicesByUnit(unity_id) {
    return API.get(`/partner/unit/${unity_id}/services/active`)
            .then(handleResponse)
            .then(user => user)
}

function getOne(id) {
    return API.get(`/partner/service/${id}`)
            .then(handleResponse)
            .then(data => data)
}