import { handleResponse } from '../_helpers'
import API from './api'

export const clientsService = {
    myClients,
    create,
    update,
    createSimple,
    getOne,
    findByName,
    remove
};

function myClients() {
    return API.get(`/partner/clients`)
            .then(handleResponse)
            .then(user => user)
}

function findByName(name) {
  return API.get(`/partner/client/find/${name}`)
          .then(handleResponse)
          .then(user => user)
}

function createSimple(body) {
  const formData = new FormData();

  formData.append('name', body.name);

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }

  return API.post(`/partner/clients`, formData, config)
          .then(handleResponse)
          .then(res => res)
          .catch(err => Promise.reject({error: err}));
}

function create(body) {
    const formData = new FormData();

    formData.append('name', body.name);
    formData.append('document', body.document);
    formData.append('email', body.email);
    formData.append('cellphone', body.cellphone);
    formData.append('instagram', body.instagram);
    formData.append('facebook', body.facebook);
    formData.append('twitter', body.twitter);

    if (body.photoFile) {
      formData.append('photoFile', body.photoFile);
    }

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }

    return API.post(`/partner/clients`, formData, config)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function update(id, body) {
    return API.put(`/partner/client/${id}`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function getOne(id) {
    return API.get(`/partner/client/${id}`)
            .then(handleResponse)
            .then(data => data)
}

function remove(id) {
    return API.delete(`/partner/client/${id}`)
            .then(handleResponse)
            .then(user => user)
}