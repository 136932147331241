import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { clientsService } from '../../_services/clients.service'
import { texts, images } from '../../settings/'
import { imageApi } from '../../_helpers/imageApi'
import ServicesList from '../../components/ServicesList'

import { getInitials } from '../../_helpers/clean-string'

class Servicos extends Component {
	state = {
		services: [],
		loaded: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		clientsService.myClients().then(data => {
			this.setState({
				services: data,
				loaded: true
			})
			console.log('get myClients', data)
		})
	}

	render = () => {
		const { services, loaded } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Serviços - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Serviços</h1>
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / Serviços</li>
							</ol>
						</nav>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12">
						<div className="box">
							<div className="box-header">
								<h3>Todos ({services.length})</h3>
							</div>
							<div className="box-body">

								{ loaded && <ServicesList items={[]} /> }

								{
									!loaded &&
									<div className="row" style={{ height: 200, paddingTop: 80 }}>
										<div className="col-md-12 text-center">
											<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
										</div>
									</div>
								}

								{
									loaded && services.length === 0 &&
									<div className="row" style={{ height: 200, paddingTop: 80 }}>
										<div className="col-md-12 text-center">
											<p className="mb-20 text-lg">Nenhum cadastro</p>
										</div>
									</div>
								}
								
							</div>
							{/* <div className="box-footer">
								<a href="#" className="text-secondary text-sm d-block">Ver mais <i className="fas fa-chevron-down"></i></a>
							</div> */}
						</div>
					</div>
				</div>
				
			</div>
    	);
	}
}

export { Servicos };