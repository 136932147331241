import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { userService } from '../../_services/user.service'
import { unitsService } from '../../_services/units.service'
import { professionalsService } from '../../_services/professionals.service'
import viaCep from '../../_services/viacep'
import PhoneInput from '../../components/MaskedInput/phone'
import ProfessionalsList from '../../components/ProfessionalsList'

const formProfissional = yup.object().shape({
	name: yup
		.string()
		.required('Campo obrigatório'),

	email: yup
		.string()
		.email('E-mail inválido')
		.required('Campo obrigatório'),

	cellphone: yup
		.string(),

	role: yup
		.string(),

	description: yup
		.string(),

	// expedient: yup
	// 	.string()
	// 	.required('Campo obrigatório'),

	photo: yup
		.string()
});

class CriarProfissional extends Component {
	state = {
		unit: null,
		loading: false,
		success: false,
		checked: true,
		hasUser: false,
		photoSource: null,
		file: null,
		percent: 0,
		newId: null
	}

	componentDidMount = () => {
		const { match: { params } } = this.props;
		
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

		this.setState({ loading: true })

		unitsService.getOne(params.id).then(data => {
			// console.info('get unit', data)
			this.setState({
				unit: data,
                loading: false
			})
        })
	}

	saveInfo = values => {
		const { checked, unit, file, hasUser, percent } = this.state
		if (hasUser && !values.email) {
			Actions.showError('Insira o e-mail');
			return;
		}

		values.has_user = (hasUser) ? 1 : 0;
		values.has_schedule = (checked) ? 1 : 0;
		values.unit = unit.unity_id;
		// values.photoFile = file;

		values.has_quota = (checked) ? 1 : 0;
		values.quota = percent;

		this.setState({loadingSubmit: true});

		professionalsService.create(values).then(res => {
			console.log('professionalsService', res);
			if (res.success) {
				Actions.showSuccess({
					title: 'Parabéns!',
					text: 'Profissional cadastrado com sucesso.'
				})
				this.setState({
					success: true,
					newId: res.data.id
				})
			} else {
				Actions.showError(res.error)
			}
			this.setState({loadingSubmit: false});
		}).catch(err => {
			Actions.showError(err.error)
			this.setState({loadingSubmit: false});
		})
	}

	handleFile = (files) => {
		if (files.length > 0) {
			this.setState({
				photoSource: URL.createObjectURL(files[0]),
				file: files[0]
			})
		} else {
			this.setState({photoSource:null, file: null})
		}
	}

	render = () => {
		const { loading, loadingSubmit, unit, success, checked, hasUser, photoSource, percent, newId } = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Adicionar Profissional - { texts.title }</title>
	            </Helmet>

				{
					success && newId &&
					<Redirect to={{
						pathname: `/profissionais/${newId}`,
						state: { from: this.props.location }
					}} />
				}

	    		<div className="units">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">Dashboard</Link> / <Link to="/unidades">Unidades</Link> / Adicionar profissional
								</li>
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-8">
							<h2 className="content-title">Adicionar Profissional</h2>

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading && unit &&
								<div className="box">
									<div className="box-header">
										{/* <b>Barbearia</b><br /> */}
										<span>{unit.establishment_data.name} - <b>{unit.name}</b></span>
									</div>

									<Formik
										initialValues={{
											name: '',
											email: '',
											cellphone: '',
											role: '',
											description: '',
											photoFile: '',
											// expedient: ''
										}}
										validationSchema={formProfissional}
										onSubmit={values => this.saveInfo(values)}
										>
										{({ errors, touched, setFieldValue, isValid }) => (
											<Form>
												<div className="box-body">
													<div className="form-group row">
														<div className="col-6">
															<b>Informações do profissional</b>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Nome <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="name" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Cargo <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="role" className="form-control" placeholder="Ex: Barbeiro"  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="role" /></small>
														</div>
													</div>

													<div className="form-group row">	
														<label className="form-label col-3">E-mail <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="email" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="email" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3"></label>
														<div className="col-9">
															<label className="form-checkbox-custom">
																<input checked={(hasUser) ? "checked" : ""} onClick={() => this.setState({hasUser:!hasUser})} type="checkbox" />
																<span className="form-label">Enviar convite por e-mail?</span>
															</label>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Celular</label>
														<div className="col-9">
															<PhoneInput
																name="cellphone"
																className="form-control"
																onChange={e => setFieldValue('cellphone', e.target.value)}
															/>
															<small className="text-danger"><ErrorMessage className="text-danger" name="cellphone" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Descrição</label>
														<div className="col-9">
															<Field name="description" component="textarea" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="description" /></small>
														</div>
													</div>

													{/* <div className="form-group row">
														<label className="form-label col-3">Foto</label>
														<div className="col-9">
															{
																photoSource &&
																<div className="avatar avatar-lg">
																	<img src={photoSource} width="100" />
																</div>
															}
															<input type="file" name="photoFile" className="form-control" onChange={e => this.handleFile(e.target.files) }  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="photoFile" /></small>
														</div>
													</div> */}

													{/* <div className="form-group row">
														<label className="form-label col-3">Expediente <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="expedient" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="expedient" /></small>
														</div>
													</div> */}

													<div className="form-group row">
														<label className="form-label col-3"></label>
														<div className="col-9">
															<label className="form-checkbox-custom">
																<input checked={(checked) ? "checked" : ""} onClick={() => this.setState({checked:!checked})} type="checkbox" />
																<span className="form-label">Profissional executa serviços</span>
															</label>
														</div>
													</div>

													{
														checked &&
														<div className="form-group row">
															<label className="form-label col-3">Cota comissional</label>
															<div className="col-4">
															<div className="input-group">
																<input
																	type="number"
																	className="form-control"
																	value={percent}
																	min="0"
																	onChange={e => this.setState({percent: e.target.value})} />
																<div className="input-group-icon">%</div>
															</div>
															</div>
														</div>
													}

												</div>

												<div className="box-footer">
													{
														loadingSubmit &&
														<p className="mb-20 text-lg pull-right"><i className="i-spinner loading"></i> Carregando...</p>
													}
													{
														!loadingSubmit &&
														<button type="submit" className="btn btn-secondary pull-right">Cadastrar</button>
													}
												</div>
											</Form>
										)}
									</Formik>

								</div>
							}

						</div>


						{
							!loading && unit &&
							<div className="col-lg-4">
								<div className="box mt-55">
									<div className="box-header">
										<h3>Profissionais da unidade</h3>
									</div>
									<div className="box-body">
										<ProfessionalsList items={unit.professionals} />
									</div>
								</div>
							</div>
						}
					</div>

				</div>
			</div>
    	);
	}
}

export { CriarProfissional };