
import moment from 'moment';

const HORARIOS = [
    { label: "00:00", value: "00:00:00" },
    { label: "00:30", value: "00:30:00" },

    { label: "01:00", value: "01:00:00" },
    { label: "01:30", value: "01:30:00" },

    { label: "02:00", value: "02:00:00" },
    { label: "02:30", value: "02:30:00" },

    { label: "03:00", value: "03:00:00" },
    { label: "03:30", value: "03:30:00" },

    { label: "04:00", value: "04:00:00" },
    { label: "04:30", value: "04:30:00" },

    { label: "05:00", value: "05:00:00" },
    { label: "05:30", value: "05:30:00" },

    { label: "06:00", value: "06:00:00" },
    { label: "06:30", value: "06:30:00" },

    { label: "07:00", value: "07:00:00" },
    { label: "07:30", value: "07:30:00" },

    { label: "08:00", value: "08:00:00" },
    { label: "08:30", value: "08:30:00" },

    { label: "09:00", value: "09:00:00" },
    { label: "09:30", value: "09:30:00" },

    { label: "10:00", value: "10:00:00" },
    { label: "10:30", value: "10:30:00" },

    { label: "11:00", value: "11:00:00" },
    { label: "11:30", value: "11:30:00" },

    { label: "12:00", value: "12:00:00" },
    { label: "12:30", value: "12:30:00" },

    { label: "13:00", value: "13:00:00" },
    { label: "13:30", value: "13:30:00" },

    { label: "14:00", value: "14:00:00" },
    { label: "14:30", value: "14:30:00" },

    { label: "15:00", value: "15:00:00" },
    { label: "15:30", value: "15:30:00" },

    { label: "16:00", value: "16:00:00" },
    { label: "16:30", value: "16:30:00" },

    { label: "17:00", value: "17:00:00" },
    { label: "17:30", value: "17:30:00" },

    { label: "18:00", value: "18:00:00" },
    { label: "18:30", value: "18:30:00" },

    { label: "19:00", value: "19:00:00" },
    { label: "19:30", value: "19:30:00" },

    { label: "20:00", value: "20:00:00" },
    { label: "20:30", value: "20:30:00" },

    { label: "21:00", value: "21:00:00" },
    { label: "21:30", value: "21:30:00" },

    { label: "22:00", value: "22:00:00" },
    { label: "22:30", value: "22:30:00" },

    { label: "23:00", value: "23:00:00" },
    { label: "22:30", value: "23:30:00" }
];

const parseHorarios = (date, available, onlyFuture = false) => {
    var ret = [];
    let todayWeek = moment(date).format("dddd");
    var hours = available.filter(obj => obj.weekday === todayWeek);

    if (hours.length > 0) {
        hours = hours[0];
        
        var start = false;

        HORARIOS.map(item => {

            if (item.value === hours.hour_start) {
                start = true;
            }

            if (start === true) {
                let add = true;

                if (onlyFuture) {
                    if (isEarlier(date, item.value)) {
                        add = false
                    } else {
                        add = true
                    }

                }

                if (add) {
                    ret.push(item);

                    if (item.value === hours.hour_end) {
                        start = false;
                    }
                }
            }
        })
    }

    return ret;
}

/*
 * @hour: format 23:30:00
*/
const isEarlier = (date, hour) => {
    let now = new Date()
    now.setTime(now.getTime() - (30 * 60 * 1000)); // Remove 30 minutos

    let cdate = moment(date).format("YYYY-MM-DD");
    let compare = new Date(`${cdate} ${hour}`)
    // let compare = new Date()
    // let c = hour.split(':');
    // compare.setHours(c[0], c[1], c[2]);

    return compare.getTime() < now.getTime();
}

export { HORARIOS, parseHorarios, isEarlier };
