import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { userService } from '../../_services/user.service'
import { establishmentService } from '../../_services/establishment.service'
import { texts } from '../../settings/';
import PhoneInput from '../../components/MaskedInput/phone'
import DocumentInput from '../../components/MaskedInput/document';

const stepInfo = yup.object().shape({
	name: yup
		.string()
		.max(25, 'Limite de 25 letras')
		.required('Campo obrigatório'),

	// email: yup
	// 	.string()
	// 	.email('E-mail inválido')
	// 	.required('Campo obrigatório'),

	phonenumber: yup
		.string()
		.required('Campo obrigatório'),

	document: yup
		.string()
		.required('Campo obrigatório'),

	// site: yup
	// 	.string(),
});

const stepSocial = yup.object().shape({
	instagram: yup
		.string(),

	facebook: yup
		.string(),

	twitter: yup
		.string()
});


class Configure extends Component {
	state = {
		user: {},
		loaded: false,
		tabSelected: 0,
		infoValues: {},
		socialValues: {},
		success: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		userService.getMyData().then(data => {
			this.setState({
				user: data,
				loaded: true
			})
		})
	}

	saveInfo = () => {
		const { infoValues, socialValues } = this.state

		const data = {
			name: infoValues.name,
			phonenumber: infoValues.phonenumber,
			instagram: socialValues.instagram,
			facebook: socialValues.facebook,
			twitter: socialValues.twitter,
			// email: infoValues.email,
			document: infoValues.document,
			// site_url: infoValues.site,
		}

		establishmentService.setup(data).then(res => {
			// console.log('establishmentService create', res)
			if (res.success == true) {
				Actions.showSuccess({
					title: 'Parabéns!',
					text: 'Seu estabelecimento foi configurado corretamente, agora cadastre sua(s) unidade(s).',
				})
				Actions.userUpdated();
				this.setState({ success: true })
			} else {
				Actions.showError(res.error)
			}
		}).catch(err => {
			Actions.showError(err.error)
			this.setState({ tabSelected: 0 })
		})
	}

	render = () => {
		const { user, tabSelected, success } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Configure - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>1. Informações sobre seu negócio</h1>
                </div>

				{
					user.establishment_data && 
					<Redirect to={{
					  pathname: "/unidades",
					  state: { from: this.props.location }
					}}
				  />
				}

				{
					success && 
					<Redirect to={{
					  pathname: "/unidades/criar",
					  state: { from: this.props.location }
					}}
				  />
				}

				<div className="row">
					<div className="col-md-8">


						<div className="box">

							<div className="tabs-box">
								<div className={tabSelected == 0 ? 'text-dark tabs-trigger active' : 'text-dark tabs-trigger'} onClick = {() => this.setState({tabSelected:0})}>Informações sobre seu negócio</div>
								<div className={tabSelected == 1 ? 'text-dark tabs-trigger active' : 'text-dark tabs-trigger'}>Redes sociais</div>
							</div>

							<div className={tabSelected == 0 ? 'tabs-item active' : 'tabs-item'}>
								<Formik
									initialValues={{
										name: '',
										// email: '',
										phonenumber: '',
										// site: '',
										document: ''
									}}
									validationSchema={stepInfo}
									onSubmit={values => {
										this.setState({
											infoValues: values,
											tabSelected: 1
										})
									}}
									>
									{({ errors, touched, setFieldValue }) => (
										<Form>
											<div className="box-body">

												<div className="form-group row">
													<label className="form-label col-md-3">Nome da sua Barbearia <sup className="text-danger">*</sup></label>
													<div className="col-md-9">
														<Field name="name" className="form-control" />
														<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
													</div>
												</div>

												<div className="form-group row">
													<label className="form-label col-md-3">CPF ou CNPJ <sup className="text-danger">*</sup></label>
													<div className="col-md-9">
														<DocumentInput
															onChange={e => setFieldValue('document', e.target.value)}
															/>
														<small className="text-danger"><ErrorMessage className="text-danger" name="document" /></small>
													</div>
												</div>

												{/* <div className="form-group row">
													<label className="form-label col-md-3">E-mail <sup className="text-danger">*</sup></label>
													<div className="col-md-9">
														<Field name="email" type="email" className="form-control" />
														<small className="text-danger"><ErrorMessage className="text-danger" name="email" /></small>
													</div>
												</div> */}

												<div className="form-group row">
													<label className="form-label col-md-3">Whatsapp <sup className="text-danger">*</sup></label>
													<div className="col-md-9">
														<PhoneInput
															name="phonenumber"
															className="form-control"
															onChange={e => setFieldValue('phonenumber', e.target.value)}
														/>
														<small className="text-danger"><ErrorMessage className="text-danger" name="phonenumber" /></small>
													</div>
												</div>

												{/* <div className="form-group row">
													<label className="form-label col-md-3">Site</label>
													<div className="col-md-9">
														<Field name="site" type="text" className="form-control" placeholder=""  />
														<small className="text-danger"><ErrorMessage className="text-danger" name="site" /></small>
													</div>
												</div> */}

											</div>

											<div className="box-footer">
												<small>
													<sup className="text-danger">(*)</sup> Campos obrigatórios
												</small>
												<button type="submit" className="btn btn-outline-secondary pull-right">Próximo</button>
											</div>
										</Form>
									)}
								</Formik>
							</div>


							<div className={tabSelected == 1 ? 'tabs-item active' : 'tabs-item'}>
								<Formik
									initialValues={{
										instagram: '',
										facebook: '',
										twitter: ''
									}}
									validationSchema={stepSocial}
									onSubmit={values => {
										this.setState({
											socialValues: values,
											tabSelected: 2
										})
										this.saveInfo();
									}}
									>
									{({ errors, touched }) => (
										<Form>
											<div className="box-body">

												<div className="form-group row">
													<label className="form-label col-md-12">
														Informe apenas o nome do perfil, ex: minhabarbearia
													</label>
												</div>

												<div className="form-group row">
													<label className="form-label col-md-2"><i className="fab fa-instagram"></i> Instagram </label>
													<div className="col-md-6">
														<div className="input-group">
															<div className="input-group-icon">@</div>
															<Field name="instagram" className="form-control" placeholder=""  />
														</div>
														<small className="text-danger"><ErrorMessage className="text-danger" name="instagram" /></small>
													</div>
												</div>

												<div className="form-group row">
													<label className="form-label col-md-2"><i className="fab fa-facebook"></i> Facebook </label>
													<div className="col-md-6">
														<div className="input-group">
															<div className="input-group-icon">@</div>
															<Field name="facebook" className="form-control" placeholder=""  />
														</div>
														<small className="text-danger"><ErrorMessage className="text-danger" name="facebook" /></small>
													</div>
												</div>

												<div className="form-group row">
													<label className="form-label col-md-2"><i className="fab fa-twitter"></i> Twitter </label>
													<div className="col-md-6">
														<div className="input-group">
															<div className="input-group-icon">@</div>
															<Field name="twitter" className="form-control" placeholder=""  />
														</div>
														<small className="text-danger"><ErrorMessage className="text-danger" name="twitter" /></small>
													</div>
												</div>

											</div>

											<div className="box-footer">
												<button 
													type="button"
													className="btn btn-link"
													onClick={() => this.setState({tabSelected:0})}>Anterior</button>

												<button type="submit" className="btn btn-outline-secondary pull-right">Enviar</button>
											</div>
										</Form>
									)}
								</Formik>
							</div>


							<div className={tabSelected == 2 ? 'tabs-item active' : 'tabs-item'}>
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							</div>

							</div>
					</div>
				</div>
				
			</div>
    	);
	}
}

export { Configure };