import React, { Component } from "react";
import { Link } from 'react-router-dom';

import './InputSearchClients.scss';
import { settings } from "../../settings/";
import { imageApi } from '../../_helpers/imageApi';
import { getInitials } from "../../_helpers/clean-string";
import { clientsService } from "../../_services/clients.service";
import { Actions } from "../../flux";


class InputSearchClients extends Component {
    state = {
        clients: []
    }

    clientName = value => {
        // console.log('clientName', value)
        if (value.length >= 2) {
            clientsService.findByName(value).then(res => {
                // console.log('findByName res', res)
                if (res.success) {
                    // console.log('My clients', res.data)
                    let emptyList = { type: 'vazio', name: value };
                    res.data.push(emptyList);
                    this.setState({ clients: res.data })
                }
            })
            .catch(err => {
                console.log('findByName err', err)
            })
        } else {
            this.setState({ clients: [] })
        }
    }

    selectClient = client => {
        const { onSelectClient } = this.props
        if (onSelectClient) onSelectClient(client)
        this.setState({clients: []})
    }

    addClient = clientName => {
        const data = { name: clientName };
        const { onSelectClient } = this.props
        clientsService.createSimple(data).then(res => {
            // console.log('createSimple', res)
            if (res.success == true) {
                if (onSelectClient) onSelectClient(res.data)
            } else {
                Actions.showError(err.error);
            }
        }).catch(err => {
            Actions.showError(err);
        })
        // this.setState({clients: []})
    }

    renderList = items => {
		return (
            <div className="client-list">
                {items.length > 0 &&
                <ul className="list-group">
                    {items.map(item => (
                        (item.type == 'vazio') 
                            ? <li key={item.id} className="list-group-item list-group-item-action" onClick={() => this.addClient(item.name)}>
                                <i className="fe fe-user-plus mr-5"></i> Criar cliente: <b>{item.name}</b>
                              </li>
                            : <li key={item.id} className="list-group-item list-group-item-action" onClick={() => this.selectClient(item)}>
                                <i className="fe fe-user-check mr-5"></i> 
                                {item.name} {(item.cellphone) ? `- ${item.cellphone}` : ''} {(item.email) ? `- ${item.email}` : ''}
                            </li>
                    ))}
                </ul>}
            </div>
		);
    }

    render = () => {
        const { clients } = this.state
        return (
            <div className="input-search-clients-component">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Busque pelo nome, celular ou e-mail"
                    onChange={e => this.clientName(e.target.value)} />

                {this.renderList(clients)}
            </div>
        );
    }
}


export { InputSearchClients };