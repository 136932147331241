import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'

import './PaymentSelector.scss';
import { settings } from "../../settings/";
import { clientsService } from "../../_services/clients.service";
import { Actions } from "../../flux";
import { numberToReal } from "../../utils/money";
import MoneyInput from "../MaskedInput/money";


class PaymentSelector extends Component {
    state = {
        payments: [],
        total: 0
    }

    componentDidMount = () => {
        const { price } = this.props
        this.setState({total:(price) ? price : 0})
    }

    componentDidUpdate = () => {
        const { price } = this.props
        const { total } = this.state
        if (price != total) {
            this.setState({
                total:(price) ? price : 0,
                payments: []
            })
        }
    }

    addPayment = (type, total) => {
        const { payments } = this.state
        let payment = {
            method: type,
            amount: total
        }
        payments.push(payment);
        this.setState(payments);
        this.updateParent();
    }

    clickItem = val => {
        const { payments } = this.state
        let value = val.split('.')
        payments[parseInt(value[0])].method = value[1];
        this.setState(payments);
        this.updateParent();
    }

    changeAmount = (i, amount) => {
        const { payments } = this.state
        payments[i].amount = amount.replace('R$ ', '');
        this.setState(payments);
        this.updateParent();
    }

    updateParent = () => {
        const { payments } = this.state
        const { onChangePayments } = this.props
        if (onChangePayments) onChangePayments(payments);
    }

	renderItems = items => items.length > 0 ?
        <div className="items">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Valor</th>
                            <th>Método</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td width="30">#{index+1}</td>
                                <td width="180">
                                    <div className="input-group">
                                        <div className="input-group-icon">R$</div>
                                        <MoneyInput
                                            value={item.amount}
                                            onChange={e => this.changeAmount(index, e.value)}
                                            />
                                    </div>
                                </td>
                                <td>
                                    <i className={`payments payments-sm payments-money ${item.method == 'money' ? '' : 'opaque'}`}
                                        data-tip="Dinheiro"
                                        onClick={() => this.clickItem(`${index}.money`)}></i>
                                    <i className={`payments payments-sm payments-generic-card ${item.method == 'card' ? '' : 'opaque'}`}
                                        data-tip="Cartão"
                                        onClick={() => this.clickItem(`${index}.card`)}></i>
                                    <i className={`payments payments-sm payments-pix ${item.method == 'pix' ? '' : 'opaque'}`}
                                        data-tip="Pix"
                                        onClick={() => this.clickItem(`${index}.pix`)}></i>
                                    <ReactTooltip />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    : null

    render = () => {
        const { payments } = this.state
        const { total } = this.state
        const paymentsTotal = payments.sum('amount');
        const paymentOver = paymentsTotal - total;
        const paymentPending = total - paymentsTotal;

        return (
            <div className="payment-selector-component">
                <p>Selecione o método de pagamento:</p>
                {
                    payments.length == 0 &&
                    <div>
                        <i className="payments payments-money" data-tip="Dinheiro" onClick={() => this.addPayment('money', total)}></i>
                        <i className="payments payments-generic-card" data-tip="Cartão" onClick={() => this.addPayment('card', total)}></i>
                        <i className="payments payments-pix" data-tip="Pix" onClick={() => this.addPayment('pix', total)}></i>
                    </div>
                }

                { payments.length == 0 && <ReactTooltip /> }

                {this.renderItems(payments)}

                { payments.length > 0 && paymentsTotal > total && <p className="text-danger">+ {numberToReal(paymentOver)}</p> }
                { payments.length > 0 && paymentsTotal < total && <p className="text-warning">- {numberToReal(paymentPending)}</p> }
                { payments.length > 0 && paymentsTotal == total && <p className="text-success">{numberToReal(paymentsTotal)}</p> }

                {
                    payments.length > 0 && payments.length < 3 &&
                    <button className="btn btn-sm btn-info" onClick={() => this.addPayment('card', 0)}>Adicionar método</button>
                }
            </div>
        );
    }
}


export default PaymentSelector;