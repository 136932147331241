import React, { Component } from "react"
import { numberToReal, numberToDollar } from '../../utils/money'

import "./index.scss";

class UnitsCustomSelect extends Component {
    state = {
        units: [],
        unitSelected: null,
        opened: false
    }
    
    componentWillMount = () => {
        const { items, selected } = this.props
        this.setState({ units: items, unitSelected: selected })
    }
    
    componentDidUpdate = () => {
        const { selected } = this.props
        const { unitSelected } = this.state
        if (selected !== unitSelected) {
            this.setState({ unitSelected: selected })
        }
    }

    select = index => {
        const { onSelectUnit } = this.props
        if (onSelectUnit) {
            onSelectUnit(index)
            this.setState({unitSelected:index})
        }
    }

	renderList = items => {
        const { unitSelected, opened } = this.state
        const { selectedText, showAll } = this.props
		return (
            <div className="user-panel user-panel__bordered" onClick={() => this.setState({opened:!opened})}>
                <div className="user-panel-info">
                    {unitSelected == null && <p>Todas as unidades</p>}
                    {unitSelected != null && <p>{items[unitSelected].name}</p>}
                    {selectedText && <small className="text-black-50">{selectedText}</small>}
                </div>

                {
                    items.length > 1 &&
                    <div className="user-panel-actions">
                        <i className="fas fa-chevron-down pull-right"></i>
                    </div>
                }

                {
                    opened &&
                    <div className="units_list">
                        {
                            showAll === true &&
                            <div key={null} className="units_list__item" onClick={() => this.select(null)}>Todas</div>
                        }
                        {items.map((item, index) => (
                            <div key={index} className="units_list__item" onClick={() => this.select(index)}>{item.name}</div>
                        ))}
                    </div>
                }
            </div>
		);
	}

    render = () => {
        const { units } = this.state;
        return (
            <div className="units-custom-select">
                {this.renderList(units)}
            </div>
        );
    }
}


export default UnitsCustomSelect;