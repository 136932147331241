import { Events } from './Events.js';
import { Dispatcher } from './Dispatcher.js';
import { cookie } from '../_helpers/cookie';
import { userService } from '../_services';

const Actions = {
    showHeader() {
        Dispatcher.emit(Events.SHOW_HEADER);
    },
    hideHeader() {
        Dispatcher.emit(Events.HIDE_HEADER);
    },
    showFooter() {
        Dispatcher.emit(Events.SHOW_FOOTER);
    },
    hideFooter() {
        Dispatcher.emit(Events.HIDE_FOOTER);
    },
    showMenu() {
        Dispatcher.emit(Events.SHOW_MENU);
    },
    hideMenu() {
        Dispatcher.emit(Events.HIDE_MENU);
    },
    toggleMenu() {
        Dispatcher.emit(Events.TOGGLE_MENU);
    },
    
    
    acceptTermsShow() {
        Dispatcher.emit(Events.ACCEPT_TERMS_SHOW);
    },
    acceptTermsHide() {
        Dispatcher.emit(Events.ACCEPT_TERMS_HIDE);
    },
    acceptTermsRequest() {
        Dispatcher.emit(Events.ACCEPT_TERMS_REQUEST);
    },
    acceptTermsSuccess() {
        Dispatcher.emit(Events.ACCEPT_TERMS_SUCCESS);
    },
    acceptTermsFailure() {
        Dispatcher.emit(Events.ACCEPT_TERMS_FAILURE);
    },
    
    
    showModalSchedule(date, hour, unit, professional) {
        // console.log('showModalSchedule', date, hour, unit, professional)
        const data = {
            when: (date) ? date : null,
            hour: (hour) ? hour : null,
            unit: (unit) ? unit : null,
            professional: (professional) ? professional : null
        }
        Dispatcher.emit(Events.ADD_ORDER_SHOW, data);
    },
    hideModalSchedule() {
        Dispatcher.emit(Events.ADD_ORDER_HIDE);
    },
    requestModalSchedule() {
        Dispatcher.emit(Events.ADD_ORDER_REQUEST);
    },
    successModalSchedule() {
        Dispatcher.emit(Events.ADD_ORDER_SUCCESS);
    },
    failureModalSchedule() {
        Dispatcher.emit(Events.ADD_ORDER_FAILURE);
    },
    scheduleDateUpdated(date) {
        if (date instanceof Date) {
            Dispatcher.emit(Events.MODAL_SCHEDULE_DATE_UPDATED, date);
            cookie.setSessionItem('scheduleDate', date);
            // document.cookie = 'scheduleDate='+date;
        } else {
            console.warn('Variável não é do tipo Date()')
        }
    },
    scheduleHourUpdated(hour) {
        Dispatcher.emit(Events.MODAL_SCHEDULE_HOUR_UPDATED, hour);
        cookie.setSessionItem('scheduleHour', hour);
    },

    // MODAL SCHEDULE DETAILS
    showModalScheduleDetails(data) {
        Dispatcher.emit(Events.MODAL_SCHEDULE_DETAILS_SHOW, data);
    },
    hideModalScheduleDetails() {
        Dispatcher.emit(Events.MODAL_SCHEDULE_DETAILS_HIDE);
    },

    // MODAL FINISH_ORDER
    showModalFinishOrder(data) {
        Dispatcher.emit(Events.MODAL_FINISH_ORDER_SHOW, data);
    },
    hideModalFinishOrder() {
        Dispatcher.emit(Events.MODAL_FINISH_ORDER_HIDE);
    },

    // QUOTA
    professionalQuotaUpdated() {
        Dispatcher.emit(Events.PROFESSIONAL_QUOTA_UPDATED);
    },

    // PLAN HAS UPDATED AND WAS SAVED ON COOKIES
    planUpdated() {
        Dispatcher.emit(Events.PLAN_UPDATED);
    },

    // USER
    userUpdated() {
		userService.getMyData().then(data => {
            let user = JSON.parse(localStorage.getItem('user'));
            user.user = data;
            localStorage.setItem('user', JSON.stringify(user));
            Dispatcher.emit(Events.USER_UPDATED);
        })
    },

    toggleProfileBox() {
        Dispatcher.emit(Events.TOGGLE_PROFILE_BOX);
    },
    closeProfileBox() {
        Dispatcher.emit(Events.CLOSE_PROFILE_BOX);
    },
    showProfileBox() {
        Dispatcher.emit(Events.SHOW_PROFILE_BOX);
    },
    logout() {
        Dispatcher.emit(Events.LOGOUT);
    },
    showError(text, type, title) {
        let typee = type != undefined ? type : 'warning';
        let titlee = title != undefined ? title : 'Ops.';
        let textt = text != undefined ? text : 'Ocorreu um erro inesperado. Tente novamente mais tarde.';
        Dispatcher.emit(Events.SHOW_ERROR, textt, typee, titlee);
    },
    showSuccess(data) {
        Dispatcher.emit(Events.SHOW_SUCCESS, data);
    },
    showModalTreinamentos() {
        Dispatcher.emit(Events.SHOW_MODAL_TREINAMENTOS);
    },
    hideModalTreinamentos() {
        Dispatcher.emit(Events.HIDE_MODAL_TREINAMENTOS);
    },
    showModal(sendToApi = true) {
        Dispatcher.emit(Events.SHOW_MODAL, sendToApi);
    },
    hideModal() {
        Dispatcher.emit(Events.HIDE_MODAL);
    },
    menuIntern() {
        Dispatcher.emit(Events.MENU_INTERN);
    },
    menuHome() {
        Dispatcher.emit(Events.MENU_HOME);
    },
    logoHome() {
        Dispatcher.emit(Events.LOGO_HOME);
    },
    logoIntern() {
        Dispatcher.emit(Events.LOGO_INTERN);
    },
    successForm() {
        Dispatcher.emit(Events.SUCCESS_FORM);
    },
    globalTransition() {
        Dispatcher.emit(Events.GLOBAL_TRANSITION);
    },
    cepChange(data) {
        Dispatcher.emit(Events.CEP_CHANGE, data);
    }
};

export { Actions };
