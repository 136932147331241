import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import ServicesList from '../../components/ServicesList'
import ProfessionalsList from '../../components/ProfessionalsList'
import { HorarioAtendimento } from '../../components/HorarioAtendimento'

import { Actions } from '../../flux';
import { texts, settings, colors, images } from '../../settings/';
import { userService } from '../../_services/user.service'
import { unitsService } from '../../_services/units.service'

const Pin = ({ text }) => (
    <div style={{
        position: 'relative',
        color: 'white', 
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',

        borderRadius:'50%',
        border: `8px solid ${colors.secondary}`,
        width: 20,
        height: 20,
        transform: 'translate(-50%, 0px) rotate(0deg)',
    }}>
        {/* {text} */}
        <div style={{
            position: 'absolute',
            width: '0px',
            height: '0px',
            bottom: '-27px',
            left: '-8px',
            border: '10px solid transparent',
            borderTop: `17px solid ${colors.secondary}`,
        }}></div>
    </div>
  );
  

class VerUnidade extends Component {
	state = {
		unit: null,
		loading: false,
        success: false,
        zoom: 14,
        center: { lat: 22.002939, lng: 45.929283 },
        mapOptions: {
            styles: colors.mapStyles
        }
	}

    getUnit = () => {
        const { match: { params } } = this.props;
		unitsService.getOne(params.id).then(data => {
            // console.log('getUnit', data);
			this.setState({
				unit: data,
                loading: false,
                center: { lat: data.lat, lng: data.lng }
			})
        })
    }

	componentDidMount = () => {

		Actions.showMenu()
		Actions.showHeader()
        Actions.showFooter()
        
		this.setState({ loading: true })

        this.getUnit();
	}

	render = () => {

		const {
			loading,
            unit,
            zoom,
            center,
            mapOptions
		} = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Ver Unidade - { texts.title }</title>
	            </Helmet>

	    		<div className="unit">
                    <nav aria-label="breadcrumb pt20">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Dashboard</Link> / <Link to="/unidades">Unidades</Link> /  Ver Unidade
                            </li>
                        </ol>
                    </nav>

                    {
                        loading &&
                        <div className="row" style={{ height: 200, paddingTop: 80 }}>
                            <div className="col-md-12 text-center">
                                <p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
                            </div>
                        </div>
                    }

                    {
                        !loading && unit &&
                        <main>
                            <div className="jumbotron">
                                <div className="row">
                                    <div className="col-md-5">
                                        <p className="mb-0">{unit.establishment_data.name}</p>
                                        <h1 className="mt-0 mb-30 display-3">{unit.name}</h1>

                                        <p className="mb-10 text-lg">ID: #{`${unit.public_string}`}</p>

                                        <p className="mb-10 text-lg"><i className="fas fa-mobile-alt"></i> {`${unit.phonenumber}`}</p>

                                        <p className="mt-10 mb-20 text-lg"><i className="fe fe-map-pin"></i> {`${unit.address}, ${unit.street_number} - ${unit.neighborhood} - ${unit.city} - ${unit.state}`}</p>
                                        {/* <button className="btn btn-lg btn-outline-primary">Open your profile</button> */}
                                    </div>
                                    <div className="col-md-7">
                                        <div className="transition-left jumbotron-bg" id="map">
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: settings.googleMapsKey }}
                                                defaultCenter={center}
                                                defaultZoom={zoom}
                                                options={mapOptions}
                                                >
                                                <Pin
                                                    lat={unit.lat}
                                                    lng={unit.lng}
                                                    text={unit.name}
                                                />
                                            </GoogleMapReact>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container-fluid">

                                {
                                    (unit.services.length === 0 || unit.professionals.length === 0 || unit.opening_hours.length === 0) &&
                                    <div className="row">
    
                                        <div classame="col-md-12">
                                            <div className="ml-15 mb-10">
                                                <div className="alert alert-warning mt-10">
                                                    <h3> <i className="fe fe-alert-octagon"></i> Atenção</h3>
                                                    <b className="text-lg">
                                                        Falta pouco para finalizar o seu cadastro:
                                                    </b>
                                                    <ul className="text-lg text-white">
                                                        {
                                                            unit.services.length === 0 && 
                                                            <li>
                                                                <Link to={`/unidade/${unit.public_string}/servico/adicionar`} className="text-white">
                                                                    Cadastre um serviço <i className="fe fe-link"></i>
                                                                </Link>
                                                            </li>
                                                        }
                                                        {
                                                            unit.professionals.length === 0 && 
                                                            <li>
                                                                <Link to={`/unidade/${unit.public_string}/profissional/adicionar`} className="text-white">
                                                                    Cadastre um profissional <i className="fe fe-link"></i>
                                                                </Link>
                                                            </li>
                                                        }
                                                        {
                                                            unit.opening_hours.length === 0 && 
                                                            <li>
                                                                <Link to={`/unidade/${unit.public_string}/horario/adicionar/util-days`} className="text-white">
                                                                    Cadastre horários de atendimento <i className="fe fe-link"></i>
                                                                </Link>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                }

                                <div className="row">

                                    <div className="col-md-8">
                                        <div className="box pb-10">
                                            <div className="box-header">
                                                <h3>Serviços</h3>
                                                <div className="box-actions">
                                                    <Link to={`/unidade/${unit.public_string}/servico/adicionar`} className="btn btn-rounded btn-secondary btn-sm btn btn-outline-secondary"><i className="fe fe-plus"></i> Adicionar</Link>
                                                </div>
                                            </div>
                                            <ServicesList items={unit.services} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="box">
                                            <div className="box-header">
                                                <h3>Profissionais</h3>
                                                <div className="box-actions">
                                                    <Link to={`/unidade/${unit.public_string}/profissional/adicionar`} className="btn btn-rounded btn-secondary btn-sm btn btn-outline-secondary"><i className="fe fe-plus"></i> Adicionar</Link>
                                                </div>
                                            </div>
                                            <div className="box-body">
                                                <ProfessionalsList items={unit.professionals} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="box">
                                            <div className="box-header">
                                                <h3>Horário atendimento</h3>
                                                <div className="box-actions">
                                                    <Link to={`/unidade/${unit.public_string}/horario/adicionar/null`} className="btn btn-rounded btn-secondary btn-sm btn btn-outline-secondary"><i className="fe fe-plus"></i> Adicionar</Link>
                                                </div>
                                            </div>
                                            <div className="box-body">
                                                <HorarioAtendimento
                                                    items={unit.opening_hours}
                                                    updateHours={this.getUnit} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </main>
                    }

				</div>
			</div>
    	);
	}
}

export { VerUnidade };