const GENERO = [
  {
    label: "Masculino",
    value: "M"
  },
  {
    label: "Feminino",
    value: "F"
  },
  {
    label: "Outros",
    value: "O"
  }
];

export { GENERO };
