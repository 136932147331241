import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { userService } from '../../_services/user.service'
import { unitsService } from '../../_services/units.service'
import viaCep from '../../_services/viacep'
import CepInput from '../../components/MaskedInput/cep'
import PhoneInput from '../../components/MaskedInput/phone'

const formUnidade = yup.object().shape({
	name: yup
		.string()
		.max(25, 'Limite de 25 letras')
		.required('Campo obrigatório'),

	cep: yup
		.string()
		.required('Campo obrigatório'),

	phonenumber: yup
		.string()
		.required('Campo obrigatório'),

	street_number: yup
		.string()
		.required('Campo obrigatório'),

	address: yup
		.string()
		.required('Campo obrigatório'),

	neighborhood: yup
		.string()
		.required('Campo obrigatório'),

	city: yup
		.string()
		.required('Campo obrigatório'),

	state: yup
		.string()
		.required('Campo obrigatório')
});

class CriarUnidade extends Component {
	state = {
		units: [],
		canCreateUnits: false,
		user: null,
		loading: false,
		zipcode: '',
		neighborhood: '',
		address: '',
		city: '',
		state: '',
		lat: '',
		lng: '',
		phonenumber: '',
		street_number: '',
		name: '',
		cepLoaded: false,
		hasAddress: false,
		success: false,
		cepError: false,
		newId: null
	}

	componentDidMount = () => {
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

		this.setState({ loading: true })

		unitsService.myUnits().then(res => {
			if (res.length > 0) this.setState({units: res})
			this.verifyCanCreateUnits();
		})

		userService.getMyData().then(data => {
			// console.log('myData', data)
			this.setState({
				user: data,
				phonenumber: (data && data.establishment_data) ? data.establishment_data.phonenumber : null,
				loading: false
			})
		})
	}

	verifyCanCreateUnits = () => {
		const { units } = this.state
		let found = false;
		if (units.length > 0) {
			units.map(unit => {
				if (found == false) {
					unit.plan_period.map(period => {
						if (found == false) {
							if (period.pagarme_subscription) {
								found = true;
								this.setState({canCreateUnits: true});
							}
						}
					});

				}
			});
		}
	}

	getLatLng = text => {
		viaCep.latLng(text).then(res => {
			if (res.status === 200) {
				const result = res.data.results[0];

				const lat = result.geometry.location.lat;
				const lng = result.geometry.location.lng;
				
				this.setState({
					lng: lng,
					lat: lat
				})
			}
		})
	}

	buscaCep = text => {
		viaCep.searchCep(text).then((res) => {
			this.setState({cepError: false})
			
			if (res.statusText === 'OK') {
				if (!res.data.erro) {
					let rua       = res.data.logradouro;
					let bairro    = res.data.bairro;
					let cidade    = res.data.localidade;
					let estado    = res.data.uf;
					let cep    	  = res.data.cep;
	
					// Mudando o estado
					this.setState({
						neighborhood: bairro,
						zipcode: cep,
						address: rua,
						city: cidade,
						state: estado,
						cepLoaded: true,
						hasAddress: (rua) ? true : false,
					})
	
					this.getLatLng(`${rua}, ${cidade}`);
				} else {
					this.setState({cepError: true})
				}
			}
		})
	}

	changeCep = value => {
		if (value.length > 8) {
			this.buscaCep(value)
		} else {
			this.clearFields();
		}
	}

	// changeNumber = value => {
	// 	this.setState({ street_number: value });
	// 	const { address, city } = this.state
	// 	this.getLatLng(`${address}, ${city}, ${value}`);
	// }

	changeField = (field, value) => {
		this.setState({
			[field]: value
		});

		const { address, city } = this.state
		this.getLatLng(`${address}, ${city}, ${value}`);
	}

	clearFields = () => {
		this.setState({
			neighborhood: '',
			zipcode: '',
			address: '',
			city: '',
			state: '',
			lng: '',
			lat: '',
			cepLoaded: false,
			hasAddress: false
		})
	}

	saveInfo = values => {
		const {
			user,
			zipcode,
			neighborhood,
			address,
			city,
			state,
			lat,
			lng,
			phonenumber,
			street_number,
			name
		} = this.state

		values.street_number = street_number;
		values.name = name;
		values.phonenumber = phonenumber;
		values.establishment = user.establishment_data.establishment_id;
		values.zipcode = zipcode;
		values.neighborhood = neighborhood;
		values.address = address;
		values.city = city;
		values.state = state;
		values.lat = lat;
		values.lng = lng;

		unitsService.create(values).then(res => {
			Actions.showSuccess({
				title: 'Parabéns!',
				text: 'Unidade cadastrada com sucesso.'
			})
			Actions.userUpdated();
			this.setState({
				success: true,
				newId: res.public_string
			})
		}).catch(err => {
			Actions.showError(err.error)
		})

	}

	render = () => {

		const {
			name,
			street_number,
			loading,
			user,
			cepLoaded,
			zipcode,
			neighborhood,
			address,
			city,
			state,
			success,
			cepError,
			phonenumber,
			canCreateUnits,
			newId
		} = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Criar Unidade - { texts.title }</title>
	            </Helmet>

				{
					success && newId &&
					<Redirect to={{
						pathname: `/unidades/${newId}`,
						state: { from: this.props.location }
					}} />
				}

				{/* {
					!canCreateUnits &&
					<Redirect to={{
						pathname: "/unidades",
						state: { from: this.props.location }
						}}
					/>
				} */}

	    		<div className="units">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">Dashboard</Link> / <Link to="/unidades">Unidades</Link> /  Criar Unidade
								</li>
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-8">
							<h2 className="content-title">Criar Unidade</h2>

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading && user &&
								<div className="box">
									<div className="box-header">
										<b>Barbearia</b><br />
										<span>{user.establishment_data.name}</span>
									</div>

									<Formik
										initialValues={{
											name: name,
											cep: zipcode,
											phonenumber: phonenumber,
											street_number: street_number,
											address: address,
											neighborhood: neighborhood,
											city: city,
											state: state
										}}
										enableReinitialize
										validationSchema={formUnidade}
										onSubmit={values => this.saveInfo(values)}
										>
										{({ errors, touched, setFieldValue, isValid }) => (
											<Form>
												<div className="box-body">
													<div className="form-group row">
														<div className="col-3">
															<b>Informações da unidade</b>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Nome da sua unidade <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field
																value={name}
																name="name"
																className="form-control"
																placeholder="Ex: Unidade Centro"
																onChange={e => {
																	this.changeField('name', e.target.value);
																	setFieldValue('name', e.target.value);
																}}
																/>
															<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Whatsapp <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<PhoneInput
																value={phonenumber}
																name="phonenumber"
																className="form-control"
																onChange={e => {
																	this.changeField('phonenumber', e.target.value);
																	setFieldValue('phonenumber', e.target.value);
																}}
															/>
															<small className="text-danger"><ErrorMessage className="text-danger" name="phonenumber" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">CEP <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<CepInput
																name="cep"
																className="form-control"
																onChange={e => {
																	this.changeCep(e.target.value);
																	setFieldValue('cep', e.target.value);
																}} 
															/>
															<small className="text-danger"><ErrorMessage className="text-danger" name="cep" /></small>
															{
																cepError &&
																<small>Falha ao encontrar cep</small>
															}
														</div>
													</div>

													{/* {
														cepLoaded && hasAddress &&
														<div className="form-group row">
															<label className="form-label col-3"></label>
															<div className="col-5">
																<div className="col-12" style={{ marginTop: 15, marginBottom: 15 }}>
																	<b>{address}</b>
																	<p>
																		{neighborhood},<br />
																		{city} - {state}
																	</p>

																	<div className="row">
																		<div className="col-6">
																			<Field name="street_number" className="form-control" placeholder="Número" 
																			onChange={e => {
																				this.changeField('street_number', e.target.value);
																				setFieldValue('street_number', e.target.value);
																			}} />
																			<small className="text-danger"><ErrorMessage className="text-danger" name="street_number" /></small>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													} */}

													{
														// cepLoaded && !hasAddress &&
														cepLoaded &&
														<div>
															<div className="form-group row">
																<label className="form-label col-3">Endereço <sup className="text-danger">*</sup></label>
																<div className="col-9">
																	<Field
																		value={address}
																		name="address"
																		className="form-control"
																		onChange={e => {
																			this.changeField('address', e.target.value);
																			setFieldValue('address', e.target.value);
																		}}
																	/>
																	<small className="text-danger"><ErrorMessage className="text-danger" name="address" /></small>
																</div>
															</div>
															<div className="form-group row">
																<label className="form-label col-3">Número <sup className="text-danger">*</sup></label>
																<div className="col-9">
																	<Field
																		value={street_number} 
																		name="street_number"
																		className="form-control"
																		placeholder="Número" 
																		onChange={e => {
																			this.changeField('street_number', e.target.value);
																			setFieldValue('street_number', e.target.value);
																		}}
																		/>
																	<small className="text-danger"><ErrorMessage className="text-danger" name="street_number" /></small>
																</div>
															</div>
															<div className="form-group row">
																<label className="form-label col-3">Bairro <sup className="text-danger">*</sup></label>
																<div className="col-9">
																	<Field
																		value={neighborhood}
																		name="neighborhood"
																		className="form-control"
																		onChange={e => {
																			this.changeField('neighborhood', e.target.value);
																			setFieldValue('neighborhood', e.target.value);
																		}}
																	/>
																	<small className="text-danger"><ErrorMessage className="text-danger" name="neighborhood" /></small>
																</div>
															</div>
															<div className="form-group row">
																<label className="form-label col-3">Cidade <sup className="text-danger">*</sup></label>
																<div className="col-9">
																	<Field
																		value={city}
																		name="city"
																		className="form-control"
																		onChange={e => {
																			this.changeField('city', e.target.value);
																			setFieldValue('city', e.target.value);
																		}}
																	/>
																	<small className="text-danger"><ErrorMessage className="text-danger" name="city" /></small>
																</div>
															</div>
															<div className="form-group row">
																<label className="form-label col-3">Estado <sup className="text-danger">*</sup></label>
																<div className="col-9">
																	<Field
																		value={state}
																		name="state"
																		className="form-control"
																		onChange={e => {
																			this.changeField('state', e.target.value);
																			setFieldValue('state', e.target.value);
																		}}
																	/>
																	<small className="text-danger"><ErrorMessage className="text-danger" name="state" /></small>
																</div>
															</div>
														</div>
													}

												</div>

												<div className="box-footer">
													<button type="submit" className="btn btn-secondary pull-right">Cadastrar</button>
												</div>
											</Form>
										)}
									</Formik>

								</div>
							}

						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { CriarUnidade };