import { handleResponse } from '../_helpers';
import { userService } from './user.service'
import API from './api'

export const productsService = {
    myProducts,
    create,
    getOne,
    updateStock,
    findByName,
    update
};

function myProducts() {
    return API.get(`/partner/products`)
            .then(handleResponse)
            .then(data => data)
}

function findByName(name) {
  return API.get(`/partner/product/find/${name}`)
          .then(handleResponse)
          .then(data => data)
}

function create(body) {
    const formData = new FormData();
    const data = Object.keys(body);

    data.map(item => {
      formData.append(item, body[item]);
    })

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }

    return API.post(`/partner/products`, formData, config)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function update(product_id, body) {
  const formData = new FormData();
  const data = Object.keys(body);

  data.map(item => {
    formData.append(item, body[item]);
  })

  const config = {
    headers: {
      // Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }

  return API.post(`/partner/product/${product_id}`, formData, config)
          .then(handleResponse)
          .then(res => res)
          .catch(err => {
              return Promise.reject({error: err})
          });
}

function updateStock(productId, quantity) {
  const data = {
    product: productId,
    quantity: quantity
  }

  return API.put(`/partner/products/update-quantity`, data)
          .then(handleResponse)
          .then(data => data)
}

function getOne(id) {
    return API.get(`/partner/product/${id}`)
            .then(handleResponse)
            .then(data => data)
}