import moment from 'moment';

export function sumDurationByServices(items) {
    if (items.length > 0) {
        let total = 0;

        items.map(item => {
            total += moment.duration(item.duration).asMinutes();
        })

        return total;
    } else {
        return 0;
    }
}

export function verifyHourAvailable(offDay, day, hour) {
    let dayCompare = new Date(`${moment(day).format("YYYY-MM-DD")} ${hour}`);
    var valid = true;

    offDay.map(it => {
        let offDayCompareStart = new Date(`${moment(day).format("YYYY-MM-DD")} ${it.hour_start}`);
        let offDayCompareEnd = new Date(`${moment(day).format("YYYY-MM-DD")} ${it.hour_end}`);

        if (
            dayCompare.getTime() >= offDayCompareStart.getTime() &&
            dayCompare.getTime() <= offDayCompareEnd.getTime()
        ) {
            valid = false;
        }
    });

    return valid;
}

export function returnHourBlocked(offDay, day, hour) {
    let dayCompare = new Date(`${moment(day).format("YYYY-MM-DD")} ${hour}`);
    var valid = false;
    console.log(offDay);
    offDay.map(it => {
        let offDayCompareStart = new Date(`${moment(day).format("YYYY-MM-DD")} ${it.hour_start}`);
        let offDayCompareEnd = new Date(`${moment(day).format("YYYY-MM-DD")} ${it.hour_end}`);

        if (
            dayCompare.getTime() >= offDayCompareStart.getTime() &&
            dayCompare.getTime() <= offDayCompareEnd.getTime()
        ) {
            valid = it;
        }
    });

    return valid;
}

export function parseHours(day, openingHours, off) {
    let dayWeekday = moment(day).format("dddd");
    let dayCompare = moment(day).format("YYYY-MM-DD");

    let weekhours = openingHours.filter(obj => obj.weekday === dayWeekday);
    let offHours = off.filter(obj => (obj.weekday === dayWeekday || (obj.date != null && obj.date.includes(dayCompare))));

    let hourStart = (weekhours.length > 0) ? weekhours[0].hour_start : null;
    let hourEnd = (weekhours.length > 0) ? weekhours[0].hour_end : null;
    if (!hourStart || !hourEnd) { 
        return {
            hours: null,
            showHours: true,
            offDay: []
        };
    }

    let start = parseInt(hourStart.substring(0, 2));
    let end = parseInt(hourEnd.substring(0, 2));

    var hours = [];
    for (let index = start; index <= end; index++) {
        hours.push({ id: `${index}:00:00`, label: `${index}:00` });
        if (index < end) {
            hours.push({ id: `${index}:30:00`, label: `${index}:30` });
        }
    }

    return {
        hours:hours,
        showHours: true,
        offDay: offHours
    };
}

export function scheduleHour(time) {
    return `${moment(time).format("HH")}:${moment(time).format("mm")}:${moment(time).format("ss")}`;
}