import React from "react";

import { ESTADOS } from "../../settings/enums";
import { Dropdown } from "../../components";

const DropdownEstados = ({ value, handleChange, disabled }) => (
  <Dropdown
    label="*Estado:"
    defaultLabel="Selecione seu estado"
    name="state"
    data={ESTADOS.map(estado => ({ label: estado.value, value: estado.value }))}
    value={value}
    handleChange={handleChange}
    disabled={disabled}
  />
);

export { DropdownEstados };
