import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import { GithubPicker } from 'react-color';

import "./CriarServico.scss";

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { unitsService } from '../../_services/units.service'
import { servicesService } from '../../_services/services.service'

import ServicesList from '../../components/ServicesList'
import MoneyInput from '../../components/MaskedInput/money';

const form = yup.object().shape({
	name: yup
		.string()
		.required('Campo obrigatório'),

	description: yup
		.string()
		.required('Campo obrigatório'),

	duration: yup
		.string()
		.required('Campo obrigatório'),

	price: yup
		.string()
		.required('Campo obrigatório')
});

class CriarServico extends Component {
	state = {
		unit: null,
		loading: false,
		success: false,
		color: null,
		pickColor: false
	}

	componentDidMount = () => {
		const { match: { params } } = this.props;
		
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

		this.setState({ loading: true })

		unitsService.getOne(params.id).then(data => {
			this.setState({
				unit: data,
                loading: false
			})
        })
	}

	saveInfo = values => {
        const { unit, color } = this.state
		if (!color) {
			Actions.showError('Selecione a cor');
			return;
		} 

		values.unity = unit.unity_id 
		values.color = color;


		servicesService.create(values).then(res => {
			console.log('servicesService create', res)
			Actions.showSuccess({
				title: 'Parabéns!',
				text: 'Serviço cadastrado com sucesso'
			})
			this.setState({ success: true })
		}).catch(err => {
			Actions.showError(err.error)
		})
	}
	
	handleChangeComplete = color => {
		console.log('handleChangeComplete', color)
		this.setState({ color: color.hex, pickColor: false });
	}

	render = () => {
		const { match: { params } } = this.props;
		const { loading, unit, success, pickColor, color, checked, photoSource } = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Adicionar Serviço - { texts.title }</title>
	            </Helmet>

				{
					success && 
					<Redirect to={{
					pathname: `/unidades/${unit.public_string}`,
					state: { from: this.props.location }
					}}
				/>
				}

	    		<div className="units">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">Dashboard</Link> / <Link to="/unidades">Unidades</Link> / Adicionar Serviço
								</li>
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-6">
							<h2 className="content-title">Adicionar Serviço</h2>

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading && unit &&
								<div className="box">
									<div className="box-header">
										{/* <b>Barbearia</b><br /> */}
										<span>Adicione os serviços na {unit.establishment_data.name} - <b>{unit.name}</b></span>
									</div>

									<Formik
										initialValues={{
											name: '',
											description: '',
											duration: '',
											price: '',
										}}
										validationSchema={form}
										onSubmit={values => this.saveInfo(values)}
										>
										{({ errors, touched, setFieldValue, isValid }) => (
											<Form>
												<div className="box-body">
													<div className="form-group row">
														<div className="col-6">
															<b>Informações do serviço</b>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">
															<b>Nome</b> <sup className="text-danger">*</sup>
														</label>
														<div className="col-9">
															<Field name="name" className="form-control" placeholder="Ex: Corte"  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">
															<b>Descrição</b> <sup className="text-danger">*</sup><br />
															Isso ficará visível para seus clientes, capriche!
														</label>
														<div className="col-9">
															<Field name="description" component="textarea" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="description" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">
															<b>Duração</b> <sup className="text-danger">*</sup>
														</label>
														<div className="col-3">
                                                            <select className="form-control" onChange={e => setFieldValue('duration', e.target.value)}>
                                                                <option value="">Selecione</option>
                                                                <option value="00:15:00">15 min</option>
                                                                <option value="00:30:00">30 min</option>
                                                                <option value="00:45:00">45 min</option>
                                                                <option value="01:00:00">1h</option>
                                                                <option value="01:30:00">1h30</option>
                                                                <option value="02:00:00">2h</option>
                                                                <option value="02:30:00">2h30</option>
                                                                <option value="03:00:00">3h</option>
                                                            </select>
															<small className="text-danger"><ErrorMessage className="text-danger" name="duration" /></small>
														</div>

														<label className="form-label col-2 text-right">
															<b>Preço</b> <sup className="text-danger">*</sup>
														</label>
														<div className="col-4">
                                                            <div className="input-group">
                                                                <div className="input-group-icon">R$</div>
																<MoneyInput onChange={e => setFieldValue('price', e.value)} />
															    {/* <Field name="price" className="form-control" /> */}
                                                            </div>
															<small className="text-danger"><ErrorMessage className="text-danger" name="price" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">
															<b>Cor</b> <sup className="text-danger">*</sup><br />
															Selecione uma cor para este tipo de serviço assim ficará mais fácil identificá-lo na agenda. ;)
														</label>
														<div className="col-9 col-offset-3 mt-30">
															{ !pickColor && color && <div className="color-selected" style={{backgroundColor: color}}></div> }
															{ !pickColor && <button className="btn btn-sm btn-info" onClick={() => this.setState({pickColor:true})}>Selecionar cor</button> }
															{ pickColor && <GithubPicker  onChangeComplete={ this.handleChangeComplete }/> }
														</div> 
													</div>

												</div>

												<div className="box-footer">
													<button type="submit" className="btn btn-secondary pull-right">Cadastrar</button>
												</div>
											</Form>
										)}
									</Formik>

								</div>
							}

						</div>


                        {
						    !loading && unit && unit.services.length > 0 &&
                            <div className="col-md-5 mt-50">
                                <div className="box pb-10">
                                    <div className="box-header">
                                        <h3>Serviços da unidade</h3>
                                    </div>
                                    <ServicesList items={unit.services} />
                                </div>
                            </div>
                        }
					</div>

				</div>
			</div>
    	);
	}
}

export { CriarServico };