import React from "react";
import { WhatsappShareButton } from 'react-share';

const WhatsappShare = ({
  size = 'md', // lg - md - sm - xs
  content,
  label
}) => {
    return (
        <WhatsappShareButton
            url={content}
            resetButtonStyle={true}
            >
            <span className={`btn btn-success btn-${size} flex`}>
                <i className="brands brands-whatsapp-w"></i> {label}
            </span>
        </WhatsappShareButton>
    );
};

export { WhatsappShare };
