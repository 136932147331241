import React, { Component } from 'react';

class PasswordRevealer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'password',
        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            type: this.state.type === 'input' ? 'password' : 'input',
        });
    }

    render() {
        const {
            label,
            name,
            value,
            handleChange,
            handleKeyPress,
            placeholder,
            containerClassNames,
            inputClassName,
        } = this.props;

        return (
            <div className={containerClassNames} style={{ position: 'relative' }}>
                {label && <label>{label}</label>}
                <input
                    type={this.state.type}
                    name={name}
                    value={value}
                    onChange={value => handleChange(value)}
                    onKeyPress={value => handleKeyPress && handleKeyPress(value)}
                    placeholder={placeholder}
                    className={inputClassName}
                    maxLength="38"
                    style={{ textTransform: 'none' }}
                />
                <span
                    className="form_icon"
                    onMouseDown={this.toggleShow}
                    onMouseUp={this.toggleShow}
                    onTouchStart={this.toggleShow}
                    onTouchEnd={this.toggleShow}
                >
                    <i className={this.state.type === 'input' ? 'fe fe-eye' : 'fe fe-eye-off'}></i>
                </span>
            </div>
        );
    }
}

export default PasswordRevealer;