import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import {
    Actions
} from '../../flux';


import {
    images,
	texts,
    settings
} from '../../settings';

import { userService } from '../../_services';
import { cleanString } from '../../_helpers';
import { professionalsService } from '../../_services/professionals.service';
import Avatar from '../../components/Avatar';
import PasswordRevealer from '../../components/PasswordRevealer';

// *
// React router Example
//	https://reacttraining.com/react-router/web/example/auth-workflow
// *

class CadastroProfissional extends Component {

	constructor(props) {
		super(props);

        this.state = {
            email: null, 

            password: '',
            passwordConfirm: '',

            loaded: false,
            loading: false,
            error: '',
            success: false,

            capsLock: false,

            professional: null
        };

		this.images = images;
		this.texts = texts;
        this.globalDelay = settings.globalDelay;

        this.runTransition = this.runTransition.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkCapsLock = this.checkCapsLock.bind(this);
    }
    
    checkCapsLock(e) {
        if (e.getModifierState("CapsLock")) {
            this.setState({ capsLock: true });
        } else {
            this.setState({ capsLock: false});
        }
    }

    runTransition() {
    	Actions.globalTransition();
    }

    handleChange(e) {
        const { name, value } = e.target;
        console.log(name, value)
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
		e.preventDefault();

        const { email, password, passwordConfirm, loading } = this.state;

        if (loading) {
        	return;
        }

        // stop here if form is invalid
        if (!email || !password || !passwordConfirm) {
            Actions.showError('Informe suas credenciais');
            return;
        }

        // stop here if form is invalid
        if (password !== passwordConfirm) {
            Actions.showError('As senhas não conferem. Verifique!');
            return;
        }

        this.setState({ loading: true });

        const data = {
            professional: email,
            password: password
        }

        professionalsService.completeSignup(data).then(res => {
            console.log('completeSignup res', res);
            this.setState({
                success: true,
                loading: false
            })
        }).catch(err => {
            Actions.showError(err);
            console.log('getProfessionalByEmail err', err);
            this.setState({
                loading: false
            })
        })

        // userService.login(username, password).then(
        //     user => {
        //         console.log('login page success', user);
        //         // this.runTransition();

        //         const { from } = this.props.location.state || { from: { pathname: "/" } };
        //         this.props.history.push(from);

        //         // setTimeout(function() {
        //         //     const { from } = this.props.location.state || { from: { pathname: "/" } };
        //         //     this.props.history.push(from);
        //         // }.bind(this), this.globalDelay);
        //     },
        //     err => {
        //         this.setState({ error: err.error, loading: false });
        //     }
        // );
	}

	componentDidMount() {
        const { match: { params } } = this.props;
        
		Actions.hideHeader();
        Actions.hideFooter();
        document.addEventListener("keydown", this.checkCapsLock);
        
        this.setState({
            email: params.email,
            loaded:false
        });
        professionalsService.getProfessionalByEmail(params.email).then(res => {
            console.log('getProfessionalByEmail res', res);
            this.setState({professional:res, loaded:true});
        }).catch(err => {
            Actions.showError(err);
            console.log('getProfessionalByEmail err', err);
        })
	}

	componentWillUnmount() {
	}

	render() {
        const { loaded, loading, capsLock, professional, password, passwordConfirm, success } = this.state;

        const btnText = (loading) ? 'Carregando...' : 'Completar cadastro';

        if (success) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: this.props.location }
                }}
              />
            )
          }

        return (
            <div className="wrapper" ref="login">
                {!loaded && <div className="body-loading"><i className="i-spinner loading super-loading mb-15"></i><br />Carregando</div>}

                {
                    loaded && 
                    <main className="px-10">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                                <div className="justify-content-center text-center mb-20">
                                    <Avatar data={professional} size="lg" />
                                    <h1>
                                        {professional.name}<br />
                                        <small>{professional.role}</small>
                                    </h1>
                                </div>
                                <form className="box" onSubmit={this.handleSubmit} autoComplete="none">
                                    <div className="box-header justify-content-center">
                                        <h3>Completar cadastro</h3>
                                    </div>
                                    <div className="box-body">
    
                                        <div className="form-group">
                                            <label htmlFor="loginEmail">E-mail para login</label>
                                            <input
                                                type="text"
                                                value={professional.email}
                                                className="form-control"
                                                disabled
                                            />
                                        </div>

                                        <PasswordRevealer 
                                            label="Sua senha"
                                            name="password"
                                            value={password}
                                            handleChange={e => this.setState({password:e.target.value})}
                                            containerClassNames="form-group form-group--inline"
                                            inputClassName="form-control"
                                        />

                                        <PasswordRevealer 
                                            label="Confirme a senha"
                                            name="password"
                                            value={passwordConfirm}
                                            handleChange={e => this.setState({passwordConfirm:e.target.value})}
                                            containerClassNames="form-group form-group--inline"
                                            inputClassName="form-control"
                                        />
    
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-lg btn-success" type="submit"><b>{ btnText }</b></button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                }
            </div>
    	);
	}
}

export { CadastroProfissional };