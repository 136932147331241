import React, { Component } from "react";
import moment from 'moment'

import "./ModalFinishOrder.scss";

// Components
import InputSearchProducts from '../InputSearchProducts'

// Helpers
import { Actions } from "../../flux";
import { ordersService } from "../../_services/orders.service";
import { numberToReal, sumProductsByQuantity, sumGiftsByQuantity } from '../../utils/money'
import { maskCPF, maskPhone } from '../../utils/masks'
import OrderServicesList from "../OrderServicesList"
import OrderProductsList from "../OrderProductsList"
import PaymentSelector from "../PaymentSelector";

class ModalFinishOrder extends Component {
    state = {
        loading: false,
        details: null,
        addProducts: false,
        products: [],
        payments: []
    }

    componentDidMount = () => {
        const { schedule } = this.props
        this.setState({details:schedule})
    }

    componentDidUpdate = nextProps => {
        const { schedule } = this.props
        if (schedule.schedule_id !== nextProps.schedule.schedule_id) {
            this.setState({details:schedule})
        }
    }

    close = () => {
        const { onCloseModal } = this.props
        if (onCloseModal) onCloseModal()
    }

    finish = () => {
        const { details, products, payments } = this.state

        const servicesTotal = (details.services.length > 0) ? details.services.sum('price') : 0;
        const productsTotal = sumProductsByQuantity(products);
        const giftsTotal = sumGiftsByQuantity(products);
        const subtotal = servicesTotal + productsTotal;
        const total = subtotal - giftsTotal;


        const paymentsTotal = payments.sum('amount');

        if (paymentsTotal !== total) {
            Actions.showError('Verifique os métodos de pagamento!');
            return;
        }

        const data = {
            unit: details.unit,
            schedule: details.schedule_id,
            cost: subtotal,
            discount: giftsTotal,
            total: total,
            products: products,
            services: details.services,
            client: (details.client_data) ? details.client_data.id : null,
            professional: details.professional,
            servicesTotal: servicesTotal,
            payments: payments
        }
        
        console.log('finishing', data)

        this.setState({loading:true})

        ordersService.finishOrder(data).then(res => {
            console.log('success', res)
            if (res.success) {
                Actions.showSuccess({title:res.message});
                this.close();
            }
        })
        .catch(err => {
            Actions.showError(err)
        })

        // this.close();
        // Actions.showModalFinishOrder(details);
    }

    // changePayments = payments => this.setState(payments)
    changePayments = payments => {
        console.log('changePayments', payments)
        this.setState({payments:payments})
    }

    selectProduct = product => {
        console.log('selectProduct', product)
        const { products } = this.state
        const index = products.map(obj => obj.id).indexOf(product.id);
        if (index >= 0) {
            if (products[index].gift === true) {
                products.push(product);
                this.setState({products})
            } else { 
                Actions.showError('Este item já foi adicionado')
            }
        } else {
            products.push(product);
            this.setState({products})
        }
    }

    render = () => {
        const {
            loading,
            details,
            addProducts,
            products
        } = this.state
        const servicesTotal = (details) ? details.services.sum('price') : 0;
        const productsTotal = sumProductsByQuantity(products);
        const giftsTotal = sumGiftsByQuantity(products);
        const subtotal = servicesTotal + productsTotal;  
        const total = subtotal - giftsTotal;
        return (
            <div className="modal-finish-order box">
                
                {loading && <div className="body-loading"><i className="i-spinner loading super-loading mb-15"></i><br />Carregando</div>}

                {           
                    !loading && details && 
                    <div className="box-header">
                        {
                            details.client_data && 
                            <h3>
                                {details.client_data.name}
                                <small>{moment(details.when).format('DD/MM/YYYY')} às {details.hour}</small>
                            </h3>
                        }
                        {
                            !details.client_data && 
                            <h3>
                                Atendimento 
                                <small>{moment(details.when).format('DD/MM/YYYY')} às {details.hour}</small>
                            </h3>
                        }
                        <div className="box-actions">
                            <button className="box-actions-item box-actions-item--circle" data-widget="dismiss" onClick={() => this.close()}><i className="fas fa-times"></i></button>
                        </div>
                    </div>
                }

                {
                    !loading && details && 
                    <div className="box-body">
                        
                        <div className="row">
                            {
                                details.client_data && 
                                <div className="col-6">
                                    <p>
                                        <b>Cliente:</b><br />
                                        {details.client_data.name && <span>{details.client_data.name}<br /></span>}
                                        {details.client_data.email && <span>{details.client_data.email}<br /></span>}
                                        {details.client_data.cellphone && <span>{maskPhone(details.client_data.cellphone)}<br /></span>}
                                        {details.client_data.document && <span>{maskCPF(details.client_data.document)}<br /></span>}
                                        {details.client_data.address && <span>{details.client_data.address}, {details.client_data.street_number} <br /></span>}
                                        {details.client_data.complement && <span>{details.client_data.complement}<br /></span>}
                                        {details.client_data.country && <span>{details.client_data.country}<br /></span>}
                                        {details.client_data.city && <span>{details.client_data.city}<br /></span>}
                                    </p>
                                </div>
                            }
                            <div className="col-6">
                                <span>
                                    <h6 className="subtitle">QUANDO</h6>
                                    <p>{moment(details.when).format('DD/MM/YYYY')} às {details.hour}</p>
                                </span>
                                {
                                    details.professional_data && 
                                    <p>
                                        <h6 className="subtitle">PROFISSIONAL</h6>
                                        {details.professional_data.name}
                                    </p>
                                }
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12">
                                <h6 className="subtitle">SERVIÇOS</h6>
                                <OrderServicesList items = {details.services} />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12">
                                <h6 className="subtitle">PRODUTOS</h6>

                                {products.length > 0 && <OrderProductsList canEdit items = {products} onChange = {items => this.setState({products:items})} />}

                                {
                                    addProducts && 
                                    <InputSearchProducts onSelectProduct = {product => this.selectProduct(product)} />
                                }


                                {
                                    !addProducts && 
                                    <button className="btn btn-info" onClick={() => this.setState({addProducts:true})}>Adicionar produtos</button>
                                }
                            </div>
                        </div>

                        <div className="row mt-20">
                            <div className="col-md-6 mt-20">
                                <h6 className="subtitle">MÉTODO DE PAGAMENTO</h6>
                                <PaymentSelector price={total} onChangePayments={items => this.changePayments(items)} />
                            </div>

                            <div className="col-md-6 mb-15">
								<div className="table-responsive">
									<table className="table">
										<tbody>
											<tr>
												<td>Serviços:</td>
												<td className="text-right">{numberToReal(servicesTotal)}</td> 
											</tr>
											<tr>
												<td>Produtos:</td>
												<td className="text-right">{numberToReal(productsTotal)}</td> 
											</tr>
											<tr>
												<td><b>Subtotal:</b></td>
												<td className="text-right">{numberToReal(subtotal)}</td> 
											</tr>
											<tr>
												<td>Cortesias: (-)</td>
												<td className="text-right text-danger">{numberToReal(giftsTotal)}</td> 
											</tr>
											<tr>
												<td><b>TOTAL:</b></td>
												<td className="text-right"><b>{numberToReal(total)}</b></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

                        </div>

                    </div>
                }


                {
                    !loading && details && 
                    <div className="box-footer">
                        <div className="pull-left">
                            {/* <p className="text-lg"><i className="i-spinner loading"></i> Carregando...</p> */}
                        </div>

                        <div className="pull-right">
                            <button className="btn btn-success pull-right" onClick={() => this.finish()}>Finalizar atendimento <i className="fas fa-check ml-5"></i></button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


export default ModalFinishOrder;