import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import { Actions } from '../../flux';
import { texts } from '../../settings';
import { cardsService } from '../../_services';
import MinhaContaNav from '../../components/MinhaContaNav';
import { CriarCartao } from './CriarCartao';

class Cartoes extends Component {
	state = {
        loaded: false,
        add: false,
        cards: [],
        editCard: null
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		cardsService.myCards().then(data => {
			this.setState({
				cards: data,
				loaded: true
			})
			console.log('get myCards', data)
		})
    }
    
    editCard = item => {
        this.setState({
            edit: false,
            editCard: null
        })

        // Aguarda 50 milisegundos para adicionar
        setTimeout(() => {
            this.setState({
                edit: true,
                editCard: item
            })
        }, 50);
    }

    dismiss = () => {
        this.setState({
            add: false,
            edit: false,
            editCard: null
        })

        this.getAll();
    }

	render = () => {
        const { cards, add, edit, editCard } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Cartões - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Cartões</h1>
                </div>

				<div className="row">
					<div className="col-md-12">
						<MinhaContaNav active = {'cartoes'} />
					</div>
					<div className="col-md-12 mt-20">
						<button onClick={() => this.setState({ add: true, edit: false })} className="btn btn-info">Adicionar</button>
					</div>

					{
						!add &&
						<div className="col-md-6 mt-40">
							{
								cards.length > 0 &&
								<div className="list-group">
									{
										cards.map(item => (
											<button onClick={() => this.editCard(item)} className={`list-group-item list-group-item-action ${(editCard && editCard.id == item.id)?'active':''}`}>
												
												{
													(editCard && editCard.id == item.id) && 
													<i className="fe fe-edit float-right mr-20"></i>
												}

												{
													(!editCard || editCard.id != item.id) && 
													<i className="fe fe-credit-card float-right mr-20"></i>
												}
												{item.number} - {item.name}
											</button>
										))
									}
								</div>
							}

							{
								cards.length == 0 &&
								<p>Nenhum cartão cadastrado</p>
							}
						</div>
					}

					{
						add &&
						<div className="col-md-6 mt-40">
							<div className="row">
								<div className="col-md-8">
									<h2 className="content-title">Adicionar Cartão</h2>
								</div>
								<div className="col-md-4 text-right">
									<button onClick={() => this.setState({ add: false })} className="btn btn-light">Cancelar</button>
								</div>
							</div>
							<CriarCartao onDismiss={() => this.dismiss()} />
						</div>
					}

					{
						edit &&
						<div className="col-md-6 mt-40">
							<div className="row">
								<div className="col-md-8">
									<h2 className="content-title">Editar Cartão</h2>
								</div>
								<div className="col-md-4 text-right">
									<button onClick={() => this.setState({ edit: false, editCard: null })} className="btn btn-light">Cancelar</button>
								</div>
							</div>
							{/* <CriarCartao edit address={editCard} onDismiss={() => this.dismiss()} /> */}
						</div>
					}

				</div>
				
			</div>
    	);
	}
}

export { Cartoes };