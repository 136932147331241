import React, { Component } from "react";
import Calendar from 'react-calendar';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import ReactSlider from 'react-slider';
import Modal from "react-modal";
import moment from 'moment';

import { verifyHourAvailable, returnHourBlocked } from "../../utils/time";
import { percentage } from "../../utils/number";
import { HORARIOS } from "../../settings/enums";
import { Dropdown } from "../Dropdown/Dropdown";

import "./react-slider.scss";
import { professionalsService } from "../../_services";
import { Actions } from "../../flux";

import 'react-datepicker/dist/react-datepicker.css';
registerLocale('pt', pt);
setDefaultLocale('pt');

class HorarioAtendimentoProfissional extends Component {    
    state = {
        horarios: [],
        off: [],
        hours: [],
        offDay: [],
        showHours: false,
        hour: null,
        day: null,
        modal: false,
        date: new Date(),
        min: null,
        slider: [25, 75],
        toChange: false,
    }
    
    componentDidMount = () => {
        const { items, blocked } = this.props
        this.setState({
            horarios: items,
            off: blocked
        })
    }
    
    componentDidUpdate = () => {
        const { items, blocked } = this.props
        const { horarios, off } = this.state
        if (items && items.length !== horarios.length) {
            this.setState({ horarios: items });
        }
        if (blocked && blocked.length !== off.length) {
            this.setState({ off: blocked });
        }
    }

    changeDate(ev) {
        this.setState({hour: null, day: ev});
        this.parseHours(ev);
    }

    parseHours(day) {
        const { horarios, off } = this.state;

        let dayWeekday = moment(day).format("dddd");
        let dayCompare = moment(day).format("YYYY-MM-DD");

        let weekhours = horarios.filter(obj => obj.weekday === dayWeekday);
        let offHours = off.filter(obj => (obj.weekday === dayWeekday || (obj.date != null && obj.date.includes(dayCompare))));

        let hourStart = (weekhours.length > 0) ? weekhours[0].hour_start : null;
        let hourEnd = (weekhours.length > 0) ? weekhours[0].hour_end : null;
        if (!hourStart || !hourEnd) { 
            this.setState({
                hours: [],
                showHours: true,
                offDay: []
            });
            return;
        }

        let start = parseInt(hourStart.substring(0, 2));
        let end = parseInt(hourEnd.substring(0, 2));

        var hours = [];
        for (let index = start; index <= end; index++) {
            hours.push({ id: `${index}:00:00`, label: `${index}:00` });
            if (index < end) {
                hours.push({ id: `${index}:30:00`, label: `${index}:30` });
            }
        }

        this.setState({
            hours: hours,
            showHours: true,
            offDay: offHours
        });
    }

    changeBlockHour = hour => {
        const { offDay, day } = this.state
        let exists = returnHourBlocked(offDay, day, hour);        
        this.setState({toChange: exists});

        if (exists) {
            let start = exists.hour_start.substring(0, 5);
            let startIndex = HORARIOS.map(obj => obj.label).indexOf(start);
            let startFinal = percentage(startIndex, HORARIOS.length);
            
            let end = exists.hour_end.substring(0, 5);
            let endIndex = HORARIOS.map(obj => obj.label).indexOf(end);
            let endFinal = percentage(endIndex, HORARIOS.length);

            this.setState({
                slider: [
                    startFinal,
                    endFinal
                ]
            })
        } else {
            this.setState({
                slider: [25, 75]
            })
        }

        this.showModal();
    }

    hideModal = () => this.setState({modal: false});
    showModal = () => this.setState({modal: true});

    returnHour = (num) => {
        const step = (100/HORARIOS.length);
        return HORARIOS[Math.round(num/step)];
    }

    returnHourLabel = (num) => {
        return this.returnHour(num).label;
    }

    returnHourValue = (num) => {
        return this.returnHour(num).value;
    }

    deleteBlock = () => {
        const { toChange } = this.state;
        professionalsService.removeBlockHour(toChange.id).then(res => {
            console.log('res', res);

            if (res.success) {
                Actions.showSuccess({title:'Horários removidos!'});
                if (this.props.onUpdateHour) this.props.onUpdateHour();

                this.setState({
                    day: new Date(),
                    showHours: false
                });

            } else {
                Actions.showError('Não foi possível remover o horário.')
            }

            this.hideModal();
        }).catch(err => {
            Actions.showError(err.error)
        })
    }

    createBlock = () => {
        const { slider, toChange, day } = this.state;
        const valueStart = this.returnHourValue(slider[0]);
        const valueEnd = this.returnHourValue(slider[1]);
        
        const body = (toChange) ? toChange : {
            unit: this.props.unit,
            hour_start: valueStart,
            hour_end: valueEnd,
            date: moment(day).format("YYYY-MM-DD")
        };
        
        if (toChange) {
            body.hour_start = valueStart;
            body.hour_end = valueEnd;

            if (body.date == null) delete body.date;
            if (body.weekday == null) delete body.weekday;
        }

        const fn = (toChange) ? professionalsService.updateBlockHour : professionalsService.addBlockHour;
        const id = (toChange) ? toChange.id : this.props.professional;
        fn(body, id).then(res => {
            // console.log('res', res);

            if (res.success) {
                Actions.showSuccess({title:'Horários alterados!'});
                if (this.props.onUpdateHour) this.props.onUpdateHour();

                this.setState({
                    day: new Date(),
                    showHours: false
                });

            } else {
                Actions.showError('Não foi possível alterar o horário.')
            }

            this.hideModal();
        }).catch(err => {
            Actions.showError(err.error)
        })
    }

    renderModal = () => {
        const { modal, day, slider, toChange } = this.state
        const customStyles = {
          overlay: {
            backgroundColor: "#cccccc52"
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0"
          }
        };
        const step = (100/HORARIOS.length);

        const defaultSlider1 = (slider.length) ? slider[0] : HORARIOS.length*25/100;
        const defaultTime1 = HORARIOS[Math.round(defaultSlider1/step)].value;

        const defaultSlider2 = (slider.length) ? slider[1] : HORARIOS.length*75/100;
        const defaultTime2 = HORARIOS[Math.round(defaultSlider2/step)].value;

        return (
            <Modal
                isOpen={modal}
                parentSelector={() => document.body}
                ariaHideApp={false}
                style={customStyles}
            >
                <div className="modal-schedule box">

                    <div className="box-header">
                        <h3>
                            {!toChange && "Fechar agenda"}
                            {toChange && "Atualizar agenda"}
                        </h3>
                        <div className="box-actions">
                            <button className="box-actions-item box-actions-item--circle" data-widget="dismiss" onClick={() => this.hideModal()}><i className="fas fa-times"></i></button>
                        </div>
                    </div>
                    
                    {/* <button className="close-modal" onClick={() => this.hideModal()}>
                        Fchar 
                    </button> */}

                    <div className="box-body">
                        <div className="row text-center">
                            <div className="col-12">
                                <p>Preencha todos os campos para fechar um intervalo</p>

                                <DatePicker
                                    clearIcon={null}
                                    disabled={true}
                                    dateFormat="dd/MM/yyyy"
                                    selected={(day) ? new Date(day) : new Date()}
                                    minDate={(day) ? new Date(day) : new Date()}
                                    onChange={this.onChange}
                                />

                                {/* <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="form-group col-md-4">
                                        <Dropdown
                                            value={defaultTime1}
                                            defaultLabel = "Das"
                                            data = {HORARIOS}
                                            // handleChange = {e => this.selectUnit(e.target.value)}
                                            />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <Dropdown
                                            value={defaultTime2}
                                            defaultLabel = "Até as"
                                            data = {HORARIOS}
                                            // handleChange = {e => this.selectUnit(e.target.value)}
                                            />
                                    </div>
                                </div> */}

                                <p>Mova a barra para selecionar o início e o fim do fechamento.</p>
                                
                                <ReactSlider
                                    step={step}
                                    value={this.state.slider}
                                    onAfterChange={(value, index) => this.setState({slider: value})}
                                    className="horizontal-slider"
                                    thumbClassName="example-thumb"
                                    trackClassName="example-track"
                                    renderThumb={(props, state) => <div {...props}>{HORARIOS[Math.round(state.valueNow/step)].label}</div>}
                                />

                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="form-group col-md-4">
                                        <button className="btn btn-secondary btn-block" onClick={() => this.createBlock()}>
                                            <i className="fe fe-lock"></i> 
                                            {!toChange && " Fechar agenda"}
                                            {toChange && " Atualizar"}
                                        </button>
                                    </div>
                                </div>

                                {
                                    toChange && 
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="form-group col-md-4">
                                            <button className="btn btn-link text-danger" onClick={() => this.deleteBlock()}>
                                                Remover bloqueio
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        );
    }

    renderHours = hours => {
        const { offDay, day } = this.state
        return (
            <React.Fragment>
                {hours && hours.map((item, index) => {
                    let verify = verifyHourAvailable(offDay, day, item.label);
                    return (
                        <div key={index}>
                            <button
                                onClick={() => this.changeBlockHour(item.label)}
                                className={[
                                    'btn',
                                    'btn-sm',
                                    'btn-block',
                                    (verify) ? 'btn-outline-secondary' : 'btn-danger'
                                ].join(' ')}>
                                    {/* {!verify && <div className="overlay-text">Bloqueado</div>} */}
                                    {item.label}
                                </button>
                        </div>
                    );
                })}
            </React.Fragment>
        );
    }

    render = () => {
        const { hours, day } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <h4>Data</h4>
                        <Calendar
                            className={['public_calendar']}
                            minDate = {new Date()}
                            onChange={ev => this.changeDate(ev)}
                        />
                        {
                            day && hours.length > 0 &&
                            <button
                                onClick={() => this.changeBlockHour('')}
                                className="btn btn-secondary mt-20">
                                    <i className="fe fe-lock"></i> fechar agenda
                            </button>
                        }

                        {this.renderModal()}
                    </div>

                    <div className="col-md-6 professionals-hours">
                        {
                            hours.length > 0 &&
                            <h4>Expediente</h4>
                        }
                        {this.renderHours(hours)}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


export { HorarioAtendimentoProfissional };