
const agenda = {
    cellHeight: 50,
    rowsPerHour: 2,
    locale: "pt-br",
    numberOfDays: 1,
    autoScale: false,
    fixedHeader: true,
    disablePrevButton: false,
    endAtTime: 0
};

export { agenda };
