import { settings } from "./settings";

const urls = {
  assets: "/app/static/",
  photoPerfil: `${settings.apiUrl}wwwroot/Content/UploadFotoPerfil/`,
  document: `${settings.apiUrl}wwwroot/Content/UploadDocumento/`,
  document2: `${settings.apiUrl}wwwroot/Content/UploadDocumento2/`,
  banner: `${settings.apiUrl}wwwroot/Content/Banner/`,
  fotoProduto: `${settings.apiUrl}wwwroot/Content/UploadFotoProduto/`
};

export { urls };
