import Axios from 'axios';

const viaCep = {
  searchCep(cep) {
    return Axios.get(`https://viacep.com.br/ws/${cep}/json`);
  },
  latLng(address) {
    const googleApiKey = 'AIzaSyCoYlYpNMG9HHHa0bar_pOPjbNak7ScOic'
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleApiKey}`;
    return Axios.get(url);
  }
}

export default viaCep;