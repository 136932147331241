import { handleResponse } from '../_helpers';
import { userService } from './user.service'
import API from './api'

export const unitsService = {
    myUnits,
    create,
    getOne,
    createOpeningHours,
    removeOpeningHours,
    getBySlug,
    getBySlugV2,
};

function getBySlug(slug) {
    return API.get(`/public/company/unit/${slug}`)
        .then(handleResponse)
        .then(res => res)
        .catch(err => {
            return Promise.reject({error: err})
        });
}

function getBySlugV2(slug) {
    return API.get(`/public/v2/company/unit/${slug}`)
        .then(handleResponse)
        .then(res => res)
        .catch(err => {
            return Promise.reject({error: err})
        });
}

function myUnits() {
    return API.get(`/partner/units`)
            .then(handleResponse)
            .then(user => user)
}

function create(body) {
    return API.post(`/partner/units`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function getOne(id) {
    return API.get(`/partner/unit/${id}`)
            .then(handleResponse)
            .then(data => data)
}

function createOpeningHours(unitId, body) {
    return API.post(`/partner/unit/${unitId}/opening-hours`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function removeOpeningHours(hourId) {
    return API.delete(`/partner/opening-hours/${hourId}`)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}