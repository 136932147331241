import { handleResponse } from '../_helpers'
import API from './api'

export const cardsService = {
    getOne,
    myCards,
    create,
    update,
    remove
}

function create(body) {
    return API.post(`/partner/cards`, body)
            .then(handleResponse)
            .then(res => res);
}

function update(id, body) {
    return API.put(`/partner/card/${id}`, body)
            .then(handleResponse)
            .then(res => res);
}

function getOne(id) {
    return API.get(`/partner/card/${id}`)
            .then(handleResponse)
            .then(data => data)
}

function myCards() { 
    return API.get(`/partner/cards`)
            .then(handleResponse)
            .then(data => data)
}

function remove(id) {
    return API.delete(`/partner/card/${id}`)
            .then(handleResponse)
            .then(data => data)
}