import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { productsService, plansService } from '../../_services'
import { UpgradeButton } from '../../components/UpgradeButton'

import {
    Actions
} from '../../flux';

import {
	texts,
	images
} from '../../settings/';

import { PRODUCTS } from "../../settings/enums";

import CardProduct from '../../components/CardProduct'

class Produtos extends Component {
	state = {
		products: [],
		fakeProducts: PRODUCTS,
		userPlan: null,
		loaded: false,
		loading: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.setState({ loading: true })

		plansService.getMyPlan().then(data => {
			if (Object.keys(data).length > 0) {
				this.setState({
					userPlan: data,
					loaded: true
				})
			} else {
				this.setState({
					loaded: true
				})
			}
		})

		productsService.myProducts().then(res => {
			if (res.length > 0) {
				this.setState({
					products: res
				})
			}
			this.setState({ loading: false })
		})
	}

	myProducts = items => {
		const { userPlan } = this.state
		let isFree = (userPlan && userPlan.plan_details.slug === 'free');
		return (
			<div className="products-list row">
				{items.map(item => (
                    <div className="col-md-3">

						{
							(!userPlan || isFree) && 
							<p className="text-center">
								<span class="badge badge-outline-secondary">Produto exemplo</span>
							</p>
						}
						<CardProduct
							item = {item}
							canEdit = {(userPlan && !isFree)} />
                    </div>
				))}

				{
					(!userPlan || isFree) &&
					<div className="col-md-4 pt-35">
						<UpgradeButton hasPlan={isFree} />
					</div>
				}
			</div>
		);
	}

	render = () => {
		const { products, loading, loaded, userPlan, fakeProducts } = this.state
		let isFree = (userPlan && userPlan.plan_details.slug === 'free');
        return (
			<div className="views">
			
				<Helmet>
	                <title>Produtos - { texts.title }</title>
	            </Helmet>

	    		<div className="products">
					<div className="content-header">
						{/* <h1>Unidades</h1> */}
						<div className="row">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / Produtos</li>
								</ol>
							</nav>
						</div>
					</div>

					<div className="row">
						<div className="col-12 col-md-6">
							<h2 className="content-title">Estoque {!loading && <small>{`${products.length} resultado${(products.length>1) ? 's' : ''}`} </small>}</h2>

							{
								loaded && userPlan && !isFree &&
								<Link to="/produtos/criar" className="btn btn-secondary pull-right">Cadastrar produto</Link>
							}
						</div>
					</div>

					<div className="row" style={{ marginTop: 20 }}>
						<div className="col-lg-11">
							{this.myProducts((loaded && userPlan && !isFree) ? products : fakeProducts)}

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}
						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { Produtos };