import { settings } from "../settings";
import { authHeader, handleResponse } from "../_helpers";
import API from './api.js'

export const userService = {
  login,
  logout,
  forgotPassword,
  isAuthenticated,
  getUserRole,
  getLocalData,
  getMyData,
  update,
  sendVerificationEmail,
  completeSignup,
  getUsers,
  getInvitations,
  sendUserInvitation,
  blockUser,
  unblockUser
};

function isAuthenticated() {
  const token = localStorage.getItem('token');
  return token ? true : false;
}

function getLocalData() {
  const userData = JSON.parse(localStorage.getItem('user'));
  return Promise.resolve(userData);
}

function getUserRole() {
  const res = JSON.parse(localStorage.getItem('user'));
  // const res = await getLocalData();
  if (res.user && res.user.role_label) {
    return res.user.role_label;
  } else {
    return null;
  }
}

function getMyData() {
  return API.get(`/partner/me`)
            .then(handleResponse)
            .then(user => user)
}

function login(username, password) {
  const userData = {
    email: username,
    password: password
  };

  // console.log('Trying to login with', userData)

  return API.post(`/partner/login`, userData) 
    .then(handleResponse)
    .then(user => {
      localStorage.setItem('token', user.token);

      var jwt = parseJwt(user.token);
      for (var attrname in jwt) {
        user[attrname] = jwt[attrname];
      }
      user.username = username;
      user.timeLogged = new Date().getTime();

      // login successful if there's a user in the response
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    })
    .catch(err => {
      return Promise.reject({error: err})
    });
}

function forgotPassword(username) {
  return Promise.reject({error: 'Método não implementado'})
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.reload();
}

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
}

function update(body) {
  return API.put(`/partner/me/update`, body)
          .then(handleResponse)
          .then(res => res);
}

function sendVerificationEmail() {
  return API.get(`/partner/send-verification-email`)
            .then(handleResponse)
            .then(user => user)
  
}

function completeSignup(body) {
  return API.post(`/partner/complete-invitation`, body)
          .then(handleResponse)
          .then(res => res)
          .catch(err => {
              return Promise.reject({error: err})
          });
}

function getInvitations() {
    return API.get(`/partner/invitations`)
            .then(handleResponse)
            .then(data => data)
}

function sendUserInvitation(email) {
  return API.post(`/partner/invitations`, { email: email })
          .then(handleResponse)
          .then(data => data)
}

function getUsers() {
    return API.get(`/partner/users`)
            .then(handleResponse)
            .then(data => data)
}

function blockUser(userId) {
  return API.post(`/partner/users/block`, { user_id: userId })
          .then(handleResponse)
          .then(data => data)
}

function unblockUser(userId) {
  return API.post(`/partner/users/unblock`, { user_id: userId })
          .then(handleResponse)
          .then(data => data)
}