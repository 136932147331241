export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatDateBrToSql(date) {
  return [date.split("/")[2], date.split("/")[1], date.split("/")[0]].join("-");
}

export function formatDateBR(date, separator = "-") {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join(separator);
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function differenceBetweenDates(first, second) {
  // One day Time in ms (milliseconds) 
  let one_day = 1000 * 60 * 60 * 24;
  
  let dateOne = new Date(first);
  let dateTwo = new Date(second);
    
  // To Calculate the result in milliseconds and then converting into days 
  let result = Math.round(dateTwo.getTime() - dateOne.getTime()) / (one_day);
    
  // To remove the decimals from the (result) resulting days value 
  return result.toFixed(0);
}