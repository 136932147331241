import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import Switch from "react-switch";

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { userService } from '../../_services/user.service'
import { plansService } from '../../_services/plans.service'
import { addressService } from '../../_services/address.service';
import { texts } from '../../settings/';
import { numberToReal } from '../../utils/money';
import { calcMonths } from '../../utils/number';
import MaskedInput from '../../components/MaskedInput/default';
import CpfInput from '../../components/MaskedInput/cpf'

const form = yup.object().shape({
	card_number: yup
	.string()
	.min(19, 'Número inválido')
	.required('Obrigatório'),
		
	card_name: yup
	.string()
	.min(3, 'Nome inválido')
	.required('Obrigatório'),
		
	card_expdate_month: yup
	.number()
	.min(1, 'Mês inválido')
	.max(12, 'Mês inválido')
	.required('Obrigatório'),
		
	card_expdate_year: yup
	.number()
	.min(22, 'Ano inválido')
	.max(99, 'Ano inválido')
	.required('Obrigatório'),
		
	card_cvv: yup
	.string()
	.min(3, 'Cód. inválido')
	.required('Obrigatório'),

	document: yup
	.string()
	.min(11, 'Documento inválido')
	.required('Obrigatório')
});

const formBoleto = yup.object().shape({
	document: yup
	.string()
	.min(11, 'Documento inválido')
	.required('Obrigatório')
});

import "./Migrar.scss";
import { cleanString } from '../../_helpers';

class PlanoMigrar extends Component {
	state = {
		addresses: [],
		userPlan: null,
		plans: [],
		plansBase: [],
		plansAnnual: [],
		user: null,
		loaded: false,
		addressesLoaded: false,
		step: 'plan',
		mainPlan: null,
		selected: null,
		addressSelected: null,
		method: 'credit_card',
		success: false,
		unitParam: null,
		period: '1_months',
	}

	componentDidMount = () => {
        const { match: { params } } = this.props;
		this.setState({ unitParam: params.id })

		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		// Local Data
		userService.getLocalData().then(data => {
			this.setState({
				user: data.user
			})
		})

		// Planos
		if (params.id) {
			plansService.getUnitPlan(params.id).then(this.parseUserPlans)
		} else  {
			plansService.getMyPlans().then(this.parseUserPlans)
		}

		// Address
		addressService.myAddresses().then(data => {
			this.setState({
				addresses: data,
				addressesLoaded: true
			})
		})
	}

	parseUserPlans = (data) => {
		if (data.length > 0) {
			this.setState({
				userPlan: data
			})
		}

		this.getPlans();
	}

	getPlans = () => {
		const { period } = this.state
		plansService.getPublicPlans().then(data => {
			this.setState({
				plansBase: data,
				plans: data[period].sort((a, b) => a.price - b.price),
				// plans: data.filter(obj => obj.periodicity === 30),
				// plansAnnual: data.filter(obj => obj.periodicity === 365),
				loaded: true
			})
			// data.map(item => {
			// 	if (item.cost > 0) {
			// 		this.setState({ mainPlan: item })
			// 	}
			// })
		})
	}

	selectPlan = planId => {
		this.setState({
			step:'address',
			selected: planId
		})
	}

	selectAddress = addressId => {
		this.setState({
			// step:'form',
			addressSelected: addressId
		})
	}

	renderPlans = items => {
		const { loaded } = this.state;
		return (
			<div className="items container-cards">
                {
                    items.length > 0 &&
					<div>
						<div className="mt-10 boxes">
            				<ul className="keen-slider">
								{items.map((item, index) => {
									if (item.price > 0) {
										return (
											<li className={`keen-slider__slide`} key={index}>
												{this.renderPlanBox(item)}
											</li>
										)
									} else {
										return null;
									}
								})}
							</ul>
						</div>
					</div>
                }
                
                {
                    items.length == 0 && loaded &&
                    <p>Nenhum plano disponível no momento...</p>
                }
			</div>
		);
	}

	renderPlanBox = item => {
		const { step } = this.state
		return (
			<div className={`plan-box ${item.recommended === 1 ? 'top-search' : ''}`}>
				<div className="plan-box-header">
					<h3>{item.name}</h3>
					<p>{item.description}</p>
				</div>

				{
					item.permissions && item.price === 0 && 
					<div className="plan-box-content">
						<p>{`${item.permissions.limit_users} ${(item.permissions.limit_users == 1) ? 'usuário' : 'usuários' } `}</p>
						<p>{`${item.permissions.limit_professionals} ${(item.permissions.limit_professionals == 1) ? 'profissional' : 'profissionais' } `}</p>
						<p>+ Link público de agendamento</p>
						<p> + Gestão de clientes</p>
					</div>
				}

				{
					item.permissions && item.price > 0 && 
					<div className="plan-box-content">
						<p>{`${item.permissions.limit_users} ${(item.permissions.limit_users == 1) ? 'usuário' : 'usuários' } `}</p>
						<p>{`${item.permissions.limit_professionals} ${(item.permissions.limit_professionals == 1) ? 'profissional' : 'profissionais' } `}</p>
						<p>+ Link de agendamento com a sua marca</p>
						<p>+ Comissionamento</p>
						<p>+ Gestão de clientes</p>
						{ (item.permissions.stock == 1) && <p>+ Controle de estoque</p> }
						{ (item.permissions.reports == 1) && <p> + Financeiro</p> }
					</div>
				}

				<div className="plan-box-footer">
					
					<div className="plan-box-footer__columns">
						<div className="plan-box-footer__column">
							{
								item.price <= 0 && 
								<p>7 dias grátis</p>
							}
							{
								item.price > 0 && 
								<p>
									{numberToReal(item.price)} <span>/mês</span>
								</p>
							}

							<small>Pague {numberToReal(item.totalPrice)} a cada {item.periodicity} dias.</small>
						</div>
						<div className="plan-box-footer__column">
							{
								step == 'plan' &&
								<button className="btn btn-outline-secondary" onClick={() => this.selectPlan(item.plan_id)}>
									Escolher
								</button>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderAddress = addresses => {
		const { addressSelected } = this.state;
		return (
			
			<div className="row">
				<div className="col-2"></div>
				<div className="col-8">
					<div className="items">
						<div className="price">
							<div className="price-top">
								<h3 className="h3 text-secondary">Endereço</h3>
								<p>Selecione o endereço de cobrança</p>
							</div>
							<div className="price-bottom">
								{
									addresses.length > 0 &&
									<div className="list-group">
										{
											addresses.map((item, i) => (
												<button key={i} onClick={() => this.selectAddress(item.id)} className={`list-group-item list-group-item-action ${(addressSelected == item.id)?'active bg-secondary':''}`}>
													{
														(addressSelected == item.id) && 
														<i className="fe fe-check float-right mr-20"></i>
													}

													{
														(!addressSelected != item.id) && 
														<i className="fe fe-home float-right mr-20"></i>
													}
													{item.name} - <small>{item.address}, {item.number} - {item.neighborhood} - {item.city}</small>
												</button>
											))
										}
									</div>
								}

								{
									addresses.length == 0 &&
									<div>
										<p>Nenhum endereço cadastrado</p>
										<Link to="/minha-conta/enderecos" className="btn btn-secondary mt-10">
											Cadastrar endereço
										</Link>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	saveInfo = values => {
		const { selected, plans, method, addressSelected, unitParam } = this.state;
		let plan = plans.filter(obj => obj.plan_id === selected);
		let data = values;

		if (plan.length === 0) {
			Actions.showError('Selecione um plano');
			return;
		} else {
			plan = plan[0];
		}

		let body = {
			plan_id: plan.plan_id,
			document: values.document,
			type: method,
			payment: (method == 'credit_card') ? {
				card: {
					name: data.card_name,
					number: cleanString(data.card_number),
					cvv: data.card_cvv,
					exp_month: data.card_expdate_month,
					exp_year: data.card_expdate_year
				}
			} : null,
			address: addressSelected
		}

		// console.log('makePayment unitParam', unitParam);

		// If unitParam exists on URL
		if (unitParam) {
			body.unit = unitParam;
		}

		// console.log('makePayment body', body);

        plansService.makePayment(body).then(res => {
            if (res.success === true) {
				Actions.showSuccess({title:res.message})
				this.setState({
					success: true
				})
            }
        })
        .catch(err => {
            console.log('schedulesService create err', err)
            Actions.showError(err)
        })
	}

	changePeriod = (newPeriod) => {
		const { plansBase } = this.state;
		this.setState({
			period: newPeriod,
			plans: plansBase[newPeriod].sort((a, b) => a.price - b.price)
		})
	}

	btnClasses = (selected) => {
		var theClasses = ['btn'];
		if (selected) {
			theClasses.push('btn-secondary');
		} else {
			theClasses.push('btn-outline-secondary');
		}
		return theClasses.join(' ');
	}

	render = () => {
		const { userPlan, addresses, addressSelected, plans, loaded, step, method, success, selected, period } = this.state

        if (
			success === true ||
			(userPlan && userPlan.plan_details.bonus == 0 && userPlan.plan_details.cost > 0)
			) {
			return (
				<Redirect
					to={{
						pathname: "/plano",
						state: { from: this.props.location }
					}}
				/>
			)
		}

		const btnBoleto = (method == 'boleto') ? ['btn', 'btn-secondary'] : ['btn', 'btn-outline-secondary'];
		const btnCreditCard = (method == 'credit_card') ? ['btn', 'btn-secondary'] : ['btn', 'btn-outline-secondary'];

        return (
			<div className="views views-migrar-plano container-fluid">
			
				<Helmet>
	                <title>Assinaturas - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1 className="display-1">
						Assinaturas
						<small className="mt-10">Ative a assinatura e eleve o seu negócio</small>
					</h1>
                </div>


                { 
					loaded && 
					<div className={`step ${(step == 'plan') ? 'step--active' : ''} step-${step}`}>
						<div className="col-md-12 text-center">
							<div className = "btn-group mb-10 select-period">
								<button className = {this.btnClasses(period == '1_months')} onClick = {() => this.changePeriod('1_months')}>Mensal</button>
								<button className = {this.btnClasses(period == '3_months')} onClick = {() => this.changePeriod('3_months')}>Trimestral</button>
								{/* <button className = {this.btnClasses(period == '6_months')} onClick = {() => this.changePeriod('6_months')}>Semestral</button> */}
								<button className = {this.btnClasses(period == '12_months')} onClick = {() => this.changePeriod('12_months')}>Anual</button>
							</div>
						</div>

						{this.renderPlans(plans)}
					</div>
				}

                { 
					!loaded && 
					<div className="col-md-12 text-center">
						<p>Carregando...</p>
					</div>
				}

				<div className={`step ${(step == 'address') ? 'step--active' : ''}`}>
					{this.renderAddress(addresses)}
				</div>

				<div className={`step ${(step == 'form') ? 'step--active' : ''}`}>
					<div className="row">
						<div className="col-md-2"></div>
						<div className="col-md-8">
							
							<div className="price">
								<div className="row">
									<div className="col-md-7">

										<div className="col-md-12 text-center mt-20">
											<div className = "btn-group mb-10 select-period">
												<button className = {btnCreditCard.join(' ')} onClick = {() => this.setState({method:'credit_card'})}>Cartão de crédito</button>
												<button className = {btnBoleto.join(' ')} onClick = {() => this.setState({method:'boleto'})}>Boleto</button>
											</div>
										</div>

										{
											method == 'credit_card' &&
											<div className="price-bottom">
												<h5 className="text-secondary"><i className="fa fa-lock mr-10"></i> Informe os dados do cartão de crédito</h5>
	
												<Formik
													initialValues={{
														card_number: '',
														card_name: '',
														card_expdate_month:  '',
														card_expdate_year: '',
														card_cvv: '',
														document: ''
													}}
													validationSchema={form}
													onSubmit={values => this.saveInfo(values)}
													>
													{({ errors, touched, setFieldValue, isValid }) => (
														<Form>
															<div className="row">
			
																<div className="form-group col-12 mt-20">
																	<label htmlFor="document">
																		CPF ou CNPJ
																		<small className="form_label_error ml-10"><ErrorMessage className="text-danger" name="document" /></small>
																	</label>
																	<CpfInput
																		name="document"
																		onChange={e => setFieldValue('document', e.target.value)}
																		/>
																</div>
	
																<div className="form-group col-12">
																	<label htmlFor="card_number">
																		Número no cartão
																		<small className="form_label_error ml-10"><ErrorMessage className="text-danger" name="card_number" /></small>
																	</label>
																	<MaskedInput
																		mask = {
																			[
																				/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
																				/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
																				/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
																				/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
																				/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
																			]}
																		onChange={e => setFieldValue('card_number', e.target.value)}
																		guide = {false}
																		/>
																</div>
			
																<div className="form-group col-12">
																	<label htmlFor="card_name">
																		Nome no cartão 
																		<small className="form_label_error ml-10"><ErrorMessage className="text-danger" name="card_name" /></small>
																	</label>
																	<Field name="card_name" className="form-control" />
																</div>
			
																<div className="form-group form-group--height col-7">
																	<label>Validade</label>
			
																	<div className="row">
																		<div className="form-group col-6">
																			<MaskedInput
																				mask = {[/[0-9]/, /[0-9]/]}
																				placeholder = {'Mês'}
																				min = {0}
																				max = {12}
																				guide = {false}
																				onChange={e => setFieldValue('card_expdate_month', e.target.value)}
																				/>
																			<small className="form_label_error"><ErrorMessage className="text-danger" name="card_expdate_month" /></small>
																		</div>
			
																		<div className="form-group col-6">
																			<MaskedInput
																				mask = {[/[0-9]/, /[0-9]/]}
																				placeholder = {'Ano'}
																				guide = {false}
																				onChange={e => setFieldValue('card_expdate_year', e.target.value)}
																				/>
																			<small className="form_label_error"><ErrorMessage className="text-danger" name="card_expdate_year" /></small>
																		</div>
																	</div>
																</div>
	
																<div className="form-group col-1"></div>
			
																<div className="form-group col-4 form-group--height">
																	<label htmlFor="card_cvv">Cód. segurança</label>
																	<MaskedInput
																		mask = {[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
																		guide = {false}
																		onChange={e => setFieldValue('card_cvv', e.target.value)}
																		/>
																	<small className="form_label_error"><ErrorMessage className="text-danger" name="card_cvv" /></small>
																</div>
			
																{/* <div className="form-group col-12">
																	<label className="switch_memorize">
																		<Switch onChange={() => this.setState({ memorize: !memorize })} checked={memorize} /> 
																		<span>Memorizar cartão para compras futuras</span>
																	</label>
																</div> */}
	
																<div className="col-md-12 text-right mt-20">
																	<button type="submit" className="btn btn-success btn-lg">Contratar plano</button>
																</div>
															</div>
														</Form>
													)}
												</Formik>
	
											</div>
										}

										{
											method == 'boleto' &&
											<div className="price-bottom">
												<h5 className="text-secondary"><i className="fa fa-lock mr-10"></i> Informe os dados abaixo para prosseguir</h5>
												<p>Após contratação, você receberá o boleto no seu e-mail.</p>
	
												<Formik
													initialValues={{
														document: ''
													}}
													validationSchema={formBoleto}
													onSubmit={values => this.saveInfo(values)}
													>
													{({ errors, touched, setFieldValue, isValid }) => (
														<Form>
															<div className="row">
			
																<div className="form-group col-12 mt-20">
																	<label htmlFor="document">
																		CPF ou CNPJ
																		<small className="form_label_error ml-10"><ErrorMessage className="text-danger" name="document" /></small>
																	</label>
																	<CpfInput
																		name="document"
																		onChange={e => setFieldValue('document', e.target.value)}
																		/>
																</div>
	
																<div className="col-md-12 text-right mt-20">
																	<button type="submit" className="btn btn-success btn-lg">Contratar plano</button>
																</div>
															</div>
														</Form>
													)}
												</Formik>
	
											</div>
										}

									</div>

									<div className="col-md-5">
										<div className='pt-20 pr-20'>
											<h5 className="text-secondary mt-70">Plano escolhido:</h5>
											{selected && this.renderPlans(plans.filter(obj => obj.plan_id == selected))}
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>

				{
					loaded && 
					step == 'address' && 
					<div className="row">
						<div className="col-2"></div>
						<div className="col-md-4">
							<div className = "mb-30">
								<button className = {'btn btn btn-outline-secondary'} onClick = {() => this.setState({step:'plan'})}>Voltar</button>
							</div>
						</div>
						{
							addressSelected && 
							<div className="col-md-4 text-right">
								<div className = "mb-30">
									<button className = {'btn btn-success'} onClick = {() => this.setState({step:'form'})}>Avançar</button>
								</div>
							</div>
						}
					</div>
				}

				{
					loaded && 
					step == 'form' && 
					<div className="row">
						<div className="col-2"></div>
						<div className="col-md-4">
							<div className = "mb-30">
								<button className = {'btn btn btn-outline-secondary'} onClick = {() => this.setState({step:'address'})}>Voltar</button>
							</div>
						</div>
						{/* <div className="col-md-4 text-right">
							<div className = "mb-30 text-right">
								<button className = {'btn btn-success'}>Finalizar</button>
							</div>
						</div> */}
					</div>
				}

			</div>
    	);
	}
}

export { PlanoMigrar };