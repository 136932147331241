import React from 'react';
import MaskedInput from 'react-text-mask';

const maskCPF = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
const maskCNPJ = [
    /[0-9]/, /[0-9]/,'.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/
];

function mask(userInput) {
    let numbers = userInput.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join("").length;
    }
  
    if (numberLength <= 11) {
      return maskCPF;
    } else {
      return maskCNPJ;
    }
  }

const input = ({
    onChange,
    value,
    disabled = false
}) => {
    return (
        <MaskedInput
            disabled={disabled}
            guide={false}
            className="form-control"
            value={value}
            mask={mask}
            onChange={onChange}
            />
    )
}

export default input;