import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Actions } from '../../flux';
import { clientsService } from '../../_services/clients.service';
import { WhatsappButton } from "../../components/WhatsappButton";
import { texts, images } from '../../settings/';
import { imageApi } from '../../_helpers/imageApi';
import Avatar from "../../components/Avatar";
import { numberToReal } from '../../utils/money';
import { PeriodFilter } from '../../components/PeriodFilter'

import "./Gerencial.scss";
import { schedulesService } from '../../_services/schedule.service';

class Agendamentos extends Component {
	state = {
		items: [],
		periodStart: null,
		periodEnd: null,
		loading: false
	}

	constructor(props) {
		super(props);
	}

	// componentDidUpdate = (nextProps) => {
	// 	console.log('nextProps', nextProps)
	// }

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.loadData();
	}

	changeStart = date => {
        // console.log('changeStart', date)
		this.setState({ periodStart: date });
		this.loadData();
	}

	changeEnd = date => {
        // console.log('changeEnd', date)
		this.setState({ periodEnd :date });
		this.loadData();
	}

	changeDates = (start, end) => {
		this.setState({
			periodStart: start,
			periodEnd: end
		});
		this.loadData();
	}

	loadData = async () => {
		this.setState({ loading: true })
		setTimeout(() => {
			const { periodStart, periodEnd } = this.state
			schedulesService.getSchedules(periodStart, periodEnd).then(data => {
				this.setState({
					items: data,
					loading: false
				})
			})
		}, 50)
	}

	listServices = services => {
		let serv = [];
		services.map(obj => {
			serv.push(`${obj.name} ${ numberToReal(obj.price) }`);
		})
		return serv.join(' & ');
	}

	sumServices = services => {
		let total = 0;
		services.map(obj => {
			total += obj.price;
		})
		return total;
	}

	renderList = items => {
		return (
			<div className="items">
                {
                    items.length > 0 &&
					<div className="table-responsive">
						<table className="table table-bordered">
							<tbody>
								<tr>
									<th>Data agendada</th>
									<th>Unidade</th>
									<th>Serviços</th>
									<th>Total</th>
									<th>Cliente</th>
									<th>Profissional</th>
								</tr>
                                {items.map((item, index) => (
                                    <tr key={item.schedule_id}>
                                        <td>{moment(`${item.when} ${item.hour}`).format("DD.MM.YYYY - HH:mm")}</td>
										<td>{item.unity.name}</td>
                                        <td>{ item.services.length > 0 && this.listServices(item.services)}</td>
                                        <td>{numberToReal(this.sumServices(item.services))}</td>
										<td>
											{(item.client_data && item.client_data.status == 0) && `Cliente excluído` }
											{(item.client_data && item.client_data.status == 1) && <Avatar data={item.client_data} size="xs" /> }
											{(item.client_data && item.client_data.status == 1) && 
												<WhatsappButton 
													phonenumber={item.client_data.cellphone}
													size='sm'
													marginTop={0} 
													text={``} /> }
										</td>
										<td>{item.professional_data && <Avatar data={item.professional_data} size="xs" /> }</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum item</p>
                }
			</div>
		);
    }

	render = () => {
		const { items, loading } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Agendamentos - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Agendamentos {`(${items.length})`} </h1>
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / <Link to="/gerencial">Gerencial</Link> / Agendamentos</li>
							</ol>
						</nav>
					</div>
				</div>
				
				<PeriodFilter
					onChangeStart={date => this.changeStart(date)}
					onChangeEnd={date => this.changeEnd(date)}
					onChangeDates={(start, end) => this.changeDates(start, end)}
					/>

                {loading && <div className="body-loading"><i className="i-spinner loading super-loading mb-15"></i><br />Carregando</div>}

				{
					!loading &&
					<div className="box">
						<div className="box-header">
							<h3>Últimos agendamentos</h3>
							<div className="btn-actions">
								<button className="btn btn-info" onClick={() => this.loadData()}><i className="fe fe-refresh-cw mr-5"></i>Atualizar</button>
							</div>
						</div>
						<div className="box-body">
							{this.renderList(items)}
						</div>
					</div>
				}
				
			</div>
    	);
	}
}

export { Agendamentos };