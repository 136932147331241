import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { addressService } from '../../_services/address.service';
import MinhaContaNav from '../../components/MinhaContaNav';
import { CriarEndereco } from './CriarEndereco';

class Enderecos extends Component {
	state = {
        loaded: false,
        add: false,
        addresses: [],
        editAddress: null
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		addressService.myAddresses().then(data => {
			this.setState({
				addresses: data,
				loaded: true
			})
			console.log('get myAddresses', data)
		})
    }
    
    editAddress = item => {
        this.setState({
            edit: false,
            editAddress: null
        })

        // Aguarda 50 milisegundos para adicionar
        setTimeout(() => {
            this.setState({
                edit: true,
                editAddress: item
            })
        }, 50);
    }

    dismiss = () => {
        this.setState({
            add: false,
            edit: false,
            editAddress: null
        })

        this.getAll();
    }

	render = () => {
        const { addresses, add, edit, editAddress } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Endereços - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Endereços</h1>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <MinhaContaNav active = {'enderecos'} />
                    </div>
                    <div className="col-md-12 mt-20">
                        <button onClick={() => this.setState({ add: true, edit: false })} className="btn btn-info">Adicionar</button>
                    </div>

                    {
                        !add &&
                        <div className="col-md-6 mt-40">
                            {
                                addresses.length > 0 &&
                                <div className="list-group">
                                    {
                                        addresses.map(item => (
                                            <button onClick={() => this.editAddress(item)} className={`list-group-item list-group-item-action ${(editAddress && editAddress.id == item.id)?'active':''}`}>
                                                
                                                {
                                                    (editAddress && editAddress.id == item.id) && 
                                                    <i className="fe fe-edit float-right mr-20"></i>
                                                }

                                                {
                                                    (!editAddress || editAddress.id != item.id) && 
                                                    <i className="fe fe-home float-right mr-20"></i>
                                                }
                                                {item.name} - <small>{item.address}, {item.number} - {item.neighborhood} - {item.city}</small>
                                            </button>
                                        ))
                                    }
                                </div>
                            }

                            {
                                addresses.length == 0 &&
                                <p>Nenhum endereço cadastrado</p>
                            }
                        </div>
                    }

                    {
                        add &&
                        <div className="col-md-6 mt-40">
                            <div className="row">
                                <div className="col-md-8">
                                    <h2 className="content-title">Adicionar Endereço</h2>
                                </div>
                                <div className="col-md-4 text-right">
                                    <button onClick={() => this.setState({ add: false })} className="btn btn-light">Cancelar</button>
                                </div>
                            </div>
                            <CriarEndereco onDismiss={() => this.dismiss()} />
                        </div>
                    }

                    {
                        edit &&
                        <div className="col-md-6 mt-40">
                            <div className="row">
                                <div className="col-md-8">
                                    <h2 className="content-title">Editar Endereço</h2>
                                </div>
                                <div className="col-md-4 text-right">
                                    <button onClick={() => this.setState({ edit: false, editAddress: null })} className="btn btn-light">Cancelar</button>
                                </div>
                            </div>
                            <CriarEndereco edit address={editAddress} onDismiss={() => this.dismiss()} />
                        </div>
                    }

                </div>
				
			</div>
    	);
	}
}

export { Enderecos };