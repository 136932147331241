import React from 'react';
import CurrencyFormat from 'react-currency-format';

const input = ({
    value,
    onChange,
    disabled = false
}) => {
    return (
        <CurrencyFormat
            disabled={disabled}
            value={value}
            className="form-control"
            prefix={''}
            decimalScale={2}
            decimalSeparator={','}
            thousandSeparator={'.'}
            onValueChange={ev => onChange(ev)} />
    )
}

export default input;