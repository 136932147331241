import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { userService } from '../../_services/user.service'
import { texts, images } from '../../settings/'
import { imageApi } from '../../_helpers/imageApi'
import MinhaContaNav from '../../components/MinhaContaNav'
import { CriarUsuario } from './CriarUsuario';

import { getInitials } from '../../_helpers/clean-string'

class Usuarios extends Component {
	state = {
		users: [],
		loaded: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		userService.getUsers().then(data => {
			console.log('get myusers', data)
			this.setState({
				users: data,
				loaded: true
			})
		})
	}

	blockUser = userId => {
		userService.blockUser(userId).then(data => {
            this.getAll();
		}).catch(err => {
			Actions.showError(err)
            this.getAll();
		})
	}

	unblockUser = userId => {
		userService.unblockUser(userId).then(data => {
            this.getAll();
		}).catch(err => {
			Actions.showError(err)
            this.getAll();
		})
	}

	renderUsers = items => {
		return (
			<div className="users-list row">
				{items.map(item => (
					<Link className="user-panel col-12" key={item.id} to={`/clientes/${item.id}`}>
						<div className="user-panel-image">
							<div className="avatar avatar-secondary avatar-sm">{getInitials(item.name)}</div>
						</div>
						<div className="user-panel-info">
							<p>{item.name}</p>
							<small className="text-black-50">{item.email}</small>
						</div>
						<div className="user-panel-actions">
							{/* <a href="#"><i className="fas fa-ellipsis-v"></i></a> */}
						</div>
					</Link>
				))}
			</div>
		);
	}

    dismiss = () => {
        this.setState({
            add: false
        })
        this.getAll();
    }

	render = () => {
		const { users, loaded, add } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Usuários - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Usuários</h1>
				</div>

                <div className="col-md-12">
                    <MinhaContaNav active = {'usuarios'} />
                </div>

                {
                    !add &&
                    <div className="col-md-12 mt-20">
                        <button onClick={() => this.setState({ add: true, edit: false })} className="btn btn-info">Adicionar</button>
                    </div>
                }

                {
                    add &&
                    <div className="col-md-5 mt-40">
                        <div className='row'>
							<div className="col-md-8">
								<h3>Enviar convite</h3>
							</div>
							<div className="col-md-4 text-right">
                                <button onClick={() => this.setState({ add: false })} className="btn btn-light pull-right">Cancelar</button>
							</div>
                        </div>
                        <CriarUsuario onDismiss={() => this.dismiss()} />
                    </div>
                }

				<div className="row">
					<div className="col-lg-12 mt-20">
						<div className="box">
							<div className="box-header">
								<h3>Todos ({users.length})</h3>
							</div>

                            {
                                !loaded &&
                                <div className="row" style={{ height: 200, paddingTop: 80 }}>
                                    <div className="col-md-12 text-center">
                                        <p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
                                    </div>
                                </div>
                            }

                            {
                                loaded &&
                                <div className="box-body">

                                    {
                                        users.length === 0 &&
                                        <div className="row" style={{ height: 200, paddingTop: 80 }}>
                                            <div className="col-md-12 text-center">
                                                <p className="mb-20 text-lg">Nenhum cadastro</p>
                                            </div>
                                        </div>
                                    }

                                    {
                                        users.length > 0 && 
                                        <div className="table-responsive">
                                            <table className="table table-hovered">
                                                <tbody>
                                                    <tr>
                                                        <th>Nome</th>
                                                        <th>E-mail</th>
                                                        <th>Perfil</th>
                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>

                                                    {users.map((item, index) => {
                                                        const isInvite = (item.accepted != null);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{(!isInvite) ? item.name : ``}</td>
                                                                <td>{item.email}</td>
                                                                <td>{(!isInvite) ? item.role : ``}</td>
                                                                <td>
                                                                    { (!isInvite && item.blocked == 0) && `Ativo` }
                                                                    { (!isInvite && item.blocked == 1) && `Bloqueado` }
                                                                </td>
                                                                <td>                                                                
                                                                    {
                                                                        (!isInvite && item.blocked == 0) &&
                                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => this.blockUser(item.user_id)}>Bloquear acesso</button>
                                                                    }

                                                                    {
                                                                        (!isInvite && item.blocked == 1) &&
                                                                        <button className="btn btn-sm btn-outline-secondary" onClick={() => this.unblockUser(item.user_id)}>Liberar acesso</button>
                                                                    }
                                                                    { (isInvite && item.accepted == 0) && `Convite enviado` }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                    
                                </div>
                            }

						</div>
					</div>
				</div>
				
			</div>
    	);
	}
}

export { Usuarios };