/*
    ./app/app.js
*/
import React from "react";
import {
  // BrowserRouter as Router,
  Route,
  browserHistory,
  Switch,
  HashRouter
} from "react-router-dom";

// Modal Styles
import Modal from 'react-modal';
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,.5)';
Modal.defaultStyles.overlay.zIndex = '10';
Modal.defaultStyles.content.width = '600px';
Modal.defaultStyles.content.padding = '0';

import { Dispatcher, Events, Actions } from "./flux";

import {
  PrivateRoute,
  Home,
  Configure,
  Login,
  Signup,
  NotFound,
  Agenda,
  AgendaPublic,
  Unidades,
  CriarUnidade,
  VerUnidade,

  CriarProfissional,
  VerProfissional,
  CadastroProfissional,
  EditarProfissional,

  Servicos,
  CriarServico,
  Produtos,
  CriarProduto,
  EditarProduto,
  Plano,
  PlanoMigrar,
  Clientes,
  CriarCliente,
  VerCliente,

  Gerencial,
  Agendamentos,
  Comissoes,
  Atendimentos,
  Faturamento,

  MinhaConta,
  // Cartoes,
  EditProfile,
  EditCompany,
  Enderecos,

  Usuarios,
  CadastroUser,

  Locked,
  // PacoteServicos,
  // CriarPacoteServicos,
  // PacoteServicosAjustes,
  // VenderPacoteServicos,
  CriarHorarioAtendimento,
  Design,
  Bank
} from "./views";

import {
  Footer,
  Header,
  Sidebar
} from './views/_layout'
import ModalSchedule from './components/ModalSchedule'
import ModalScheduleDetails from './components/ModalScheduleDetails'
import ModalFinishOrder from './components/ModalFinishOrder'

import $ from "jquery";
import { Helmet } from "react-helmet";
import SweetAlert from "sweetalert2-react";
import Analytics from "react-router-ga";

import { images, texts, settings, urls } from "./settings/";
import { userService } from "./_services/";

import "./app.scss";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')
Modal.setAppElement(document.getElementById('root'));

Array.prototype.sum = function (prop) {
  var total = 0
  for ( var i = 0, _len = this.length; i < _len; i++ ) {
    let val = (typeof this[i][prop] === 'number') ? this[i][prop] : parseFloat(this[i][prop])
    total += (typeof val === 'number') ? val : 0
  }
  return total;
}

export default class App extends React.Component {
  constructor(props) {
    super(props);

    window.jQuery = $;
    window.$ = $;
    this.$ = $;
    global.jQuery = $;

    this.images = images;
    this.urls = urls;
    this.settings = settings;


    this.state = {
      headerVisible: false,
      footerVisible: false,
      menuVisible: false,
      menuOpened: (window.innerWidth >= 768) ? true : false,

      newScheduleData: null,
      scheduleDetails: null,

      error: false,
      errorText: "",

      success: false,
      successText: "",
      successTitle: "",
      successRedirect: "",
      
      mobileMenu: "",
      backButton: "",
      logoIntern: "",
      menuButton: "",
      modal: false,
      modalTreinamentos: false,
      modalApi: false,

      domain: "",

      scheduleModalIsOpen: false,
      detailsModalIsOpen: false,
      finishOrderModalIsOpen: false
    };

    this.lastActiveTime;
    this.elmOverlay;
    this.overlay;

    this.globalDelay = settings.globalDelay;
    this.bodyRef = React.createRef();

    // document.addEventListener("DOMContentLoaded", this.loadbar, false);
  }

  openModal = modal => {
    switch (modal) {
      case 'schedule': this.setState({scheduleModalIsOpen: true}); break;
      case 'schedule-details': this.setState({detailsModalIsOpen: true}); break;
      case 'finish-order':

        Modal.defaultStyles.content.width = '800px';
        this.setState({finishOrderModalIsOpen: true});
        break;
    }
  }

  closeModal = modal => {
    switch (modal) {
      case 'schedule': this.setState({scheduleModalIsOpen: false}); break;
      case 'schedule-details': this.setState({detailsModalIsOpen: false}); break;
      case 'finish-order': this.setState({finishOrderModalIsOpen: false}); break;
    }
  }

  logout = () => {
    userService.logout();
  }

  showHeader() {
    this.setState({ headerVisible : true });
  }

  hideHeader() {
    this.setState({ headerVisible : false });
  }

  showFooter() {
    this.setState({ footerVisible : true });
  }

  hideFooter() {
    this.setState({ footerVisible : false });
  }

  showMenu() {
    this.setState({ menuVisible : true });
  }

  hideMenu() {
    this.setState({ menuVisible : false });
  }

  dLogout = () => {
    this.logout();
  }

	dShowHeader = () => {
    this.showHeader();
  }

  dHideHeader() {
    this.hideHeader();
  }

  dShowFooter() {
    this.showFooter();
  }

  dHideFooter() {
    this.hideFooter();
  }

  dShowMenu() {
    this.showMenu();
  }

  dHideMenu() {
    this.hideMenu();
  }

	toggleMenu = () => {
		const { menuOpened } = this.state
		this.setState({menuOpened:!menuOpened})
	}

  dShowError(text) {
    this.showError(text);
  }

  dShowSuccess(data) {
    this.showSuccess(data);
  }


  showError(text, type, title) {
    this.setState({
      error: true,
      errorText: text,
      errorType: type,
      errorTitle: title,
    });
  }

  showSuccess(data) {
    this.setState({
      success: true,
      successText: data.text,
      successTitle: data.title,
    });
  }

  // Accept terms
  showAcceptTerms() {
    // console.log('showAcceptTerms')
  }

  hideAcceptTerms() {
    // console.log('hideAcceptTerms')
  }


  showModalSchedule = data => {
    // console.log('listener', data);
    this.setState({newScheduleData: data})
    this.openModal('schedule');
  }
  hideModalSchedule = () => {
    this.closeModal('schedule');
  }

  showModalScheduleDetails = data => {
    this.setState({scheduleDetails: data})
    this.openModal('schedule-details');
  }
  hideModalScheduleDetails = () => {
    this.closeModal('schedule-details');
  }

  showModalFinishOrder = data => {
    console.log('showModalFinishOrder', data)
    this.setState({scheduleDetails: data})
    this.openModal('finish-order');
  }
  hideModalFinishOrder = () => {
    this.closeModal('finish-order');
  }

	componentWillMount() {
    // // Dispatchers
    Dispatcher.on(Events.LOGOUT, this.dLogout.bind(this) );
    Dispatcher.on(Events.SHOW_ERROR, this.dShowError.bind(this));
    Dispatcher.on(Events.SHOW_SUCCESS, this.dShowSuccess.bind(this));
    Dispatcher.on(Events.SHOW_HEADER, this.dShowHeader.bind(this) );
    Dispatcher.on(Events.HIDE_HEADER, this.dHideHeader.bind(this) );
    Dispatcher.on(Events.SHOW_FOOTER, this.dShowFooter.bind(this) );
    Dispatcher.on(Events.HIDE_FOOTER, this.dHideFooter.bind(this) );
    Dispatcher.on(Events.SHOW_MENU, this.dShowMenu.bind(this) );
    Dispatcher.on(Events.HIDE_MENU, this.dHideMenu.bind(this) );

		Dispatcher.on(Events.TOGGLE_MENU, this.toggleMenu.bind(this) );

    Dispatcher.on(Events.ACCEPT_TERMS_SHOW, this.showAcceptTerms.bind(this) );
    Dispatcher.on(Events.ACCEPT_TERMS_HIDE, this.hideAcceptTerms.bind(this) );

    Dispatcher.on(Events.ADD_ORDER_SHOW, this.showModalSchedule.bind(this) );
    Dispatcher.on(Events.ADD_ORDER_HIDE, this.hideModalSchedule.bind(this) );

    Dispatcher.on(Events.MODAL_SCHEDULE_DETAILS_SHOW, this.showModalScheduleDetails.bind(this) );
    Dispatcher.on(Events.MODAL_SCHEDULE_DETAILS_HIDE, this.hideModalScheduleDetails.bind(this) );

    Dispatcher.on(Events.MODAL_FINISH_ORDER_SHOW, this.showModalFinishOrder.bind(this) );
    Dispatcher.on(Events.MODAL_FINISH_ORDER_HIDE, this.hideModalFinishOrder.bind(this) );
  }

  componentWillUnmount() {
    // // Dispatchers
    Dispatcher.removeListener(Events.LOGOUT, this.dLogout );
    Dispatcher.removeListener(Events.SHOW_ERROR, this.dShowError);
    Dispatcher.removeListener(Events.SHOW_SUCCESS, this.dShowSuccess);
    Dispatcher.removeListener(Events.SHOW_HEADER, this.dShowHeader );
    Dispatcher.removeListener(Events.HIDE_HEADER, this.dHideHeader );
    Dispatcher.removeListener(Events.SHOW_FOOTER, this.dShowFooter );
    Dispatcher.removeListener(Events.HIDE_FOOTER, this.dHideFooter );
    Dispatcher.removeListener(Events.SHOW_MENU, this.dShowMenu );
    Dispatcher.removeListener(Events.HIDE_MENU, this.dHideMenu );
		Dispatcher.removeListener(Events.TOGGLE_MENU, this.toggleMenu );

    Dispatcher.removeListener(Events.ACCEPT_TERMS_SHOW, this.showAcceptTerms );
    Dispatcher.removeListener(Events.ACCEPT_TERMS_HIDE, this.hideAcceptTerms );

    Dispatcher.removeListener(Events.ADD_ORDER_SHOW, this.showModalSchedule );
    Dispatcher.removeListener(Events.ADD_ORDER_HIDE, this.hideModalSchedule );

    Dispatcher.removeListener(Events.MODAL_SCHEDULE_DETAILS_SHOW, this.showModalScheduleDetails );
    Dispatcher.removeListener(Events.MODAL_SCHEDULE_DETAILS_HIDE, this.hideModalScheduleDetails );

    Dispatcher.removeListener(Events.MODAL_FINISH_ORDER_SHOW, this.showModalFinishOrder );
    Dispatcher.removeListener(Events.MODAL_FINISH_ORDER_HIDE, this.hideModalFinishOrder );
  }

  render = () => {
    const {
      headerVisible,
      footerVisible,
      menuVisible,
      newScheduleData,
      scheduleDetails,
      menuOpened
    } = this.state

    return (
      <HashRouter history={browserHistory}>
        <Analytics id={this.settings.gaId} debug>
          <div className = {(menuOpened) ? "app app--menu-opened wrapper theme-inverse" : "app wrapper theme-inverse"} ref={this.bodyRef}>
            <Helmet>
              <title>{texts.title}</title>
              <meta name="title" content={texts.title} />
              <meta name="description" content={texts.description} />
            </Helmet>

            <Modal
              isOpen={this.state.scheduleModalIsOpen}
              onRequestClose={() => this.closeModal('schedule')}
              style={customStyles}
              contentLabel="Novo agendamento"
              shouldCloseOnOverlayClick={false}
              >
              <ModalSchedule
                when={(newScheduleData && newScheduleData.when) ? newScheduleData.when : null}
                hour={(newScheduleData && newScheduleData.hour) ? newScheduleData.hour : null}
                unit={(newScheduleData && newScheduleData.unit) ? newScheduleData.unit : null}
                professional={(newScheduleData && newScheduleData.professional) ? newScheduleData.professional : null}
                onCloseModal = {() => this.closeModal('schedule')} />
            </Modal>

            <Modal
              isOpen={this.state.detailsModalIsOpen}
              onRequestClose={() => this.closeModal('schedule-details')}
              style={customStyles}
              contentLabel="Detalhes"
              shouldCloseOnOverlayClick={false}
              >
              <ModalScheduleDetails
                schedule = {scheduleDetails}
                onCloseModal = {() => this.closeModal('schedule-details')} />
            </Modal>

            <Modal
              isOpen={this.state.finishOrderModalIsOpen}
              onRequestClose={() => this.closeModal('finish-order')}
              style={customStyles}
              contentLabel="Finalizar Pedido"
              shouldCloseOnOverlayClick={false}
              >
              <ModalFinishOrder
                schedule = {scheduleDetails}
                onCloseModal = {() => this.closeModal('finish-order')} />
            </Modal>

            <SweetAlert
              show={this.state.error}
              title="Ops."
              text={this.state.errorText}
              type="warning"
              onConfirm={() => this.setState({ error: false })}
            />

            <SweetAlert
              show={this.state.success}
              title={this.state.successTitle}
              text={this.state.successText}
              type="success"
              onConfirm={() => this.setState({ success: false })}
            />

            {menuVisible && <Sidebar />}
            {headerVisible && <Header />}

            <main className="content">
              <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/:company/agenda-public" exact component={AgendaPublic} />
                <Route path="/b/:company" exact component={AgendaPublic} />
                <Route path="/signup" exact component={Signup} />

                <Route path="/user/activate" exact component={Clientes} />
                <Route path="/professional/profile/complete/:email" exact component={CadastroProfissional} />
                <Route path="/user/profile/complete/:token" exact component={CadastroUser} />

                <PrivateRoute path="/" exact component={Home} />
                <PrivateRoute path="/configure" exact component={Configure} onlyRole="Parceiro" />
                <PrivateRoute path="/agenda" planProtected exact component={Agenda} />

                <PrivateRoute path="/unidades" planProtected exact component={Unidades} />
                <PrivateRoute path="/unidades/criar" planProtected exact component={CriarUnidade} onlyRole="Parceiro" />
                <PrivateRoute path="/unidades/:id" planProtected exact component={VerUnidade} />
                
                <PrivateRoute path="/unidade/:id/profissional/adicionar" planProtected exact component={CriarProfissional} onlyRole="Parceiro" />
                <PrivateRoute path="/unidade/:id/servico/adicionar" planProtected exact component={CriarServico} onlyRole="Parceiro" />
                
                <PrivateRoute path="/unidade/:id/plano" planProtected exact component={Plano} onlyRole="Profissional"  />
                <PrivateRoute path="/unidade/:id/plano/migrar" exact component={PlanoMigrar} onlyRole="Profissional"  />

                <PrivateRoute path="/unidade/:id/horario/adicionar/:weekday" planProtected exact component={CriarHorarioAtendimento} />
                <PrivateRoute path="/profissional/editar/:id" planProtected exact component={EditarProfissional} onlyRole="Parceiro" />
                <PrivateRoute path="/servicos" planProtected exact component={Servicos} onlyRole="Profissional"  />

                <PrivateRoute path="/produtos" planProtected exact component={Produtos} onlyRole="Profissional"  />
                <PrivateRoute path="/produtos/criar" planProtected exact component={CriarProduto} onlyRole="Profissional"  />
                <PrivateRoute path="/produtos/editar/:id" planProtected exact component={EditarProduto} onlyRole="Profissional"  />

                <PrivateRoute path="/clientes" planProtected exact component={Clientes} onlyRole="Profissional" />
                <PrivateRoute path="/clientes/criar" planProtected exact component={CriarCliente} onlyRole="Profissional"  />
                <PrivateRoute path="/clientes/:id" planProtected exact component={VerCliente} onlyRole="Profissional"  />

                <PrivateRoute path="/profissionais/:id" planProtected exact component={VerProfissional} onlyRole="Parceiro" />

                <PrivateRoute path="/gerencial" planProtected exact component={Gerencial} onlyRole="Parceiro" />
                <PrivateRoute path="/gerencial/agendamentos" planProtected exact component={Agendamentos} onlyRole="Parceiro" />
                <PrivateRoute path="/gerencial/comissoes" planProtected exact component={Comissoes} onlyRole="Profissional" />
                <PrivateRoute path="/gerencial/atendimentos" planProtected exact component={Atendimentos} onlyRole="Parceiro" />
                <PrivateRoute path="/gerencial/faturamento" planProtected exact component={Faturamento} onlyRole="Parceiro" />

                <PrivateRoute path="/minha-conta" planProtected exact component={MinhaConta} onlyRole="Profissional" />
                <PrivateRoute path="/minha-conta/perfil" planProtected exact component={EditProfile} onlyRole="Profissional" />
                <PrivateRoute path="/minha-conta/company" planProtected exact component={EditCompany} onlyRole="Parceiro" />
                <PrivateRoute path="/minha-conta/enderecos" planProtected exact component={Enderecos} onlyRole="Parceiro" />
                <PrivateRoute path="/minha-conta/design" planProtected exact component={Design} onlyRole="Parceiro" />
                <PrivateRoute path="/minha-conta/bank" planProtected exact component={Bank} onlyRole="Parceiro" />
                <PrivateRoute path="/minha-conta/usuarios" planProtected exact component={Usuarios} onlyRole="Parceiro" />

                {/* <PrivateRoute path="/pacote-servicos" planProtected exact component={PacoteServicos} onlyRole="Profissional" />
                <PrivateRoute path="/pacote-servicos/venda" planProtected exact component={VenderPacoteServicos} onlyRole="Profissional" />
                <PrivateRoute path="/pacote-servicos/configuracoes" planProtected exact component={PacoteServicosAjustes} onlyRole="Profissional" />
                <PrivateRoute path="/pacote-servicos/configuracoes/criar" planProtected exact component={CriarPacoteServicos} onlyRole="Profissional" /> */}

                <PrivateRoute path="/plano" exact component={Plano} onlyRole="Profissional" />
                <PrivateRoute path="/plano/migrar/:period" exact component={PlanoMigrar} onlyRole="Profissional" />
                <PrivateRoute path="/locked" exact component={Locked} />

                <PrivateRoute path="*" component={NotFound} />
              </Switch>

            </main>

            {footerVisible && <Footer />}

          </div>
        </Analytics>
      </HashRouter>
    );
  }
}
