import React from 'react';
import MaskedInput from 'react-text-mask';

const input = ({
    value,
    onChange,
    mask,
    placeholder,
    guide = true,
    min,
    max,
    disabled = false
}) => {
    return (
        <MaskedInput
            disabled={disabled}
            max={max}
            min={min}
            guide={guide}
            value={value}
            className="form-control"
            mask={mask}
            onChange={onChange}
            placeholder={placeholder}
            />
    )
}

export default input;