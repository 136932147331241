const WEEKDAYS = [
    {
        label: "Segunda-feira",
        value: "segunda-feira"
    },
    {
        label: "Terça-feira",
        value: "terca-feira"
    },
    {
        label: "Quarta-feira",
        value: "quarta-feira"
    },
    {
        label: "Quinta-feira",
        value: "quinta-feira"
    },
    {
        label: "Sexta-feira",
        value: "sexta-feira"
    },
    {
        label: "Sábado",
        value: "sabado"
    },
    {
        label: "Domingo",
        value: "domingo"
    }
];

export { WEEKDAYS };