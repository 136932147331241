import React, { Component } from 'react';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Switch from "react-switch";

import { Actions } from '../../flux';
import { cardsService } from '../../_services';
import MaskedInput from '../../components/MaskedInput/default';
import { cleanString } from '../../_helpers';

const current_date = new Date();
const formCartao = yup.object().shape({
	card_number: yup
	.string()
	.min(19, 'Número inválido')
	.required('Obrigatório'),
		
	card_name: yup
	.string()
	.min(3, 'Nome inválido')
	.required('Obrigatório'),
		
	card_expdate_month: yup
	.number()
	.min(1, 'Mês inválido')
	.max(12, 'Mês inválido')
	.required('Obrigatório'),
		
	card_expdate_year: yup
	.number()
	.min(1900+current_date.getYear(), 'Mês inválido')
	.required('Obrigatório'),
		
	card_cvv: yup
	.string()
	.min(3, 'Cód. inválido')
	.required('Obrigatório')
});

class CriarCartao extends Component {
	state = {
		unit: null,
		loading: false,
        success: false,
		memorize: false
    }
    
    removeItem = () => {
        const { edit, cards } = this.props

        this.setState({loadingSubmit: true});
        
        console.log(`removeItem`, cards);

        if (edit && cards) {
            cardsService.remove(cards.id).then(res => {
                if (res.success) {
                    Actions.showSuccess({ title: 'Cartão removido!' })
                    this.props.onDismiss();
                } else {
                    Actions.showError(res.error)
                }
    
                this.setState({loadingSubmit: false});
            }).catch(err => {
                Actions.showError(err.error)
                this.props.onDismiss();
    
                this.setState({loadingSubmit: false});
            })
        }
    }

	saveInfo = values => {
        const { edit } = this.props
        this.setState({loadingSubmit: true});
        
        let data = values;
        data.zipcode = cleanString(data.zipcode);
        
        console.log(`saveInfo`, data);
        alert('área em integração');

        // if (edit) {
        //     cardsService.update(this.props.cards.id, data).then(res => {
        //         if (res.success) {
        //             Actions.showSuccess({ title: 'Cartão atualizado!' })
        //             this.props.onDismiss();
        //         } else {
        //             Actions.showError(res.error)
        //         }

        //         this.setState({loadingSubmit: false});
        //     }).catch(err => {
        //         Actions.showError(err.error)
        //         this.props.onDismiss();

        //         this.setState({loadingSubmit: false});
        //     })

        // } else {
        //     cardsService.create(data).then(res => {
        //         if (res.success) {
        //             Actions.showSuccess({ title: 'Cartão cadastrado!' })
        //             this.props.onDismiss();
        //         } else {
        //             Actions.showError(res.error)
        //         }

        //         this.setState({loadingSubmit: false});
        //     }).catch(err => {
        //         Actions.showError(err.error)
        //         this.props.onDismiss();

        //         this.setState({loadingSubmit: false});
        //     })
        // }
    }

	render = () => {
        const { loadingSubmit, memorize } = this.state
        const { cards } = this.props

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="box">
                        <div className="box-header">
                            <b>Informações do Cartão</b>
                        </div>

                        <div className="box-body">
                            <Formik
                                initialValues={{
                                    card_number: '',
                                    card_name: '',
                                    card_expdate_month:  '',
                                    card_expdate_year: '',
                                    card_cvv: ''
                                }}
                                validationSchema={formCartao}
                                onSubmit={values => this.saveInfo(values)}
                                >
                                {({ errors, touched, setFieldValue, isValid }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="form-group col-12 mt-20">
                                                <label htmlFor="card_number">
                                                    Número no cartão
                                                    <small className="form_label_error ml-10"><ErrorMessage className="text-danger" name="card_number" /></small>
                                                </label>
                                                <MaskedInput
                                                    mask = {
                                                        [
                                                            /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
                                                            /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
                                                            /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
                                                            /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ',
                                                            /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/
                                                        ]}
                                                    onChange={e => setFieldValue('card_number', e.target.value)}
                                                    guide = {false}
                                                    />
                                            </div>

                                            <div className="form-group col-12">
                                                <label htmlFor="card_name">
                                                    Nome no cartão 
                                                    <small className="form_label_error ml-10"><ErrorMessage className="text-danger" name="card_name" /></small>
                                                </label>
                                                <Field name="card_name" className="form-control" />
                                            </div>

                                            <div className="form-group form-group--height col-7">
                                                <label>Validade</label>

                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <MaskedInput
                                                            mask = {[/[0-9]/, /[0-9]/]}
                                                            placeholder = {'Mês'}
                                                            min = {0}
                                                            max = {12}
                                                            guide = {false}
                                                            onChange={e => setFieldValue('card_expdate_month', e.target.value)}
                                                            />
                                                        <small className="form_label_error"><ErrorMessage className="text-danger" name="card_expdate_month" /></small>
                                                    </div>

                                                    <div className="form-group col-6">
                                                        <MaskedInput
                                                            mask = {[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                            placeholder = {'Ano'}
                                                            guide = {false}
                                                            onChange={e => setFieldValue('card_expdate_year', e.target.value)}
                                                            />
                                                        <small className="form_label_error"><ErrorMessage className="text-danger" name="card_expdate_year" /></small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-1"></div>

                                            <div className="form-group col-4 form-group--height">
                                                <label htmlFor="card_cvv">Cód. segurança</label>
                                                <MaskedInput
                                                    mask = {[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                    guide = {false}
                                                    onChange={e => setFieldValue('card_cvv', e.target.value)}
                                                    />
                                                <small className="form_label_error"><ErrorMessage className="text-danger" name="card_cvv" /></small>
                                            </div>

                                            <div className="form-group col-12">
                                                <label className="switch_memorize">
                                                    <Switch onChange={() => this.setState({ memorize: !memorize })} checked={memorize} /> 
                                                    <span>Memorizar cartão para compras futuras</span>
                                                </label>
                                            </div>

                                            <div className="col-md-12 mt-20">
                                                {
                                                    loadingSubmit &&
                                                    <p className="mb-20 text-lg pull-left"><i className="i-spinner loading"></i> Carregando...</p>
                                                }
                                                {
                                                    !loadingSubmit &&
                                                    <button type="submit" className="btn btn-success btn-lg">Confirmar contratação</button>
                                                }
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </div>

                </div>
            </div>
    	);
	}
}

export { CriarCartao };