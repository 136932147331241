import React from "react";

import './Footer.scss'

// Utils
import { images } from '../../settings'

// Components
import { DelayLink } from '../../components';

class Footer extends React.PureComponent {
	render = () => {
		return (
			<footer className="footer">
				<p>Powered by BarberTrack © 2024 - Agendamento online em salões de beleza e barbearias</p> 
			</footer>
		)
	}
}

export { Footer };