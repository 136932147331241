import { handleResponse } from '../_helpers'
import API from './api'

export const bankService = {
    get,
    create,
    update
}

function get() {
    return API.get(`/partner/banks`)
            .then(handleResponse)
            .then(data => data)
}

function create(body) {
    console.log('create', body)
    return API.post(`/partner/banks`, body)
            .then(handleResponse)
            .then(res => res);
}

function update(body) {
    return API.put(`/partner/banks`, body)
            .then(handleResponse)
            .then(res => res);
}