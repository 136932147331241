export function cleanString(str) {
  if (!str) return;
  let strFormatted = str.replace(/[^\w\s]/gi, "");
  strFormatted = strFormatted.replace(/\s/g, "");
  return strFormatted;
}

export const isOnlyNumbers = str => {
  const s = /^[0-9]*$/.test(str.trim());
  return s;
};

export const getFirstWord = str => {
  let spacePosition = str.indexOf(' ');
  if (spacePosition === -1) {
    return str;
  } else {
    return str.substr(0, spacePosition);
  }
}

export const getInitials = str => {
  var names = str.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) initials += names[names.length - 1].substring(0, 1).toUpperCase();
  return initials;
};