import { handleResponse } from '../_helpers'
import API from './api'
import moment from 'moment'

export const schedulesService = {
    create,
    createPublic,
    getOne,
    getSchedulesByUnit,
    getPublicSchedule,
    getSchedules,
    cancelSchedule,
    updateScheduleHour
}

function create(body) {
    return API.post(`/partner/schedules`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function createPublic(slug, body) {
    return API.post(`/public/company/scheduling/${slug}`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function getPublicSchedule(schedule_id) {
    return API.get(`/public/company/schedule/${schedule_id}`)
            .then(handleResponse)
            .then(user => user)
}

async function getSchedules(from = null, to = null) {
    let _from = moment(from).format('YYYY-MM-DD');
    let _to = moment(to).format('YYYY-MM-DD');
    return API.get(`/partner/schedules${from?'?from='+_from:''}${to?'&to='+_to:''}`)
            .then(handleResponse)
            .then(user => user)
}

function cancelSchedule(id) {
    return API.delete(`/partner/schedule/${id}`)
            .then(handleResponse)
            .then(user => user)
}

function updateScheduleHour(id, hour) {
    return API.put(`/partner/schedule/update-hour/${id}`, { hour: hour })
            .then(handleResponse)
            .then(user => user)
}

function getSchedulesByUnit(unity_id, from = null) {
    let _from = (from) ? moment(from).format('YYYY-MM-DD') : null;
    return API.get(`/partner/unit/${unity_id}/schedules${from?'?from='+_from:''}`)
            .then(handleResponse)
            .then(user => user)
}

function getOne(id) {
    return API.get(`/partner/schedule/${id}`)
            .then(handleResponse)
            .then(data => data)
}