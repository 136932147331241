import { userService } from '../_services/user.service'

export function handleResponse(response) {
  // console.log('Handle Response', response);

  if (response.status === 401) userService.logout();

  // return (response.data.success) ? response.data : Promise.reject(response.data.error);
  return (response.status === 200 || response.status === 201) 
      ? response.data
      : Promise.reject(
        (response.data.error)
        ? response.data.error
        : (response.data.message) ? response.data.message : 'Ocorreu um erro inesperado.'
        );
}