import { handleResponse } from '../_helpers';
import { userService } from './user.service'
import API from './api'
import moment from 'moment'

export const professionalsService = {
    myProfessionals,
    create,
    update,
    getOne,
    addQuota,
    addService,
    getProfessionalServices,
    getProfessionalsByUnit,
    getProfessionalByEmail,
    completeSignup,
    getComissions,
    addBlockHour,
    updateBlockHour,
    removeBlockHour,
};

function myProfessionals() {
    return API.get(`/partner/professionals`)
            .then(handleResponse)
            .then(user => user)
}

function getProfessionalsByUnit(unity_id) {
    return API.get(`/partner/unit/${unity_id}/professionals`)
            .then(handleResponse)
            .then(user => user)
}

function getProfessionalByEmail(b64_email) {
    return API.get(`/partner/complete-professional/${b64_email}`)
            .then(handleResponse)
            .then(user => user)
}

function completeSignup(body) {
  // console.log('Create Professional, signup', body)

  return API.post(`/partner/complete-professional`, body)
          .then(handleResponse)
          .then(res => res)
          .catch(err => {
              return Promise.reject({error: err})
          });
}

function create(body) {
    // console.log('Create Professional, quota', body)

    const formData = new FormData();
    formData.append('name', body.name);
    formData.append('email', body.email);
    formData.append('cellphone', body.cellphone);
    formData.append('role', body.role);
    formData.append('description', body.description);
    formData.append('has_schedule', body.has_schedule);
    formData.append('has_user', body.has_user);
    formData.append('unit', body.unit);

    formData.append('has_quota', body.has_quota);
    formData.append('quota', body.quota);

    if (body.photoFile) {
      formData.append('photoFile', body.photoFile);
    }

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }

    return API.post(`/partner/professionals`, formData, config)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function update(id, body) {
    return API.put(`/partner/professional/${id}`, body)
            .then(handleResponse)
            .then(res => res)
            .catch(err => {
                return Promise.reject({error: err})
            });
}

function getOne(id) {
    return API.get(`/partner/professional/${id}`)
            .then(handleResponse)
            .then(data => data)
}

function addQuota(percent, professional) {
  const body = {
    professional: professional,
    quota: parseFloat(percent),
    type: 'service'
  }
  // console.log('addQuota Service', body)
  return API.post(`/partner/professional/${professional}/add-quota`, body)
          .then(handleResponse)
          .then(data => data)
}

function getProfessionalServices(id) {
    return API.get(`/partner/professional-service/${id}`)
            .then(handleResponse)
            .then(data => data)
}

function addService(active, professional_id, service_id) {
  const body = {
      active: active,
      professional: professional_id,
      service: service_id
  }
  
  // console.log('addService Service', body)
  return API.post(`/partner/professional-service`, body)
          .then(handleResponse)
          .then(data => data)
}

function getComissions(from = null, to = null) {
  let _from = moment(from).format('YYYY-MM-DD');
  let _to = moment(to).format('YYYY-MM-DD');
  return API.get(`/partner/comissions${from?'?from='+_from:''}${to?'&to='+_to:''}`)
    .then(handleResponse)
    .then(user => user)
}

function addBlockHour(body, professionalId) {
  return API.post(`/partner/professional/${professionalId}/block-hours`, body)
          .then(handleResponse)
          .then(data => data)
}

function updateBlockHour(body, itemId) {
  return API.post(`/partner/professional/${itemId}/block-hours/update`, body)
          .then(handleResponse)
          .then(data => data)
}

function removeBlockHour(itemId) {
  return API.post(`/partner/professional/${itemId}/block-hours/delete`)
          .then(handleResponse)
          .then(data => data)
}