const Events = {
	SHOW_SUCCESS: 'SHOW_SUCCESS',
	SHOW_HEADER: 'SHOW_HEADER',
	HIDE_HEADER: 'HIDE_HEADER',
	SHOW_FOOTER: 'SHOW_FOOTER',
	HIDE_FOOTER: 'HIDE_FOOTER',
	SHOW_MENU: 'SHOW_MENU',
	HIDE_MENU: 'HIDE_MENU',
	TOGGLE_MENU: 'TOGGLE_MENU',


	ACCEPT_TERMS_SHOW: 'ACCEPT_TERMS_SHOW',
	ACCEPT_TERMS_HIDE: 'ACCEPT_TERMS_HIDE',
	ACCEPT_TERMS_REQUEST: 'ACCEPT_TERMS_REQUEST',
	ACCEPT_TERMS_SUCCESS: 'ACCEPT_TERMS_SUCCESS',
	ACCEPT_TERMS_FAILURE: 'ACCEPT_TERMS_FAILURE',


	ADD_ORDER_SHOW: 'ADD_ORDER_SHOW',
	ADD_ORDER_HIDE: 'ADD_ORDER_HIDE',
	ADD_ORDER_REQUEST: 'ADD_ORDER_REQUEST',
	ADD_ORDER_SUCCESS: 'ADD_ORDER_SUCCESS',
	ADD_ORDER_FAILURE: 'ADD_ORDER_FAILURE',

	MODAL_SCHEDULE_DETAILS_SHOW: 'MODAL_SCHEDULE_DETAILS_SHOW',
	MODAL_SCHEDULE_DETAILS_HIDE: 'MODAL_SCHEDULE_DETAILS_HIDE',
	MODAL_SCHEDULE_DATE_UPDATED: 'MODAL_SCHEDULE_DATE_UPDATED',
	MODAL_SCHEDULE_HOUR_UPDATED: 'MODAL_SCHEDULE_HOUR_UPDATED',

	MODAL_FINISH_ORDER_SHOW: 'MODAL_FINISH_ORDER_SHOW',
	MODAL_FINISH_ORDER_HIDE: 'MODAL_FINISH_ORDER_HIDE',

	PROFESSIONAL_QUOTA_UPDATED: 'PROFESSIONAL_QUOTA_UPDATED',

	PLAN_UPDATED: 'PLAN_UPDATED',

	USER_UPDATED: 'USER_UPDATED',


	TOGGLE_PROFILE_BOX: 'TOGGLE_PROFILE_BOX',
	CLOSE_PROFILE_BOX: 'CLOSE_PROFILE_BOX',
	SHOW_PROFILE_BOX: 'SHOW_PROFILE_BOX',
	SHOW_MODAL_TREINAMENTOS: 'SHOW_MODAL_TREINAMENTOS',
	HIDE_MODAL_TREINAMENTOS: 'HIDE_MODAL_TREINAMENTOS',
	SHOW_MODAL: 'SHOW_MODAL',
	HIDE_MODAL: 'HIDE_MODAL',
	LOGOUT: 'LOGOUT',
	SHOW_ERROR: 'SHOW_ERROR',
	MENU_INTERN: 'MENU_INTERN',
	MENU_HOME: 'MENU_HOME',
	LOGO_INTERN: 'LOGO_INTERN',
	LOGO_HOME: 'LOGO_HOME',
	SUCCESS_FORM: 'SUCCESS_FORM',
	GLOBAL_TRANSITION: 'GLOBAL_TRANSITION',
};

export { Events };
