import React from 'react';
import { Link } from 'react-router-dom';

import "./MinhaContaNav.scss";

const minhaContaNav = ({
    active
}) => {
    return (
        <div className="minha-conta-nav list-group list-group--horizontal">
            <Link to="/minha-conta/perfil" className={`list-group-item list-group-item-action ${(active == 'perfil') ? 'active' : ''}`}>
                Editar perfil
            </Link>
            <Link to="/minha-conta/company" className={`list-group-item list-group-item-action ${(active == 'company') ? 'active' : ''}`}>
                Editar barbearia
            </Link>
            {/* <Link to="/minha-conta/cartoes" className={`list-group-item list-group-item-action ${(active == 'cartoes') ? 'active' : ''}`}>
                Cartões
            </Link> */}
            <Link to="/minha-conta/usuarios" className={`list-group-item list-group-item-action ${(active == 'usuarios') ? 'active' : ''}`}>
                Usuários
            </Link>
            <Link to="/minha-conta/enderecos" className={`list-group-item list-group-item-action ${(active == 'enderecos') ? 'active' : ''}`}>
                Endereços
            </Link>
            <Link to="/minha-conta/design" className={`list-group-item list-group-item-action ${(active == 'design') ? 'active' : ''}`}>
                Visual
            </Link>
            <Link to="/plano" className={`list-group-item list-group-item-action ${(active == 'plano') ? 'active' : ''}`}>
                Plano
            </Link>
        </div>
    )
}

export default minhaContaNav;