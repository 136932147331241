import React, { Component } from 'react';

import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';
import moment from 'moment';
import AddToCalendar from 'react-add-to-calendar';
import { Select, CaretIcon, ModalCloseButton } from 'react-responsive-select';

import { cookie } from "../../_helpers/cookie"
import { unitsService } from '../../_services/units.service'
import { schedulesService, getPublicSchedule } from '../../_services/schedule.service'
import { numberToReal } from "../../utils/money"
import { verifyHourAvailable, parseHours } from '../../utils/time'
import { cleanString } from '../../_helpers'
import PhoneInput from "../../components/MaskedInput/phone"

// for default styles...
import 'react-responsive-select/dist/react-responsive-select.css';
import './AgendaPublic.scss';

import {
    Actions
} from '../../flux';

import {
	images,
	texts,
    urls
} from '../../settings/';

// Components
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { HORARIOS, isEarlier } from '../../settings/enums';
import { WhatsappButton } from '../../components';

class AgendaPublic extends Component {

	constructor(props) {
        super(props);
        this.state = {
            cookieName: 'public_schedule',
            isTrial: null,
            expired: null,
            design: null,
            width: window.innerWidth,
            data: null,
            unitId: '',
            companyName: '',
            service: null,
            professional: null,
            day: null,
            hour: null,
            showHours: false,
            off: [],
            offDay: [],

            professionals_complete: null,
            services_complete: null,
            professionals: null,
            services: null,
            openingHours: null,

            hours_old: HORARIOS,
            
            hours: null,

            confirm: false,
            name: '',
            phone: '',

            loading: false,
            error: false,
            success: false,
            successObject: null,

            event: {
                title: '',
                description: '',
                location: '',
                startTime: '',
                endTime: ''
            },

            unit: null,
        };
        this.runTransition = this.runTransition.bind(this);
	}

    runTransition() {
    	Actions.globalTransition();
    }

	componentDidMount() {
        this.setState({ loading: true });

		Actions.hideMenu();
		Actions.hideHeader();
        Actions.showFooter();
        
        const { match: { params } } = this.props;
        
        this.setState({unitId:params.company});

        unitsService
            .getBySlugV2(params.company)
            .then(res => {
                // console.log('unit v2 >>> ', res)
                this.setState({
                    data: res,
                    companyName: res.name,
                    unit: res.unit,
                    loading: false,
                    design: res.design,
                    hasPlan: res.hasPlan,
                    isTrial: res.isTrial,
                    expired: res.expired,
                });
                this.verifyPlan();
                // this.parseProfessionals(res.professionals);
                this.parseServices(res.services);

                // Verifica o Cookie Salvo
                this.verifyCookie();

            }).catch(err => {
                console.warn('getBySlug ERROR', err)
                this.setState({ error: true });
            })
        
	}

	componentWillUnmount() {}

    verifyCookie = () => {
        let saved = cookie.getItem(this.state.cookieName);
        if (saved) {
            let split = saved.split('-');
            if (split.length > 2) {
                schedulesService.getPublicSchedule(split[2]).then(res => {
                    // console.log('getPublicSchedule', res);

                    this.setState({
                        success:true,
                        successObject: res
                    });

                    this.parseCalendarEvent();
                }).catch(err => {
                    console.warn('getPublicSchedule ERROR', err)
                })
            }
        }
    }

    verifyPlan = () => {
        const { expired } = this.state;
        if (expired) this.setState({error: true});
    }
    
    changeProfessional(ev) {
        this.setState({professional: ev});
        this.setHours(ev);
    }
    
    changeService(ev) {
        const { data } = this.state;
        let services = data.services.filter(obj => obj.service_id == ev);

        this.setState({service: ev});
        if (services.length > 0) {
            this.parseProfessionals(services[0].professionals);
        }
    }

    changeDate(ev) {
        const { openingHours, off } = this.state;
		var parsed = parseHours(ev, openingHours, off);

        this.setState({
            hour: null,
            day: ev,
            hours: parsed.hours,
            showHours: parsed.showHours,
            offDay: parsed.offDay,
        });
    }

    parseProfessionals(items) {
        var professionals = [
            { text: 'Profissional', optHeader: true, value: '' }
        ];
        items.map(item => {
            professionals.push({
                value: item.professional_data.id,
                label: item.professional_data.name,
                text: item.professional_data.name
            })
        });
        // console.log('parseProfessionals', professionals);
        this.setState({
            professionals: professionals,
            professionals_complete: items
        });
    }

    listServices(professionalId) {
        const { data } = this.state;
        let professional = data.professionals.filter(obj => obj.id == professionalId);
        if (professional.length > 0) {
            this.parseServices(professional[0].services);
        }
    }

    setHours(professionalId) {
        const { professionals_complete } = this.state;
        let professional = professionals_complete.filter(obj => obj.professional_data.id == professionalId);
        if (professional.length > 0) {
            this.setState({
                openingHours: professional[0].opening_hours,
                off: professional[0].future_blocked_hours
            });
        }
    }

    parseServices(items) {
        var services = [
            { text: 'Serviço', optHeader: true, value: '' }
        ];
        items.map(item => { 
            services.push({
                value: item.service_id,
                label: item.name,
                text: item.name
            })
        });
        this.setState({
            services:services,
            services_complete: items
        });
    }

    // parseHours(day) {
    //     const { openingHours, off } = this.state;

    //     let dayWeekday = moment(day).format("dddd");
    //     let dayCompare = moment(day).format("YYYY-MM-DD");

    //     let weekhours = openingHours.filter(obj => obj.weekday === dayWeekday);
    //     let offHours = off.filter(obj => (obj.weekday === dayWeekday || (obj.date != null && obj.date.includes(dayCompare))));

    //     let hourStart = (weekhours.length > 0) ? weekhours[0].hour_start : null;
    //     let hourEnd = (weekhours.length > 0) ? weekhours[0].hour_end : null;
    //     if (!hourStart || !hourEnd) { 

    //         this.setState({
    //             hours: null,
    //             showHours: true,
    //             offDay: []
    //         });
    //         return;
    //     }

    //     let start = parseInt(hourStart.substring(0, 2));
    //     let end = parseInt(hourEnd.substring(0, 2));

    //     var hours = [];
    //     for (let index = start; index <= end; index++) {
    //         hours.push({ id: `${index}:00:00`, label: `${index}:00` });
    //         if (index < end) {
    //             hours.push({ id: `${index}:30:00`, label: `${index}:30` });
    //         }
    //     }

    //     this.setState({
    //         hours:hours,
    //         showHours: true,
    //         offDay: offHours
    //     });
    // }

    getServiceSelected = () => {
        const {
            data,
            professional, 
            service
        } = this.state;
        let professionalSelected = data.professionals.filter(obj => obj.id == professional);
        if (professionalSelected.length == 0) {
            return null;
        }

        let serviceSelected = professionalSelected[0].services.filter(obj => obj.service_id == service);
        return (serviceSelected.length > 0) ?  serviceSelected[0] : null;
    }

    confirm() {
        const {
            unitId,
            professional, 
            service,
            day,
            hour,
            services_complete,
            name,
            phone
        } = this.state;

        let serviceSelected = services_complete.filter(obj => obj.service_id == service);
        if (serviceSelected.length == 0) {
            Actions.showError('Servico não selecionado');
            return;
        }
        
        if (!name) {
            Actions.showError('Nome não informado');
            return;
        } 
        if (!phone) {
            Actions.showError('Telefone não informado');
            return;
        } 
        
        let body = {
            client_phone: cleanString(phone),
            client_name: name,
            unit: serviceSelected[0].unity,
            professional: parseInt(professional),
            service: [parseInt(service)],
            date: moment(day).format("YYYY-MM-DD"),
            hour: hour
        }

        schedulesService.createPublic(unitId, body)
            .then(res => {

                // Salva o Cookie
                cookie.setItem(this.state.cookieName, `sch-${body.unit}-${res.data.public_string}`);

                // Atualiza o State
                this.setState({
                    success:true,
                    successObject: res.data
                });

                this.parseCalendarEvent();

                Actions.showSuccess({title:res.message});
            })
            .catch(err => {
                console.warn(err);
                Actions.showError((err.error) ? err.error : 'Não foi possível agendar');
            });
    }

    parseCalendarEvent = () => {
        let { successObject, companyName, data } = this.state;

        let professional_data = successObject.professional_data;
        let services = successObject.services;
        let title = `BarberTrack: ${services[0].name} com ${professional_data.name} em ${companyName}`;

        let startTime = `${moment(successObject.when).format("YYYY-MM-D")}T${successObject.hour}-03:00`;
        let endTime = `${moment(startTime).add(services[0].duration).format("YYYY-MM-DD[T]HH:mm:ss")}-03:00`;
        let local = `${data.unit.address}, ${data.unit.street_number} - ${data.unit.neighborhood} - ${data.unit.city} - ${data.unit.state}`;

        let event = {
            title: title,
            description: "Agendamento de serviço via " + title + ".\n\n" + "Endereço: " + local,
            location: local,
            startTime: startTime,
            endTime: endTime
        }

        this.setState({
            event: event
        });
    }

    clearCookie = () => {
        cookie.removeItem(this.state.cookieName);
        window.location.reload();
    }

	render = () => {
        const {
            companyName,
            professional, 
            service,
            day,
            hour,
            showHours,
            confirm,
            professionals,
            services,
            hours,
            loading,
            error,
            success,
            successObject,
            design,
            event,
            data,
            offDay,
        } = this.state;

        const classItem = [
            'agenda_public',
            (day) ? 'agenda_public--open' : ''
        ];

        // console.log('service changed', service)

        let bodyStyle = {
            backgroundColor: (design && design.public_background_color) ? design.public_background_color : null,
            backgroundImage: (design && design.public_background_image) ? `url(${design.public_background_image})` : null,
        };

        return (
			<div className="views"> 
				<Helmet>
	                <title>{texts.title} - Página customizada - {companyName}</title>
	            </Helmet>

	    		<div className={ classItem.join(' ') } style={bodyStyle}>

                    {
                        loading && 
                        <div className="agenda_public__centered agenda_public__loading">
                            {/* <img src={ images.logo } className="agenda_public__logo agenda_public__logo--default" /> */}
                            <div className='agenda_public__loading__icon'>
                                <i class="i-spinner loading" data-tooltip="i-spinner loading"></i>
                            </div>
                        </div>
                    }
                    
                    {
                        !loading &&
                        <div className="agenda_public__centered">
                            {
                                !error && design && design.public_banner &&
                                <img src={ design.public_banner } className="agenda_public__logo" />
                            }

                            {
                                !error && (!design || !design.public_banner) &&
                                <img src={ images.logo } className="agenda_public__logo agenda_public__logo--default" />
                            }

                            {
                                success && 
                                <div className="agenda_public__view">
                                    <div className="agenda_public__left" style={{ width: "100%"}}>
                                        <header className="text-center">
                                            <br />
                                            <h2>Horário reservado!</h2>
                                            
                                            <h4>{moment(successObject.when).format("dddd").toUpperCase()}</h4>
                                            <p>{moment(successObject.when).format("D [de] MMMM [de] YYYY")} às {successObject.hour}</p>
                                            <p>
                                                Serviço: {successObject.services[0].name}
                                                <br />
                                                Profissional: {successObject.professional_data.name}
                                                <br />
                                                Valor: {numberToReal(successObject.services[0].price)}
                                                <br />
                                                Local: {event.location}
                                            </p>

                                            <br />
                                            {
                                                event.title &&
                                                <AddToCalendar
                                                    buttonWrapperClass="text-secondary"
                                                    // dropdownClass="btn"
                                                    displayItemIcons={true}
                                                    optionsOpen={true}
                                                    buttonLabel="Adicionar lembrete"
                                                    event={event} />
                                            }

                                            <br />
                                            <h5>Tudo certo. Agora é só aguardar!</h5>
                                            <p>No dia, tente chegar com 10 minutos de antecedência.</p>
                                            <br />


                                            {
                                                data &&
                                                <section className="mt-10 mb-20">
                                                    <p>
                                                        <small>
                                                            Para cancelamentos ou alterações, entre em contato
                                                        </small>
                                                        <br />
                                                        <WhatsappButton
                                                            phonenumber={data.phonenumber}
                                                            size="lg"
                                                            text="Cancelar" />
                                                    </p>
                                                </section>
                                            }

                                            <button className="btn btn-link btn-sm text-dark" onClick={this.clearCookie}>Agendar novo horário</button>
                                        </header>
                                    </div>
                                </div>
                            }

                            {
                                error && 
                                <div className="agenda_public__view">
                                    <div className="agenda_public__left" style={{ width: "100%"}}>
                                        <header className="text-center">
                                            <br />
                                            <h2>Local não encontrado</h2>
                                        </header>
                                    </div>
                                </div>
                            }

                            {
                                !error && !success &&
                                <div className="agenda_public__view">
                                    <div className="agenda_public__left" style={{ width: (day) ? "30%" : "50%"}}>
                                        <header>
                                            <h5>Você está agendando em:</h5>
                                            <h1>{companyName}</h1>
                                            <p>via BarberTrack.</p>
                                        </header>
                                        {
                                            !loading &&
                                            <section>
                
                                                {
                                                    services &&
                                                    <div className="select__service">
                                                        {/* <Dropdown
                                                            label = "Escolha o serviço desejado"
                                                            defaultLabel = "Selecione"
                                                            data = {services}
                                                            handleChange = {e => this.changeService(e.target.value)}
                                                            /> */}

                                                        <Select
                                                            modalCloseButton={<ModalCloseButton />}
                                                            options={services}
                                                            caretIcon={<CaretIcon />}
                                                            noSelectionLabel="Selecione o serviço"
                                                            onChange={e => this.changeService(e.value)}
                                                            onSubmit={() => console.log('onSubmit')}
                                                            />

                                                    </div>
                                                }
                
                                                {
                                                    service &&
                                                    <div className="select__professional mt-20">
                                                        {/* <Dropdown
                                                            label = "Com quem você quer ser atendido?"
                                                            defaultLabel = "Selecione"
                                                            data = {professionals}
                                                            handleChange = {e => this.changeProfessional(e.target.value)}
                                                            /> */}

                                                        <Select
                                                            modalCloseButton={<ModalCloseButton />}
                                                            key={service}
                                                            options={professionals}
                                                            caretIcon={<CaretIcon />}
                                                            noSelectionLabel="Selecione o profissional"
                                                            onChange={e => this.changeProfessional(e.value)}
                                                            onSubmit={() => console.log('onSubmit')}
                                                            />
                                                    </div>
                                                }
                                            </section>
                                        }
            
                                        {
                                            loading && 
                                            <section>
                                                <p>Carregando...</p>
                                            </section>
                                        }


                                        {
                                            data &&
                                            <section className="mt-20">
                                                <p>
                                                    <small>
                                                        Para cancelamentos ou alterações, entre em contato.
                                                    </small>
                                                    <br />
                                                    <WhatsappButton
                                                        phonenumber={data.phonenumber}
                                                        size="lg"
                                                        text="Whatsapp" />
                                                </p>
                                            </section>
                                        }
                                    </div>
            
                                    {
                                        professional && service &&
                                        <div className="agenda_public__right" style={{ width: (day) ? "70%" : "50%"}}>
                                            {
                                                !confirm && 
                                                <div>
                                                    <header>
                                                        <h4>Escolha a Data & Horário</h4>
                                                    </header>
                                                    <section>
                                                        <div className="content__date">
                                                            <Calendar
                                                                className={['public_calendar']}
                                                                minDate = {new Date()}
                                                                onChange={ev => this.changeDate(ev)}
                                                                />
                                                        </div>
                                                        {
                                                            day && showHours &&
                                                            <div className="content__hour">
                                                                <div className="content__hour__mobile">
                                                                    <div
                                                                        className="back_arrow"
                                                                        onClick={() => this.setState({showHours:false})}
                                                                        >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.453" height="18.453"><path data-name="Caminho 1" d="M18.453 8.073H4.382l6.459-6.458L9.226 0 0 9.226l9.226 9.227 1.614-1.614-6.458-6.46h14.071z"/></svg>
                                                                    </div>
                                                                    <h4>{moment(day).format("dddd")}</h4>
                                                                    <p>{moment(day).format("D [de] MMMM [de] YYYY")}</p>
                                                                </div>
                                                                <p className="content__hour__desktop">{moment(day).format("dddd, D [de] MMMM")}</p>
                                                                <div className="content__hour__options">
                                                                    <h5>Selecione um horário</h5>
                                                                    {hours && hours.map((item, index) => {
                                                                        // console.log('item', item);
                                                                        let verify = verifyHourAvailable(offDay, day, item.label);
                                                                        if (verify && !isEarlier(day, item.id)) {
                                                                            return (
                                                                                <div key={index}>
                                                                                    <button
                                                                                        onClick={() => this.setState({hour: item.id})}
                                                                                        className={['btn', 'btn-lg', (hour === item.id) ? 'btn-info' : 'btn-outline-info'].join(' ')}>{item.label}</button>
                                                                                    {
                                                                                        hour === item.id &&
                                                                                        <button onClick={() => this.setState({confirm: true})} className="btn btn-lg btn-success">Confirmar</button>
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    })}
                                                                    {
                                                                        !hours  &&
                                                                        <div>
                                                                            <p>Sem horários disponíveis</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </section>
                                                </div>
                                            }
            
                                            {
                                                confirm && 
                                                <div className="agenda_public__right__user">
                                                    <header>
                                                        <h4>Idenfitique-se</h4>
                                                    </header>
                                                    <section>
                                                        <div>
                                                            <label>Nome</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                required
                                                                onChange={e => this.setState({name: e.target.value})} />
                                                        </div>
                                                        <div>
                                                            <label>Telefone celular</label>
                                                            <PhoneInput
                                                                onChange={e => this.setState({phone: e.target.value})}
                                                                placeholder="(00) 00000-0000"
                                                                />
                                                        </div>
                                                    </section>
                                                    <section>
                                                        <div>
                                                            <h4>Confirmar agendamento em {companyName}?</h4>
                                                            <button className="btn btn-lg btn-success" onClick={() => this.confirm()}>Agendar</button>
                                                            <br />
                                                            <button className="btn btn-link mt-20 text-dark" onClick={() => this.setState({confirm: false})}>{"< Trocar horário"}</button>
                                                        </div>
                                                    </section>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }

				</div>
			</div>
    	);
	}
}

export { AgendaPublic };