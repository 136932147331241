import React from "react";

const Dropdown = ({
  label,
  defaultLabel,
  name,
  data,
  value,
  handleChange,
  disabled
}) => {
  return (
    <div>
      <label>{label}</label>
      <select
        name={name}
        onChange={val => handleChange(val)}
        value={value}
        disabled={disabled}
        className="form-control"
      >
        <option value="">{defaultLabel}</option>
        {data.map(item => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export { Dropdown };
