import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { numberToReal } from '../../utils/money'
import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { servicePacksService } from '../../_services';

class PacoteServicosAjustes extends Component {
	state = {
        loaded: false,
        items: []
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		servicePacksService.myServicePacks().then(data => {
			this.setState({
				items: data,
				loaded: true
			})
			console.log('get items', data)
		})
    }

	render = () => {
        const { items } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Pacote de serviços - Configurações - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Pacote de serviços <small>CONFIGURAÇÕES</small></h1>
                </div>

                <div className="row">

                    <div className="col-md-12 mt-20">
                        <Link to="/pacote-servicos/configuracoes/criar" className="btn btn-info">Criar novo</Link>
                    </div>

                    <div className="col-md-12 mt-40">
                        {
                            items.length > 0 &&
                            <div className="row">
                                {
                                    items.map((item, index) => (
                                        <div className="col-md-4" key={index}>
                                            <Link to={`/pacote-servicos/configuracoes/pacote/${item.id}`} style={{textDecoration: 'none'}}>
                                                <div className="info-box">
                                                    <div className="info-box-content">
                                                        <div className="info-box-text">
                                                            <span className="info-box-number">{item.name}</span>
                                                            Clique para detalhes
                                                        </div>
                                                    </div>
                                                    <div className="info-box-footer">
                                                        <div className="row">
                                                            <div className="col-4 text-center"><small>Serviços</small><br />{item.max_services}</div>
                                                            <div className="col-4 text-center"><small>Preço</small><br />{ numberToReal(item.price) }</div>
                                                            <div className="col-4 text-center"><small>Expira em</small><br />{item.expire} dias</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                        }

                        {
                            items.length == 0 &&
                            <p>Nenhum pacote cadastrado</p>
                        }
                    </div>

                </div>
				
			</div>
    	);
	}
}

export { PacoteServicosAjustes };