import React, { Component } from "react";
import moment from 'moment'

import "./ModalScheduleDetails.scss";

// Components
import Avatar from "../Avatar";
import { Dropdown } from '../Dropdown/Dropdown';

// Helpers
import { getFirstWord } from "../../_helpers/clean-string";
import { HORARIOS } from "../../settings/enums";
import { Actions } from "../../flux";
import { schedulesService } from "../../_services/schedule.service";
import { numberToReal } from '../../utils/money';
import { maskPhone } from '../../utils/masks';
import ServicesTags from "../ServicesTags";
import { WhatsappButton } from "../WhatsappButton";

class ModalScheduleDetails extends Component {
    state = {
        loading: false,
        details: null,
        editTime: false,
        editHour: null
    }

    componentDidMount = () => {
        const { schedule } = this.props
        this.setState({details:schedule})
    }

    componentDidUpdate = nextProps => {
        const { schedule } = this.props
        if (schedule.schedule_id !== nextProps.schedule.schedule_id) {
            this.setState({details:schedule})
        }
    }

    close = () => {
        const { onCloseModal } = this.props
        if (onCloseModal) onCloseModal()
    }

    finish = () => {
        const { details } = this.state
        console.log('finish', details)
        this.close();
        Actions.showModalFinishOrder(details);
    }

    cancelSchedule = () => {
        const { details } = this.state
        schedulesService.cancelSchedule(details.schedule_id).then(res => {
            if (res.success === true) {
                Actions.showSuccess({title:res.message});
                this.close();
            }
        }).catch(err => {
            Actions.showError(err)
        })
    }

    updateHour = () => {
        const { editHour, details } = this.state
        if (!editHour) {
            Actions.showError('Selecione o horário');
        }
        
        schedulesService.updateScheduleHour(details.schedule_id, editHour).then(res => {
            if (res.success === true) {
                Actions.showSuccess({title:res.message});
                this.close();
            }
        }).catch(err => {
            Actions.showError(err)
        })
    }

    render = () => {
        const {
            loading,
            details,
            editTime,
            editHour
        } = this.state
        const servicesTotal = (details) ? details.services.sum('price') : 0;
        return (
            <div className="modal-schedule-details box">

                <div className="box-header">
                    <h3>Detalhes</h3>
                    <div className="box-actions">
                        <button className="box-actions-item box-actions-item--circle" data-widget="dismiss" onClick={() => this.close()}><i className="fas fa-times"></i></button>
                    </div>
                </div>

                {
                    !loading && details && 
                    <div className="box-body">

                        <div className="row">
                            <div className="col-12">
                                <h6 className="subtitle">CLIENTE</h6>
                            </div>
                        </div>
                        {
                            details.client_data &&
                            <div className="row mt-8">
                                <div className="col-2">
                                    <Avatar data = {details.client_data} />
                                </div>
                                <div className="col-4 pl-0 client-infos">
                                    <h3>
                                        {details.client_data.name} 
                                        { details.client_data.cellphone && <small>{maskPhone(details.client_data.cellphone)}</small> }
                                    </h3>
                                </div>
                                <div className="col-6 pl-0 client-infos">
                                    {
                                        details.client_data.cellphone && 
                                        <WhatsappButton 
                                            phonenumber={details.client_data.cellphone}
                                            size='md'
                                            marginTop={0} 
                                            text={`Falar com ${getFirstWord(details.client_data.name)}`} />
                                    }
                                </div>
                            </div>
                        }



                        <div className="row mt-25">
                            <div className="col-12">
                                <h6 className="subtitle">SERVIÇOS</h6>
                            </div>
                        </div>
                        <div className="row mt-8">
                            <div className="col-12">
                                <ServicesTags items = {details.services} />
                            </div>
                        </div>
                        <div className="row mt-13">
                            <div className="col-12">
                                <p>Total: <span className="badge badge-success">{numberToReal(servicesTotal)}</span></p>
                            </div>
                        </div>
                        


                        <div className="row mt-25">
                            <div className="col-12">
                                <h6 className="subtitle">PROFISSIONAL</h6>
                            </div>
                        </div>
                        {
                            details.professional_data &&
                            <div className="row mt-8">
                                <div className="col-2">
                                    <Avatar data = {details.professional_data} />
                                </div>
                                <div className="col-10 pl-0 client-infos">
                                    <h3>
                                        {details.professional_data.name} 
                                        <small>{details.professional_data.role} - {details.professional_data.cellphone}</small>
                                    </h3>
                                </div>
                            </div>
                        }
                        

                        {
                            !editTime && 
                            <div className="row mt-25">
                                <div className="col-12">
                                    <h6 className="subtitle">QUANDO</h6>
                                </div>
                            </div>
                        }

                        {
                            !editTime && 
                            <div className="row mt-8">
                                <div className="col-12">
                                    <h4>{moment(details.when).format('DD/MM/YYYY')} às {details.hour}</h4>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-outline-dark btn-sm" onClick={() => this.setState({editTime:true,editHour:details.hour})}>alterar horário</button>
                                </div>
                            </div>
                        }


                        {
                            editTime && 
                            <div className="row mt-25">
                                <div className="col-4">
                                    <Dropdown
                                        label = "Horário"
                                        defaultLabel = "Selecione"
                                        data = {HORARIOS}
                                        value = {(editHour) ? editHour : details.hour}
                                        handleChange = {e => this.setState({editHour:e.target.value})}
                                        />
                                </div>
                            </div>
                        }
                        {
                            editTime && 
                            <div className="row mt-10">
                                <div className="col-3">
                                    <button className="btn btn-outline-dark btn-sm" onClick={() => this.setState({editTime:false})}>cancelar</button>
                                </div>
                                <div className="col-4">
                                    <button className="btn btn-info btn-sm" onClick={() => this.updateHour()}>Confirmar alteração</button>
                                </div>
                            </div>
                        }

                    </div>
                }

                <div className="box-footer">
                    <div className="pull-left">
                        <button className="btn btn-link-danger mr-10" onClick={() => this.cancelSchedule()}>Cancelar agendamento</button>
                        {loading && <p className="text-lg"><i className="i-spinner loading"></i> Carregando...</p>}
                    </div>

                    <div className="pull-right">
                        <button className="btn btn-success pull-right" onClick={() => this.finish()}>Finalizar atendimento <i className="fas fa-check ml-5"></i></button>
                    </div>
                </div>
            </div>
        );
    }
}


export default ModalScheduleDetails;