import React, { Component } from "react";
import Schedule from '../Schedule'
import { daysInMonth } from '../../_helpers/format-date'
import { Actions } from "../../flux"
import './PeriodFilter.scss';

class PeriodFilter extends Component {
    state = {
		periodStart: null,
		periodEnd: null,
		filtered: false,
    }

	// constructor(props) {
	// 	super(props);
	// }

    componentDidMount = () => {
        const { initStart, initEnd } = this.props;
        if (initStart) this.changeStart(initStart);
        if (initEnd) this.changeEnd(initEnd);
    }

	changeStart = date => {
        // console.log('changeStart', date)
        const { onChangeStart } = this.props;
		this.setState({ periodStart: date, filtered: false });
		onChangeStart(date);
	}

	changeEnd = date => {
        // console.log('changeEnd', date)
        const { onChangeEnd } = this.props;
		this.setState({ periodEnd: date, filtered: false });
		onChangeEnd(date);
	}

    clearDate = () => {
        // console.log('clearDate')
        const { onChangeStart, onChangeEnd, onChangeDates } = this.props;
		this.setState({
            periodStart: null,
            periodEnd: null,
            filtered: false
        });

        onChangeDates(null, null);
		// onChangeStart(null);
		// onChangeEnd(null);
    }
	
	setDate = period => {
        const { onChangeStart, onChangeEnd, onChangeDates } = this.props;
		let start, end;

		switch (period) {
			case 'today':
				start = new Date();
				end = new Date();
				break;

			case 'yesterday':
				start = new Date();
				start.setDate( start.getDate() - 1 );
				end = new Date();
				end.setDate( end.getDate() - 1 );
				break;

			case 'week':
				start = new Date();
				start.setDate( start.getDate() - 7 );
				end = new Date();
				break;

			case 'month':
				start = new Date(`${new Date().getFullYear()}/${new Date().getMonth()+1}/01`);
				end = new Date(`${new Date().getFullYear()}/${new Date().getMonth()+1}/${daysInMonth(new Date().getMonth()+1, new Date().getFullYear())}`);
				break;


			case 'last-month':
				// Create a new Date object for the current date
				var currentDate = new Date();

				// Get the month of the current date (0-indexed, January is 0)
				var currentMonth = currentDate.getMonth();

				// Set the month of the date object to the previous month
				currentDate.setMonth(currentMonth - 1);

				start = new Date(`${currentDate.getFullYear()}/${currentDate.getMonth()+1}/01`);
				end = new Date(`${currentDate.getFullYear()}/${currentDate.getMonth()+1}/${daysInMonth(currentDate.getMonth(), currentDate.getFullYear())}`);
				break;
		
			default:
				start = new Date();
				end = new Date();
				break;
		}

		this.setState({
			periodStart: start,
			periodEnd: end,
			filtered: period
		})

        onChangeDates(start, end);

		// onChangeStart(start);
		// onChangeEnd(end);
	}

    render = () => {
        const { periodStart, periodEnd, filtered } = this.state
        return (
            <div className="row mb-20 period-combo">
                <div className="col-md-2">
                    <p className="mb-10">Filtrar período de:</p>
                    <div className="period-combo__item">
                        <Schedule
                            setDate = {periodStart}
                            onSelectDates = {date => this.changeStart(date)} />
                    </div>
                </div>
                <div className="col-md-2">
                    <p className="mb-10">Até:</p>
                    <div className="period-combo__item">
                        <Schedule
                            setDate = {periodEnd}
                            minDate = {periodStart}
                            onSelectDates = {date => this.changeEnd(date)} />
                    </div>
                </div>

                <div className="col-md-8">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="mb-5"><i className="fe fe-calendar mr-5"></i> Atalhos</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {
                                filtered == 'today' && 
                                <button className={`btn mr-5 btn-secondary`} onClick={() => this.clearDate()}>Hoje</button>
                            }
                            {
                                filtered != 'today' && 
                                <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('today')}>Hoje</button>
                            }

                            {
                                filtered == 'yesterday' && 
                                <button className={`btn mr-5 btn-secondary`} onClick={() => this.clearDate()}>Ontem</button>
                            }
                            {
                                filtered != 'yesterday' && 
                                <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('yesterday')}>Ontem</button>
                            }

                            {
                                filtered == 'week' && 
                                <button className={`btn mr-5 btn-secondary`} onClick={() => this.clearDate()}>Última semana</button>
                            }
                            {
                                filtered != 'week' && 
                                <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('week')}>Última semana</button>
                            }

                            {
                                filtered == 'month' && 
                                <button className={`btn mr-5 btn-secondary`} onClick={() => this.clearDate()}>Este mês</button>
                            }
                            {
                                filtered != 'month' && 
                                <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('month')}>Este mês</button>
                            }

                            {
                                filtered == 'last-month' && 
                                <button className={`btn mr-5 btn-secondary`} onClick={() => this.clearDate()}>Mês passado</button>
                            }
                            {
                                filtered != 'last-month' && 
                                <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('last-month')}>Mês passado</button>
                            }

                            {/* <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('today')}>Hoje</button>
                            <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('yesterday')}>Ontem</button>
                            <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('week')}>Última semana</button>
                            <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('month')}>Este mês</button>
                            <button className={`btn mr-5 btn-outline-secondary`} onClick={() => this.setDate('last-month')}>Mês passado</button> */}

                            {
                                (periodStart || periodEnd) && 
                                <button className={`btn btn-link-secondary mr-5 ml-5`} onClick={() => this.clearDate()}>Limpar</button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { PeriodFilter };