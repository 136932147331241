import React from "react";

import { cleanString } from "../../_helpers/clean-string";

const WhatsappButton = ({
  size = 'lg', // lg - md - sm - xs
  phonenumber,
  text = 'Whatsapp',
  marginTop = 10,
  inverted = false
}) => {
    return (
        <a
            className={`btn btn-${inverted ? 'secondary' : 'success'} btn-${size} mt-${marginTop} flex`}
            href={`https://api.whatsapp.com/send?phone=55${cleanString(phonenumber)}`}
            target="_blank">
            <i className="brands brands-whatsapp-w"></i> {text}
        </a>
    );
};

export { WhatsappButton };
