
const partnerRoles = ['Parceiro', 'Editor', 'Administrador'];
const professionalRoles = ['Profissional'];
const allRoles = [
    ...partnerRoles,
    ...professionalRoles,
];


export {
    allRoles,
    partnerRoles,
    professionalRoles
}