import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import { Dropdown } from "../../components";

import { HORARIOS, WEEKDAYS } from "../../settings/enums"
import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { unitsService } from '../../_services/units.service'

class CriarHorarioAtendimento extends Component {
	state = {
		unit: [],
		WEEKDAYS_OPTIONS: [
			{
				label: "De Segunda à Sexta-feira",
				value: "util-days"
			},
			...WEEKDAYS,
		],
        weekday: '',
        hour_start: '',
        hour_end: '',
		loading: false,
		success: false
	}

	componentDidMount = () => {
        const { match: { params } } = this.props;
		console.log('searchParams', window.location);

		// const searchParams = new URLSearchParams(window.location.search).get("weekday");
		// console.log('searchParams', searchParams);

		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

		console.log('params.weekday', params.weekday);

		this.setState({ 
			weekday: params.weekday.replace('ç', 'c'),
			loading: true
		})

		unitsService.getOne(params.id).then(data => {
			this.setState({
				unit: data,
				
                loading: false
			})
        })
	}

	clearFields = () => {
		this.setState({
            weekday: '',
            hour_start: '',
            hour_end: '',
		})
	}

    saveWeekday = (e) => {
        this.setState({
            weekday: e.target.value
        })
    }

    saveHourStart = (e) => {
        this.setState({
            hour_start: e.target.value
        })
    }

    saveHourEnd = (e) => {
        this.setState({
            hour_end: e.target.value
        })
    }

	saveInfo = () => {
        const {
            unit,
			weekday,
			hour_end,
			hour_start
		} = this.state
        
        var values = {
            weekday: weekday,
            hour_end: hour_end,
            hour_start: hour_start
        };

        // console.log('save values', values);
        // console.log('save unit', unit);

		unitsService.createOpeningHours(unit.public_string, values).then(res => {
			console.log('unitsService createOpeningHours', res)
			Actions.showSuccess({
				title: 'Horário cadastrado!',
			})
			Actions.userUpdated();
			this.setState({ success: true })
		}).catch(err => {
			Actions.showError(err.error)
		})
	}

	render = () => {

		const {
			loading,
			unit,
			success,
			weekday,
			WEEKDAYS_OPTIONS
		} = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Criar Unidade - { texts.title }</title>
	            </Helmet>

				{
					success && 
					<Redirect to={{
					pathname: `/unidades/${unit.public_string}`,
					state: { from: this.props.location }
					}}
				/>
				}

	    		<div className="units">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">Dashboard</Link> / <Link to="/unidades">Unidades</Link> /  Criar Horário atendimento
								</li>
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-8">
							<h2 className="content-title">Criar Horário de Atendimento</h2>

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading && unit &&
								<div className="box">
                                        <div className="box-body">
                                            <div className="form-group row">
                                                <div className="col-3">
                                                    <b>Horário</b>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="form-label col-3">Dia da semana <sup className="text-danger">*</sup></label>
                                                <div className="col-9">
                                                    <Dropdown
														value={weekday}
                                                        defaultLabel="Selecione o dia da semana"
                                                        name="weekdays"
                                                        data={WEEKDAYS_OPTIONS}
                                                        handleChange={this.saveWeekday}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="form-label col-3">Início expediente <sup className="text-danger">*</sup></label>
                                                <div className="col-9">
                                                    <Dropdown
                                                        defaultLabel="Selecione o horário"
                                                        name="hour_start"
                                                        data={HORARIOS}
                                                        handleChange={this.saveHourStart}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="form-label col-3">Fim expediente <sup className="text-danger">*</sup></label>
                                                <div className="col-9">
                                                    <Dropdown
                                                        defaultLabel="Selecione o horário"
                                                        name="hour_end"
                                                        data={HORARIOS}
                                                        handleChange={this.saveHourEnd}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="box-footer">
                                            <button type="submit" className="btn btn-secondary pull-right" onClick={this.saveInfo}>Criar</button>
                                        </div>

								</div>
							}

						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { CriarHorarioAtendimento };