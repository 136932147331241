import React, { Component } from 'react';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { addressService } from '../../_services/address.service';
import MaskedInput from '../../components/MaskedInput/default';
import viaCep from '../../_services/viacep'
import { cleanString } from '../../_helpers';

const formEndereço = yup.object().shape({
	name: yup
		.string()
        .required('Campo obrigatório'),
        
    zipcode: yup
        .string()
        .required('Campo obrigatório'),

    address: yup
        .string()
        .required('Campo obrigatório'),

    number: yup
        .string()
        .required('Campo obrigatório'),

    neighborhood: yup
        .string()
        .required('Campo obrigatório'),

    city: yup
        .string()
        .required('Campo obrigatório'),
        
    state: yup
        .string()
        .required('Campo obrigatório'),
    
    complement: yup
        .string()
});

class CriarEndereco extends Component {
	state = {
		unit: null,
		loading: false,
        success: false
    }
    
    removeItem = () => {
        const { edit, address } = this.props

        this.setState({loadingSubmit: true});
        
        console.log(`removeItem`, address);

        if (edit && address) {
            addressService.remove(address.id).then(res => {
                if (res.success) {
                    Actions.showSuccess({ title: 'Endereço removido!' })
                    this.props.onDismiss();
                } else {
                    Actions.showError(res.error)
                }
    
                this.setState({loadingSubmit: false});
            }).catch(err => {
                Actions.showError(err.error)
                this.props.onDismiss();
    
                this.setState({loadingSubmit: false});
            })
        }
    }

	saveInfo = values => {
        const { edit } = this.props
        this.setState({loadingSubmit: true});
        
        let data = values;
        data.zipcode = cleanString(data.zipcode);
        
        console.log(`saveInfo`, data);

        if (edit) {
            addressService.update(this.props.address.id, data).then(res => {
                if (res.success) {
                    Actions.showSuccess({ title: 'Endereço atualizado!' })
                    this.props.onDismiss();
                } else {
                    Actions.showError(res.error)
                }

                this.setState({loadingSubmit: false});
            }).catch(err => {
                Actions.showError(err.error)
                this.props.onDismiss();

                this.setState({loadingSubmit: false});
            })

        } else {
            addressService.create(data).then(res => {
                if (res.success) {
                    Actions.showSuccess({ title: 'Endereço cadastrado!' })
                    this.props.onDismiss();
                } else {
                    Actions.showError(res.error)
                }

                this.setState({loadingSubmit: false});
            }).catch(err => {
                Actions.showError(err.error)
                this.props.onDismiss();

                this.setState({loadingSubmit: false});
            })
        }
    }

    buscaCep = async (cepUnformatted) => {
		return viaCep.searchCep(cepUnformatted).then((res) => {
            console.log('searchCep', res)
                if (res.erro) {
                    Actions.showError("CEP inválido");
                    return;
                }
                return res.data;
            })
            .catch(err => {
                console.warn("Falha na requisição à api de CEP", err);
                return false;
            });
    }

	render = () => {
        const { loadingSubmit } = this.state
        const { address } = this.props

        let initialValues = (this.props.edit) ? {
            name: address.name,
            zipcode: address.zipcode,
            address: address.address,
            number: address.number,
            city: address.city,
            state: address.state,
            complement: address.complement,
            neighborhood: address.neighborhood
        } : {
            name: '',
            zipcode: '',
            address: '',
            number: '',
            city: '',
            state: '',
            complement: '',
            neighborhood: ''
        };

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="box">
                        <div className="box-header">
                            <b>Informações do Endereço</b>
                        </div>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={formEndereço}
                            onSubmit={values => this.saveInfo(values)}
                            >
                            {({ errors, touched, setFieldValue, isValid }) => (
                                <Form>
                                    <div className="box-body">

                                        <div className="form-group row">
                                            <label className="form-label col-3">Nome <sup className="text-danger">*</sup></label>
                                            <div className="col-9">
                                                <Field name="name" className="form-control" placeholder="Ex: Casa, trabalho, escritório, etc" />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="form-label col-3">CEP <sup className="text-danger">*</sup></label>
                                            <div className="col-3">
                                                <MaskedInput
                                                    mask = {[ /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-',/[0-9]/, /[0-9]/, /[0-9]/ ]}
                                                    onChange={async e => {
                                                        setFieldValue('zipcode', e.target.value)

                                                        if (e.target.value.length == 9) {
                                                            let busca = await this.buscaCep(e.target.value);

                                                            // Seta os valores
                                                            setFieldValue('address', busca.logradouro)
                                                            setFieldValue('neighborhood', busca.bairro)
                                                            setFieldValue('city', busca.localidade)
                                                            setFieldValue('state', busca.uf)
                                                        }
                                                    }}
                                                    guide = {false}
                                                    // value = {address.zipcode}
                                                    />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="zipcode" /></small>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="form-label col-3">Endereço <sup className="text-danger">*</sup></label>
                                            <div className="col-6">
                                                <Field name="address" className="form-control" />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="address" /></small>
                                            </div>
                                            <div className="col-3">
                                                <Field name="number" className="form-control" placeholder="Número" />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="number" /></small>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="form-label col-3">Bairro</label>
                                            <div className="col-9">
                                                <Field name="neighborhood" className="form-control" />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="neighborhood" /></small>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="form-label col-3">Cidade <sup className="text-danger">*</sup></label>
                                            <div className="col-9">
                                                <Field name="city" className="form-control" />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="city" /></small>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="form-label col-3">Estado <sup className="text-danger">*</sup></label>
                                            <div className="col-9">
                                                <Field name="state" className="form-control" />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="state" /></small>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="form-label col-3">Complemento</label>
                                            <div className="col-9">
                                                <Field name="complement" className="form-control" />
                                                <small className="text-danger"><ErrorMessage className="text-danger" name="complement" /></small>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="box-footer">
                                        {
                                            (this.props.edit && !this.state.remove) &&
                                            <button type="button" onClick={() => this.setState({remove: true})} className="btn text-danger pull-left">Excluir</button>
                                        }
                                        {
                                            (this.props.edit && this.state.remove) &&
                                            <button type="button" onClick={() => this.removeItem()} className="btn btn-danger pull-left">Confirmar exclusão</button>
                                        }
                                        
                                        {
                                            loadingSubmit &&
                                            <p className="mb-20 text-lg pull-left"><i className="i-spinner loading"></i> Carregando...</p>
                                        }
                                        {
                                            !loadingSubmit &&
                                            <button type="submit" className="btn btn-success pull-right">{(this.props.edit ? `Salvar` : `Cadastrar`)}</button>
                                        }
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>

                </div>
            </div>
    	);
	}
}

export { CriarEndereco };