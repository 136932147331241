import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { texts } from '../../settings/'

class Gerencial extends Component {
	state = {
		userPlan: null,
		user: null,
		loaded: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();
	}

	render = () => {
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Gerencial - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Relatório de dados</h1>
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / Gerencial</li>
							</ol>
						</nav>
					</div>
				</div>

                <div className="row">
                    <div className="col-md-4">
                        <Link to="/gerencial/agendamentos" style={{textDecoration: 'none'}}>
                            <div className="info-box">
                                <div className="info-box-content">
                                    <div className="info-box-text">
                                        <span className="info-box-number">Agendamentos</span>
                                        Clique para detalhes
                                    </div>
                                    <div className="info-box-icon text-primary">
                                        <i className="fe fe-calendar"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-4">
                        <Link to="/gerencial/faturamento" style={{textDecoration: 'none'}}>
                            <div className="info-box">
                                <div className="info-box-content">
                                    <div className="info-box-text">
                                        <span className="info-box-number">Faturamento</span>
                                        Clique para detalhes
                                    </div>
                                    <div className="info-box-icon text-success">
                                        <i className="fe fe-dollar-sign"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    {/* <div className="col-md-4">
                        <Link to="/gerencial/atendimentos" style={{textDecoration: 'none'}}>
                            <div className="info-box">
                                <div className="info-box-content">
                                    <div className="info-box-text">
                                        <span className="info-box-number">Atendimentos finalizados</span>
                                        Clique para detalhes
                                    </div>
                                    <div className="info-box-icon text-primary">
                                        <i className="fe fe-scissors"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div> */}

                    <div className="col-md-4">
                        <Link to="/gerencial/comissoes" style={{textDecoration: 'none'}}>
                            <div className="info-box">
                                <div className="info-box-content">
                                    <div className="info-box-text">
                                        <span className="info-box-number">Comissões</span>
                                        Clique para detalhes
                                    </div>
                                    <div className="info-box-icon text-info">
                                        <i className="fe fe-users"></i>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    
                </div>
				
			</div>
    	);
	}
}

export { Gerencial };