import React from "react";
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { numberToReal } from '../../utils/money';

const AgendaItem = ({
    style,
    handleClick,
    className,
    item,
    showProfessional
}) => {
    return (
        <div className={className} onClick={()=> handleClick(item)} style={style}>
            <ReactTooltip />
            {
                item.finished == true && 
                <div className="custom_agenda_item__icon custom_agenda_item__icon--finished"><i className="fa fa-check" data-tip="Finalizado"></i></div>
            }
            {
                item.finished == false && 
                <div className="custom_agenda_item__icon custom_agenda_item__icon--scheduled"><i className="fa fa-clock" data-tip="Agendado"></i></div>
            }
            <div className="custom_agenda_item__texts">
                <div className="custom_agenda_item__name">
                    {(showProfessional && item.professional) ? `${item.professional.name}: ` : ''}
                    {item.name} 
                </div>
                <div className="custom_agenda_item__values">
                    <span>{moment(item.startDateTime).format("HH")}h{moment(item.startDateTime).format("mm")}</span>
                    {
                        item.finished == true && 
                        <small>{numberToReal(item.order.total)}</small>
                    }
                </div>
            </div>
        </div>
    );
};

export default AgendaItem;
