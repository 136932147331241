import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { servicePacksService } from '../../_services';

const form = yup.object().shape({
	name: yup
		.string()
        .required('Campo obrigatório'),

    max_services: yup
        .string()
        .required('Campo obrigatório'),

    price: yup
        .string()
        .required('Campo obrigatório'),
        
    expire: yup
        .string()
        .required('Campo obrigatório'),

    description: yup
        .string()
});

class CriarPacoteServicos extends Component {
	state = {
        loaded: false,
        success: false,
        items: [],
        loadingSubmit: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		// this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		servicePacksService.myServicePacks().then(data => {
			this.setState({
				items: data,
				loaded: true
			})
			console.log('get items', data)
		})
    }

	saveInfo = values => {
        this.setState({loadingSubmit: true});
        let data = values;
        console.log(`saveInfo`, data);

        servicePacksService.create(data).then(res => {
            if (res.success) {
                Actions.showSuccess({ title: 'Pacote criado!' })
                this.setState({ success: true });

            } else {
                Actions.showError(res.error)
            }

            this.setState({ loadingSubmit: false });
        }).catch(err => {
            Actions.showError(err.error)

            this.setState({loadingSubmit: false});
        })
    }

	render = () => {
        const { loadingSubmit, success } = this.state
        return (
			<div className="views container-fluid">

				{
					success && 
					<Redirect to={{
                        pathname: `/pacote-servicos/configuracoes`,
                        state: { from: this.props.location }
                    }} />
				}
			
				<Helmet>
	                <title>Pacote de serviços - Criar novo - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Pacote de serviços  <small>CRIAR NOVO</small></h1>
                </div>

                <div className="row">

                    <div className="col-md-12">
                        <Link to="/pacote-servicos/configuracoes" className="btn btn-light"><i className="fe fe-arrow-left"></i> Voltar</Link>
                    </div>

                    <div className="col-md-6 mt-40">
                        <div className="box">

                            <Formik
                                initialValues={{
                                    name: '',
                                    max_services: '',
                                    price: '',
                                    expire: '',
                                    description: ''
                                }}
                                validationSchema={form}
                                onSubmit={values => this.saveInfo(values)}
                                >
                                {({ errors, touched, setFieldValue, isValid }) => (
                                    <Form>
                                        <div className="box-body">

                                            <div className="form-group row">
                                                <label className="form-label col-3">Nome <sup className="text-danger">*</sup></label>
                                                <div className="col-9">
                                                    <Field name="name" className="form-control" placeholder="Ex: Compo multi" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="form-label col-3">Max serviços <sup className="text-danger">*</sup></label>
                                                <div className="col-9">
                                                    <Field name="max_services" className="form-control" placeholder="Ex: 3" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="max_services" /></small>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="form-label col-3">Preço <sup className="text-danger">*</sup></label>
                                                <div className="col-9">
                                                    <Field name="price" className="form-control" placeholder="Ex: 3" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="price" /></small>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="form-label col-3">Expira em: <sup className="text-danger">*</sup></label>
                                                <div className="col-9">
                                                    <Field name="expire" className="form-control" placeholder="Ex: 30 days" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="expire" /></small>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="form-label col-3">Descrição </label>
                                                <div className="col-9">
                                                    <Field name="description" className="form-control" placeholder="" />
                                                    <small className="text-danger"><ErrorMessage className="text-danger" name="description" /></small>
                                                </div>
                                            </div>

                                            {/* <div className="form-group row">
                                                <label className="form-label col-3">Serviços </label>
                                                <div className="col-12">
                                                    <p>Combo de serviços vai aqui</p>
                                                </div>
                                            </div> */}

                                        </div>

                                        <div className="box-footer">
                                            {
                                                loadingSubmit &&
                                                <p className="mb-20 text-lg pull-left"><i className="i-spinner loading"></i> Carregando...</p>
                                            }
                                            {
                                                !loadingSubmit &&
                                                <button type="submit" className="btn btn-success pull-right">Criar</button>
                                            }
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            
                        </div>
                    </div>

                </div>
				
			</div>
    	);
	}
}

export { CriarPacoteServicos };