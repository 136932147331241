import React, { Component } from "react"
import { professionalsService } from "../../_services"
import { Actions } from "../../flux"
import { numberToReal } from "../../utils/money";

class ProfessionalServicesList extends Component {
    state = {
        professional: null,
        services: [],
        addItem: false,
        percent: 0
    }
    
    componentDidMount = () => {
        const { items, professional } = this.props

        this.setState({
            services: items,
            professional: professional,
        });
    }

    changeStatus = (service_id, value) => {
        const { services, professional } = this.state
        
        services.map(s => {
            if (s.service === service_id) {
                s.active = (value == true) ? 1 : 0;
            }
        })

        professionalsService.addService(value, professional.id, service_id)
            .then(res => this.setState({ services: services }))
            .catch(err => {
                Actions.showError({title: err.message});
            })

        this.setState({ services: services })
    }

	renderList = items => {
		return (
			<div className="items">
                {
                    items.length > 0 &&
				    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Nome</th>
                                    <th>Preço</th>
                                    <th>Status</th>
                                </tr>
                                {items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.service_data.name}</td>
                                        <td>{numberToReal(item.service_data.price)}</td>
                                        <td width={150}>
                                            <input
                                                className="mr-10"
                                                type="checkbox"
                                                defaultChecked={item.active}
                                                onChange={e => this.changeStatus(item.service, e.target.checked)}
                                                />
                                            {(item.active) ? 'Ativo'  : 'Inativo' }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum item</p>
                }
			</div>
		);
    }

    render = () => {
        const { services } = this.state;
        return (
            <div className="quotas-list">
                {this.renderList(services)}
            </div>
        );
    }
}


export default ProfessionalServicesList;