import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'

import { settings } from "../../settings/";
import { imageApi } from '../../_helpers/imageApi';
import { getInitials } from "../../_helpers/clean-string";
import Avatar from "../Avatar";

class ProfessionalAvatars extends Component {
    state = {
        professionals: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ professionals: items })
    }
    
    componentDidUpdate = () => {
        const { items } = this.props
        const { professionals } = this.state
        if (items.length !== professionals.length) {
            this.setState({ professionals: items })
        }
    }

    selectProfessional = professional => {
        const { onSelectProfessional } = this.props
        if (onSelectProfessional) onSelectProfessional(professional)
    }

	renderList = items => {
		return (
			<div>
                {items.length > 0 && items.map((item, index) => (
                    <Avatar
                        key = {index}
                        data = {item}
                        handleClick={data => this.selectProfessional(data)}
                        />
				))}
                
                {
                    items.length == 0 &&
                    <p>Nenhum profissional</p>
                }
			</div>
		);
    }

    render = () => {
        const { professionals } = this.state;
        return (
            <div className="professionals-list">
                {this.renderList(professionals)}
            </div>
        );
    }
}


export default ProfessionalAvatars;