import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { clientsService } from '../../_services/clients.service'
import { imageApi } from '../../_helpers/imageApi'
import { getFirstWord, getInitials } from '../../_helpers/clean-string'
import PhoneInput from '../../components/MaskedInput/phone'
import CpfInput from '../../components/MaskedInput/cpf'

const form = yup.object().shape({
	name: yup
		.string()
        .required('Campo obrigatório'),
        
    document: yup
        .string(),

	email: yup
		.string()
		.email('E-mail inválido'),

	cellphone: yup
		.string(),

    instagram: yup
        .string(),

    facebook: yup
        .string(),

    twitter: yup
        .string(),
});

class VerCliente extends Component {
    constructor(props) {
        super(props);

        this.removeClient = this.removeClient.bind(this);
    }

	state = {
		client: null,
		loading: false,
		success: false,
        edit: false,
        loadingSubmit: false,
        remove: false
	}

	componentDidMount = () => {
		const { match: { params } } = this.props;
		
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

        this.setState({ loading: true })
        
		clientsService.getOne(params.id).then(data => {
			console.log('clientsService', data)
			this.setState({
				client: data,
				loading: false
			})
		})
	}

	saveInfo = values => {
		const { client } = this.state
        delete values.client;

		this.setState({loadingSubmit: true});

		clientsService.update(client.id, values).then(res => {
			if (res.success) {
				Actions.showSuccess({ title: 'Cliente atualizado!' })
				this.setState({
                    client: res.data,
                    edit: false
                })
			} else {
				Actions.showError(res.error)
			}
			this.setState({loadingSubmit: false});
		}).catch(err => {
			Actions.showError(err.error)
			this.setState({loadingSubmit: false});
		})
    }
    
    removeClient = () => {
        const { client } = this.state
        this.setState({loadingSubmit: true});
        
        const data = {
            status: 0
        }

		clientsService.update(client.id, data).then(res => {
			if (res.success) {
				Actions.showSuccess({ title: 'Cliente excluído!' })
				this.setState({ success: true })
			} else {
				Actions.showError(res.error)
			}
			this.setState({loadingSubmit: false});
		}).catch(err => {
			Actions.showError(err.error)
			this.setState({loadingSubmit: false});
		})
    }

	render = () => {
		const { loading, client, edit, loadingSubmit, success, remove } = this.state

        return (
			<div className="views">
                {
                    success && 
                    <Redirect to={{
                        pathname: `/clientes/`,
                        state: { from: this.props.location }
                        }}
                    />
                }
			
				<Helmet>
	                <title>Clientes - { texts.title }</title>
	            </Helmet>

                {
                    loading &&
                    <div className="row" style={{ height: 200, paddingTop: 80 }}>
                        <div className="col-md-12 text-center">
                            <p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
                        </div>
                    </div>
                }

                {
                    !loading && client &&
                    <div>
                        <div className="content-header">
                            <h1>{getFirstWord(client.name)}<br /><small>Cliente</small></h1>
                        </div>
                
                        {/* <div className="card card-bg card-bg-solid transition-dark-bottom">
                            <div className="card-image">
                                <img src="images/stock-1.jpg" alt=""/>
                            </div>
                            <div className="card-body card-body-jumbotron">
                                <div className="d-flex flex-column">
                                    <h3 className="text-white mb-0 display-4">{client.name}</h3>
                                    <small className="text-white-50">Administrator and maintainer</small>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="d-flex justify-content-center text-white mb-15">
                                        <a href="#1" className="icon"><i className="fab fa-facebook"></i></a>
                                        <a href="#2" className="icon"><i className="fab fa-twitter"></i></a>
                                        <a href="#3" className="icon"><i className="fab fa-instagram"></i></a>
                                        <a href="#4" className="icon"><i className="fab fa-linkedin"></i></a>
                                        <a href="#6" className="icon"><i className="fab fa-vk"></i></a>
                                    </div>
                                    <div className="btn-toolbar">
                                        <a href="#" className="btn btn-primary">Message</a>
                                        <a href="#" className="btn btn-success">Follow</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">
                            <div className="col-lg-5">
                                <div className="profile">
                                    <div className="profile-bg profile-bg-blur">
                                        <img src="images/avatar-1.jpg" alt=""/>
                                    </div>
                                    <div className="user-panel user-panel-column">
                                        <div className="user-panel-image">
                                            {/* {
                                                client.photo !== '' && client.photo !== null &&
                                                <div className="avatar avatar-lg avatar-bordered">
                                                    <img src={imageApi(client.photo)} />
                                                </div>
                                            }
                                            {
                                                client.photo == '' || client.photo == null &&
                                                <div className="avatar avatar-secondary avatar-lg avatar-bordered">{getInitials(client.name)}</div>
                                            } */}
                                            <div className="avatar avatar-secondary avatar-lg avatar-bordered">{getInitials(client.name)}</div>
                                        </div>
                                        <div className="user-panel-info mb-10">
                                            <p className="text-black">{getFirstWord(client.name)}</p>
                                            <small className="text-secondary">Cliente</small>
                                        </div>
                                        <hr />

                                        {
                                            client.status === 1 &&
                                            <div className="user-panel-info mb-10">
                                                {
                                                    !remove && 
                                                    <div className="btn btn-danger btn-sm" onClick={() => this.setState({remove: true})}><i className="fa fa-trash"></i> Excluir</div>
                                                }
                                                {
                                                    remove &&
                                                    <span>
                                                        <div className="btn btn-outline-danger btn-sm mr-20" onClick={this.removeClient}><i className="fa fa-trash"></i> Confirmar</div>
                                                        <div className="btn btn-secondary btn-sm" onClick={() => this.setState({remove: false})}>Cancelar</div>
                                                    </span>
                                                }
                                            </div>
                                        }

                                    </div>
                                    {/* <hr/>
                                    <div className="row">
                                        <div className="col-4">
                                            12
                                            <small className="text-secondary d-block">Atendimentos</small>
                                        </div>
                                        <div className="col-4">
                                            28
                                            <small className="text-secondary d-block">Serviços pagos</small>
                                        </div>
                                        <div className="col-4">
                                            <b>R$ 1120</b>
                                            <small className="text-secondary d-block">Gasto total</small>
                                        </div>
                                    </div> */}
                                </div>

                                {/* <div className="box">
                                    <div className="box-header">
                                        <h3 >Followers</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="user-panel">
                                            <div className="user-panel-image">
                                                <a href="#" className="avatar avatar-sm">
                                                    <i className="fas fa-circle text-success"></i>
                                                    <img src="images/avatar-1.jpg" alt=""/>
                                                </a>
                                            </div>
                                            <div className="user-panel-info">
                                                <a href="#">{client.name}</a>
                                                <small className="text-secondary">142 followers</small>
                                            </div>
                                            <div className="user-panel-actions">
                                                <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                            </div>
                                        </div>
                                        <div className="user-panel">
                                            <div className="user-panel-image">
                                                <a href="#" className="avatar avatar-sm">
                                                    <i className="fas fa-circle text-success"></i>
                                                    <img src="images/avatar-1.jpg" alt=""/>
                                                </a>
                                            </div>
                                            <div className="user-panel-info">
                                                <a href="#">{client.name}</a>
                                                <small className="text-secondary">142 followers</small>
                                            </div>
                                            <div className="user-panel-actions">
                                                <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                            </div>
                                        </div>
                                        <div className="user-panel">
                                            <div className="user-panel-image">
                                                <a href="#" className="avatar avatar-sm">
                                                    <img src="images/avatar-1.jpg" alt=""/>
                                                </a>
                                            </div>
                                            <div className="user-panel-info">
                                                <a href="#">{client.name}</a>
                                                <small className="text-secondary">142 followers</small>
                                            </div>
                                            <div className="user-panel-actions">
                                                <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="box-footer">
                                        <a href="#" className="text-secondary text-sm d-block">Show more followers <i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </div> */}
{/*                                 
                                <div className="box">
                                    <div className="box-header">
                                        <h3 >Videos</h3>
                                        <div className="badge">71</div>
                                    </div>
                                    <div className="box-body">
                                        <div className="video">
                                            <div className="video-content">
                                            </div>
                                            <div className="video-title">
                                                <a href="#">Some inresting video 1</a>
                                                <small className="text-secondary text-sm">01:37</small>
                                            </div>
                                            <small className="text-secondary text-sm">
                                                <i className="far fa-heart"></i> 312 &nbsp;
                                                <i className="far fa-comment"></i> 14
                                            </small>
                                        </div>
                                        <div className="video">
                                            <div className="video-content">
                                            </div>
                                            <div className="video-title">
                                                <a href="#">Some inresting video 2</a> 
                                                <small className="text-secondary">02:12</small>
                                            </div>
                                            <p className="text-secondary">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum, fugiat aut? Quas provident numquam, accusantium.</p>
                                            <small className="text-secondary text-sm">
                                                <i className="far fa-heart"></i> 1743 &nbsp;
                                                <i className="far fa-comment"></i> 89
                                            </small>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <a href="#" className="text-secondary text-sm d-block">Show more videos <i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </div> */}

                            </div>
                            <div className="col-lg-6">
                                <div className="box">
                                    <div className="box-header">
                                        <h3>Perfil</h3>
                                        { !edit && <button className="btn btn-secondary" onClick={() => this.setState({edit:true})}>Editar</button> }
                                    </div>

                                    {
                                        edit &&
                                        <Formik
                                            initialValues={{
                                                name: client.name ? client.name : '',
                                                document: client.document ? client.document : '',
                                                email: client.email ? client.email : '',
                                                cellphone: client.cellphone ? client.cellphone : '',
                                                instagram: client.instagram ? client.instagram : '',
                                                facebook: client.facebook ? client.facebook : '',
                                                twitter: client.twitter ? client.twitter : ''
                                            }}
                                            validationSchema={form}
                                            onSubmit={values => this.saveInfo(values)}
                                            >
                                            {({ errors, touched, setFieldValue, isValid }) => (
                                                <Form>
                                                    <div className="box-body">

                                                        <div className="form-group row">
                                                            <label className="form-label col-3">Nome <sup className="text-danger">*</sup></label>
                                                            <div className="col-9">
                                                                <Field name="name" className="form-control" />
                                                                <small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="form-label col-3">CPF</label>
                                                            <div className="col-9">
                                                                <CpfInput
                                                                    value={client.document}
                                                                    name="document"
                                                                    onChange={e => setFieldValue('document', e.target.value)}
                                                                />
                                                                <small className="text-danger"><ErrorMessage className="text-danger" name="document" /></small>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">	
                                                            <label className="form-label col-3">E-mail</label>
                                                            <div className="col-9">
                                                                <Field name="email" className="form-control" />
                                                                <small className="text-danger"><ErrorMessage className="text-danger" name="email" /></small>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="form-label col-3">Celular</label>
                                                            <div className="col-9">
                                                                <PhoneInput
                                                                    value={client.cellphone}
                                                                    name="cellphone"
                                                                    className="form-control"
                                                                    onChange={e => setFieldValue('cellphone', e.target.value)}
                                                                />
                                                                <small className="text-danger"><ErrorMessage className="text-danger" name="cellphone" /></small>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="form-label col-3">Instagram</label>
                                                            <div className="col-9">
                                                                <Field name="instagram" className="form-control" />
                                                                <small className="text-danger"><ErrorMessage className="text-danger" name="instagram" /></small>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="form-label col-3">Facebook</label>
                                                            <div className="col-9">
                                                                <Field name="facebook" className="form-control" />
                                                                <small className="text-danger"><ErrorMessage className="text-danger" name="facebook" /></small>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="form-label col-3">Twitter</label>
                                                            <div className="col-9">
                                                                <Field name="twitter" className="form-control" />
                                                                <small className="text-danger"><ErrorMessage className="text-danger" name="twitter" /></small>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="box-footer">
                                                        {
                                                            loadingSubmit &&
                                                            <p className="mb-20 text-lg pull-right"><i className="i-spinner loading"></i> Carregando...</p>
                                                        }
                                                        {
                                                            !loadingSubmit &&
                                                            <button type="button" className="btn btn-outline-secondary" onClick={()=>this.setState({edit:false})}>Cancelar</button>
                                                        }
                                                        {
                                                            !loadingSubmit &&
                                                            <button type="submit" className="btn btn-success pull-right">Salvar</button>
                                                        }
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    }

                                    {
                                        !edit &&
                                        <div className="box-body">

                                            <div className="mb-10">
                                                <div className="text-partition">
                                                    <b>Geral</b>
                                                </div>
                                                <dl className="row">
                                                    <dt className="col-md-3">Nome completo:</dt>
                                                    <dd className="col-md-9">{client.name}</dd>

                                                    <dt className="col-md-3">E-mail:</dt>
                                                    <dd className="col-md-9">{client.email}</dd>

                                                    <dt className="col-md-3">Endereço:</dt>
                                                    <dd className="col-md-9 d-flex">{(client.address) ? client.address : "--"}</dd>
                                                    
                                                    <dt className="col-md-3">Documento:</dt>
                                                    <dd className="col-md-9">{client.document}</dd>
                                                </dl>
                                            </div>
                                            <div className="mb-20">
                                                <div className="text-partition">
                                                    <b>Contatos</b>
                                                </div>
                                                <dl className="row">
                                                    <dt className="col-md-3">Telefone:</dt>
                                                    <dd className="col-md-9">{(client.cellphone) ? client.cellphone : "--"}</dd>

                                                    {
                                                        client.facebook !== null &&
                                                        client.facebook !== "" &&
                                                        <dt className="col-md-3">Facebook:</dt>
                                                    }
                                                    {
                                                        client.facebook !== null &&
                                                        client.facebook !== "" &&
                                                        <dd className="col-md-9">
                                                            <a href={`https://facebook.com/${client.facebook}`} target="_blank">https://facebook.com/{client.facebook}</a>
                                                        </dd>
                                                    }
                                                    
                                                    {
                                                        client.twitter !== null &&
                                                        client.twitter !== "" &&
                                                        <dt className="col-md-3">Twitter:</dt>
                                                    }
                                                    {
                                                        client.twitter !== null &&
                                                        client.twitter !== "" &&
                                                        <dd className="col-md-9">
                                                            <a href={`https://twitter.com/${client.twitter}`} target="_blank">https://twitter.com/{client.twitter}</a>
                                                        </dd>
                                                    }
                                                </dl>
                                            </div>
                                            <div className="mb-10">
                                                <div className="text-partition">
                                                    <b>Histórico</b>
                                                </div>
                                                <dl className="row">
                                                    <dt className="col-md-3">Cadastrado em:</dt>
                                                    <dd className="col-md-9">{moment(client.created_at).format("DD/MM/YYYY - HH:mm")}</dd>

                                                    <dt className="col-md-3">Editado em:</dt>
                                                    <dd className="col-md-9">{moment(client.updated_at).format("DD/MM/YYYY - HH:mm")}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-4">
                                        <div className="info-box">
                                            <div className="info-box-content">
                                                <div className="info-box-text">
                                                    <span className="info-box-number">543</span>
                                                    Atendimentos
                                                </div>
                                                <div className="info-box-icon text-primary">
                                                    <i className="i-paper"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="info-box">
                                            <div className="info-box-content">
                                                <div className="info-box-text">
                                                    <span className="info-box-number">94</span>
                                                    Comentários
                                                </div>
                                                <div className="info-box-icon text-warning">
                                                    <i className="i-chat-bubble"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="info-box">
                                            <div className="info-box-content">
                                                <div className="info-box-text">
                                                    <span className="info-box-number">894</span>
                                                    Avaliações
                                                </div>
                                                <div className="info-box-icon text-danger">
                                                    <i className="i-like"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
{/*                                 
                                <h2 className="content-title">Posts</h2>
                                <button data-toggle="#postCreate" className="btn btn-primary btn-block mb-20">Add new post (click me!)</button>
                                <div id="postCreate" className="box hidden">
                                    <div className="box-header">
                                        <h3><i className="fas fa-pencil-alt text-primary"></i> Create post</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="form-group">
                                            <label htmlFor="createPost">Enter post text:</label>
                                            <textarea id="createPost" className="form-control"></textarea>
                                            <small className="text-secondary text-sm">1500 max. characters</small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="fileInput">Files:</label>
                                            <input id="fileInput" type="file" className="form-control" />
                                            <small className="text-secondary text-sm">15MB max. Formats: PDF, DOC, XLS, TXT, PNG, JPG.</small>
                                        </div>
                                        <div className="form-group">
                                            <label>Options:</label>
                                            <div className="d-flex">
                                                <label className="form-checkbox-custom">
                                                    <input type="checkbox" />
                                                    <span className="form-label">Don't notify</span>
                                                </label>
                                                <label className="form-checkbox-custom">
                                                    <input type="checkbox" />
                                                    <span className="form-label">Fixed</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-footer d-flex">
                                        <button className="btn btn-success">Submit</button>
                                        <a href="#" className="btn btn-link-secondary pull-right">Cancel</a>
                                    </div>
                                </div>

                                <div className="box">
                                    <div className="box-body">
                                        <div className="user-panel">
                                            <div className="user-panel-image">
                                                <a href="#" className="avatar avatar-sm">
                                                    <i className="fas fa-circle text-success"></i>
                                                    <img src="images/avatar-1.jpg" alt=""/>
                                                </a>
                                            </div>
                                            <div className="user-panel-info">
                                                <a href="#">{client.name}</a>
                                                <small className="text-secondary">3 hours ago</small>
                                            </div>
                                            <div className="user-panel-actions">
                                                <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                            </div>
                                        </div>
                                        <hr/>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur officia laboriosam, blanditiis obcaecati hic veniam itaque modi! Repudiandae consectetur eligendi quasi cupiditate? Necessitatibus aspernatur, sapiente illum, ipsa sequi consectetur placeat?</p>
                                        <hr/>
                                        <div className="d-flex">
                                            <button className="btn btn-link-secondary"><i className="far fa-heart"></i> 14</button>
                                            <button className="btn btn-link-secondary"><i className="far fa-comment"></i> 5</button>
                                            <span className="btn btn-link-secondary pull-right"><i className="fas fa-eye"></i> 378</span>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="comments bg-white">
                                            <div className="user-panel align-items-start">
                                                <div className="user-panel-image">
                                                    <a href="#" className="avatar avatar-sm">
                                                        <img src="images/avatar-2.jpg" alt=""/>
                                                    </a>
                                                </div>
                                                <div className="user-panel-info">
                                                    <a href="#">Sam Lee</a>
                                                    <p className="text-secondary">Good thinking!</p>
                                                </div>
                                                <div className="user-panel-actions text-black-25">
                                                    <small className="text-secondary">1 hour ago</small>
                                                    <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                                </div>
                                            </div>
                                            <div className="user-panel align-items-start">
                                                <div className="user-panel-image">
                                                    <a href="#" className="avatar avatar-sm">
                                                        <img src="images/avatar-3.jpg" alt=""/>
                                                    </a>
                                                </div>
                                                <div className="user-panel-info">
                                                    <a href="#">Alex White</a>
                                                    <p className="text-secondary">Very intresting...</p>
                                                </div>
                                                <div className="user-panel-actions text-black-25">
                                                    <small className="text-secondary">47 minutes ago</small>
                                                    <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                                </div>
                                            </div>
                                            <div className="user-panel align-items-start">
                                                <div className="user-panel-image">
                                                    <a href="#" className="avatar avatar-sm">
                                                        <img src="images/avatar-4.jpg" alt=""/>
                                                    </a>
                                                </div>
                                                <div className="user-panel-info">
                                                    <a href="#">Jessica Evans</a>
                                                    <p className="text-secondary">It's seems like a great idea!</p>
                                                </div>
                                                <div className="user-panel-actions text-black-25">
                                                    <small className="text-secondary">1 minute ago</small>
                                                    <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary btn-block mt-15">Load more comments</button>
                                        </div>
                                    </div>
                                </div> 
                                <div className="box">
                                    <div className="box-body">
                                        <div className="user-panel">
                                            <div className="user-panel-image">
                                                <a href="#" className="avatar avatar-sm">
                                                    <i className="fas fa-circle text-success"></i>
                                                    <img src="images/avatar-3.jpg" alt=""/>
                                                </a>
                                            </div>
                                            <div className="user-panel-info">
                                                <a href="#">Alex White</a>
                                                <small className="text-secondary">2 days ago</small>
                                            </div>
                                            <div className="user-panel-actions">
                                                <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                            </div>
                                        </div>
                                        <hr/>
                                        <p>Hey, look at my photos! Great, isn't it?</p>
                                        <div className="d-flex flex-wrap">
                                            <div className="img-square img-rounded">
                                                <img src="images/stock-1.jpg" alt=""/>
                                            </div>
                                            <div className="img-square img-rounded">
                                                <img src="images/stock-2.jpg" alt=""/>
                                            </div>
                                            <div className="img-square img-rounded">
                                                <img src="images/stock-3.jpg" alt=""/>
                                            </div>
                                            <div className="img-square img-rounded">
                                                <img src="images/stock-4.jpg" alt=""/>
                                            </div>
                                            <div className="img-square img-rounded">
                                                <img src="images/stock-5.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="d-flex">
                                            <button className="btn btn-link-secondary"><i className="far fa-heart"></i> 14</button>
                                            <button className="btn btn-link-secondary"><i className="far fa-comment"></i> 5</button>
                                            <span className="btn btn-link-secondary pull-right"><i className="fas fa-eye"></i> 78</span>
                                        </div>
                                    </div>
                                    <div className="box-footer">
                                        <div className="comments bg-white">
                                            <div className="user-panel align-items-start">
                                                <div className="user-panel-image">
                                                    <a href="#" className="avatar avatar-sm">
                                                        <img src="images/avatar-1.jpg" alt=""/>
                                                    </a>
                                                </div>
                                                <div className="user-panel-info">
                                                    <a href="#">{client.name}</a>
                                                    <p className="text-secondary">Yeah, nice photos! Well done.</p>
                                                </div>
                                                <div className="user-panel-actions text-black-25">
                                                    <small className="text-secondary">2 days ago</small>
                                                    <a href="#"><i className="fas fa-ellipsis-v"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-link btn-block mb-20">Show more</button>
                                <button className="btn btn-link btn-block mb-20"><i className="i-spinner loading"></i></button>*/}
                            </div>
                        </div>
                    </div>
                }

			</div>
    	);
	}
}

export { VerCliente };