import React, { Component, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import QRCode from "react-qr-code";
import { WhatsappShare } from '../../components/WhatsappShare';

import { Actions } from "../../flux";
import { texts } from "../../settings/";
import { userService } from "../../_services";
import { publicLink, publicLinkWhatsapp } from '../../_helpers/url';

import "./Home.scss";
import { WhatsappButton } from "../../components";

class Home extends Component {
	constructor(props) {
    super(props);
    this.state = {
      loaded: false, 
      user: {},
      copySuccess: false
    }
  }
  
  componentDidMount = () => {
    Actions.showMenu();
    Actions.showHeader();
    Actions.showFooter();

		userService.getMyData().then(data => {
			this.setState({
				user: data,
				loaded: true
			})
		})
  }

  copyLink = (e) => {
    this.copyInput.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ copySuccess: true });
  };

  myUnits = () => {
    const { user: { establishment_data } } = this.state
    return (establishment_data) ? establishment_data.units.length : null;
  }

  myUnitsItems = () => {
    const { user: { establishment_data: { units } } } = this.state
    return units;
  }

  render = () => {
    const { user, loaded, copySuccess } = this.state
    return (
      <div className="container-fluid">
        <Helmet>
          <title>Home - {texts.title}</title>
        </Helmet>
        {
          !loaded &&
	    		<div className="jumbotron">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
              </div>
            </div>
          </div>
        }

        {
          loaded &&
          !user.establishment_data && 
	    		<div className="jumbotron">
            <div className="row">
              <div className="col-md-12">
                <h1 className="display-4">Olá, <b className="text-primary">{user.name}</b></h1>
                <p className="mb-40 text-lg">Seja bem-vindo ao BarberTrack.</p>
                <p className="mb-20 text-lg text-dark">
                  Você precisará terminar a configuração da sua conta:
                </p>
                <ol className="mb-40">
                  <li className="text-dark text-lg">Informações sobre seu negócio;</li>
                  <li className="text-dark text-lg">Inclua os profissionais;</li>
                  <li className="text-dark text-lg">Defina os serviços e preços oferecidos.</li>
                </ol>
                <Link to="/configure" className="btn btn-secondary">Configure seu estabelecimento</Link>
              </div>
            </div>
          </div>
        }

        {
          loaded &&
          user.establishment_data && 
          <div>

            <div className="content-header content-header-scene">
              <h1>Olá, <b>{user.name}</b>.</h1>
              {/* <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Dashboard</li>
                </ol>
              </nav> */}
            </div>
    
            {
              this.myUnits() &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                  <div className="info-box info-box--copy">
                    <div className="pt-15 pl-15">
                      <div className="info-box-text">
                        <span className="info-box-number">
                          Página customizada 
                          <Link to="/minha-conta/design" className="btn btn-sm btn-outline-secondary ml-10">Configure sua página</Link>
                        </span>
                        <br />
                        Compartilhe o link com seus clientes.<br />
                        Assim, eles poderão agendar horários diretamente na sua agenda.
                        <br />
                        <br />

                        <div className="col-12 mb-20 pr-30">
                          <div className="row">
                            {
                              this.myUnitsItems().map((unit, index) => {
                                return (
                                  <div key={index} className="mt-20 mb-20 col-12 col-md-6">
                                    <div className="home-unity">
                                      <div className="home-unity__data">

                                        <h4>Unidade: {unit.name}</h4>

                                        <div className="home-unity__qrcode">
                                          <QRCode 
                                            data-tip="Aponte a câmera do celular"
                                            value={publicLink(unit.public_string)}
                                            size={86}
                                            />
                                          <ReactTooltip />
                                        </div>

                                        {/* <div className="input-group">
                                          <input
                                            className="form-control" 
                                            readonly
                                            ref={(input) => this.copyInput = input}
                                            value={publicLink(unit.public_string)}
                                            />
                                          <button className="btn btn-secondary" data-tip="Clique para copiar" onClick={(e) => this.copyLink(e)}>
                                            <i className="fas fa-copy"></i> Copiar
                                          </button>
                                          <ReactTooltip />
                                        </div> */}
            
                                        <div className="btn-group mt-10">
                                          <a className="btn btn-secondary mr-10" href={publicLink(unit.public_string)} target="_blank">
                                            <i className="fas fa-link"></i> Visualizar
                                          </a>
                                          <WhatsappShare
                                            label="Compartilhar no Whatsapp"
                                            content={`Confira minha agenda no BarberTrack: ${publicLink(unit.public_string)}`}
                                            >
                                          </WhatsappShare>
                                        </div>

                                        { copySuccess && <p className="info-box__success">Copiado!</p>}
                                      </div>
                                    </div>
        
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          
            {
              !this.myUnits() &&
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                  <div className="info-box info-box--copy">
                    <div className="info-box-content">
                      <div className="info-box-text">
                        <span className="info-box-number">
                          Você ainda não tem unidades
                        </span>
                        <Link to="/unidades/criar" className="btn btn-secondary mt-10">Cadastrar unidade</Link>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            }
            
            {/*   <div className="col-md-6 col-lg-3">
                <div className="info-box">
                  <div className="info-box-content">
                    <div className="info-box-text">
                      <span className="info-box-number">
                        3510 <small className="text-sm text-danger"><i className="fas fa-chevron-down"></i>-3</small>
                      </span>
                      Serviços
                    </div>
                    <div className="info-box-icon bg-success">
                      <i className="i-shopping-cart"></i>
                    </div>
                  </div>
                  <canvas className="info-box-chart" height="70" id="chartJs2"></canvas>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="info-box">
                  <div className="info-box-content">
                    <div className="info-box-text">
                      <span className="info-box-number">
                        19 <small className="text-sm text-secondary"><i className="fas fa-minus"></i></small>
                      </span>
                      Avaliações
                    </div>
                    <div className="info-box-icon bg-warning">
                      <i className="i-chat-bubble"></i>
                    </div>
                  </div>
                  <canvas className="info-box-chart" height="70" id="chartJs3"></canvas>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="info-box">
                  <div className="info-box-content">
                    <div className="info-box-text">
                      <span className="info-box-number">
                        19 <small className="text-sm text-success"><i className="fas fa-chevron-up"></i>+17%</small>
                      </span>
                      Faturamento
                    </div>
                    <div className="info-box-icon bg-danger">
                      <i className="i-circle-chart"></i>
                    </div>
                  </div>
                  <canvas className="info-box-chart" height="70" id="chartJs4"></canvas>
                </div>
              </div>
            </div>
    
            <div className="box">
              <div className="box-header">
                <h3>Serviços</h3>
                <div className="box-actions">
                  <a href="#" className="box-actions-item">Link</a>
                  <button className="box-actions-item"><i className="fas fa-minus"></i></button>
                  <button className="box-actions-item"><i className="fas fa-times"></i></button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <th>Avatar</th>
                      <th>Full name</th>
                      <th>Country</th>
                      <th>Date</th>
                      <th>Payment</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Progress</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>7719</td>
                      <td>
                        <div className="avatar avatar-xs">
                          <img src="images/avatar-1.jpg" alt="" />
                        </div>
                      </td>
                      <td>John Doe</td>
                      <td><i className="flags flags-ru"></i></td>
                      <td>10.10.2018</td>
                      <td><i className="payments payments-visa"></i></td>
                      <td>5471.00 $</td>
                      <td><span className="status-icon bg-warning"></span> Waiting</td>
                      <td>
                        <div className="d-flex justify-content-between mb-5">
                          <span className="text-sm"><b>25%</b></span>
                          <span className="text-sm text-secondary">Jun 11, 2018 - Jul 11, 2018</span>
                        </div>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-warning"  style={{width: '25%'}}></div>
                        </div>
                      </td>
                      <td>
                        <a href="#" className="icon text-black-25"><i className="fas fa-ellipsis-v"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>7720</td>
                      <td>
                        <div className="avatar avatar-xs">
                          <img src="images/avatar-3.jpg" alt="" />
                        </div>
                      </td>
                      <td>Alex White</td>
                      <td><i className="flags flags-bl"></i></td>
                      <td>15.10.2018</td>
                      <td><i className="payments payments-mastercard"></i></td>
                      <td>3933.00 $</td>
                      <td><span className="status-icon bg-danger"></span> Failed</td>
                      <td>
                        <div className="d-flex justify-content-between mb-5">
                          <span className="text-sm"><b>10%</b></span>
                          <span className="text-sm text-secondary">Jun 11, 2018 - Jul 11, 2018</span>
                        </div>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-danger"  style={{width: '10%'}}></div>
                        </div>
                      </td>
                      <td>
                        <a href="#" className="icon text-black-25"><i className="fas fa-ellipsis-v"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>7721</td>
                      <td>
                        <div className="avatar avatar-xs">
                          <img src="images/avatar-4.jpg" alt="" />
                        </div>
                      </td>
                      <td>Jessica Evans</td>
                      <td><i className="flags flags-de"></i></td>
                      <td>16.10.2018</td>
                      <td><i className="payments payments-paypal"></i></td>
                      <td>973.00 $</td>
                      <td><span className="status-icon bg-success"></span> Success</td>
                      <td>
                        <div className="d-flex justify-content-between mb-5">
                          <span className="text-sm"><b>100%</b></span>
                          <span className="text-sm text-secondary">Jun 11, 2018 - Jul 11, 2018</span>
                        </div>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-success" style={{width: '100%'}}></div>
                        </div>
                      </td>
                      <td>
                        <a href="#" className="icon text-black-25"><i className="fas fa-ellipsis-v"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>7722</td>
                      <td>
                        <div className="avatar avatar-xs">
                          <img src="images/avatar-5.jpg" alt="" />
                        </div>
                      </td>
                      <td>Lily Wilson</td>
                      <td><i className="flags flags-us"></i></td>
                      <td>16.10.2018</td>
                      <td><i className="payments payments-bitcoin"></i></td>
                      <td>312.00 $</td>
                      <td><span className="status-icon bg-success"></span> Success</td>
                      <td>
                        <div className="d-flex justify-content-between mb-5">
                          <span className="text-sm"><b>100%</b></span>
                          <span className="text-sm text-secondary">Jun 11, 2018 - Jul 11, 2018</span>
                        </div>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-success" style={{width: '100%'}}></div>
                        </div>
                      </td>
                      <td>
                        <a href="#" className="icon text-black-25"><i className="fas fa-ellipsis-v"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>7722</td>
                      <td>
                        <div className="avatar avatar-xs">
                          <img src="images/avatar-6.jpg" alt="" />
                        </div>
                      </td>
                      <td>Jacob Brown</td>
                      <td><i className="flags flags-gb"></i></td>
                      <td>17.10.2018</td>
                      <td><i className="payments payments-visa"></i></td>
                      <td>4876.00 $</td>
                      <td><span className="status-icon bg-secondary"></span> Pending</td>
                      <td>
                        <div className="d-flex justify-content-between mb-5">
                          <span className="text-sm"><b>0%</b></span>
                          <span className="text-sm text-secondary">Jun 11, 2018 - Jul 11, 2018</span>
                        </div>
                        <div className="progress progress-sm">
                          <div className="progress-bar bg-success"  style={{width: '25%'}}></div>
                        </div>
                      </td>
                      <td>
                        <a href="#" className="icon text-black-25"><i className="fas fa-ellipsis-v"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
    
            <div className="box">
              <div className="box-header">
                <h3>Weekly Recap Report</h3>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-8">
                    <canvas className="chart" id="areaChartJs"></canvas>
                  </div>
                  <div className="col-md-4">
                    <h3>Goals</h3>
                    <div className="d-block mb-20">
                      <div className="d-flex justify-content-between">
                        <p className="mb-5">Complete Purchase</p>
                        <p className="mb-5 text-secondary"><b>47/50</b></p>
                      </div>
                      <div className="progress progress-lg" id="barChart1"></div>
                    </div>
    
                    <div className="d-block mb-20">
                      <div className="d-flex justify-content-between">
                        <p className="mb-5">Add Product to Favorite</p>
                        <p className="mb-5 text-secondary"><b>25/50</b></p>
                      </div>
                      <div className="progress progress-lg" id="barChart2"></div>
                    </div>
    
                    <div className="d-block mb-25">
                      <div className="d-flex justify-content-between">
                        <p className="mb-5">Add product to favorite</p>
                        <p className="mb-5 text-secondary"><b>12/50</b></p>
                      </div>
                      <div className="progress progress-lg" id="barChart3"></div>
                    </div>
    
                    <div className="row text-center">
                      <div className="col-4">
                        <div className="chart-circle" id="circleChart1"></div>
                        <p>Mail orders</p>
                      </div>
                      <div className="col-4">
                        <div id="circleChart2"></div>
                        <p>Online</p>
                      </div>
                      <div className="col-4">
                        <div id="circleChart3"></div>
                        <p>In store</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <h2 className="content-title">Work</h2>
    
            <div className="row">
              <div className="col-lg-4">
                <div className="box">
                  <div className="box-header">
                    <h3>Chat</h3>
                  </div>
                  <div className="chat-content">
                    <div className="chat-messages">
    
                      <div className="message message-self">
                        <div className="message-content">
                          <div className="message-bubble">
                            <p>Hello, Alex!</p>
                          </div>
                          <small className="text-secondary text-sm">Today, 10:35</small>
                        </div>
                        <div className="message-actions">
                          <button><i className="far fa-trash-alt"></i></button>
                        </div>
                      </div>
                      <div className="message message-self">
                        <div className="message-content">
                          <div className="message-bubble">
                            <p>The project finally complete! Do you go to our team meeting? We have great news!</p>
                          </div>
                          <small className="text-secondary text-sm">Today, 10:35</small>
                        </div>
                        <div className="message-actions">
                          <button><i className="far fa-trash-alt"></i></button>
                        </div>
                      </div>
                      <div className="message">
                        <div className="avatar avatar-sm">
                          <img src="images/avatar-3.jpg" alt="" />
                        </div>
                        <div className="message-content">
                          <div className="message-bubble">
                            <p>Yes, I'll come!</p>
                          </div>
                          <small className="text-secondary text-sm">Today, 10:36</small>
                        </div>
                        <div className="message-actions">
                          <button><i className="far fa-trash-alt"></i></button>
                          <button><i className="far fa-flag"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className="chat-footer">
                      <div className="input-group">
                        <input id="inputGroupEmailButton2" type="text" className="form-control" placeholder="This is input with button" />
                        <button className="btn btn-primary">Send</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box">
                  <div className="box-header">
                    <h3>Browser Usage</h3>
                  </div>
                  <div className="box-body">
                    <canvas id="pieChartJs" className="chart" height="200"></canvas>
                    <ul className="list-bordered unstyled">
                      <li className="d-flex justify-content-between">Germany <span className="badge badge-primary badge-pill pull-right">+1236</span></li>
                      <li className="d-flex justify-content-between">USA <span className="badge badge-primary badge-pill pull-right">+1031</span></li>
                      <li className="d-flex justify-content-between">Australia <span className="badge badge-primary badge-pill pull-right">+833</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="box">
                  <div className="box-header">
                    <h3>Event columns</h3>
                    <div className="box-actions">
                      <a href="#" className="box-actions-item">Link</a>
                      <button className="box-actions-item"><i className="fas fa-minus"></i></button>
                      <button className="box-actions-item"><i className="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div className="box-body">
                    <div className="event-column event-column-success">
                      <div className="event-content">
                        <small className="text-secondary"><i className="far fa-envelope"></i> From example@gmail.com</small>
                        <p><a href="#">Alex White</a> sent you an email.</p>
                      </div>
                      <small className="text-secondary pull-right">1 hr.</small>
                    </div>
                    <div className="event-column event-column-success">
                      <div className="avatar avatar-xs">
                        <img src="images/avatar-2.jpg" alt="" />
                      </div>
                      <div className="event-content">
                        <small className="text-secondary"><i className="fas fa-code-branch"></i> awesome-branch</small>
                        <p><a href="#">Sam Lee</a> new pull request.</p>
                      </div>
                      <small className="text-secondary pull-right">43 min.</small>
                    </div>
                    <div className="event-column event-column-primary">
                      <div className="event-content">
                        <small className="text-secondary"><i className="fas fa-thumbtack"></i> New task</small>
                        <p><b>To <a href="#">John Doe</a>: new API methods.</b></p>
                        <p className="text-secondary text-sm">Implement new API methods for mobile app.</p>
                      </div>
                      <small className="text-secondary pull-right">7 min.</small>
                    </div>
                    <div className="event-column event-column-secondary">
                      <div className="event-content">
                        <small className="text-secondary"><i className="far fa-bell"></i> Notification</small>
                        <p><a href="#">Alex White</a> joined to project</p>
                      </div>
                      <small className="text-secondary pull-right">now</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>  */}
          </div>
        }
        
      </div>
    );
  }
}

export { Home };
