import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2'

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { InputSearchClients } from '../../components/InputSearchClients';
import { PackServiceList } from '../../components/PackServiceList';
import PaymentSelector from "../../components/PaymentSelector";

class VenderPacoteServicos extends Component {
	state = {
        loaded: false,
        success: false,
        items: [],
        loadingSubmit: false,
        clientSelected: null,
        packSelected: null,
        paymentSelected: null
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();
	}

	// saveInfo = values => {
    //     this.setState({loadingSubmit: true});
    //     let data = values;
    //     console.log(`saveInfo`, data);

    //     servicePacksService.create(data).then(res => {
    //         if (res.success) {
    //             Actions.showSuccess({ title: 'Pacote criado!' })
    //             this.setState({ success: true });

    //         } else {
    //             Actions.showError(res.error)
    //         }

    //         this.setState({ loadingSubmit: false });
    //     }).catch(err => {
    //         Actions.showError(err.error)

    //         this.setState({loadingSubmit: false});
    //     })
    // }

    confirm = () => {
        const { loadingSubmit, clientSelected, packSelected, paymentSelected } = this.state

        if (loadingSubmit) {
            return;
        }

        if (!clientSelected) {
            Swal.fire({
                title: 'Alerta',
                text: 'Você deve selecionar o Cliente',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

        if (!packSelected) {
            Swal.fire({
                title: 'Alerta',
                text: 'Você deve selecionar o Pacote de serviços',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

        if (!paymentSelected) {
            Swal.fire({
                title: 'Alerta',
                text: 'Você deve informar o método de pagamento',
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }

        // servicePacksService.create(data).then(res => {
        //     if (res.success) {
        //         Actions.showSuccess({ title: 'Pacote criado!' })
        //         this.setState({ success: true });

        //     } else {
        //         Actions.showError(res.error)
        //     }

        //     this.setState({ loadingSubmit: false });
        // }).catch(err => {
        //     Actions.showError(err.error)

        //     this.setState({loadingSubmit: false});
        // })
    }

	render = () => {
        const { loadingSubmit, success, clientSelected, packSelected, paymentSelected } = this.state
        return (
			<div className="views container-fluid">

				{
					success && 
					<Redirect to={{
                        pathname: `/pacote-servicos/configuracoes`,
                        state: { from: this.props.location }
                    }} />
				}
			
				<Helmet>
	                <title>Pacote de serviços - Vender novo - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Pacote de serviços  <small>NOVA VENDA</small></h1>
                </div>

                <div className="row">

                    <div className="col-md-12">
                        <Link to="/pacote-servicos" className="btn btn-light"><i className="fe fe-arrow-left"></i> Voltar</Link>
                    </div>

                    <div className="col-11 mt-40">
                        <div className="box">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-5">

                                        <div className="form-group mt-20">
                                            { clientSelected === null && <label className="form-label">Selecione o cliente que está comprando o pacote</label> }
                                            { clientSelected !== null && <label className="form-label">Cliente</label> }
                                            

                                            {
                                                clientSelected !== null &&
                                                <input type="text" className="form-control" value={`${clientSelected.name} ${(clientSelected.cellphone) ? `- ${clientSelected.cellphone}` : ''} ${(clientSelected.email) ? `- ${clientSelected.email}` : ''}`} disabled />
                                            }

                                            {
                                                clientSelected === null &&
                                                <InputSearchClients onSelectClient = {client => this.setState({clientSelected: client, packSelected: null, paymentSelected: null })} />
                                            }

                                            {
                                                clientSelected !== null &&
                                                <button className="btn btn-outline-dark btn-sm mt-20" onClick={() => this.setState({clientSelected:null})}>mudar cliente</button>
                                            }
                                        </div>

                                        {
                                            clientSelected && packSelected &&
                                            <div className="form-group mt-30">
                                                <label className="form-label">Método de pagamento</label>
                                                <PaymentSelector
                                                    price={packSelected.price}
                                                    onChangePayments={items => this.setState({ paymentSelected: items})}
                                                    />
                                            </div>
                                        }
                                    </div>

                                    <div className="col-md-7">
                                        {
                                            clientSelected && 
                                            <div className="form-group mt-30">
                                                { packSelected === null && <label className="form-label">Selecione o pacote a ser comprado</label> }
                                                { packSelected !== null && <label className="form-label">Pacote</label> }
                                                <PackServiceList onChange = {item => this.setState({ packSelected: item, paymentSelected: null })} />
                                            </div>
                                        }

                                    </div>

                                    <div className="col-md-12 mt-30">
                                        {
                                            clientSelected && packSelected && paymentSelected &&
                                            <div className="form-group mt-30 text-right">
                                                <button className="btn btn-success btn-lg" onClick = {() => this.confirm()}>Confirmar venda</button>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
				
			</div>
    	);
	}
}

export { VenderPacoteServicos };