import React, { Component, Fragment } from "react";
import InputMask from "react-input-mask";

import { Actions } from '../flux';
import { DropdownEstados } from './';
import { addTemporaryListener } from '../utils/temporaryListener';

class EnderecoCompleto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingCepData: false
    };
  }

  onCepBlur = e => {
    let cepUnformatted = e.target.value.replace(/\D/g, "");

    if (cepUnformatted == "") {
      return;
    }

    const validadorCep = /^[0-9]{8}$/;
    if (!validadorCep.test(cepUnformatted)) {
      Actions.showError("CEP inválido");
      addTemporaryListener("click", ".swal2-confirm", "cep");
      return false;
    }

    const loadingState = {
      address: "Carregando...",
      neighborhood: "Carregando...",
      city: "Carregando...",
      state: "Carregando..."
    };

    this.changeState(loadingState, true);

    const initialState = {
      cep: "",
      address: "",
      neighborhood: "",
      city: "",
      state: ""
    };

    fetch(`https://viacep.com.br/ws/${cepUnformatted}/json`)
      .then(res => res.json())
      .then(dados => {
        if (dados.erro) {
          Actions.showError("CEP inválido");
          this.changeState({ ...initialState, cep: "" }, false);
          addTemporaryListener("click", ".swal2-confirm", "cep");
          return;
        }

        this.changeState(
          {
            cep: dados.cep,
            address: dados.logradouro,
            neighborhood: dados.bairro,
            city: dados.localidade,
            state: dados.uf
          },
          false
        );
      })
      .catch(err => {
        console.log("Falha na requisição à api de CEP", err);
        this.changeState({ ...initialState, cep: "" }, false);
      });
  };

  changeState = (data, isLoadingCepData) => {
    this.setState({
      isLoadingCepData: isLoadingCepData
    });
    Actions.cepChange(data);
  };

  render() {
    const {
      cep,
      address,
      number,
      complement,
      neighborhood,
      city,
      state,
      referencePoint,
      handleChange
    } = this.props;

    const { isLoadingCepData } = this.state;

    return (
      <Fragment>
        <div className="row">
          <div className="col-6">
            <div className="form-group form-group--inline">
              <label>*CEP:</label>
              <InputMask
                type="text"
                mask="99999-999"
                maskChar=" "
                name="cep"
                value={cep}
                onChange={e => handleChange(e)}
                onBlur={e => this.onCepBlur(e)}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group form-group--inline">
              <label>*Endereço:</label>
              <input
                type="text"
                name="address"
                value={address}
                onChange={e => handleChange(e)}
                disabled={isLoadingCepData}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="form-group form-group--inline">
              <label>*Nº</label>
              <input
                type="text"
                name="number"
                value={number}
                onChange={e => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group form-group--inline">
              <label>Complemento:</label>
              <input
                type="text"
                name="complement"
                value={complement}
                onChange={e => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group form-group--inline">
              <label>*Bairro:</label>
              <input
                type="text"
                name="neighborhood"
                value={neighborhood}
                onChange={e => handleChange(e)}
                disabled={isLoadingCepData}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="form-group form-group--inline">
              <label>*Cidade:</label>
              <input
                type="text"
                name="city"
                value={city}
                onChange={e => handleChange(e)}
                disabled={isLoadingCepData}
              />
            </div>
          </div>
          <div className="col-4">
            <DropdownEstados
              value={state}
              handleChange={handleChange}
              disabled={isLoadingCepData}
            />
          </div>
          <div className="col-4">
            <div className="form-group form-group--inline">
              <label>Ponto de referência:</label>
              <input
                type="text"
                name="referencePoint"
                value={referencePoint}
                onChange={e => handleChange(e)}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export { EnderecoCompleto };
