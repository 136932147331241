import React from "react";
import { Route, Redirect } from "react-router-dom";

import { partnerRoles } from '../settings/roles'
import { userService, plansService } from "../_services/";

const PrivateRoute = ({
  planProtected,
  onlyRole,
  component: Component,
  ...rest
}) => {
  var planValid = true;
  if (planProtected) {
    planValid = plansService.getValid();
  }

  const role = (userService.isAuthenticated()) ? userService.getUserRole() : null;

  return (
    <Route
      {...rest}
      render = {props => {
        if (userService.isAuthenticated() && onlyRole) {
          if (!partnerRoles.includes(role) && role != onlyRole) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location }
                }}
              />
            )
          }
        }

        // Verifica se o plano está válido
        // if (!planValid) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/locked",
        //         state: { from: props.location }
        //       }}
        //     />
        //   )
        // }

        // return padrão
        return userService.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export { PrivateRoute };
