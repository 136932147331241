import React, { Component } from "react";

import './PackServiceList.scss';
import { servicePacksService } from "../../_services";
import { numberToReal } from '../../utils/money';

class PackServiceList extends Component {
    state = {
        loaded: false,
        packs: [],
        selected: null
    }

    componentDidMount = () => {
		servicePacksService.myServicePacks().then(data => {
			this.setState({
				packs: data,
				loaded: true
			})
		})
    }

    select = item => {
        const { onChange } = this.props
        this.setState({selected: item});
        onChange(item);
    }

    renderList = items => {
        const { selected, loaded } = this.state
		return (
            <div className="list-group">
                {items.length > 0 &&
                <ul className="list-group">
                    {items.map(item => (
                        <button onClick={() => this.select(item)} className={`list-group-item list-group-item-action ${(selected && selected.id == item.id)?'active':''}`}> 
                            <div className="row">
                                <div className="col-5">{item.name}</div>
                                <div className="col-2 text-center"><small>Serviços</small><br />{item.max_services}</div>
                                <div className="col-2 text-center"><small>Preço</small><br />{ numberToReal(item.price) }</div>
                                <div className="col-2 text-center"><small>Expira em</small><br />{item.expire} dias</div>
                                <div className="col-1 text-right check-column">
                                    {(selected && selected.id == item.id) && <i className="fe fe-check"></i>}
                                </div>
                            </div>
                        </button>
                    ))}

                    {
                        !loaded &&
                        <p className="p-30">Carregando...</p>
                    }

                    {
                        (loaded && items.length == 0) &&
                        <p className="p-30">Nenhum pacote encontrado</p>
                    }

                </ul>}
            </div>
		);
    }

    render = () => {
        const { packs } = this.state
        return (
            <div className="pack-service-list-component">
                {this.renderList(packs)}
            </div>
        );
    }
}


export { PackServiceList };