import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { servicePacksService } from '../../_services';
import moment from 'moment-timezone';

class PacoteServicos extends Component {
	state = {
        loaded: false,
        items: []
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.getAll();
	}

	getAll = () => {
		this.setState({ loaded: false })

		servicePacksService.myServicePacksSolds().then(data => {
			this.setState({
				items: data,
				loaded: true
			})
			console.log('get items', data)
		})
    }

	render = () => {
        const { items } = this.state
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Pacote de serviços - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>Pacote de serviços</h1>
                    <Link to="/pacote-servicos/configuracoes" className="btn btn-light float-right"><i className="fe fe-settings"></i> Configurações</Link>
                </div>

                <div className="row">

                    <div className="col-md-12 mt-20">
                        <Link to="/pacote-servicos/venda" className="btn btn-info">Nova venda</Link>
                        <Link to="/pacote-servicos/inativos" className="btn btn-light">Ver inativos</Link>
                    </div>

                    <div className="col-md-12 mt-40">
                        <div className="box">
                            <div className="table-responsive">
                                {
                                    items.length > 0 &&
                                    <div className="table table-stripped">
                                        <tbody>
                                            <tr>
                                                <th>Pacote</th>
                                                <th>Cliente</th>
                                                <th>Serviços restantes</th>
                                                <th>Expira em</th>
                                                <th>Data contratação</th>
                                                <th>Data expiração</th>
                                            </tr>

                                            {
                                                items.map(item => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>{item.expire} dias</td>
                                                        <td>{ moment(item.created_at).format('DD-MM-YYYY')}</td>
                                                        <td>-</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </div>
                                }
                            </div>

                            {
                                items.length == 0 &&
                                <p style={{padding: 20}}>Nenhum pacote ativo no momento</p>
                            }
                        </div>
                    </div>

                </div>
				
			</div>
    	);
	}
}

export { PacoteServicos };