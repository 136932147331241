import React, { Component } from "react";

import { unitsService } from "../../_services/units.service";

class HorarioAtendimento extends Component {
    state = {
        horarios: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ horarios: items })
    }
    
    componentDidUpdate = () => {
        const { items } = this.props
        const { horarios } = this.state
        if (items.length !== horarios.length) {
            this.setState({ horarios: items })
        }
    }

    deleteHour = (id) => {
		unitsService.removeOpeningHours(id).then(data => {
            console.log('removeOpeningHours', data)
            this.props.updateHours();
        })
    }

	renderList = items => {
		return (
			<div className="items">
                {items.length > 0 && items.map(item => (
					<div key={item.id}>
                            <p>
                                {item.weekday} - De: {item.hour_start} - Até: {item.hour_end}
                                <button className="ml-20 btn btn-outline-danger btn-sm" onClick={() => this.deleteHour(item.id)}>remover horário</button>
                            </p>
                    </div>
				))}
                
                {
                    items.length == 0 &&
                    <p className="text-lg">Cadastre ao menos 1 opção de horário.</p>
                }
			</div>
		);
	}

    render = () => {
        const { horarios } = this.state;
        return (
            <div>
                <div className="professionals-list">
                    {this.renderList(horarios)}
                </div>
                {/* <div className="professionals-list mt-20">
                    <p>Adicionar</p>
                </div> */}
            </div>
        );
    }
}


export { HorarioAtendimento };