import React, { Component, useState } from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom'
import moment from 'moment'

import { Actions } from '../../flux'
import { userService } from '../../_services/user.service'
import { plansService } from '../../_services/plans.service'
import { unitsService } from '../../_services/units.service'
import { texts } from '../../settings/'
import { numberToReal } from '../../utils/money'
import { calcMonths } from '../../utils/number'
import { UpgradeButton } from '../../components/UpgradeButton'
import MinhaContaNav from '../../components/MinhaContaNav';

import "./Plano.scss";

class Plano extends Component {
	state = {
		userPlan: null,
		user: null,
		plans: [],
		loaded: false,
		unitParam: null,
		unit: null
	}

	componentDidMount = () => {
        const { match: { params } } = this.props;
		this.setState({ unitParam: params.id })

		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		userService.getLocalData().then(data => {
			this.setState({
				user: data.user
			})
		})

		if (params.id) {
			unitsService.getOne(params.id).then(data => this.setState({ unit: data }));
			plansService.getUnitPlan(params.id).then(this.parseUserPlans)
		} else  {
			plansService.getMyPlans().then(this.parseUserPlans)
		}
	}

	parseUserPlans = (data) => {
		if (data.length > 0) {
			this.setState({
				userPlan: data,
				loaded: true
			})
		} else {
			this.setState({
				loaded: true
			})
		}

		this.getPlans();
	}

	getPlans = () => {
		this.setState({
			loaded: false
		});

		plansService.getPlans().then(data => {
			this.setState({
				plans: data.filter(obj => obj.slug !== 'free'),
				loaded: true
			})
		})
	}

	renderMyPlan = items => {
		if (!items.length) return;
		// console.log('renderMyPlan', items)	
		return (
			<div className="items">
                {
                    items.length > 0 &&
					<div>

						<div className="item-planos row mt-10">
							{items.map((item, index) => {
								if (item.plan_details.cost > 0) {
									const percentOff = item.plan_details.annual_discount;
									const months = calcMonths(item.plan_details.periodicity);
									const mensal = item.plan_details.cost / months;

									return (
										<div className='col-md-4' key={index}>
											<div className="card card-column">
												<div className="card-body">
													<h4 className="display-4">{item.plan_details.name}</h4>

													{ item.plan_details.cost > 0 && <p className="text-lg">{numberToReal(mensal/100)}</p> }
													{ item.plan_details.cost > 0 && <small>Pago a cada {item.plan_details.periodicity} dias.</small> }
													{ item.plan_details.cost == 0 && <p className="text-lg">Plano sem custo</p> }

													<p className="mt-10">
														{/* {
															item.plan_details.savings == 0 &&
																<b>
																	<span className="badge badge-outline-secondary">0% OFF</span>
																	<br />
																	<br />
																</b>
														} */}
														{
															item.plan_details.savings > 0 &&
																<b>
																	<span className="badge badge-success">{percentOff.toFixed(0)}% OFF</span>
																	<br />
																	Economizando {numberToReal(item.plan_details.savings)} no período.
																</b>
														}

														{
															months > 1 &&
															<small><br />Pague {numberToReal(item.plan_details.cost)} a cada {months} meses.</small>
														}
													</p>
												</div>
											</div>
								
											<div className="mt-20">
												<p><i className="fe fe-info text-info mr-5"></i>Válido até {moment(item.valid_date.date).format('DD/MM/YYYY')}.</p>
												{/* {
													item.pagarme_subscription &&
													<p><i className="fe fe-settings text-info mr-5"></i><a href={item.pagarme_subscription.manage_url} className="text-secondary" target="_blank">Gerenciar assinatura</a></p>
												} */}
											</div>
										</div>
									)
								} else {
									return null;
								}
							})}
						</div>
					</div>
                }
			</div>
		);
	}

	render = () => {
		const { userPlan, loaded, plans, unit, unitParam } = this.state

		let isFree = false;
		// let isFree = (userPlan && userPlan.plan_details.slug === 'free');
		if (userPlan && userPlan.length > 0) {
			userPlan.map(plan => {
				if (plan.plan_details.slug === 'free') {
					isFree = true;
				}
			});
		}

        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Assinatura - { texts.title }</title>
	            </Helmet>

                <div className="content-header">
                    <h1>Assinaturas</h1>
                </div>

				<div className="row">
					<div className="col-md-12">
						<MinhaContaNav active = {'plano'} />
					</div>
				</div>

				{ !loaded && <p className='item-planos'>Carregando...</p>}

				{
					loaded &&
					<div className="row mt-20">

						<div className="col-md-12">

							{
								!userPlan && 
								<div className=''>
									
									<p>Você não possui nenhum plano contratado.</p>
									<p>Para contratar plano, vá até a página <Link to="/unidades" className='btn btn-sm btn-dark'>Unidades</Link></p>
	
								</div>
							}

							{ userPlan && this.renderMyPlan(userPlan) }

							
						</div>

						{
							userPlan && 
							<div className="col-md-4">
								{
									isFree &&
									<UpgradeButton 
										unit={unitParam}
										hasPlan={(userPlan.plan_details.cost > 0) ? true : false}
										/>
								}
							</div>
						}
					</div>
				}
				
			</div>
    	);
	}
}

export { Plano };