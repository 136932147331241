import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import moment from 'moment';

import "./profissional.scss";

import { Dispatcher, Events, Actions } from '../../flux';
import { texts } from '../../settings/';
import { professionalsService } from '../../_services/professionals.service'
import { imageApi } from '../../_helpers/imageApi'
import { getFirstWord, getInitials } from '../../_helpers/clean-string'
import { sumArray, numberToReal } from '../../utils/money'

import QuotasList from '../../components/QuotasList'
import ProfessionalServicesList from '../../components/ProfessionalServicesList'
import { HorarioAtendimentoProfissional } from '../../components/HorarioAtendimento/professional'
import ComissionsList from '../../components/ComissionsList'
// import { unitsService } from '../../_services';

class VerProfissional extends Component {
    constructor(props) {
        super(props);

        this.toggleHasSchedule = this.toggleHasSchedule.bind(this);
    }
	state = {
        services: [],
		professional: null,
		loading: false,
        success: false,
        loadingSubmit: false
    }
    

	componentWillMount = () => {
        // // Dispatchers
        Dispatcher.on(Events.PROFESSIONAL_QUOTA_UPDATED, this.loadProfessional.bind(this));
    }

    componentWillUnmount = () => {
        Dispatcher.removeListener(Events.PROFESSIONAL_QUOTA_UPDATED, this.loadProfessional );   
    }

	componentDidMount = () => {
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

        this.setState({ loading: true });
        this.loadProfessional();
	}

    loadProfessional = () => {
		const { match: { params } } = this.props;

        // Para pegar os profissionais
		professionalsService.getOne(params.id).then(data => {
			this.setState({
				professional: data,
                loading: false
			})
		})

        professionalsService.getProfessionalServices(params.id).then(data => {
            console.log('getProfessionalServices', data);
            this.setState({
                services: data.data,
                loading: false
            })
        })
    }

    toggleHasSchedule = e => {
        const { professional, loadingSubmit } = this.state

        if (loadingSubmit === true) {
            return;
        }
        
        this.setState({loadingSubmit: true});
        
        const data = {
            has_schedule: (e === false) ? 0 : 1
        }

		professionalsService.update(professional.id, data).then(res => {
            // console.log('res', res);
			if (res.success) {
                professional.has_schedule = (e === false) ? 0 : 1;
                this.setState({professional: professional});
			} else {
				Actions.showError(res.error)
            }
            
			this.setState({loadingSubmit: false});
		}).catch(err => {
			Actions.showError(err.error)
			this.setState({loadingSubmit: false});
		})
    }

	render = () => {
        const { loading, professional, services, loadingSubmit } = this.state
        
        // const average = (professional) ? sumArray(professional.order_quotas, 'amount') / professional.order_quotas.length : 0;

        return (
			<div className="views">
			
				<Helmet>
	                <title>Profissional - { texts.title }</title>
	            </Helmet>

                {
                    loading &&
                    <div className="row" style={{ height: 200, paddingTop: 80 }}>
                        <div className="col-md-12 text-center">
                            <p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
                        </div>
                    </div>
                }

                {
                    !loading && professional &&
                    <div>
                        <div className="content-header">
                            <h1>
                                {getFirstWord(professional.name)}
                                <br />
                                <small>
                                    {(professional.role == '' || professional.role == null) ? 'Profissional' : professional.role} em <Link to={`/unidades/${professional.unity.public_string}`}>{professional.unity.name}</Link>
                                </small>
                            </h1>
                        </div>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="profile">
                                    <div className="profile-bg profile-bg-blur">
                                        <img src="images/avatar-1.jpg" alt=""/>
                                    </div>
                                    <div className="user-panel user-panel-column">
                                        <div className="user-panel-image">
                                            {/* {
                                                professional.photo !== '' && professional.photo !== null &&
                                                <div className="avatar avatar-lg avatar-bordered">
                                                    <img src={imageApi(professional.photo)} />
                                                </div>
                                            }
                                            {
                                                professional.photo == '' || professional.photo == null &&
                                                <div className="avatar avatar-secondary avatar-lg avatar-bordered">{getInitials(professional.name)}</div>
                                            } */}
                                            <div className="avatar avatar-secondary avatar-lg avatar-bordered">{getInitials(professional.name)}</div>
                                        </div>
                                        <div className="user-panel-info mb-10">
                                            <p className="text-black">{getFirstWord(professional.name)}</p>
                                            <small className="text-secondary">{professional.role}</small>
                                            <small className="text-secondary">{professional.unity.name}</small>
                                        </div>
                                        {/* <div className="btn-toolbar justify-content-center">
                                            <a href="#" className="btn btn-secondary btn-sm">alterar foto</a>
                                        </div> */}
                                    </div>

                                    <hr/>
                                
                                    {/* <div className="row">
                                        <div className="col-12 text-center">
                                            <p>Histórico</p>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-4">
                                            {professional.order_quotas.length}
                                            <small className="text-secondary d-block">Atendimentos</small>
                                        </div>
                                        <div className="col-4">
                                            <b>{numberToReal(average)}</b>
                                            <small className="text-secondary d-block">Média</small>
                                        </div>
                                        <div className="col-4">
                                            <b>{numberToReal(sumArray(professional.order_quotas, 'amount'))}</b>
                                            <small className="text-secondary d-block">Total</small>
                                        </div>
                                    </div> <hr/>
                                    */}

                                    

                                    <div className="row">
                                        <div className="col-12 mt-20 has_schedule">
                                            <div>
                                                <Switch onChange={this.toggleHasSchedule} checked={(professional.has_schedule) ? true : false} /> 
                                                {
                                                    (professional.has_schedule  === 1)
                                                    ? <span>Profissional executa serviços</span>
                                                    : <span>Profissional não executa serviços</span>
                                                }
                                            </div>

                                            {/* {
                                                (professional.has_schedule  === 1)
                                                ? <p><i className="fe fe-check-circle text-success"></i> Profissional executa serviços</p>
                                                : <p><i className="fe fe-alert-octagon text-danger"></i> Profissional não executa serviços</p>
                                            } */}
                                        </div>
                                        {
                                            loadingSubmit &&
                                            <div className="loading_has_schedule">
                                                <p>Carregando...</p>
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className="box">
                                    <div className="box-header row">
                                        <div className="col-md-6">
                                            <h3>Perfil</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <Link className="btn btn-secondary" to={`/profissional/editar/${professional.id}`}>Editar</Link>
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        <div className="mb-10">
                                            <div className="text-partition">
                                                <b>Geral</b>
                                            </div>
                                            <dl className="row">
                                                <dt className="col-md-3">Nome completo:</dt>
                                                <dd className="col-md-9">{professional.name}</dd>

                                                <dt className="col-md-3">Cargo:</dt>
                                                <dd className="col-md-9">{(professional.role) ? professional.role : "--"}</dd>

                                                {/* <dt className="col-md-3">Endereço:</dt>
                                                <dd className="col-md-9 d-flex">{(professional.address) ? professional.address : "--"}</dd> */}
                                                
                                                {/* <dt className="col-md-3">Documento:</dt>
                                                <dd className="col-md-9">{professional.document}</dd> */}
                                            </dl>
                                        </div>
                                        <div className="mb-20">
                                            <div className="text-partition">
                                                <b>Contatos</b>
                                            </div>
                                            <dl className="row">
                                                <dt className="col-md-3">Telefone:</dt>
                                                <dd className="col-md-9">{(professional.cellphone) ? professional.cellphone : "--"}</dd>
                                            </dl>
                                        </div>
                                        <div className="mb-10">
                                            <div className="text-partition">
                                                <b>Histórico</b>
                                            </div>
                                            <dl className="row">
                                                <dt className="col-md-3">Cadastrado em:</dt>
                                                <dd className="col-md-9">{moment(professional.created_at).format("DD/MM/YYYY - HH:mm")}</dd>

                                                <dt className="col-md-3">Editado em:</dt>
                                                <dd className="col-md-9">{moment(professional.updated_at).format("DD/MM/YYYY - HH:mm")}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-8">

                                {
                                    professional.has_schedule === 1 &&
                                    <div className="box">
                                        <div className="box-header">
                                            <h3>Horários</h3>
                                        </div>
                                        <div className="box-body">

                                            <HorarioAtendimentoProfissional
                                                unit={professional.unity.unity_id}
                                                professional={professional.id}
                                                items={professional.opening_hours}
                                                blocked={professional.future_blocked_hours}
                                                onUpdateHour={() => this.loadProfessional()} />
                                                
                                        </div>
                                    </div>
                                }
                                
                                {
                                    professional.has_schedule === 1 &&
                                    <div className="box">
                                        <div className="box-header">
                                            <h3>Cotas comissionais <small>Configure as cotas de comissão de {professional.name}</small></h3>
                                        </div>
                                        <div className="box-body">
                                            <QuotasList
                                                items = {professional.quotas}
                                                professional = {professional} />
                                        </div>
                                    </div>
                                }
                                
                                {
                                    professional.has_schedule === 1 &&
                                    <div className="box">
                                        <div className="box-header">
                                            <h3>Serviços que executa <small>Serviços que aparecerão na agenda de {professional.name}</small></h3>
                                        </div>
                                        <div className="box-body">
                                            {
                                                services.length > 0 && 
                                                <ProfessionalServicesList
                                                    items = {services}
                                                    professional = {professional} />
                                            }
                                        </div>
                                    </div>
                                }

                                <div className="box">
                                    <div className="box-header">
                                        <h3>Comissões</h3>
                                    </div>
                                    <div className="box-body">
                                        
                                        <div className="row mb-20">
                                            <div className="col-12 col-md-4">
                                                <div className="info-box">
                                                    <div className="info-box-content">
                                                        <div className="info-box-text">
                                                            <span className="info-box-number">{numberToReal(professional.comissions.today)}</span>
                                                            Hoje
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="info-box">
                                                    <div className="info-box-content">
                                                        <div className="info-box-text">
                                                            <span className="info-box-number">{numberToReal(professional.comissions.week)}</span>
                                                            Esta semana
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="info-box">
                                                    <div className="info-box-content">
                                                        <div className="info-box-text">
                                                            <span className="info-box-number">{numberToReal(professional.comissions.month)}</span>
                                                            Este mês
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <hr/>

                                        <div className="row mt-20">
                                            <div className="col-12">
                                                <p>HISTÓRICO:</p>
                                            </div>
                                        </div>
                                   
                                        <ComissionsList
                                            items = {professional.order_quotas}
                                            professional = {professional} />
                                            
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                }

			</div>
    	);
	}
}

export { VerProfissional };