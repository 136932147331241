import React from "react";
import { Link } from 'react-router-dom';
import { numberToReal } from '../../utils/money'
import { imageApi } from '../../_helpers/imageApi'
import { productsService } from '../../_services/products.service'
import { Actions } from '../../flux';

class Delete extends React.Component {
  state = {
    toRemove: false
  }

  remove = id => {
    console.log('REMOVEEEE', id)
  }
  
  render = () => {
    const { id } = this.props
    const { toRemove } = this.state
    return(
      <div className="pull-right" style={{cursor: 'pointer'}}>
        {!toRemove && <span onClick = {() => this.setState({toRemove: true})}><i className="fe fe-trash-2"></i> <small>Remover</small></span>}
  
        {toRemove && <div className="btn btn-link-dark mr-10" onClick = {() => this.setState({toRemove: false})}>Cancelar</div>}
        {toRemove && <div className="btn btn-danger btn-sm" onClick = {() => this.remove(id)}>Confirmar remoção</div>}
      </div>
    )
  }
}

class AddStock extends React.Component {
  state = {
    editted: false,
    add: false,
    quantity: this.props.initialQuantity
  }

  confirm = () => {
    const { quantity, editted } = this.state
    const { id, onChangeStock } = this.props

    if (editted === false) {
      this.setState({ add: false });
      return;
    }
    
    productsService.updateStock(id, quantity).then(res => {
      // console.log('confirm res ', res)
      if (res.success) {
        Actions.showSuccess({
          title: 'Estoque alterado!',
        })
        this.setState({ add: false });
        if (onChangeStock) onChangeStock(res.product.quantity)

      } else {
        Actions.showError(res.error)
      }
    }).catch(err => {
      console.log('confirm err', err)
      Actions.showError(err.error)
    }) 
  }

  changeValue = val => {
    this.setState({ quantity: val, editted: true })
  }
  
  render = () => {
    const { add, quantity } = this.state
    return(
      <div className="d-flex justify-content-between" style={{cursor: 'pointer'}}>
        { !add && <button className="btn btn-rounded btn-outline-secondary btn-block btn-sm"  onClick = {() => this.setState({add: true})}><i className="fe fe-shopping-bag"></i> Alterar estoque</button> }
  
        {
          add && 
          <input
            type="text"
            className="form-control"
            value={quantity}
            onChange={e => this.changeValue(e.target.value)} />
        }
        {add && <div className="btn btn-success btn-sm" onClick = {() => this.confirm()}>OK</div>}
      </div>
    )
  }
}

class CardProduct extends React.Component {
  state = {
    quantity: this.props.item.quantity,
  }

  stockChanged = qtd => {
    console.log('stockChanged', qtd)
    this.setState({quantity: qtd})
  }

  render = () => {
    const { quantity } = this.state
    const { item, canEdit } = this.props
    return (
      <div className="card card-column" style={{minHeight: 440}}>
          <div className="card-image" style={{ backgroundColor: '#ccc' }}>
            {
              item.photo &&
              <img src={imageApi(item.photo)} />
            }
            {!item.photo && <p className="text-center">Sem foto</p>}
          </div>
          <div className="card-body">
            <h3>{item.name}</h3>
            <p>{item.description}</p>
  
            <ul className="list-bordered unstyled">
                <li className="d-flex justify-content-between">Marca <b>{item.brand}</b></li>
                <li className="d-flex justify-content-between">
                    Estoque 
                    { quantity > 0 && <span className="badge badge-secondary badge-pill pull-right">{quantity} itens</span> }
                    { !quantity && <span className="badge badge-danger badge-pill pull-right">{quantity} itens</span> }
                </li>
                <li className="d-flex justify-content-between">Valor <b>{numberToReal(item.cost)}</b></li>

                <li className="d-flex justify-content-between">
                  {(item.quota_included && item.quota) ? 'Incluso na comissão' : 'Não incluso na comissão'}
                  {(item.quota_included && item.quota) ? <b>{item.quota}%</b> : ''}
                  </li>

                {
                  canEdit &&
                  <AddStock
                  onChangeStock = {qtd => this.stockChanged(qtd)}
                  id = {item.id}
                  initialQuantity = {quantity} />
                }

                {
                  canEdit &&
                  <li className="d-flex justify-content-between pt-10">
                    <Link to={`/produtos/editar/${item.id}`} className="btn btn-rounded btn-outline-secondary btn-block btn-sm"><i className="fe fe-edit-3"></i> Editar produto</Link>
                  </li>
                }

                {/* <li className="d-flex justify-content-between">
                  <Delete id = {item.id}/>
                </li> */}
            </ul>
          </div>
      </div>
    )
  }
}

export default CardProduct;
