import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import './Locked.scss';

import { Actions } from '../../flux';
import { texts } from '../../settings';
import { UpgradeButton } from '../../components/UpgradeButton';

class Locked extends Component {
	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();
	}

	render = () => {
        const classItem = [
        	'locked'
        ];

        return (
			<div className="views">
			
				<Helmet>
	                <title>Locked - Seu plano expirou! - { texts.title }</title>
	            </Helmet>

	    		<div className={ classItem.join(' ') }>

	    			<div className="locked_view">
	    				<h1>Ops!</h1>
	    				<p className="mb-40 mt-20">Não fique sem acesso.</p>

						<UpgradeButton />
	            	</div>

				</div>
			</div>
    	);
	}
}

export { Locked };