import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { Actions } from '../../flux';
import { texts } from '../../settings/';
import { userService } from '../../_services/user.service'
import { clientsService } from '../../_services/clients.service'
import PhoneInput from '../../components/MaskedInput/phone'
import CpfInput from '../../components/MaskedInput/cpf'

const form = yup.object().shape({
	name: yup
		.string()
        .required('Campo obrigatório'),
        
    document: yup
        .string(),

	email: yup
		.string()
		.email('E-mail inválido'),

	cellphone: yup
		.string(),

    photo: yup
        .string(),

    instagram: yup
        .string(),

    facebook: yup
        .string(),

    twitter: yup
        .string(),
});

class CriarCliente extends Component {
	state = {
		user: null,
		loading: false,
		success: false,
		photoSource: null,
		file: null
	}

	componentDidMount = () => {
		const { match: { params } } = this.props;
		
		Actions.showMenu()
		Actions.showHeader()
		Actions.showFooter()

        this.setState({ loading: true })
        
		userService.getMyData().then(data => {
			console.log('getMyData', data)
			this.setState({
				user: data,
				loading: false
			})
		})
	}

	saveInfo = values => {
        console.log('saveInfo', values);
        const { file } = this.state
		values.photoFile = file;

		this.setState({loadingSubmit: true});

		clientsService.create(values).then(res => {
			if (res.success) {
				Actions.showSuccess({ title: 'Cliente cadastrado!' })
				this.setState({ success: true })
			} else {
				Actions.showError(res.error)
			}
			this.setState({loadingSubmit: false});
		}).catch(err => {
			Actions.showError(err.error)
			this.setState({loadingSubmit: false});
		})
	}

	handleFile = (files) => {
		if (files.length > 0) {
			this.setState({
				photoSource: URL.createObjectURL(files[0]),
				file: files[0]
			})
		} else {
			this.setState({photoSource:null, file: null})
		}
	}

	render = () => {
		const { match: { params } } = this.props;
		const { loading, loadingSubmit, user, success, photoSource } = this.state

        return (
			<div className="views">
			
				<Helmet>
	                <title>Cadastrar cliente - { texts.title }</title>
	            </Helmet>

				{
					success && 
					<Redirect to={{
					pathname: `/clientes`,
					state: { from: this.props.location }
					}}
				/>
				}

	    		<div className="units">
					<div className="content-header">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to="/">Dashboard</Link> / <Link to="/clientes">Clientes</Link> / Cadastrar cliente
								</li>
							</ol>
						</nav>
					</div>

					<div className="row">
						<div className="col-lg-8">
							<h2 className="content-title">Cadastrar cliente</h2>

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}

							{
								!loading && user &&
								<div className="box">
									<div className="box-header">
										<b>Informações do cliente</b>
									</div>

									<Formik
										initialValues={{
											name: '',
											document: '',
											email: '',
											cellphone: '',
											photo: '',
											instagram: '',
											facebook: '',
											twitter: ''
										}}
										validationSchema={form}
										onSubmit={values => this.saveInfo(values)}
										>
										{({ errors, touched, setFieldValue, isValid }) => (
											<Form>
												<div className="box-body">

													<div className="form-group row">
														<label className="form-label col-3">Nome <sup className="text-danger">*</sup></label>
														<div className="col-9">
															<Field name="name" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="name" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">CPF</label>
														<div className="col-9">
															<CpfInput
																name="document"
																onChange={e => setFieldValue('document', e.target.value)}
															/>
															<small className="text-danger"><ErrorMessage className="text-danger" name="document" /></small>
														</div>
													</div>

													<div className="form-group row">	
														<label className="form-label col-3">E-mail</label>
														<div className="col-9">
															<Field name="email" className="form-control" />
															<small className="text-danger"><ErrorMessage className="text-danger" name="email" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Celular</label>
														<div className="col-9">
															<PhoneInput
																name="cellphone"
																className="form-control"
																onChange={e => setFieldValue('cellphone', e.target.value)}
															/>
															<small className="text-danger"><ErrorMessage className="text-danger" name="cellphone" /></small>
														</div>
													</div>

													<div className="form-group row">
														<label className="form-label col-3">Foto</label>
														<div className="col-9">
															{
																photoSource &&
																<div className="avatar avatar-lg">
																	<img src={photoSource} width="100" />
																</div>
															}
															<input type="file" name="photo" className="form-control" onChange={e => this.handleFile(e.target.files) }  />
															<small className="text-danger"><ErrorMessage className="text-danger" name="photo" /></small>
														</div>
													</div>

                                                    <div className="form-group row">
                                                        <label className="form-label col-3">Instagram</label>
                                                        <div className="col-9">
                                                            <Field name="instagram" className="form-control" />
                                                            <small className="text-danger"><ErrorMessage className="text-danger" name="instagram" /></small>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="form-label col-3">Facebook</label>
                                                        <div className="col-9">
                                                            <Field name="facebook" className="form-control" />
                                                            <small className="text-danger"><ErrorMessage className="text-danger" name="facebook" /></small>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="form-label col-3">Twitter</label>
                                                        <div className="col-9">
                                                            <Field name="twitter" className="form-control" />
                                                            <small className="text-danger"><ErrorMessage className="text-danger" name="twitter" /></small>
                                                        </div>
                                                    </div>

												</div>

												<div className="box-footer">
													{
														loadingSubmit &&
														<p className="mb-20 text-lg pull-right"><i className="i-spinner loading"></i> Carregando...</p>
													}
													{
														!loadingSubmit &&
														<button type="submit" className="btn btn-success pull-right">Cadastrar</button>
													}
												</div>
											</Form>
										)}
									</Formik>

								</div>
							}

						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { CriarCliente };