const settings = {
  apiUrl: process.env.REACT_APP_API_URL,
  googleMapsKey: process.env.REACT_APP_MAPS_API_KEY,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  // facebookAppId: "554586768360063",
  globalDelay: 1000,
  isMobile: window.innerWidth <= 769,

  gaId: process.env.REACT_APP_GA_ID,

  estados: [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO"
  ]
};

export { settings };
