import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { UpgradeButton } from '../../components/UpgradeButton'
import { unitsService } from '../../_services/units.service'

import {
    Actions
} from '../../flux';

import {
	texts,
	images
} from '../../settings/';

class Unidades extends Component {
	state = {
		units: [],
		canCreateUnits: false,
		loading: false
	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		this.setState({ loading: true })

		unitsService.myUnits().then(res => {
			if (res.length > 0) {
				this.setState({
					units: res
				})
			}
			this.verifyCanCreateUnits();
			this.setState({ loading: false })
		})
	}

	verifyCanCreateUnits = () => {
		const { units } = this.state
		let found = false;
		if (units.length > 0) {
			units.map(unit => {
				if (found == false) {
					unit.plan_period.map(period => {
						if (found == false) {
							if (period.pagarme_subscription) {
								found = true;
								this.setState({canCreateUnits: true});
							}
						}
					});

				}
			});
		}
	}

	plansStatus = (details, unity_id) => {
		return (
			<div>
				{details.map((item, i) => (
					<div key={i}>
						{
							item.plan_details && 
							<div className="btn btn-light"><i className="fa fa-star mr-5"></i> {`Plano: ${item.plan_details.name}`}</div>
						}
					</div>
				))}

				{
					details.length == 0 && 
					<UpgradeButton
						unit={unity_id}
						small
						/>
				}
			</div>
		)
	}

	myUnits = items => {
		return (
			<div className="units-list row">
				{items.map((item, index) => (
					<div className="col-md-12" key={index}>
						<div className={`box`}>
							<div className="box-header">
								<h3>{item.name}</h3>
								<div className="box-actions">
									{this.plansStatus(item.plan_period, item.public_string)}
								</div>
							</div>
							<div className="box-body">
								<p>Identificador: <span className="text-dark">#{item.public_string}</span></p>
								<p>
									Endereço: <br/>
									<span className="text-dark">{`${item.address}, ${item.street_number} - ${item.neighborhood} - ${item.city} - ${item.state}`}</span>
								</p>
							</div>
							<div className="box-footer">
								<Link to={`/unidades/${item.public_string}`} className="btn btn-outline-secondary">
									<i className="fas fa-eye"></i> Ver
								</Link>
								<Link to={`/unidade/${item.public_string}/servico/adicionar`} className="btn btn-outline-secondary">
									<i className="fa fa-plus"></i> Criar serviço
								</Link>
								<Link to={`/unidade/${item.public_string}/profissional/adicionar`} className="btn btn-outline-secondary">
									<i className="fa fa-plus"></i> Criar profissional
								</Link>
								<Link to={`/unidade/${item.public_string}/horario/adicionar/null`} className="btn btn-outline-secondary">
									<i className="fa fa-plus"></i> Criar expediente
								</Link>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}

	criarUnidade = () => {
		Actions.showError('Contrate um plano para criar mais unidades.');
	}

	render = () => {
		const { units, loading, canCreateUnits } = this.state
        return (
			<div className="views">
			
				<Helmet>
	                <title>Unidades - { texts.title }</title>
	            </Helmet>

	    		<div className="units">
					<div className="content-header">
						{/* <h1>Unidades</h1> */}
						<div className="row">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / Unidades</li>
								</ol>
							</nav>
						</div>
					</div>

					{/* <form className="form-group" method="GET" action="/units">
						<div className="input-group input-group-lg">
							<div className="input-group-icon"><i className="fas fa-search"></i></div>
							<input type="text" className="form-control" name="s" placeholder="Procurar" value="" />
						</div>
					</form> */}

					<div className="row">
						<div className="col-6">
							<h2 className="content-title">Selecione a unidade {!loading && <small>{`${units.length} resultado${(units.length>1) ? 's' : ''}`} </small>}</h2>
							{
								(!loading && !canCreateUnits) && 
								<button className="btn btn-secondary pull-right" onClick={() => this.criarUnidade()}>Cadastrar unidade</button>
							}
							{
								(!loading && canCreateUnits) && 
								<Link to="/unidades/criar" className="btn btn-secondary pull-right">Cadastrar unidade</Link>
							}
						</div>
					</div>

					<div className="row" style={{ marginTop: 20 }}>
						<div className="col-lg-11">
							{this.myUnits(units)}

							{
								loading &&
								<div className="row" style={{ height: 200, paddingTop: 80 }}>
									<div className="col-md-12 text-center">
										<p className="mb-20 text-lg"><i className="i-spinner loading"></i> Carregando...</p>
									</div>
								</div>
							}
						</div>
					</div>

				</div>
			</div>
    	);
	}
}

export { Unidades };