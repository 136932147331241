import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Switch from "react-switch";

import { Actions } from '../../flux';
import { texts } from '../../settings';
import MaskedInput from '../../components/MaskedInput/default';
import DocumentInput from '../../components/MaskedInput/document';
import { bankService, userService } from '../../_services'

class Bank extends Component {
	state = {
        user: null,
        data: null,
        enabled: false,
        exists: false,
        loaded: false,
        loadingSubmit: false,
        agencia: null,
        agencia_dv: null,
        bank_code: null,
        conta: null,
        conta_dv: null,
        document_number: null,
        legal_name: null,

	}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();

		// Local Data
		userService.getMyData().then(data => {
			this.setState({
				user: data
			})
            console.log('data', data.establishment_data)

            // Get Data after local User Data
            this.getData();
		})
    }

    getData = () => {
        const { user } = this.state;

		bankService.get().then(data => {
			this.setState({
                data: data,
                enabled: data.enabled,
                exists: (data.bank_details) ? true : false,
                loaded: true,
			})

            if (data.bank_details) {
                this.setState({
                    agencia: (data.bank_details) ? data.bank_details.agencia : null,
                    agencia_dv: (data.bank_details) ? data.bank_details.agencia_dv : null,
                    bank_code: (data.bank_details) ? data.bank_details.bank_code : null,
                    conta: (data.bank_details) ? data.bank_details.conta : null,
                    conta_dv: (data.bank_details) ? data.bank_details.conta_dv : null,
                    document_number: (data.bank_details) ? data.bank_details.document_number : null,
                    legal_name: (data.bank_details) ? data.bank_details.legal_name : null,
                })
            } else {
                this.setState({
                    document_number: (user) ? user.establishment_data.document : null,
                    legal_name: (user) ? user.establishment_data.legal_name : null,
                })
            }
		})
    }
    
    saveInfo = () => {
        const { 
            agencia, 
            agencia_dv, 
            bank_code, 
            conta, 
            conta_dv, 
            document_number, 
            legal_name
        } = this.state


        this.setState({loadingSubmit: true});

        let values = {
            agencia: agencia,
            agencia_dv: agencia_dv,
            bank_code: bank_code,
            conta: conta,
            conta_dv: conta_dv,
            document_number: document_number,
            legal_name: legal_name,
        }

        bankService.create(values).then(res => {
            if (res.success) {
                Actions.showSuccess({ title: 'Dados atualizados!' });
            } else {
                Actions.showError(res.error)
            }
            this.setState({loadingSubmit: false});
        }).catch(err => {
            Actions.showError(err.error);
            this.setState({loadingSubmit: false});
        })
    }

    changeEnabled = () => {
        this.setState({loadingSubmit: true});

        const { enabled } = this.state
        const values = {
            enabled: !enabled
        }

        bankService.update(values).then(res => {

            Actions.showSuccess({ title: 'Dados atualizados!' });
            this.getData();
            this.setState({loadingSubmit: false});

        }).catch(err => {
            Actions.showError(err.error);
            this.setState({loadingSubmit: false});
        })

    }

	render = () => {
        const { 
            agencia, 
            agencia_dv, 
            bank_code, 
            conta, 
            conta_dv, 
            document_number, 
            legal_name,
            loaded, 
            loadingSubmit,
            exists,
            enabled,
            user
        } = this.state

        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>BarberTrack PAY - { texts.title }</title>
	            </Helmet>
				
                <div className="content-header">
                    <h1>BarberTrack PAY</h1>
                </div>

				<div className="row">

                    {
                        !loaded &&
                        <div className="col-md-12 mt-20">
                            <p>Carregando ...</p>
                        </div>
                    }

                    {
                        loaded && 
                        <div className="col-md-6 mt-20">

                            {
                                exists && 
                                <div className="box">
                                    <div className="box-header">
                                        <b>Empresa recebe pagamentos online na ferramenta?</b>
                                    </div>
                                    <div className='box-body'>

                                        <p>{enabled ? 'Ativado' : 'Desativado'}</p>

                                        <label style={{display: "inline-block"}}>
                                            <Switch onChange={this.changeEnabled} checked={enabled} />
                                        </label>

                                        <br />

                                        <div className="mb-10">
                                            <div className="alert alert-warning mt-10">
                                                <h4> <i className="fe fe-alert-octagon"></i> Atenção</h4>
                                                Ao ativar os pagamentos online, você está sujeito às taxas do BarberTrack. Confira aqui
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }

                            <div className="box">
                                <div className="box-header">
                                    <b>Dados da conta bancária</b>
                                </div>

                                <div>
                                    <div className="box-body">

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Código do banco</label>
                                                <MaskedInput
                                                    disabled={exists}
                                                    value={bank_code}
                                                    mask = {[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                    onChange={e => this.setState({bank_code: e.target.value})}
                                                    guide={false}
                                                    />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Agência</label>
                                                <MaskedInput
                                                    disabled={exists}
                                                    value={agencia}
                                                    mask = {[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                    onChange={e => this.setState({agencia: e.target.value})}
                                                    guide={false}
                                                    />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Dígito</label>
                                                <MaskedInput
                                                    disabled={exists}
                                                    value={agencia_dv}
                                                    mask = {[/[0-9]/, /[0-9]/]}
                                                    onChange={e => this.setState({agencia_dv: e.target.value})}
                                                    guide={false}
                                                    />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label>Conta</label>
                                                <MaskedInput
                                                    disabled={exists}
                                                    value={conta}
                                                    mask = {[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                                    onChange={e => this.setState({conta: e.target.value})}
                                                    guide={false}
                                                    />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Dígito</label>
                                                <MaskedInput
                                                    disabled={exists}
                                                    value={conta_dv}
                                                    mask = {[/[0-9]/, /[0-9]/]}
                                                    onChange={e => this.setState({conta_dv: e.target.value})}
                                                    guide={false}
                                                    />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>CPF ou CNPJ</label>
                                                <DocumentInput
                                                    disabled={exists}
                                                    value={document_number}
                                                    onChange={e => this.setState({document_number: e.target.value})}
                                                    />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-12">
                                                <label>Nome ou Razão social</label>
                                                <input
                                                    className="form-control"
                                                    defaultValue={legal_name}
                                                    disabled={exists}
                                                    onChange={e => this.setState({legal_name: e.target.value})}
                                                    />
                                            </div>
                                        </div>

                                        
                                        
                                    </div>

                                    <div className="box-footer">
                                        {
                                            loadingSubmit &&
                                            <p className="mb-20 text-lg pull-left"><i className="i-spinner loading"></i> Carregando...</p>
                                        }

                                        {
                                            !loadingSubmit &&
                                            <button
                                                type="submit" className="btn btn-success"
                                                disabled={exists}
                                                onClick={this.saveInfo}>
                                                Salvar alterações
                                            </button>
                                        }
                                    </div>
                                </div>
    
                            </div>
    
                        </div>
                    }

				</div>
				
			</div>
    	);
	}
}

export { Bank };