import React, { Component } from 'react';
import { ReactAgenda } from 'react-agenda';
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';

import { agenda } from '../../settings';
import { numberToReal } from '../../utils/money';
import AgendaItem from '../AgendaItem';

import './ProfessionalAgenda.scss';

var AgendaParse = function(props) {
    const itemClasses = [
        'custom_agenda_item',
        (props.item.finished) ? 'custom_agenda_item--finished' : ''
    ]
    return <AgendaItem 
            className={itemClasses.join(' ')}
            item={props.item}
            handleClick={(item)=> props.edit(item)}
            />
}

class ProfessionalAgenda extends Component {
	state = {
			items: [],
			startDate: new Date(),
			now: new Date(),
			hour: new Date(),
			opening: []
	}

	componentDidMount = () => {
		// console.info('ProfessionalAgenda', this.props.unit);
		const { items, startDate, colors } = this.props
		if (items) {
			this.parseItems(items);
		}

		if (startDate) {
			this.setState({startDate:startDate})
		}

		if (colors) {
			this.setState({colors:colors})
		}
	}

	componentDidUpdate = nextProps => {
		const { items, startDate, colors, nowUpdate } = this.props
		if (items !== nextProps.items) {
			this.parseItems(items);
		}

		if (startDate !== nextProps.startDate) {
			this.setState({startDate:startDate})
		}

		if (colors !== nextProps.colors) {
			this.setState({colors:colors})
		}

		if (nowUpdate !== nextProps.nowUpdate) {
			this.setState({nowUpdate:nowUpdate})
		}
	}

	parseItems = items => {
			const { professional } = this.props
			
			const newItems = [];
			items.map(obj => {
					if (obj.professional !== null && obj.professional.id === professional.id) {
							newItems.push(obj);
					}
			})

			this.setState({items:newItems})
	}

	onHandleCellSelectionCustom = item => {
		const { professional, onHandleCellSelection } = this.props
		onHandleCellSelection(item, professional);
	}

	onHandleRangeSelectionCustom = item => {
		const { professional, onHandleRangeSelection } = this.props
		onHandleRangeSelection(item, professional);
	}

	onHandleItemEditCustom = item => {
		const { professional, onHandleItemEdit } = this.props
		onHandleItemEdit(item, professional);
	}

	render = () => {
        const {
            now,
            items,
            startDate
        } = this.state

        const {
            hideHours,
            openingHours,
            unit
        } = this.props

        let classes = [
            'professional_agenda',
            (hideHours) ? '' : '--hide_hours'
        ];
        
        // Set Opening and Closing Hours
        let todayWeek = moment(startDate).format("dddd");
        // let todayWeek = moment(now).format("dddd");

        // console.log('todayWeek', todayWeek);

        let hours = openingHours.filter(obj => obj.weekday === todayWeek);
        // let todayHours = (hours.length > 0) ? hours[0] : { hour_start: '04:00:00', hour_end: '23:00:00' }
        let todayHours = (hours.length > 0) ? hours[0] : null
        // console.log('todayHours hour_start', parseInt(todayHours.hour_start.substring(0, 2)));

        // console.log('startDate', startDate);

        return (
            <div className={classes.join(' ')}>
                {
                    hours.length > 0 &&
                    <ReactAgenda
                        startDate={startDate}
                        items={items}
                        itemColors={this.state.colors}
    
                        itemComponent={AgendaParse}
    
                        minDate={now}
                        maxDate={new Date(now.getFullYear(), now.getMonth()+3)}
                        disablePrevButton={agenda.disablePrevButton}
                        cellHeight={agenda.cellHeight}
                        locale={agenda.locale}
                        numberOfDays={agenda.numberOfDays}
                        rowsPerHour={agenda.rowsPerHour}
                        autoScale={agenda.autoScale}
                        fixedHeader={agenda.fixedHeader}
    
                        startAtTime={parseInt(todayHours.hour_start.substring(0, 2))}
                        endAtTime={parseInt(todayHours.hour_end.substring(0, 2))}
                        
                        onItemEdit={this.onHandleItemEditCustom}
                        onCellSelect={this.onHandleCellSelectionCustom}
                        onRangeSelection={this.onHandleRangeSelectionCustom}
                        />
                }

                { hours.length === 0 && <p className="ml-20 mr-20 mt-10 mb-10">Não há expediente cadastrado para {todayWeek}.</p> }

                { hours.length === 0 && <Link to={`/unidade/${unit.public_string}/horario/adicionar/${todayWeek}`} className="btn btn-rounded btn-secondary btn-sm btn btn-outline-secondary ml-20 mr-20 mt-10 mb-10"><i className="fe fe-plus"></i> Adicionar horário de atendimento</Link> }
            </div>
        )
    }
}

export default ProfessionalAgenda;