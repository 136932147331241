import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import { Actions } from '../../flux';
import { clientsService } from '../../_services/clients.service'
import { texts, images } from '../../settings/'
import { imageApi } from '../../_helpers/imageApi'

import "./Gerencial.scss";
import { getInitials } from '../../_helpers/clean-string';

class Atendimentos extends Component {
	state = {}

	componentDidMount = () => {
		Actions.showMenu();
		Actions.showHeader();
		Actions.showFooter();
	}

	render = () => {
        return (
			<div className="views container-fluid">
			
				<Helmet>
	                <title>Atendimentos - { texts.title }</title>
	            </Helmet>

				<div className="content-header">
                    <h1>Atendimentos</h1>
					<div className="row">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><Link to="/">Dashboard</Link> / <Link to="/gerencial">Gerencial</Link> / Atendimentos</li>
							</ol>
						</nav>
					</div>
				</div>
				
			</div>
    	);
	}
}

export { Atendimentos };