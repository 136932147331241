import React, { Component } from 'react';

import { browserHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import InputMask from 'react-input-mask';

import './Login.scss';

import {
    Actions
} from '../../flux';

import { PasswordRevealer } from '../../components/';

import {
	images,
	texts,
    settings
} from '../../settings/';

import { userService } from '../../_services';
import { cleanString } from '../../_helpers';

// *
// React router Example
//	https://reacttraining.com/react-router/web/example/auth-workflow
// *

class Login extends Component {

	constructor(props) {
		super(props);

        this.state = {
            width: window.innerWidth,
            
            // username: '05218424412', // Login 2
            // password: 'Natalia9484', // Login 2

            username: '',
            password: '',

            loading: false,
            error: '',
            success: '',

            capsLock: false,
        };

		this.images = images;
		this.texts = texts;
        this.globalDelay = settings.globalDelay;

        this.runTransition = this.runTransition.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearError = this.clearError.bind(this);
        this.clearSuccess = this.clearSuccess.bind(this);
        this.firstAccessBtn = this.firstAccessBtn.bind(this);
        this.checkCapsLock = this.checkCapsLock.bind(this);
    }
    
    checkCapsLock(e) {
        if (e.getModifierState("CapsLock")) {
            this.setState({ capsLock: true });
        } else {
            this.setState({ capsLock: false});
        }
    }

    runTransition() {
    	Actions.globalTransition();
    }

    clearError() {
    	this.setState({ error: '' });
    }

    clearSuccess() {
    	this.setState({ success: '' });
    }

    handleChange(e) {
        const { name, value } = e.target;
        console.log(name, value)
        this.setState({ [name]: value });
    }

    forgotPassword() {
		this.clearError();

        const { username, loading } = this.state;

        if (loading) {
        	return;
        }

        // stop here if form is invalid
        if (!(username)) {
			this.setState({ error: 'Informe o seu E-mail' });
            return;
        }

        this.setState({ loading: true });

        userService.forgotPassword(username).then(
            user => {
                console.log('forgot password success', user);
                this.setState({ success: 'Senha enviada com sucesso para o e-mail de cadastro', loading: false });
                // const { from } = this.props.location.state || { from: { pathname: "/" } };
                // this.props.history.push(from);
            },
            err =>  { 
                this.setState({ error: err.error, loading: false });
            }
        );
    }

    firstAccessBtn(e) {
        e.preventDefault();

        this.clearError();

        const { username, loading } = this.state;

        if (loading) {
            return;
        }

        // stop here if form is invalid
        if (!(username)) {
            this.setState({ error: 'Informe o seu CPF' });
            return;
        }

        const usernameFormatted = cleanString(username);
        if (usernameFormatted.length < 11) {
            this.setState({ error: 'Informe um CPF válido' }); 
            return;
        }

        userService.firstAccess(usernameFormatted)
            .then(
                data => {
                    console.log('firstAccess', data);
                    if (data && data.user) {
                        this.runTransition();

                        const link = "/primeiro-acesso/" + usernameFormatted;

                        setTimeout(function() {
                            this.props.history.push(link);
                        }.bind(this), this.globalDelay);
                    }
                },
                (err) =>  {
                    this.setState({ error: err });
                }
            );
    }

    handleSubmit(e) {
        console.log('form submitted')
		e.preventDefault();

        const { username, password, returnUrl, loading } = this.state;

        if (loading) {
        	return;
        }

        // stop here if form is invalid
        if (!(username && password)) {
			this.setState({ error: 'Informe suas credenciais' });
            return;
        }

        this.setState({ loading: true, error: '' });

        userService.login(username, password).then(
            user => {
                const { from } = this.props.location.state || { from: { pathname: "/" } };
                this.props.history.push(from);
            },
            err => {
                this.setState({ error: err.error, loading: false });
            }
        );
	}

	componentDidMount() {
		Actions.hideHeader();
        Actions.hideFooter();
        document.addEventListener("keydown", this.checkCapsLock);

		if (userService.isAuthenticated()) {
            const { from } = this.props.location.state || { from: { pathname: "/" } };
            this.props.history.push(from);
		}
	}

	componentWillUnmount() {
	}

	render() {
        const { width, loading, error, success, capsLock } = this.state;
        const isMobile = width <= 769;

        const btnText = (loading) ? 'Carregando...' : 'Entrar';

        const successLabel = (success != '') ? <div className="success_label" onClick={ this.clearSuccess }>{ success.toString() }</div> : '';

        return (
			// <div className="views">
			
			// 	<Helmet>
	        //         <title>Login - { this.texts.title }</title>
	        //     </Helmet>

	    	// 	<div className="login" ref="login">

	    	// 		<div className="login_view">
		    //         	<div className="container">
			//             	<div className="row">
			// 	            	<div className="col-6">
			// 		            	<div className="site_logo">
			// 		            		<img src={ this.images.logo } />
			// 		            	</div> 

			// 	            		<form onSubmit={this.handleSubmit}>
			// 	            			<div className="form-group">
			// 		            			<label>Preencha os campos e clique em entrar</label>
			// 	            			</div>
			// 	            			<div className="form-group">
			// 	            				<InputMask type="tel" mask="999.999.999-99" maskChar=" " placeholder="CPF" className="input-cpf" name="username" defaultValue={this.state.username} onChange={this.handleChange}  />
			// 	            			</div>
            //                             <PasswordRevealer
            //                                 name="password"
            //                                 placeholder="SENHA"
            //                                 value={this.state.password}
            //                                 handleChange={this.handleChange}
            //                                 containerClassNames="form-group"
            //                                 inputClassNames="input-password"
            //                             />
			// 	            			<div className="form-group">
            //                                 {capsLock && <span>Atenção: CapsLock ATIVO</span>}
			// 		            			<button type="button" className="forgot-link pull-right" onClick={this.forgotPassword}>ESQUECI MINHA SENHA</button>
			// 	            			</div>
			// 	            			<div className="form-group">
			// 		            			<button type="submit">{ btnText }</button>
			// 	            			</div>
			// 	            			{/* <div className="form-group">
			// 		            			<button type="button" className="btn-login-facebook">Entrar com Facebook</button>
			// 	            			</div> */}
			// 	            			<div className="form-group">
            //                                 <button type="button" className="btn-primeiro-acesso" onClick={this.firstAccessBtn}>Primeiro Acesso</button>
			// 	            			</div>
			// 	            			<div className="form-group status_space">
			// 		            			{ errorLabel }
			// 		            			{ successLabel }
			// 	            			</div>
			// 	            		</form>
			// 	            	</div>

			// 	            	<div className="col-6">
			// 	            	</div>
			//             	</div>
		    //         	</div>
	        //     	</div>

			// 	</div>
            // </div>
            <div className="wrapper" ref="login">
                <main className="content px-10">
                    <div className="row">
                        <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                            <div className="logo justify-content-center text-primary">
                                <img src={images.logo} />
                            </div>
                            <form className="box" onSubmit={this.handleSubmit}>
                                <div className="box-header justify-content-center">
                                    <h3>Login</h3>
                                </div>
                                <div className="box-body">

                                    <div className="form-group">
                                        <label htmlFor="loginEmail">Usuário</label>
                                        <input
                                            type="email" 
                                            required 
                                            name="username"
                                            className="form-control" 
                                            placeholder="Insira seu e-mail" 
                                            defaultValue={this.state.username}
                                            onChange={this.handleChange}
                                            />
                                        <span className="invalid-feedback">Enter valid email address.</span>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="loginPassword">Senha</label>
                                        <input
                                            type="password" 
                                            required 
                                            name="password"
                                            className="form-control" 
                                            placeholder="Insira sua senha" 
                                            defaultValue={this.state.password}
                                            onChange={this.handleChange}
                                            />
                                        <span className="invalid-feedback">Enter your password.</span>
                                    </div>

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button className="btn btn-primary" type="submit">{ btnText }</button>
                                                <button className="btn btn-link" onClick={() => this.forgotPassword() }>Esqueceu a senha?</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </form>	
                            
                            {
                                error != '' && 
                                <div className="alert alert-danger" onClick={() => this.clearError() }>
                                    <button type="button" data-widget="dismiss" className="alert-close"><i className="fas fa-times"></i></button>
                                    <h4>Erro</h4>
                                    { error.toString() }
                                </div>
                            }
                            
                            {
                                success != '' && 
                                <div className="alert alert-success" onClick={() => this.clearSuccess() }>
                                    <button type="button" data-widget="dismiss" className="alert-close"><i className="fas fa-times"></i></button>
                                    <h4>Sucesso</h4>
                                    { success.toString() }
                                </div>
                            }
                        </div>
                    </div>
                </main>
            </div>
    	);
	}
}

export { Login };