import React, { Component } from "react"
import { numberToReal, numberToDollar } from '../../utils/money'
import MoneyInput from '../MaskedInput/money';
import { servicesService } from '../../_services/services.service'
import { Actions } from "../../flux";

class ServicesList extends Component {
    state = {
        services: [],
        loading: false
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ services: items || [] })
    }

    editValue = service_id => {
        const { services } = this.state
        services.map(s => {
            if (s.service_id === service_id) {
                s.edit = true;
            } else {
                s.edit = false;
            }
        })
        this.setState({ services: services })
    }

    changeValue = (service_id, value) => {
        console.log('changeValue', value)
        const { services } = this.state
        services.map(s => {
            if (s.service_id === service_id) {
                s.price = value;
            }
        })
        this.setState({ services: services })
    }

    changeStatus = (service_id, value) => {
        console.log('status', service_id, value)
        const { services } = this.state
        services.map(s => {
            if (s.service_id === service_id) {
                s.status = (value == true) ? 1 : 0;
            }
        })
        this.setState({ services: services })
    }
    
    cancelEdition = () => {
        const { services } = this.state
        services.map(s => {
            s.edit = false;
        })
        this.setState({ services: services })
    }

    saveValue = service_id => {
        if (this.state.loading == true) {
            return;
        }

        this.setState({loading:true})
        const { services } = this.state
        let service = services.filter(obj => obj.service_id === service_id);
        service = service[0];

        const data = { price: service.price, status: service.status };

        servicesService.update(service_id, data).then(res => {
            // console.log('update client', res)
            this.cancelEdition();
            this.setState({loading:false})
        }).catch(err => {
            Actions.showError(err);
            this.setState({loading:false})
        })
    }

	renderList = items => {
        const { loading } = this.state
		return (
			<div className="items">
                {
                    items.length > 0 &&
				    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Serviço</th>
                                    <th>Cor</th>
                                    <th>Descrição</th>
                                    <th>Duração</th>
                                    <th>Ativo</th>
                                    <th>Valor</th>
                                    <th></th>
                                </tr>
                                {items.map(item => (
                                    <tr key={item.service_id}>
                                        <td>{item.name}</td>
                                        <td><span className="status-icon" style={{backgroundColor: item.color}}></span></td>
                                        <td>{item.description}</td>
                                        <td>{item.duration}</td>
                                        <td>
                                            {!item.edit && item.status === 1 && `Ativo`}
                                            {!item.edit && item.status === 0 && `Inativo`}
                                            {
                                                item.edit &&
                                                <input type="checkbox" defaultChecked={item.status} onChange={e => this.changeStatus(item.service_id, e.target.checked)} />
                                            }
                                        </td>
                                        <td>
                                            { !item.edit && numberToReal(item.price) }
                                            { item.edit && <MoneyInput value={item.price} onChange={e => this.changeValue(item.service_id, e.value)} /> }
                                        </td>
                                        <td>
                                            { !item.edit && <button className="btn btn-sm btn-secondary" onClick={() => this.editValue(item.service_id)}>editar</button> }
                                            { item.edit && <button className="btn btn-sm btn-success" onClick={() => this.saveValue(item.service_id)}>
                                                { loading && <i className="i-spinner loading"></i> }
                                                { !loading && <span>salvar</span> }
                                            </button> }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p className="ml-25 mt-15 text-lg">Cadastre ao menos 1 serviço.</p>
                }
			</div>
		);
	}

    render = () => {
        const { services } = this.state;
        return (
            <div className="services-list">
                {this.renderList(services)}
            </div>
        );
    }
}


export default ServicesList;