import React, { Component } from "react"
import moment from 'moment';
import { professionalsService } from "../../_services/professionals.service"
import { Actions } from "../../flux"
import { numberToReal } from "../../utils/money"

class ComissionsList extends Component {
    state = {
        quotas: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ quotas: items })
    }
    
    componentDidUpdate = () => {
        const { quotas } = this.state
        const { items } = this.props
        if (items.length !== quotas.length) {
            this.setState({ quotas: items })
        }
    }

	renderList = items => {
		return (
			<div className="items">
                {
                    items.length > 0 &&
				    <div className="table-responsive">
                        <table className="table table-hovered">
                            <tbody>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Comissão</th>
                                    <th>%</th>
                                    <th>Data</th>
                                    <th>Status</th>
                                </tr>
                                {items.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{item.the_order.schedule_data.client_data.name}</td>
                                        <td>{numberToReal(parseFloat(item.amount))}</td>
                                        <td>{item.quota}%</td>
                                        <td>{moment(item.created_at).format("MM/DD/YYYY - HH:mm")}</td>
                                        <td>{item.the_order.status_data.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum item</p>
                }
			</div>
		);
    }

    render = () => {
        const { quotas } = this.state;
        return (
            <div className="quotas-list">
                {this.renderList(quotas)}
            </div>
        );
    }
}


export default ComissionsList;