import React from "react";
import ReactTooltip from 'react-tooltip'

import { imageApi } from '../../_helpers/imageApi';
import { getInitials } from "../../_helpers/clean-string";

const Avatar = ({
  data,
  handleClick,
  size = 'sm', // lg - md - sm - xs
  bordered,
  icon
}) => {
    return (
        <div
            data-tip = {data.name}
            className = {`mr-5 avatar avatar-${size} ${(bordered) ? 'avatar-bordered' : ''} ${(data.photo !== '' && data.photo !== null) ? 'avatar-secondary' : ''}`}
            style = {{cursor:'pointer'}}
            onClick = {() => handleClick(data)}
            >
            {/* { !icon && data.photo !== '' && data.photo !== null && data.photo !== undefined && <img src={imageApi(data.photo)} /> }
            { !icon && (data.photo == '' || data.photo == null) && getInitials(data.name) } */}
            { getInitials(data.name) }
            { icon && <u className="fa fa-user"></u> }
            <ReactTooltip />
        </div>
    );
};

export default Avatar;
