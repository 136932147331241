import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { guid } from 'react-agenda';


import './InputSearchProducts.scss';
import { settings } from "../../settings/";
import { imageApi } from '../../_helpers/imageApi';
import { getInitials } from "../../_helpers/clean-string";
import { productsService } from "../../_services/products.service";
import { numberToReal } from "../../utils/money";
import { Actions } from "../../flux"


class InputSearchProducts extends Component {
    state = {
        items: []
    }

    find = value => {
        if (value.length >= 2) {
            productsService.findByName(value).then(res => {
                if (res.success) {
                    this.setState({ items: res.data })
                }
            })
            .catch(err => {
                console.log('findByName err', err)
            })
        } else {
            this.setState({ items: [] })
        }
    }

    selectItem = item => {
        const { onSelectProduct } = this.props
        if (item.quantity > 0) {
            item.guid = guid();
            item.quantitySelected = 1;
            console.log('selectItem', item)
            if (onSelectProduct) onSelectProduct(item)
            this.setState({items: []})
        } else {
            Actions.showError('Item sem estoque');
        }
    }

    renderList = items => {
		return (
            <div className="client-list">
                {items.length > 0 &&
                <ul className="list-group">
                    {items.map(item => (
                        <li className="list-group-item list-group-item-action" onClick={() => this.selectItem(item)}>
                            <div className="row">
                                <div className="col-1">
                                    {item.photo !== null && <img src={imageApi(item.photo)} height="30" />}
                                </div>
                                <div className="col-7">{item.name} {(item.description) ? `- ${item.description}` : ``} {(item.brand) ? `- ${item.brand}` : ``}</div>
                                <div className="col-2">
                                    Estoque: 
                                    <b>
                                        {item.quantity == 0 && <span className="text-danger">{item.quantity}</span>}
                                        {item.quantity > 0 && item.quantity <= 5 && <span className="text-warning">{item.quantity}</span>}
                                        {item.quantity > 5 && <span>{item.quantity}</span>}
                                    </b>
                                </div>
                                <div className="col-2 text-right">{numberToReal(item.cost)}</div>
                            </div>
                        </li>
                    ))}
                </ul>}
            </div>
		);
    }

    render = () => {
        const { items } = this.state
        return (
            <div className="input-search-products-component">
                <p>Busque pelo nome do produto:</p>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Ex: Óleo para barba"
                    onChange={e => this.find(e.target.value)} />

                {this.renderList(items)}
            </div>
        );
    }
}


export default InputSearchProducts;