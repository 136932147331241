import React, { Component } from "react";
import { CSSTransition } from 'react-transition-group';
import moment from 'moment'

import "./ModalSchedule.scss";

// Components
import ProfessionalAvatars from '../ProfessionalAvatars'
import { InputSearchClients } from '../InputSearchClients'
import Schedule from '../Schedule'
import { Dropdown } from '../Dropdown/Dropdown'
import ServicesCombo from '../ServicesCombo'
import Avatar from "../Avatar";

// Helpers
import { cookie } from "../../_helpers/cookie"
import { parseHorarios } from "../../settings/enums"
import { Actions, Dispatcher, Events } from "../../flux";
import { professionalsService } from "../../_services/professionals.service";
import { unitsService } from "../../_services/units.service";
import { servicesService } from "../../_services/services.service";
import { schedulesService } from "../../_services/schedule.service";
import { numberToReal } from "../../utils/money";

class ModalSchedule extends Component {
	constructor(props) {
        super(props);
    }

    state = {
        loading: false,
        units: [],
        professionals: [],
        services: [],

        unitSelected: null,
        professionalSelected: null,
        serviceSelected: null,
        serviceSelectedArray: null,
        clientSelected: null,
        dateSelected: new Date(),
        hourSelected: null,

        dateSetted: false,
        hourSetted: false,

        initDate: new Date()
    }

	componentWillMount() {
        Dispatcher.on(Events.MODAL_SCHEDULE_DATE_UPDATED, this.updateDate.bind(this) );
        Dispatcher.on(Events.MODAL_SCHEDULE_HOUR_UPDATED, this.updateHour.bind(this) );
    }

    componentWillUnmount() {
        Dispatcher.removeListener(Events.MODAL_SCHEDULE_DATE_UPDATED, this.updateDate );
        Dispatcher.removeListener(Events.MODAL_SCHEDULE_HOUR_UPDATED, this.updateHour );
    }
   
    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
        }
        return "";
    }

    updateDate = date => {
        this.setState({
            dateSelected: date,
            initDate: date,
            dateSetted: true
        })
    }

    updateHour = date => {
        // this.setState({
        //     dateSelected: date,
        //     initDate: date,
        //     dateSetted: true
        // })
    }

    componentDidMount = () => {
        let ddate = cookie.getItem('scheduleDate');
        let dhour = cookie.getItem('scheduleHour');
        let unit = this.props.unit;
        let professional = this.props.professional;

        this.setState({ loading: true })

		unitsService.myUnits().then(res => {
			if (res.length > 0) {
                let newUnits = [];
                res.map(item => {
                    let tempUnit = item;
                    tempUnit.value = item.public_string;
                    tempUnit.label = item.name;
                    newUnits.push(tempUnit);
                })

                this.setState({ units: newUnits })

                if (newUnits.length === 1) {
                    this.selectUnit(newUnits[0].value)
                }

                // Se vem a unidade na prop, ele seta
                if (unit) {
                    this.selectUnit(unit.public_string);
                }

                if (professional) {
                    this.selectProfessional(professional);
                }
            }
            
			this.setState({ loading: false })
        })

        let theDate =  (ddate) ? ddate : (this.props.when) ? new Date(this.props.when) : new Date();
        // let theDate = (this.props.when) ? new Date(this.props.when) : new Date();

        this.setState({
            dateSelected: theDate,
            initDate: theDate,
            dateSetted: (ddate) ? ddate : false,
            hourSetted: (dhour) ? dhour : null,
            hourSelected: (dhour) ? dhour : null
        })
    }

    loadProfessionals = id => {
        this.setState({ loading: true })

        professionalsService.getProfessionalsByUnit(id).then(res => {
            this.setState({
                professionals: (res.length > 0) ? res : [],
                loading: false
            })
        })
        .catch(err => {
            Actions.showError(err)
            this.setState({ loading: false })
        })
    }

    loadServices = id => {
        this.setState({ loading: true })

        servicesService.getActiveServicesByUnit(id).then(res => {
            this.setState({
                services: (res.length > 0) ? res : [],
                loading: false
            })
        })
        .catch(err => {
            Actions.showError(err)
            this.setState({ loading: false })
        })
    }

    reset = () => {
        this.setState({
            professionals: [],
            services: [],
            unitSelected: null,
            professionalSelected: null,
            clientSelected: null,
            serviceSelected: null,
            serviceSelectedArray: null
        })
    }

    close = () => {
        const { onCloseModal } = this.props
        if (onCloseModal) onCloseModal()
    }

    selectUnit = selected => {
        if (selected != undefined && selected != null) {
            this.setState({ unitSelected: selected })
            this.loadProfessionals(selected)
            this.loadServices(selected)
        }
    }

    selectProfessional = data => {
        this.setState({ professionalSelected: data })
    }

    selectServices = items => {
        let services = items.filter(obj => obj.selected == true);
        let ids = services.map(obj => obj.service_id);
        // console.log('service', services, ids)
        this.setState({serviceSelectedArray:services})
        this.setState({serviceSelected:ids})
    }

    agendar = forceFinish => {
        const {
            clientSelected,
            unitSelected,
            professionalSelected,
            serviceSelected,
            dateSelected,
            hourSelected
        } = this.state

        if (!unitSelected) {
            Actions.showError('Selecione a unidade');
            return;
        }
        
        if (!forceFinish && !professionalSelected) {
            Actions.showError('Selecione o profissional');
            return;
        }

        if (!forceFinish && !clientSelected) {
            Actions.showError('Selecione o cliente');
            return;
        }

        if  (!forceFinish && (!serviceSelected || serviceSelected == '')) {
            Actions.showError('Selecione os serviços');
            return;
        }

        if (!dateSelected) {
            Actions.showError('Selecione a data do atendimento');
            return;
        }

        if (!hourSelected) {
            Actions.showError('Selecione o horário do atendimento');
            return;
        }

        const body = {
            client: (clientSelected) ? clientSelected.id : null,
            unit: unitSelected,
            professional: (professionalSelected) ? professionalSelected.id: null,
            service: serviceSelected,
            date: moment(dateSelected).format('YYYY-MM-DD'),
            hour: hourSelected
        }

        // console.log('init atendimento', body)
        schedulesService.create(body).then(res => {
            // console.log('schedulesService create res', res)
            if (res.success === true) {
                if (forceFinish) {
                    this.close();
                    Actions.showModalFinishOrder(res.data);
                } else {
                    Actions.showSuccess({title:res.message})
                    this.close();
                }
            }
        })
        .catch(err => {
            console.log('schedulesService create err', err)
            Actions.showError(err)
        })
    }

    render = () => {
        const {
            clientSelected,
            units,
            unitSelected,
            loading,
            professionals,
            professionalSelected,
            services,
            serviceSelectedArray,
            initDate,
            dateSetted,
            hourSetted
        } = this.state

        let unit = units.filter(obj => obj.public_string == unitSelected);
        unit = (unit.length > 0) ? unit[0] : null;
        const servicesTotal = (serviceSelectedArray) ? serviceSelectedArray.sum('price') : 0;
        return (
            <div className="modal-schedule box">

                <div className="box-header"> 
                    <h3>Novo atendimento</h3>
                    <div className="box-actions">
                        <button className="box-actions-item box-actions-item--circle" data-widget="dismiss" onClick={() => this.close()}><i className="fas fa-times"></i></button>
                    </div>
                </div>

                <div className="box-body bg-light">
                    <div className="form-group row">
                        <div className="col-12">
                            {
                                units.length === 0 && 
                                <label>Carregando unidades...</label>
                            }

                            {
                                (units.length === 1 && unit) && 
                                <label>Unidade: <b>{unit.name}</b></label>
                            }

                            {
                                units.length > 1 && 
                                <Dropdown
                                    label = "Unidade"
                                    defaultLabel = "Selecione a unidade"
                                    data = {units}
                                    value = {(unit) ? unit.public_string : undefined}
                                    handleChange = {e => this.selectUnit(e.target.value)}
                                    />
                            }
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-6">
                            { dateSetted && <label>Data</label> }
                            { !dateSetted && <label>Escolha uma data</label> }
                            
                            {
                                dateSetted && 
                                <div>
                                    {moment(initDate).format('DD/MM/YYYY')}<br />
                                    <small>Para alterar, selecione a data na agenda.</small>
                                </div>
                            }
                            {
                                !dateSetted && 
                                <Schedule setDate = {initDate} onSelectDates = {dates => this.setState({dateSelected:dates})} />
                            }
                        </div>
                        <div className="col-4">
                            <Dropdown
                                label = "Horário"
                                defaultLabel = "Selecione"
                                data = {(unit) ? parseHorarios(dateSetted, unit.opening_hours, true) : []}
                                value = {hourSetted}
                                handleChange = {e => {
                                    // console.log('Dropdown Changed: ', e.target.value)
                                    this.setState({
                                        hourSelected:e.target.value,
                                        hourSetted:e.target.value
                                    })}
                                } 
                                />
                        </div>
                    </div>
                </div>

                <div className="box-body">

                    <div className="form-group row">
                        <div className="col-8">
                            <label>Cliente:</label>
                            {
                                clientSelected !== null &&
                                <input type="text" className="form-control" value={`${clientSelected.name} ${(clientSelected.cellphone) ? ` ${clientSelected.cellphone}` : ''}`} disabled />
                            }

                            {   
                                clientSelected === null &&
                                <InputSearchClients onSelectClient = {client => this.setState({clientSelected: client})} />
                            }
                        </div>
                        <div className="col-4">
                            {
                                clientSelected !== null &&
                                <button className="btn btn-outline-dark btn-sm mt-30" onClick={() => this.setState({clientSelected:null})}>mudar cliente</button>
                            }
                            {/* {
                                clientSelected === null &&
                                <Link className="btn btn-outline-info btn-sm mt-30" onClick={() => this.close()} to={`/clientes/criar`}>Cliente novo</Link>
                            } */}
                        </div>
                    </div>

                    <CSSTransition
                            in={(clientSelected) ? true : false}
                            timeout={300}
                            classNames="my-node"
                            unmountOnExit
                        > 
                        <div className="form-group row">
                            <div className="col-12">
                                <label>Adicionar a agenda do profissional:</label>
                                
                                {
                                    professionalSelected === null && 
                                    <ProfessionalAvatars
                                        items = {professionals}
                                        onSelectProfessional = {data => this.selectProfessional(data)}
                                        />
                                }

                                {
                                    professionalSelected !== null && 
                                    <Avatar data = {professionalSelected} />
                                }

                                {
                                    professionalSelected !== null &&
                                    <button className="btn btn-outline-dark btn-sm mt-10" onClick={() => this.setState({professionalSelected:null})}>mudar profissional</button>
                                }

                            </div>
                        </div>
                    </CSSTransition>

                    <CSSTransition
                            in={(clientSelected && professionalSelected) ? true : false}
                            timeout={300}
                            classNames="my-node"
                            unmountOnExit
                        > 
                        <div className="form-group row">
                            <div className="col-12">
                                <label>Escolha os serviços</label>
                                <ServicesCombo
                                    items = {services}
                                    onChangeServices = {items => this.selectServices(items)}
                                    />
                            </div>
                            <div className="col-12">
                                <label>Total:</label>
                                <b>{ numberToReal(servicesTotal) }</b>
                            </div>
                        </div>
                    </CSSTransition>
                </div>

                <CSSTransition
                        in={(serviceSelectedArray && clientSelected && professionalSelected) ? true : false}
                        timeout={300}
                        classNames="my-node"
                        unmountOnExit
                    >
                    <div className="box-footer">
                        {loading && <p className="text-lg"><i className="i-spinner loading"></i> Carregando...</p>}

                        <div className="pull-right">
                            <button className="btn btn-success pull-right" onClick={() => this.agendar(false)}>Agendar <i className="fas fa-calendar ml-5"></i></button>
                            {/* <span className="mr-10">ou</span>
                            <button className="btn btn-success pull-right" onClick={() => this.agendar(true)}>Finalizar atendimento<i className="fas fa-check ml-5"></i></button> */}
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}


export default ModalSchedule;