import React from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import 'react-datepicker/dist/react-datepicker.css';
import './FilterDates.scss';

registerLocale('pt', pt);
setDefaultLocale('pt');

const FilterDates = ({ from, to, handleChangeTo, handleChangeFrom }) => {
    return (
        <div className="row">
            <div className="col-6">
                <div className="form-group form-group--inline filterDates">
                    <label>de:</label>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={from}
                        onChange={date => handleChangeFrom(date)}
                        maxDate={new Date()}
                    />
                </div>
            </div>
            <div className="col-6">
                <div className="form-group form-group--inline filterDates">
                    <label>até:</label>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={to}
                        onChange={date => handleChangeTo(date)}
                        maxDate={new Date()}
                    />
                </div>
            </div>
        </div>
    );
};

export { FilterDates };
