import React, { Component } from "react"
import { numberToReal, numberToDollar } from '../../utils/money'
import ReactTooltip from 'react-tooltip'

import './OrderProductsList.scss'
import { imageApi } from '../../_helpers/imageApi';
import { Actions } from "../../flux"

class OrderProductsList extends Component {
    state = {
        products: []
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ products: items })
    }

    addOne = id => {
        const { products } = this.state
        const index = products.map(obj => obj.guid).indexOf(id);
        let actualQuantity = products[index].quantitySelected ? products[index].quantitySelected : 1;
        let nextQuantity = actualQuantity+1;
        if (nextQuantity <= products[index].quantity) {
            products[index].quantitySelected = nextQuantity;

        } else {
            Actions.showError('Quantidade não disponível no estoque');
        }
        this.setState({products});
        this.changeItems();
    }

    removeOne = id => {
        const { products } = this.state
        const index = products.map(obj => obj.guid).indexOf(id);
        let actualQuantity = products[index].quantitySelected ? products[index].quantitySelected : 1;
        if (actualQuantity > 1) {
            products[index].quantitySelected = actualQuantity-1;
        }
        this.setState({products});
        this.changeItems();
    }

    removeItem = id => {
        const { products } = this.state
        const index = products.map(obj => obj.guid).indexOf(id);
        products.splice(index, 1)
        this.changeItems();
    }

    setGift = id => {
        const { products } = this.state
        const index = products.map(obj => obj.guid).indexOf(id);
        products[index].gift = !products[index].gift;
        this.setState({products});
        this.changeItems();
    }

    changeItems = () => {
        const { products } = this.state
        const { onChange } = this.props
        if (onChange) onChange(products)
    }

	renderList = items => {
        const { canEdit } = this.props
		return (
			<div className="items">
                {
                    items.length > 0 &&
				    <div className="table-responsive">
                        <table className="table table-stripped mt-15 mb-25">
                            <tbody>
                                <tr>
                                    <th>Item</th>
                                    <th></th>
                                    <th>Produto</th>
                                    <th>Marca</th>
                                    <th>Valor</th>
                                    <th>Cortesia</th>
                                    <th>Quantidade</th>
                                    <th></th>
                                </tr>
                                {items.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index+1}</td>
                                        <td>{item.photo !== null && <img src={imageApi(item.photo)} height="30" />}</td>
                                        <td>{item.name}</td>
                                        <td>{item.brand}</td>
                                        <td>
                                            {item.gift === true && <s>{numberToReal(item.cost)}</s>}
                                            {item.gift !== true && <span>{numberToReal(item.cost)}</span>}
                                        </td>

                                        <td>
                                            <input type="checkbox" checked={item.gift ? `checked` : ``} onClick={() => this.setGift(item.guid)} />
                                        </td>
                                        <td>
                                            {
                                                canEdit && 
                                                <div className="input-group">
                                                    <button className="badge badge-outline-secondary mt-10 mr-5" onClick={() => this.removeOne(item.guid)}>-</button>
                                                    <input type="text" value={(item.quantitySelected) ? item.quantitySelected : 1} className="form-control input-quantity" />
                                                    <button className="badge badge-outline-secondary mt-10 ml-5" onClick={() => this.addOne(item.guid)}>+</button>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <i className="fe fe-x-circle text-danger" data-tip="Remover" onClick={() => this.removeItem(item.guid)}></i>
                                            <ReactTooltip />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum produto</p>
                }
			</div>
		);
	}

    render = () => {
        const { products } = this.state;
        return (
            <div className="products-list">
                {this.renderList(products)}
            </div>
        );
    }
}


export default OrderProductsList;