import React, { Component } from 'react';
import { Link } from "react-router-dom";

import {
    Actions
} from '../../flux';


import {
    images,
	texts,
    settings
} from '../../settings';

import { userService } from '../../_services';
import { cleanString } from '../../_helpers';
import Avatar from '../../components/Avatar';
import PasswordRevealer from '../../components/PasswordRevealer';

// *
// React router Example
//	https://reacttraining.com/react-router/web/example/auth-workflow
// *

class CadastroUser extends Component {

	constructor(props) {
		super(props);

        this.state = {
            token: null, 

            password: '',
            passwordConfirm: '',

            loaded: false,
            loading: false,
            error: '',
            success: false,

            capsLock: false,

            professional: null
        };

		this.images = images;
		this.texts = texts;
        this.globalDelay = settings.globalDelay;

        this.runTransition = this.runTransition.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkCapsLock = this.checkCapsLock.bind(this);
    }
    
    checkCapsLock(e) {
        if (e.getModifierState("CapsLock")) {
            this.setState({ capsLock: true });
        } else {
            this.setState({ capsLock: false});
        }
    }

    runTransition() {
    	Actions.globalTransition();
    }

    handleChange(e) {
        const { name, value } = e.target;
        console.log(name, value)
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
		e.preventDefault();

        const { token, password, passwordConfirm, loading } = this.state;

        if (loading) {
        	return;
        }

        // stop here if form is invalid
        if (!password || !passwordConfirm) {
            Actions.showError('Informe suas credenciais');
            return;
        }

        // stop here if form is invalid
        if (password !== passwordConfirm) {
            Actions.showError('As senhas não conferem. Verifique!');
            return;
        }

        this.setState({ loading: true });

        const data = {
            token: token,
            password: password
        }

        userService.completeSignup(data).then(res => {
            Actions.showSuccess({title: 'Conta criada com sucesso.'});
            this.setState({
                loading: false,
                success: true
            })
        }).catch(err => {
            Actions.showError(err.error);
            console.log('saveComplete err', err);
            this.setState({
                loading: false
            })
        })
	}

	componentDidMount() {
        const { match: { params } } = this.props;
        
		Actions.hideHeader();
        Actions.hideFooter();
        document.addEventListener("keydown", this.checkCapsLock);
        
        this.setState({
            token: params.token,
            loaded: true
        });
	}

	componentWillUnmount() {
	}

	render() {
        const { loaded, loading, capsLock, password, passwordConfirm, success } = this.state;
        const btnText = (loading) ? 'Carregando...' : 'Completar cadastro';

        return (
            <div className="wrapper" ref="login">

                {!loaded && <div className="body-loading"><i className="i-spinner loading super-loading mb-15"></i><br />Carregando</div>}

                <div className="row text-center">
                    <img src={ images.logo } className="agenda_public__logo agenda_public__logo--default" />
                </div>

                {
                    loaded && 
                    <main className="px-10">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                                <form className="box" onSubmit={this.handleSubmit} autoComplete="none">
                                    <div className="box-header justify-content-center">
                                        <h3>Completar cadastro</h3>
                                    </div>


                                    {
                                        success &&
                                        <div className="box-body">
        
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <Link to="/login" className="btn btn-lg btn-success"><b>Fazer login</b></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    }

                                    {
                                        !success &&
                                        <div className="box-body">

                                            <PasswordRevealer 
                                                label="Sua senha"
                                                name="password"
                                                value={password}
                                                handleChange={e => this.setState({password:e.target.value})}
                                                containerClassNames="form-group form-group--inline"
                                                inputClassName="form-control"
                                            />

                                            <PasswordRevealer 
                                                label="Confirme a senha"
                                                name="password"
                                                value={passwordConfirm}
                                                handleChange={e => this.setState({passwordConfirm:e.target.value})}
                                                containerClassNames="form-group form-group--inline"
                                                inputClassName="form-control"
                                            />
        
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <button className="btn btn-lg btn-success" type="submit"><b>{ btnText }</b></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </main>
                }
            </div>
    	);
	}
}

export { CadastroUser };