import React, { Component } from "react"
import moment from 'moment';
import { professionalsService } from "../../_services/professionals.service"
import { Actions } from "../../flux"

class QuotasList extends Component {
    state = {
        quotas: [],
        addItem: false,
        percent: 0
    }
    
    componentDidMount = () => {
        const { items } = this.props
        this.setState({ quotas: items })
    }
    
    componentDidUpdate = () => {
        const { quotas } = this.state
        const { items } = this.props
        if (items.length !== quotas.length) {
            this.setState({ quotas: items })
        }
    }

	renderList = items => {
		return (
			<div className="items">
                {
                    items.length > 0 &&
				    <div className="table-responsive">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Comissão</th>
                                    <th>Tipo</th>
                                    <th>Status</th>
                                    <th>Data</th>
                                </tr>
                                {items.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{item.quota}%</td>
                                        <td>{item.type}</td>
                                        <td>
                                            {((index+1) == items.length)
                                                    ? <span className="status-icon bg-success"></span> 
                                                    : <span className="status-icon bg-secondary"></span> }

                                            {((index+1) == items.length) ? 'Ativo' : 'Cancelado' }
                                        </td>
                                        <td>{moment(item.created_at).format("MM/DD/YYYY - HH:mm")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
                
                {
                    items.length == 0 &&
                    <p>Nenhum item</p>
                }
			</div>
		);
    }
    
    confirm = () => {
        const { percent } = this.state
        const { professional } = this.props
        console.log('trying to save quota...', percent)
        if (professional) {
            professionalsService.addQuota(percent, professional.id)
                .then(res => {
                    console.log('QUOTA ADDED res', res)
                    Actions.showSuccess({title:'Cota comissional adicionada.'});
                    Actions.professionalQuotaUpdated();
                    this.reset();
                })
                .catch(err => {
                    console.log('QUOTA ADDED err', err)
                })
        }
    }

    reset = () => {
        this.setState({addItem:false, percent:0})
    }

    render = () => {
        const { quotas, addItem, percent } = this.state;
        return (
            <div className="quotas-list">
                {this.renderList(quotas)}

                {
                    addItem &&
                    <div className="card">
                        <div className="card-body">
                            <div className="row mt-10">
                                <div className="col-12">
                                    <p>
                                        {(quotas.length > 0) ? 'Alterar % da cota:' : 'Adicionar cota:'}
                                        <br />
                                        <small>Porcentagem de repasse ao profissional por serviço executado.</small>
                                    </p>
                                </div>  
                                <div className="col-5">
                                    <div className="input-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={percent}
                                            min="0"
                                            onChange={e => this.setState({percent: e.target.value})} />
                                        <div className="input-group-icon">%</div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <button className="btn btn-success" onClick = {() => this.confirm()}>Confirmar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    !addItem && 
                    <div className="row mt-10">
                        <div className="col">
                            <button className="btn btn-rounded btn-secondary btn-sm btn btn-outline-secondary" onClick={() => this.setState({addItem:true})}>
                                <i className="fe fe-plus"></i> {(quotas.length > 0) ? 'Alterar cota de comissão' : 'Adicionar cota de comissão'}
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


export default QuotasList;